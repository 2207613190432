import React from "react";
import { defineMessages, useIntl } from "react-intl";
import acknowledgements from "@etc/acknowledgements.json";
import { Link } from "@natera/navigation";

import "./faqInfoText.scss";

const messages = defineMessages({
  faqInfoTextHeaderTitle: {
    id: "faqInfoTextHeaderTitle",
    defaultMessage: "Need help?",
  },
  faqInfoTextContactUs: {
    id: "faqInfoTextContactUs",
    defaultMessage: "Contact Us",
  },
  faqInfoTextFAQ: {
    id: "faqInfoTextFAQ",
    defaultMessage: "FAQ",
  },
  faqInfoTextContactUsAndFAQ: {
    id: "faqInfoTextContactUsAndFAQ",
    defaultMessage: "{contactUs} or visit our {faq}",
  },
});

const FAQInfoText: React.FC = () => {
  const intl = useIntl();

  return (
    <div className="faq-info-text">
      <p className="faq-info-text__needs-help">
        <p>{intl.formatMessage(messages.faqInfoTextHeaderTitle)}</p>
        {intl.formatMessage(messages.faqInfoTextContactUsAndFAQ, {
          contactUs: (
            <Link
              target="_blank"
              key="privacyPolicy"
              rel="noreferrer"
              to={{ pathname: acknowledgements.links.contactUs }}
            >
              {intl.formatMessage(messages.faqInfoTextContactUs)}
            </Link>
          ),
          faq: (
            <Link
              target="_blank"
              key="privacyPolicy"
              rel="noreferrer"
              to={{ pathname: acknowledgements.links.faq }}
            >
              {intl.formatMessage(messages.faqInfoTextFAQ)}
            </Link>
          ),
        })}
      </p>
    </div>
  );
};

export default FAQInfoText;
