import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Compose } from "@natera/platform/lib/components/compose";
import {
  BloodDrawProvider,
  FetalSexProvider,
  MfaSetupProvider,
  OrderRenewalProvider,
  PasswordPolicyProvider,
  ProfileProvider,
  TestCardProvider,
  TestCardUtilsProvider,
  TestHistoryProvider,
  UserContext,
  ViewResultProvider,
} from "@app/provider";
import { PreBillContextWrapper } from "@app/preBill/preBillWrapper";
import { Header, Navigation } from "@app/components";
import { useHistory } from "react-router-dom";
import { STAGE } from "@app/service/user";

import "./main.scss";
import classNames from "classnames";
import useIsMobileDimension from "../../../hooks/useIsMobileDimension";

const Main: FC = ({ children }) => {
  const history = useHistory();
  const { uppUser, loadProfile } = useContext(UserContext);
  const isMobile = useIsMobileDimension();

  useEffect(() => {
    loadProfile();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  const isMenuVisible = useMemo(() => {
    if (uppUser?.stage) {
      return ![
        STAGE.BLANK,
        STAGE.CONFIRMATION,
        STAGE.DATE_OF_BIRTH_VERIFICATION,
        STAGE.PROFILE_SETUP,
        STAGE.MFA_SETUP,
        STAGE.COMMUNICATION_PREFERENCES,
      ].includes(uppUser.stage);
    }
  }, [uppUser]);

  useEffect(() => {
    return history.listen(() => {
      setIsMenuOpen(false);
    });
  }, [history]);

  return (
    <>
      <Header toggleMenu={toggleMenu} isBurgerVisible={isMenuVisible} />
      <main className="private-main">
        {isMenuVisible && (
          <Navigation isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        )}
        <div
          className={classNames("private-content", {
            "private-content--menu-visible": !isMobile && isMenuVisible,
          })}
        >
          <PreBillContextWrapper>
            <Compose
              components={[
                ProfileProvider,
                MfaSetupProvider,
                PasswordPolicyProvider,
                TestCardUtilsProvider,
                TestCardProvider,
                BloodDrawProvider,
                FetalSexProvider,
                ViewResultProvider,
                TestHistoryProvider,
                OrderRenewalProvider,
              ]}
            >
              {children}
            </Compose>
          </PreBillContextWrapper>
        </div>
      </main>
    </>
  );
};

export default Main;
