import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import classNames from "classnames";

import { Button, Form, Textfield } from "@natera/form";
import { ResultCodes } from "@app/service/resultCodes";
import {
  DropdownWrapper,
  FormField,
  Notification,
  PasswordField,
  PasswordNotice,
  Progress,
  usePasswordPolicy,
} from "@app/components";
import { IntlContext, ResetPasswordContext } from "@app/provider";
import { routes } from "@app/routing";
import { useQuery } from "@app/utils";

import { ErrorBody } from "@app/provider/user";

import "./setPassword.scss";

const messages = defineMessages({
  setPasswordTitle: {
    id: "setPasswordTitle",
    defaultMessage: "Reset Password",
  },
  setPasswordCondition: {
    id: "setPasswordCondition",
    defaultMessage: "Your new password cannot be the same as an old password.",
  },
  setPasswordPasswordLabel: {
    id: "setPasswordPasswordLabel",
    defaultMessage: "Create New Password",
  },
  setPasswordRetypePasswordLabel: {
    id: "setPasswordRetypePasswordLabel",
    defaultMessage: "Re-enter New Password",
  },
  setPasswordSaveNewPassword: {
    id: "setPasswordSaveNewPassword",
    defaultMessage: "Save New Password",
  },
  setPasswordDifferentPasswordError: {
    id: "setPasswordDifferentPasswordError",
    defaultMessage: "Passwords must match.",
  },
  setPasswordInvalidPassword: {
    id: "setPasswordInvalidPassword",
    defaultMessage: "Invalid password.",
  },
  setPasswordHeader: {
    id: "setPasswordHeader",
    defaultMessage: "Reset Password",
  },
  setPasswordOldMatchesNewPass: {
    id: "setPasswordOldMatchesNewPass",
    defaultMessage: "Your new password cannot be the same as an old password.",
  },
});

const SetPassword: React.FC = () => {
  const {
    setPassword,
    setPasswordIsLoading,
    setPasswordData,
    checkResetToken,
    resetTokenData,
    setPasswordError,
  } = React.useContext(ResetPasswordContext);
  const { changeLanguage } = useContext(IntlContext);

  const intl = useIntl();
  const query = useQuery();
  const history = useHistory();
  const token = query.get("token");

  const [email, setEmail] = useState("");
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [password, setPassword$] = React.useState("");
  const [secondaryPassword, setSecondaryPassword$] = React.useState("");
  const [oldPasswordError, setOldPasswordError] = useState<
    string | undefined
  >();
  const [passwordNoticeIsVisible, setPasswordNoticeIsVisible] = useState(false);
  const {
    invalidPasswordRequirement: firstInvalidPasswordRequirement,
    passwordPolicyComplexity: firstPolicyComplexity,
  } = usePasswordPolicy({ password, email });
  const {
    invalidPasswordRequirement: secondInvalidPasswordRequirement,
  } = usePasswordPolicy({ password: secondaryPassword, email });

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setPassword$(e.target.value);
  };

  const handlePasswordFieldFocus = () => {
    setPasswordNoticeIsVisible(true);
  };

  const handlePasswordFieldBlur = () => {
    setPasswordNoticeIsVisible(false);
  };

  const handleSecondaryPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setSecondaryPassword$(e.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = e.currentTarget;

    const password = formData.password.value;
    const secondaryPassword = formData.retypePassword.value;

    if (
      password !== secondaryPassword ||
      firstInvalidPasswordRequirement ||
      secondInvalidPasswordRequirement
    ) {
      setShowPasswordError(true);
    } else if (token) {
      setPassword({ token, password });
    }
  };

  React.useEffect(() => {
    setPasswordData &&
      history.replace(`${routes.signIn}?code=${ResultCodes.PASSWORD_UPDATED}`);
  }, [setPasswordData]);

  useEffect(() => {
    if (token) {
      checkResetToken(token);
    } else {
      history.replace(routes.root);
    }
  }, []);

  useEffect(() => {
    if (resetTokenData) {
      setEmail(resetTokenData.email);
      if (resetTokenData.language) {
        changeLanguage(resetTokenData.language);
      }
    }
  }, [resetTokenData]);

  useEffect(() => {
    const errorCode = (setPasswordError?.graphQLErrors?.[0]?.extensions
      ?.exception as ErrorBody)?.code;

    if (
      errorCode &&
      errorCode === ResultCodes.OLD_PASSWORD_MATCHES_NEW_PASSWORD
    ) {
      setOldPasswordError(
        intl.formatMessage(messages.setPasswordOldMatchesNewPass)
      );
    } else {
      setOldPasswordError(undefined);
    }
  }, [setPasswordError]);

  const getErrorMessage = () => {
    if (firstInvalidPasswordRequirement || secondInvalidPasswordRequirement) {
      return intl.formatMessage(messages.setPasswordInvalidPassword);
    }
    if (password !== secondaryPassword) {
      return intl.formatMessage(messages.setPasswordDifferentPasswordError);
    }
    return "";
  };

  const error = (
    <Notification
      className={classNames("set-password-error", {
        "set-password-error--visible": showPasswordError,
      })}
      role="alert"
      type="error"
    >
      <div>{getErrorMessage()}</div>
    </Notification>
  );

  return (
    <article className="set-password__container">
      <Progress className="set-password-progress" value={100} />
      <h1>{intl.formatMessage(messages.setPasswordHeader)}</h1>
      {error}
      <p>{intl.formatMessage(messages.setPasswordCondition)}</p>
      <Form
        onSubmit={handleSubmit}
        className="reset-password-form"
        onChange={() => setShowPasswordError(false)}
        buttons={
          <Button type="submit" loading={setPasswordIsLoading} raised>
            {intl.formatMessage(messages.setPasswordSaveNewPassword)}
          </Button>
        }
      >
        <FormField
          required
          label={intl.formatMessage(messages.setPasswordPasswordLabel)}
          error={oldPasswordError}
          htmlFor="password"
          withPadding
        >
          <PasswordField
            required
            id="password"
            name="password"
            outline
            placeholder={intl.formatMessage(messages.setPasswordPasswordLabel)}
            onChange={handlePasswordChange}
            onFocus={handlePasswordFieldFocus}
            onBlur={handlePasswordFieldBlur}
          />
        </FormField>

        <DropdownWrapper isOpen={passwordNoticeIsVisible}>
          <PasswordNotice passwordPolicyComplexity={firstPolicyComplexity} />
        </DropdownWrapper>

        <FormField
          required
          label={intl.formatMessage(messages.setPasswordRetypePasswordLabel)}
          htmlFor="retypePassword"
          withPadding
        >
          <Textfield
            required
            type="password"
            id="retypePassword"
            name="retypePassword"
            outline
            placeholder={intl.formatMessage(
              messages.setPasswordRetypePasswordLabel
            )}
            onChange={handleSecondaryPasswordChange}
          />
        </FormField>
      </Form>
    </article>
  );
};

export default SetPassword;
