import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { SuccessInfoBox } from "@app/preBill/components/successInfoBox";
import { AccountInfoBox } from "@app/preBill/components/accountInfoBox";
import { FAQInfoText } from "@app/preBill/components/faqInfoText";
import { UserContext } from "@app/provider";

import "./ncsPaymentConclusion.scss";

const messages = defineMessages({
  ncsPaymentConclusionTitle: {
    id: "ncsPaymentConclusionTitle",
    defaultMessage: "Thank you for your payment!",
  },
  ncsPaymentConclusionDescription: {
    id: "ncsPaymentConclusionDescription",
    defaultMessage:
      "We’ve received your payment. You’ll receive a confirmation email shortly.",
  },
});

const NcsPaymentConclusion: React.FC = () => {
  const intl = useIntl();
  const { uppUser } = useContext(UserContext);

  return (
    <>
      <StepperHeaderProgress />
      <div className="ncs-payment-conclusion">
        <SuccessInfoBox
          title={intl.formatMessage(messages.ncsPaymentConclusionTitle)}
          description={intl.formatMessage(
            messages.ncsPaymentConclusionDescription
          )}
        />
        {!uppUser && <AccountInfoBox />}
        <FAQInfoText />
      </div>
    </>
  );
};

export default NcsPaymentConclusion;
