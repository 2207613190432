import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/form";
import { routes } from "@app/routing";
import "./caPnsSupplementalDialog.scss";

const messages = defineMessages({
  caPnsSupplementalTitle: {
    id: "caPnsSupplementalTitle",
    defaultMessage: "Important information about your test results",
  },
  caPnsSupplementalDesc: {
    id: "caPnsSupplementalDesc",
    defaultMessage:
      "This report includes partial results. Your healthcare provider will contact you when they have your results regarding trisomies 21, 18, and 13, and fetal sex if they were ordered through the California Prenatal Screening Program.",
  },
  caPnsSupplementalConfirm: {
    id: "caPnsSupplementalConfirm",
    defaultMessage: "Continue to Results",
  },
});

export interface CaPnsSupplementalDialogProps {
  onCloseDialog: () => void;
  orderUid: string;
  testUid: string;
  testDocumentUid: string;
}
const CaPnsSupplementalDialog: FC<CaPnsSupplementalDialogProps> = ({
  onCloseDialog,
  orderUid,
  testUid,
  testDocumentUid,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const handleConfirm = () => {
    onCloseDialog();
    history.push(routes.testResultPdfPage(orderUid, testUid, testDocumentUid));
  };

  return (
    <Dialog
      className="ca-pns-supplemental-dialog-modal"
      data-testid="ca-pns-modal"
      onClose={handleConfirm}
      showCrossButton
      title={intl.formatMessage(messages.caPnsSupplementalTitle)}
      actions={
        <Button onClick={handleConfirm} raised>
          {intl.formatMessage(messages.caPnsSupplementalConfirm)}
        </Button>
      }
    >
      <div className="ca-pns-supplemental-dialog__container">
        <p>{intl.formatMessage(messages.caPnsSupplementalDesc)}</p>
      </div>
    </Dialog>
  );
};

export default CaPnsSupplementalDialog;
