import React, { FC, useContext, useMemo } from "react";
import R from "ramda";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/form";
import {
  DrawRequestContext,
  IntlContext,
  TestDetailsContext,
} from "@app/provider";
import { AppointmentTimeslots } from "@app/components/appointmentTimeslots";
import { AppointmentAddress } from "@app/components/appointmentAddress";
import { contactPhoneMapper } from "@app/utils/contactPhoneMapper";
import { BusinessUnits, editableMpStatuses } from "@app/provider/drawRequest";

import { showAddressSection } from "@app/utils";

import Svg from "@natera/material/lib/svg";
import Clock from "@assets/svg/icons/clock.svg";
import "./appointmentDetails.scss";
import { TestCardStatus } from "@app/provider/testData/types";

const messages = defineMessages({
  appointmentDetailsTitle: {
    id: "appointmentDetailsTitle",
    defaultMessage: "Appointment",
  },
  appointmentDetailsHeader: {
    id: "appointmentDetailsHeader",
    defaultMessage: "See appointment details below.",
  },
  appointmentDetailsScheduleADrawButton: {
    id: "appointmentDetailsScheduleADrawButton",
    defaultMessage: "Schedule a Draw",
  },
  appointmentDetailsParagraph: {
    id: "appointmentDetailsParagraph",
    defaultMessage:
      "You will get a call from the scheduling team to confirm your appointment 24-48 hours prior to your first selected time slot.",
  },
  appointmentDetailsNotesHeader: {
    id: "appointmentDetailsNotesHeader",
    defaultMessage: "Notes",
  },
  appointmentDetailsReference: {
    id: "appointmentDetailsReference",
    defaultMessage:
      "Since your order has now been assigned to a phlebotomist, please contact the Customer Experience team to make any changes to your appointment at {phone}.",
  },
  appointmentDetailsReferenceWH: {
    id: "appointmentDetailsReferenceWH",
    defaultMessage:
      "Since your order has now been assigned to a phlebotomist, please contact the Customer Experience team to make any changes to your appointment at {phone}.",
  },
  appointmentDetailsCancelAppointmentButton: {
    id: "appointmentDetailsCancelAppointmentButton",
    defaultMessage: "Cancel Appointment",
  },
  appointmentDetailsModifyAppointmentButton: {
    id: "appointmentDetailsModifyAppointmentButton",
    defaultMessage: "Change Appointment",
  },
});

type AppointmentDetailsProps = {
  handleModifyAppointment: () => void;
  handleScheduleDraw?: () => void;
  testCardStatus?: TestCardStatus;
  hideCancelButton?: boolean;
};

const AppointmentDetails: FC<AppointmentDetailsProps> = ({
  handleModifyAppointment,
  handleScheduleDraw,
  testCardStatus,
  hideCancelButton,
}) => {
  const intl = useIntl();

  const { getTestDetails } = useContext(TestDetailsContext);
  const {
    openCancelAppointmentDialog,
    drawRequestData,
    verifiedSampleDrawData,
  } = useContext(DrawRequestContext);
  const { currentLanguage } = useContext(IntlContext);

  const testDetails = getTestDetails();
  const drawRequest = testDetails?.drawRequest || drawRequestData;
  const timeZone = drawRequest?.jobTimezone;
  const businessUnit =
    testDetails?.businessUnit ?? verifiedSampleDrawData?.businessUnit;
  const testStatus = testDetails?.status ?? testCardStatus;

  const appointmentDetailsReferenceText = useMemo(() => {
    const phone = contactPhoneMapper(businessUnit);

    switch (businessUnit) {
      case BusinessUnits.WOMENS_HEALTH:
        return intl.formatMessage(messages.appointmentDetailsReferenceWH, {
          phone,
        });
      default:
        return intl.formatMessage(messages.appointmentDetailsReference, {
          phone,
        });
    }
  }, [businessUnit, currentLanguage]);

  const isEditableStatus = R.includes(
    drawRequest?.jobStatus,
    editableMpStatuses
  );

  const showAddress = showAddressSection(drawRequest?.shippingAddress);

  return (
    <div className="appointment-details">
      <div className="appointment-details__header">
        <div>
          <Svg content={Clock} />
          {intl.formatMessage(messages.appointmentDetailsTitle)}
        </div>
        {!drawRequest && handleScheduleDraw && (
          <Button raised onClick={handleScheduleDraw}>
            {intl.formatMessage(messages.appointmentDetailsScheduleADrawButton)}
          </Button>
        )}
      </div>
      {drawRequest && (
        <>
          <div className="appointment-details__content">
            <div className="appointment-details__content__head">
              <h3 className="appointment-details__content__head-title">
                {intl.formatMessage(messages.appointmentDetailsHeader)}
              </h3>
              <p className="appointment-details__content__head-text">
                {intl.formatMessage(messages.appointmentDetailsParagraph)}
              </p>
            </div>
            <AppointmentTimeslots
              drawRequest={drawRequest}
              selectedTimeZone={timeZone}
              status={testStatus}
            />
            {showAddress && (
              <AppointmentAddress
                shippingAddress={drawRequest?.shippingAddress}
              />
            )}
          </div>
          {drawRequest?.jobStatus && isEditableStatus ? (
            <div className="appointment-details__actions">
              {!hideCancelButton && (
                <Button onClick={openCancelAppointmentDialog} outlined>
                  {intl.formatMessage(
                    messages.appointmentDetailsCancelAppointmentButton
                  )}
                </Button>
              )}
              <Button outlined onClick={handleModifyAppointment}>
                {intl.formatMessage(
                  messages.appointmentDetailsModifyAppointmentButton
                )}
              </Button>
            </div>
          ) : (
            <div className="appointment-details__reference">
              <p>{appointmentDetailsReferenceText}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AppointmentDetails;
