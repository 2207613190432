import React, { FC, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { TestCard as BaseTestCard } from "@natera/patient";
import { Utils } from "@natera/material/lib/date";
import { Button } from "@natera/form";
import classNames from "classnames";
import * as R from "ramda";

import {
  ParsedTestCard,
  PrimaryAction,
  TestCardStatus,
  TestType,
} from "@app/provider/testData/types";
import {
  ConfigContext,
  NotificationContext,
  ViewResultContext,
} from "@app/provider";
import { HEAP_EVENTS } from "@app/provider/types";
import { routes } from "@app/routing";
import { openNewWindow, snakeCaseToCapitalizedWords } from "@app/utils";
import { getStatusText } from "@app/provider/testData/utils";
import { UrlVideoType } from "@app/pages/private/simpleOrder/aboutYourTest/aboutYourTest";
import { TestCardTitle } from "@app/components/testCard/testCardComponents";
import "./style.scss";

const messages = defineMessages({
  testCardOrdered: {
    id: "testCardOrdered",
    defaultMessage: "Ordered on {date}",
  },
  testCardSampleWasReceived: {
    id: "testCardSampleWasReceived",
    defaultMessage: "On {date}",
  },
  testCardResultReceived: {
    id: "testCardResultReceived",
    defaultMessage: "Results received on {date}",
  },
  testCardCancelled: {
    id: "testCardCancelled",
    defaultMessage: "Cancelled on {date}",
  },
  testCardExpired: {
    id: "testCardExpired",
    defaultMessage: "Expired on {date}",
  },
  testCardCurrent: {
    id: "testCardCurrent",
    defaultMessage: "Current",
  },
  testCardNext: {
    id: "testCardNext",
    defaultMessage: "Next",
  },
  testCardActionSchedule: {
    id: "testCardActionSchedule",
    defaultMessage: "Schedule a Draw",
  },
  testCardActionTrack: {
    id: "testCardActionTrack",
    defaultMessage: "Track Kit Status",
  },
  testCardActionAppointment: {
    id: "testCardActionAppointment",
    defaultMessage: "Appointment Detail",
  },
  testCardActionResult: {
    id: "testCardActionResult",
    defaultMessage: "View Your Results",
  },
  testCardActionCompleteOrder: {
    id: "testCardActionCompleteOrder",
    defaultMessage: "Complete Order",
  },
  testCardViewTestDetails: {
    id: "testCardViewTestDetails",
    defaultMessage: "View Test Details",
  },
  testCardKitTrackingNotAvailable: {
    id: "testCardKitTrackingNotAvailable",
    defaultMessage:
      "We're sorry. Kit tracking information is not available at the moment.",
  },
});

const HEAP_CTA_EVENTS_TEST_TYPES = [
  TestType.RENASIGHT,
  TestType.PROSPERA,
  TestType.PANORAMA,
  TestType.HORIZON,
  TestType.EMPOWER,
  TestType.VISTARA,
];

export interface TestCardProps {
  testCard: ParsedTestCard;
  showPrimaryAction?: boolean;
  leftSideClickable?: boolean;
  isHistory?: boolean;
}

const TestCard: FC<TestCardProps> = ({
  testCard,
  showPrimaryAction = true,
  leftSideClickable,
  isHistory,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { config } = useContext(ConfigContext);
  const { addNotification } = useContext(NotificationContext);
  const { openResultPage, openTestResultDetailsPage } = useContext(
    ViewResultContext
  );

  const handleCompleteOrder = (orderUid: string) => {
    const urlVideoType = testCard.isComboOrder
      ? UrlVideoType.COMBO_ORDER
      : testCard.testType;
    history.push(routes.aboutTestPage(orderUid, urlVideoType));
  };

  const openTestDetails = (orderUid: string, testUid: string) => {
    history.push(routes.testDetailsPage(orderUid, testUid));
  };

  const handleScheduleDraw = (orderUid: string, testUid: string) => {
    history.push(routes.sampleDrawPage(orderUid, testUid));
  };

  const viewResults = () => openResultPage(testCard);

  const openTestResultDetails = () =>
    openTestResultDetailsPage(testCard, isHistory);

  const trackKitStatus = () =>
    testCard.trackingNumber
      ? openNewWindow(`${config.links.TRACKING}${testCard.trackingNumber}`)
      : addNotification({
          type: "error",
          message: intl.formatMessage(messages.testCardKitTrackingNotAvailable),
        });

  const primaryActionParams = useMemo(
    () => ({
      [PrimaryAction.SCHEDULE_A_DRAW]: {
        title: intl.formatMessage(messages.testCardActionSchedule),
        action: handleScheduleDraw,
      },
      [PrimaryAction.TRACK_KIT_STATUS]: {
        title: intl.formatMessage(messages.testCardActionTrack),
        action: trackKitStatus,
      },
      [PrimaryAction.APPOINTMENT_DETAIL]: {
        title: intl.formatMessage(messages.testCardActionAppointment),
        action: openTestDetails,
      },
      [PrimaryAction.VIEW_YOUR_RESULTS]: {
        title: intl.formatMessage(messages.testCardActionResult),
        action: viewResults,
      },
      [PrimaryAction.VIEW_RESULT_PAGE]: {
        title: intl.formatMessage(messages.testCardActionResult),
        action: openTestResultDetails,
      },
      [PrimaryAction.COMPLETE_ORDER]: {
        title: intl.formatMessage(messages.testCardActionCompleteOrder),
        action: handleCompleteOrder,
      },
      [PrimaryAction.VIEW_TEST_DETAILS]: {
        title: intl.formatMessage(messages.testCardViewTestDetails),
        action: openTestDetails,
      },
    }),
    [testCard, intl]
  );

  const renderPrimaryAction = () => {
    const primaryAction = testCard.primaryAction;
    if (!primaryAction) return null;

    const currentPrimaryAction = primaryActionParams[primaryAction];

    const action = () => {
      const allowHeapEventForTestType = R.includes(
        testCard.testType,
        HEAP_CTA_EVENTS_TEST_TYPES
      );

      if (allowHeapEventForTestType) {
        const cta =
          testCard.primaryAction === PrimaryAction.VIEW_RESULT_PAGE
            ? PrimaryAction.VIEW_YOUR_RESULTS
            : testCard.primaryAction;

        heap.track(HEAP_EVENTS.upp_click_testcardcta, {
          test_name: snakeCaseToCapitalizedWords(testCard.testType),
          cta: snakeCaseToCapitalizedWords(cta),
          lims_clinic_id: testCard.clinic?.limsId,
          order_uid: testCard.orderUid,
        });
      }

      return currentPrimaryAction.action(testCard.orderUid, testCard.uid);
    };

    return (
      <div className="upp-test-card__btn">
        <Button unelevated onClick={action}>
          {currentPrimaryAction.title}
        </Button>
      </div>
    );
  };
  const renderDate = () => {
    if (isHistory && testCard.cancelledAt) {
      const date = Utils.getUSLocaleDate(new Date(testCard.cancelledAt));
      const message =
        testCard.status === TestCardStatus.EXPIRED_TEST
          ? messages.testCardExpired
          : messages.testCardCancelled;
      return intl.formatMessage(message, {
        date,
      });
    }
    if (isHistory && testCard.resultReleasedAt) {
      return intl.formatMessage(messages.testCardResultReceived, {
        date: Utils.getUSLocaleDate(new Date(testCard.resultReleasedAt)),
      });
    }
    return intl.formatMessage(messages.testCardOrdered, {
      date: Utils.getUSLocaleDate(new Date(testCard.createdAt)),
    });
  };

  const showSampleReceivedDate = useMemo(() => {
    return (
      [
        TestCardStatus.SAMPLE_RECEIVED,
        TestCardStatus.SAMPLES_RECEIVED,
      ].includes(testCard.status) && testCard.sampleReceivedDate
    );
  }, [testCard.status, testCard.sampleReceivedDate]);

  return (
    <div className="upp-test-card">
      <BaseTestCard
        title={
          <TestCardTitle
            testCard={testCard}
            leftSideClickable={leftSideClickable}
          />
        }
        description={testCard.description}
        bottomLine={renderDate()}
        expandable={false}
        childrenExpanded={undefined}
        data-test-uid={testCard.uid}
        data-testid="test-card"
      >
        <div className="upp-test-card__content">
          <div className="upp-test-card__sidebar">
            <div className="upp-test-card__status upp-test-card__status--visible">
              <div className="upp-test-card__label">
                {intl.formatMessage(messages.testCardCurrent)}
              </div>
              <div className={classNames("upp-test-card__value")}>
                {getStatusText(testCard.status, intl)}
              </div>
              {testCard.sampleReceivedDate && showSampleReceivedDate && (
                <div className="upp-test-card__label upp-test-card__label--received-date">
                  {intl.formatMessage(messages.testCardSampleWasReceived, {
                    date: Utils.getUSLocaleDate(
                      new Date(testCard.sampleReceivedDate)
                    ),
                  })}
                </div>
              )}
            </div>
            {showPrimaryAction && renderPrimaryAction()}
            <div
              className={classNames(
                "upp-test-card__status upp-test-card__status--next",
                {
                  "upp-test-card__status--visible": testCard.nextStatus,
                }
              )}
            >
              <div className="upp-test-card__label">
                {testCard.nextStatus &&
                  intl.formatMessage(messages.testCardNext)}
              </div>
              <div className={classNames("upp-test-card__value")}>
                {getStatusText(testCard.nextStatus, intl)}
              </div>
            </div>
          </div>
          <div className="upp-test-card__timeline" />
        </div>
      </BaseTestCard>
    </div>
  );
};

export default TestCard;
