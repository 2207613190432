import { Language } from "@app/provider";

export const getFormattedLocalizeDate = (
  date?: string,
  language: Language = Language.EN,
  short = false
): string => {
  if (!date) return "";

  const locale = language === Language.EN ? "en-US" : "es-ES";

  const options: Intl.DateTimeFormatOptions = short
    ? { timeZone: "UTC", month: "long", day: "2-digit" }
    : { timeZone: "UTC", year: "numeric", month: "long", day: "2-digit" };

  return new Date(date).toLocaleDateString(locale, options);
};
