import React, { FC } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Link } from "@natera/navigation";
import { Notification } from "@app/components";
import acknowledgements from "@etc/acknowledgements.json";

export const messages = defineMessages({
  dobValidationErrorTitle: {
    id: "dobValidationErrorTitle",
    defaultMessage: "Validation failed",
  },
  dobValidationErrorContent: {
    id: "dobValidationErrorContent",
    defaultMessage:
      "Date of Birth value does not match our records. Please try again or {contactUs} ",
  },
  dobValidationErrorContactUs: {
    id: "dobValidationErrorContactUs",
    defaultMessage: "Contact Us.",
  },
});

const DOBValidationErrorNotification: FC = () => {
  const intl = useIntl();

  return (
    <section className="title__container">
      <Notification
        titleElem={intl.formatMessage(messages.dobValidationErrorTitle)}
        type="note"
      >
        <div>
          {intl.formatMessage(messages.dobValidationErrorContent, {
            contactUs: (
              <Link
                target="_blank"
                key="privacyPolicy"
                rel="noreferrer"
                to={{ pathname: acknowledgements.links.contactUs }}
              >
                {intl.formatMessage(messages.dobValidationErrorContactUs)}
              </Link>
            ),
          })}
        </div>
      </Notification>
    </section>
  );
};

export default DOBValidationErrorNotification;
