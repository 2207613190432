import R from "ramda";
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLazyQuery } from "@apollo/client";
import TestCardService from "@app/service/testCard";
import { NotificationContext, TestCardUtilsContext } from "@app/provider";
import {
  ParsedTestCard,
  SecondaryCard,
  TestCard,
} from "@app/provider/testData/types";
import { useIntl } from "react-intl";
import { AddNotificationParameters } from "@app/provider/notification";
import { getExtendedServerErrorNotificationParams } from "@app/utils/getExtendedServerErrorNotificationParams";

export interface TestCardController {
  isLoading: boolean;
  testCards: ParsedTestCard[] | undefined;
  secondaryActionCards: SecondaryCard[];
  getTestsDataForHomePage: () => Promise<ParsedTestCard[]>;
  getPrimaryTestCards: () => Promise<ParsedTestCard[]>;
  primaryTestCardsLoading: boolean;
  isTestsHistoryExists: boolean | undefined;
}

export const Context = createContext<TestCardController>({
  isLoading: false,
  testCards: undefined,
  secondaryActionCards: [],
  getTestsDataForHomePage: async () => [],
  getPrimaryTestCards: async () => [],
  primaryTestCardsLoading: false,
  isTestsHistoryExists: undefined,
});

Context.displayName = "TestCardContext";

const TestCardProvider: FC = ({ children }) => {
  const intl = useIntl();

  const rerenderCardData = () => {
    const result = R.map(
      (testCard) => getTestCardData(testCard),
      testCardsData
    );
    setParsedTestCards(result);

    setSecondaryActionCards(
      R.map(
        (secondaryCard) => getSecondaryActionCardData(secondaryCard),
        secondaryActionCards
      )
    );
  };

  const { getTestCardData, getSecondaryActionCardData } = useContext(
    TestCardUtilsContext
  );

  const [testCardsData, setTestCardsData] = useState<TestCard[]>([]);
  const [parsedTestCards, setParsedTestCards] = useState<
    ParsedTestCard[] | undefined
  >(undefined);
  const [secondaryActionCards, setSecondaryActionCards] = useState<
    SecondaryCard[]
  >([]);
  const [isTestsHistoryExists, setIsTestsHistoryExists] = useState<
    boolean | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { addNotification } = useContext(NotificationContext);

  const [getTestsDataForHomePageQuery] = useLazyQuery(
    TestCardService.getTestsDataForHomePage(),
    {
      fetchPolicy: "no-cache",
    }
  );

  const [
    getPrimaryTestCardsQuery,
    { loading: primaryTestCardsLoading },
  ] = useLazyQuery(TestCardService.getTestCards(), {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    rerenderCardData();
  }, [intl.locale, testCardsData.length, secondaryActionCards.length]);

  const getPrimaryTestCards = async () => {
    const { data, error } = await getPrimaryTestCardsQuery();

    if (error) {
      const notificationParams: AddNotificationParameters = getExtendedServerErrorNotificationParams(
        error
      );
      addNotification(notificationParams);
      return [];
    }

    const testCards: TestCard[] = data?.getTestCards;

    return R.map((testCard) => getTestCardData(testCard), testCards);
  };

  const getTestsDataForHomePage = async () => {
    let result: ParsedTestCard[] = [];

    setIsLoading(true);
    const { data, error } = await getTestsDataForHomePageQuery();

    setIsLoading(false);

    if (error) {
      const notificationParams: AddNotificationParameters = getExtendedServerErrorNotificationParams(
        error
      );
      addNotification(notificationParams);
      return result;
    }

    const { testCards, secondaryCards, isUserHasArchivedTests } =
      data?.getTestsDataForHomePage || {};

    if (testCards) {
      result = R.map((testCard) => getTestCardData(testCard), testCards);
      setTestCardsData(testCards);
      setParsedTestCards(result);
    }

    if (secondaryCards) {
      setSecondaryActionCards(
        R.map(
          (secondaryCard) => getSecondaryActionCardData(secondaryCard),
          secondaryCards
        )
      );
    }

    if (isUserHasArchivedTests) {
      setIsTestsHistoryExists(isUserHasArchivedTests);
    }

    return result;
  };

  const testCardController: TestCardController = useMemo(
    () => ({
      isLoading,
      testCards: parsedTestCards,
      getTestsDataForHomePage,
      getPrimaryTestCards,
      primaryTestCardsLoading,
      secondaryActionCards,
      isTestsHistoryExists,
    }),
    [
      isLoading,
      parsedTestCards,
      getTestsDataForHomePage,
      getPrimaryTestCards,
      primaryTestCardsLoading,
      secondaryActionCards,
      isTestsHistoryExists,
    ]
  );

  return (
    <Context.Provider value={testCardController}>{children}</Context.Provider>
  );
};

export default TestCardProvider;
