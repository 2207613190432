export enum ResultCodes {
  // error codes
  ACTIVATION_TIMEOUT_ERROR = "ACTIVATION_TIMEOUT_ERROR",
  OKTA_ERROR = "OKTA_ERROR",
  NOT_FOUND_ERROR = "NOT_FOUND_ERROR",
  OKTA_ACCOUNT_NOT_FOUND_ERROR = "OKTA_ACCOUNT_NOT_FOUND_ERROR",
  USER_REGISTERED_VIA_GOOGLE = "USER_REGISTERED_VIA_GOOGLE",
  USER_REGISTERED_VIA_APPLE = "USER_REGISTERED_VIA_APPLE",
  ACCOUNT_ALREADY_ACTIVATED_ERROR = "ACCOUNT_ALREADY_ACTIVATED_ERROR",
  USER_CREATION_ERROR = "USER_CREATION_ERROR",
  USER_ALREADY_CREATED_ERROR = "USER_ALREADY_CREATED_ERROR",
  USER_ALREADY_HAVE_LINKED_PROFILE = "USER_ALREADY_HAVE_LINKED_PROFILE",
  PROFILE_ALREADY_LINKED_ERROR = "PROFILE_ALREADY_LINKED_ERROR",
  UNCAUGHT_ERROR = "UNCAUGHT_ERROR",
  USER_NOT_FOUND_ERROR = "USER_NOT_FOUND_ERROR",
  PROFILE_NOT_FOUND_ERROR = "PROFILE_NOT_FOUND_ERROR",
  DRAW_REQUEST_NOT_FOUND_ERROR = "DRAW_REQUEST_NOT_FOUND_ERROR",
  PROFILE_SETUP_ERROR = "PROFILE_SETUP_ERROR",
  UNAUTHORIZED_ERROR = "UNAUTHORIZED_ERROR",
  FORBIDDEN_ERROR = "FORBIDDEN_ERROR",
  INVALID_PROVIDER_TYPE = "INVALID_PROVIDER_TYPE",
  INCORRECT_ACTIVATION_CODE = "INCORRECT_ACTIVATION_CODE",
  USER_IS_LOCKED_OUT = "USER_IS_LOCKED_OUT",
  UNABLE_TO_VERIFY_IDENTITY = "UNABLE_TO_VERIFY_IDENTITY",
  FACTOR_IS_ALREADY_SETUP = "FACTOR_IS_ALREADY_SETUP",
  EMAIL_ALREADY_IN_USE = "EMAIL_ALREADY_IN_USE",
  PASSWORD_RESET_TIMEOUT_ERROR = "PASSWORD_RESET_TIMEOUT_ERROR",
  EMAIL_UPDATE_TIMEOUT_ERROR = "EMAIL_UPDATE_TIMEOUT_ERROR",
  OLD_PASSWORD_IS_NOT_CORRECT = "OLD_PASSWORD_IS_NOT_CORRECT",
  OLD_PASSWORD_MATCHES_NEW_PASSWORD = "OLD_PASSWORD_MATCHES_NEW_PASSWORD",
  PASSWORD_CANNOT_BE_YOUR_CURRENT_PASSWORD = "PASSWORD_CANNOT_BE_YOUR_CURRENT_PASSWORD",
  PASSWORD_HAS_BEEN_USED_TOO_RECENTLY = "PASSWORD_HAS_BEEN_USED_TOO_RECENTLY",
  SAMPLE_FOUND_ERROR = "SAMPLE_FOUND_ERROR",
  PHONE_VALIDATION_FAILED = "PHONE_VALIDATION_FAILED",
  TEST_NOT_FOUND = "TEST_NOT_FOUND",
  CANNOT_CREATE_DRAW_REQUEST = "CANNOT_CREATE_DRAW_REQUEST",
  INVALID_ACCESS_TOKEN = "INVALID_ACCESS_TOKEN",
  CODE_WAS_RECENTLY_SENT_ERROR = "CODE_WAS_RECENTLY_SENT",
  EXTERNAL_SERVER_ERROR = "EXTERNAL_SERVER_ERROR",

  // success codes

  ACCOUNT_SUCCESSFULLY_ACTIVATED = "ACCOUNT_SUCCESSFULLY_ACTIVATED",
  SIGN_OUT = "SIGN_OUT",
  EMAIL_VERIFIED = "EMAIL_VERIFIED",
  PASSWORD_UPDATED = "PASSWORD_UPDATED",

  // warning codes
  USER_IS_DELETED = "USER_IS_DELETED",

  // token
  TOKEN_IS_USED = "TOKEN_IS_USED",
  TOKEN_INVALID_SIGNATURE_ERROR = "TOKEN_INVALID_SIGNATURE_ERROR",
  TOKEN_EXPIRED_ERROR = "TOKEN_EXPIRED_ERROR",
  TOKEN_VERIFIED = "TOKEN_VERIFIED",
}
