import {
  ApolloClient,
  from,
  HttpLink,
  NormalizedCacheObject,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ResultCodes } from "@app/service/resultCodes";

import { ConfigServiceInterface } from "@natera/platform/lib/service/config";

import { SessionServiceInterface } from "@natera/platform/lib/service/session";
import { apolloCache } from "./apolloCache";
import { ErrorBody } from "@app/provider/user";

export default class UppClient {
  private client: ApolloClient<NormalizedCacheObject>;

  constructor(
    readonly configService: ConfigServiceInterface,
    readonly sessionService: SessionServiceInterface<{
      uid: string;
      sub: string;
    }>
  ) {}

  public getApolloClient = async (): Promise<
    ApolloClient<NormalizedCacheObject>
  > => {
    const uppServiceUrl = `/graphql`;
    const apolloServiceLink = this.getServerLink(uppServiceUrl);
    const errorLink = this.getErrorLink();

    this.client = new ApolloClient({
      cache: apolloCache,
      link: from([errorLink, apolloServiceLink]),
    });

    return this.client;
  };

  private getServerLink = (serverLink: string) => {
    const getAuthorizedFetch = async (uri: string, options: RequestInit) => {
      const token = await this.sessionService.getToken();

      const isExpired =
        !token?.expiresAt || Date.now() / 1000 - token.expiresAt > 0;

      if (!isExpired && token?.accessToken) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token.accessToken}`,
        };
      }

      return fetch(uri, options);
    };

    return new HttpLink({ uri: serverLink, fetch: getAuthorizedFetch });
  };

  private getErrorLink = () =>
    onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(async ({ extensions }) => {
          if (
            (extensions?.exception as ErrorBody)?.code ===
            ResultCodes.INVALID_ACCESS_TOKEN
          ) {
            await this.sessionService.logout();
          }
        });
      }
    });
}
