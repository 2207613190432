import React, { FC, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDialog } from "@natera/platform/lib/hooks";
import {
  OrderRenewalContext,
  RedirectionContext,
  TestCardContext,
  TestResultContext,
  UserContext,
} from "@app/provider";
import {
  SpinnerView,
  TestCard,
  SecondaryActionCard,
  FinancialAidCard,
  FlowHeader,
  BillingUpdatesCard,
} from "@app/components";
import { routes } from "@app/routing";
import { STAGE } from "@app/service/user";
import SetUpProfileDialog from "./setUpProfileDialog";
import "./home.scss";
import NoActiveTestsView from "./noActiveTestsView";
import classNames from "classnames";
import { useIsMobileDimension, useTokenizedLinks } from "@app/hooks";
import RenewalBanner from "@app/components/renewalBanner";
import {
  ParsedTestCard,
  SecondaryActionCardType,
  TestType,
} from "@app/provider/testData/types";

const Home: FC = () => {
  const history = useHistory();
  const { isLoading: isLoadingLink } = useTokenizedLinks();
  const { redirectionTrigger, handleRedirection } = useContext(
    RedirectionContext
  );

  useEffect(() => {
    if (redirectionTrigger) {
      handleRedirection();
    }
  }, [redirectionTrigger]);

  const { isLoading: isUppUserLoading, uppUser, loadProfile } = useContext(
    UserContext
  );

  const {
    isLoading: isTestCardLoading,
    getTestsDataForHomePage,
    testCards,
    secondaryActionCards,
    isTestsHistoryExists,
  } = useContext(TestCardContext);

  const {
    renewalInfo,
    getRenewalInfo,
    isLoading: isOrderRenewalLoading,
    openRenewalDialog,
  } = React.useContext(OrderRenewalContext);

  const {
    testResultInfoToken,
    getTestResultInfoFromToken,
    setTestResultInfoToken,
    isLoading: isTestResultLoading,
  } = useContext(TestResultContext);

  const setUpProfileDialog = useDialog(SetUpProfileDialog);

  const hasSignateraOrder = (testCards?: ParsedTestCard[]): boolean => {
    if (!testCards) {
      return false;
    }
    return testCards.some(
      (testCard) => testCard.testType === TestType.SIGNATERA
    );
  };

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    if (hasSignateraOrder(testCards)) {
      getRenewalInfo({});
    }
  }, [testCards]);

  useEffect(() => {
    if (
      uppUser?.patientUID &&
      !isTestCardLoading &&
      uppUser?.stage === STAGE.FILLED
    ) {
      getTestsDataForHomePage();
    }
  }, [uppUser?.patientUID]);

  useEffect(() => {
    switch (uppUser?.stage) {
      case STAGE.BLANK: {
        setUpProfileDialog.open({
          isLoading: isUppUserLoading,
          onSubmit: () => {
            if (uppUser.invite) {
              history.replace(
                routes.dateOfBirthVerification + history.location.search
              );
            } else {
              history.replace(routes.setupProfile + history.location.search);
            }
          },
        });

        break;
      }

      case STAGE.PROFILE_SETUP: {
        setUpProfileDialog.open({
          isLoading: isUppUserLoading,
          onSubmit: () => {
            history.replace(routes.setupProfile);
          },
        });

        break;
      }

      case STAGE.MFA_SETUP:
      case STAGE.COMMUNICATION_PREFERENCES: {
        if (uppUser.invite) {
          history.replace(routes.confirmation + history.location.search);
        } else {
          history.replace(routes.setupProfile);
        }
        break;
      }

      case STAGE.FILLED: {
        if (testResultInfoToken) {
          getTestResultInfoFromToken(testResultInfoToken)
            .then((info) => {
              if (info) {
                history.replace(
                  routes.testResultDetailsPage(info.orderUid, info.testUid)
                );
              }
            })
            .finally(() => {
              setTestResultInfoToken("");
            });
        }

        break;
      }
    }
  }, [uppUser, isUppUserLoading]);

  const isMobile = useIsMobileDimension();
  const renderTestCards = () => (
    <div className="home__test-cards-column">
      {renewalInfo?.hasRenewal && isMobile && (
        <RenewalBanner
          openRenewalDialog={openRenewalDialog}
          limsId={renewalInfo?.clinicLimsId}
          orderUid={renewalInfo.renewalOrderUid}
        />
      )}
      {testCards?.map((testCard) => (
        <TestCard key={testCard.uid} testCard={testCard} leftSideClickable />
      ))}
    </div>
  );

  const renderSecondaryActionCards = () => {
    return (
      <div className="home__test-cards-column">
        {renewalInfo?.hasRenewal && !isMobile && (
          <RenewalBanner
            openRenewalDialog={openRenewalDialog}
            limsId={renewalInfo?.clinicLimsId}
            orderUid={renewalInfo.renewalOrderUid}
          />
        )}
        {secondaryActionCards.map((card) =>
          card.type === SecondaryActionCardType.COMPASSIONATE_CARE ? (
            <FinancialAidCard
              key={card.type}
              limsClinicId={card.limsClinicId}
              title={card.title!}
              description={card.description!}
              note={card.note}
              showIcon
              showDialog
            />
          ) : (
            <SecondaryActionCard key={card.type} card={card} />
          )
        )}
      </div>
    );
  };

  const isLoading =
    isUppUserLoading ||
    isTestCardLoading ||
    isTestResultLoading ||
    isLoadingLink ||
    isOrderRenewalLoading;

  const noActiveTestsView =
    uppUser?.stage === STAGE.FILLED &&
    testCards &&
    testCards.length === 0 &&
    !isLoading;

  return (
    <section
      className={classNames("home__container", {
        no_active_tests_view: noActiveTestsView,
      })}
      data-testid="home-page"
    >
      <SpinnerView isLoading={isLoading} />
      {uppUser?.stage !== STAGE.FILLED && <FlowHeader />}
      {setUpProfileDialog.getDialog()}
      <BillingUpdatesCard />
      <div className="home__test-cards">
        {testCards && renderTestCards()}
        {renderSecondaryActionCards()}
      </div>
      {noActiveTestsView && (
        <NoActiveTestsView isTestsHistoryExists={isTestsHistoryExists} />
      )}
    </section>
  );
};

export default Home;
