import React, { FC, useContext, useMemo } from "react";
import { TestType } from "@app/provider/testData/types";
import { useLazyQuery } from "@apollo/client";
import HeapAnalyticDataService from "@app/service/heapAnalyticData";
import { LoadingContext } from "@natera/platform/lib/components/context";
import { useQuery } from "@app/utils";
import { UppAuthContext } from ".";
import { useParams } from "react-router-dom";
import { TestIndetifyer } from "./testData/testDetails";

export interface DrawRequestDataForHeapEventResponse {
  identity: string;
  test_name: TestType;
  job_type: string;
  order_uid: string;
  lims_clinic_id?: number;
  business_unit?: string;
  tests_ordered?: string;
}

export interface HeapAnalyticDataController {
  drawRequestDataForHeapEventData:
    | DrawRequestDataForHeapEventResponse
    | undefined;
}

export const Context = React.createContext<HeapAnalyticDataController>({
  drawRequestDataForHeapEventData: undefined,
});

Context.displayName = "HeapAnalyticDataContext";

interface HeapAnalyticDataProviderProps {
  children: React.ReactNode;
}

const HeapAnalyticDataProvider: FC<HeapAnalyticDataProviderProps> = ({
  children,
}) => {
  const query = useQuery();
  const token = query.get("token");
  const { orderUid, testUid } = useParams<TestIndetifyer>();

  const { setGuestProfileUid } = useContext(UppAuthContext);

  const [
    getDrawRequestDataForHeapEvent,
    {
      loading: drawRequestDataForHeapEventIsLoading,
      data: drawRequestDataForHeapEventData,
    },
  ] = useLazyQuery<{
    getDrawRequestDataForHeapEvent: DrawRequestDataForHeapEventResponse;
  }>(HeapAnalyticDataService.getDrawRequestDataForHeapEvent(), {
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    if (token || (orderUid && testUid)) {
      getDrawRequestDataForHeapEvent({
        variables: {
          payload: {
            token,
            orderUid,
            testUid,
          },
        },
      });
    }
  }, [orderUid, testUid, token]);

  React.useEffect(() => {
    if (drawRequestDataForHeapEventData) {
      setGuestProfileUid(
        drawRequestDataForHeapEventData.getDrawRequestDataForHeapEvent.identity
      );
    }
  }, [drawRequestDataForHeapEventData]);

  const heapAnalyticDataController: HeapAnalyticDataController = useMemo(
    () => ({
      drawRequestDataForHeapEventData:
        drawRequestDataForHeapEventData?.getDrawRequestDataForHeapEvent,
    }),
    [drawRequestDataForHeapEventData]
  );
  return (
    <Context.Provider value={heapAnalyticDataController}>
      <LoadingContext isLoading={drawRequestDataForHeapEventIsLoading}>
        {children}
      </LoadingContext>
    </Context.Provider>
  );
};

export default HeapAnalyticDataProvider;
