import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { HeapPHIDecorator } from "@app/components/analytics";
import { messages } from "../messages";
import { Textfield } from "@natera/form";
import { GetValidationError } from "@natera/platform/lib/hooks/useError";
import { ConfigContext } from "@app/provider";
import { FormField } from "@app/components";

const StreetAddressField: React.FC<{
  getValidationError: GetValidationError;
  streetAddress: string;
}> = ({ getValidationError, streetAddress }) => {
  const intl = useIntl();
  const { config } = useContext(ConfigContext);

  return (
    <FormField
      title={intl.formatMessage(messages.addressFormStreetAddress)}
      label={intl.formatMessage(messages.addressFormStreetAddress)}
      aria-label={intl.formatMessage(messages.addressFormStreetAddress)}
      error={getValidationError("streetAddress")}
      htmlFor="streetAddress"
      required
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Textfield
          id="streetAddress"
          name="streetAddress"
          data-testid="streetAddress"
          placeholder={intl.formatMessage(
            messages.addressFormStreetAddressPlaceholder
          )}
          defaultValue={streetAddress}
          maxLength={config.TEXT_FIELD_MAX_LENGTH}
          autoComplete="street-address"
          outline
          required
        />
      </HeapPHIDecorator>
    </FormField>
  );
};

export { StreetAddressField };
