import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { SuccessInfoBox } from "@app/preBill/components/successInfoBox";
import { AccountInfoBox } from "@app/preBill/components/accountInfoBox";
import { FAQInfoText } from "@app/preBill/components/faqInfoText";
import { WhatsNextSection } from "@app/preBill/components/whatNextSection";
import { SurveySection } from "@app/preBill/components/surveySection";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { UserContext } from "@app/provider";

import "./billingNextSteps.scss";

const messages = defineMessages({
  billingNextStepTitle: {
    id: "billingNextStepTitle",
    defaultMessage: "Next steps",
  },
  billingNextStepSubTitle: {
    id: "billingNextStepSubTitle",
    defaultMessage: "You’re all set!",
  },
  billingNextStepDescription: {
    id: "billingNextStepDescription",
    defaultMessage:
      "We'll continue processing with your insurance. While we do that, feel free to explore next steps.",
  },
});

const BillingNextStep: React.FC = () => {
  const intl = useIntl();
  const { uppUser } = useContext(UserContext);
  return (
    <>
      <StepperHeaderProgress />
      <div className="billing-next-step">
        <StepperContent
          title={intl.formatMessage(messages.billingNextStepTitle)}
        >
          <SuccessInfoBox
            title={intl.formatMessage(messages.billingNextStepSubTitle)}
            description={intl.formatMessage(
              messages.billingNextStepDescription
            )}
          />
          <div className="billing-next-step__divider" />
          <WhatsNextSection />
          <SurveySection />
          {!uppUser && <AccountInfoBox />}
          <FAQInfoText />
        </StepperContent>
      </div>
    </>
  );
};

export default BillingNextStep;
