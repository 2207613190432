import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/form";
import { LinkHeap } from "@app/components";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";
import { ConfigContext } from "@app/provider";
import "./timingInfoDialog.scss";

const messages = defineMessages({
  timingInfoDialogHeader: {
    id: "timingInfoDialogHeader",
    defaultMessage: "Before scheduling a draw, please consider:",
  },
  timingInfoDialogDescription: {
    id: "timingInfoDialogDescription",
    defaultMessage:
      "Blood draws should be coordinated around patient treatment in order to not impact results. Please wait:",
  },
  timingInfoDialogDescriptionPartOne: {
    id: "timingInfoDialogDescriptionPartOne",
    defaultMessage: "3 days post IV chemo",
  },
  timingInfoDialogDescriptionPartTwo: {
    id: "timingInfoDialogDescriptionPartTwo",
    defaultMessage: "24 hours post radioactive PET isotope",
  },
  timingInfoDialogDescriptionPartThree: {
    id: "timingInfoDialogDescriptionPartThree",
    defaultMessage:
      "3 weeks post Packed Red Cell transfusion or 3 months post Whole Blood Transfusion",
  },
  timingInfoDialogButtonContinue: {
    id: "timingInfoDialogButtonContinue",
    defaultMessage: "Continue",
  },
  timingInfoDialogDescriptionHelp: {
    id: "timingInfoDialogDescriptionHelp",
    defaultMessage: "Need help scheduling a draw?",
  },
  timingInfoDialogDescriptionContactUs: {
    id: "timingInfoDialogDescriptionContactUs",
    defaultMessage: "Contact Us",
  },
});

type Props = {
  onClose: () => void;
  onContinue: () => void;
};

const TimingInfoDialog: React.FC<Props> = ({ onClose, onContinue }) => {
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);

  return (
    <Dialog
      className="mobile-phlebotomy-modal oncology-dialog"
      onClose={onClose}
      showCrossButton
      title={intl.formatMessage(messages.timingInfoDialogHeader)}
      actions={
        <>
          <Button raised onClick={onContinue}>
            {intl.formatMessage(messages.timingInfoDialogButtonContinue)}
          </Button>
          <div className="guest-mobile-phlebotomy-modal__container--footer">
            <p>
              {intl.formatMessage(messages.timingInfoDialogDescriptionHelp)}{" "}
            </p>
            <p>
              <LinkHeap
                target="_blank"
                key="privacyPolicy"
                rel="noopener noreferrer"
                to={{ pathname: config.links.CONTACT_US }}
                heapEventName={HEAP_EVENTS.upp_click_contactnatera}
                heapEventProps={{
                  location: HeapEventLocation.mp_flow_initiation_page,
                }}
              >
                {intl.formatMessage(
                  messages.timingInfoDialogDescriptionContactUs
                )}
              </LinkHeap>
            </p>
          </div>
        </>
      }
    >
      <div className="guest-mobile-phlebotomy-modal__container">
        <p>{intl.formatMessage(messages.timingInfoDialogDescription)}</p>
        <ul>
          <li>
            {intl.formatMessage(messages.timingInfoDialogDescriptionPartOne)}
          </li>
          <li>
            {intl.formatMessage(messages.timingInfoDialogDescriptionPartTwo)}
          </li>
          <li>
            {intl.formatMessage(messages.timingInfoDialogDescriptionPartThree)}
          </li>
        </ul>
      </div>
    </Dialog>
  );
};

export default TimingInfoDialog;
