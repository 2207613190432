import { DocumentNode, gql } from "@apollo/client";

class TokenizedLinksService {
  public static getResourceFromToken = (): DocumentNode => gql`
    query GetResourceFromToken($token: String!) {
      getResourceFromToken(token: $token) {
        action
        data {
          ... on ActivateUser {
            email
          }
          ... on TokenizedLinkTestData {
            orderUid
            testType
            testUid
          }
          ... on ResetPasswordData {
            email
          }
          ... on UpdateEmailData {
            email
          }
        }
        sourceData {
          source
        }
      }
    }
  `;

  public static getTokenSource = (): DocumentNode => gql`
    query GetTokenSource($token: String!) {
      getTokenSource(token: $token) {
        source
        language
      }
    }
  `;

  public static getUserEmailByToken = (): DocumentNode => gql`
    query GetUserEmailByToken($token: String!) {
      getUserEmailByToken(token: $token) {
        email
      }
    }
  `;

  public static isUserExist = (): DocumentNode => gql`
    query checkUserExistByToken($token: String!) {
      checkUserExistByToken(token: $token) {
        isExist
      }
    }
  `;

  public static getOrderUidFromToken = (): DocumentNode => gql`
    query GetOrderUidFromToken($token: String!) {
      getOrderUidFromToken(token: $token) {
        action
        orderUid
        sourceData {
          source
          language
          notificationType
        }
      }
    }
  `;

  public static getGeneratedTokenizedLink = (): DocumentNode => gql`
    query GetGeneratedTokenizedLink(
      $generatedTokenizedLink: GeneratedTokenizedLink!
    ) {
      getGeneratedTokenizedLink(
        generatedTokenizedLink: $generatedTokenizedLink
      ) {
        link
      }
    }
  `;
}

export default TokenizedLinksService;
