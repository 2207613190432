import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, ReactElement, useContext, useEffect, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  ClearErrors,
  ClearValidationError,
  SetValidationError,
} from "@natera/platform/lib/hooks/useError";
import { Button, Form, Textfield } from "@natera/form";
import {
  ConfigContext,
  ErrorContext,
  NotificationContext,
} from "@app/provider";
import { FormField, PasswordField } from "@app/components";
import { useHistory, useLocation } from "react-router-dom";
import validateEmail from "../../../utils/validateEmail";

import "./loginForm.scss";

const messages = defineMessages({
  loginFormEmail: {
    id: "loginFormEmail",
    defaultMessage: "Email Address",
  },
  loginFormPassword: {
    id: "loginFormPassword",
    defaultMessage: "Password",
  },
  loginFormEmailInvalid: {
    id: "loginFormEmailInvalid",
    defaultMessage: "Please enter a valid email address. (username@domain)",
  },
  loginFormFieldEmpty: {
    id: "loginFormFieldEmpty",
    defaultMessage: "Please fill in this field",
  },
  loginFormContinueWithEmail: {
    id: "loginFormContinueWithEmail",
    defaultMessage: "Continue with Email",
  },
  loginFormEmailPlaceholder: {
    id: "loginFormEmailPlaceholder",
    defaultMessage: "Enter Your Email Address",
  },
  loginFormPasswordPlaceholder: {
    id: "loginFormPasswordPlaceholder",
    defaultMessage: "Enter Your Password",
  },
});

interface LoginFormProps {
  setValidationError: SetValidationError;
  clearValidationError: ClearValidationError;
  clearErrors: ClearErrors;
  onSubmit: (props: { email: string; password: string }) => void;
  isLoading: boolean;
  prefilledEmail?: string;
}

const LoginForm: FC<LoginFormProps> = ({
  setValidationError,
  clearValidationError,
  clearErrors,
  onSubmit,
  isLoading,
  prefilledEmail,
}) => {
  const intl = useIntl();
  const { getValidationError } = useContext(ErrorContext);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { clear } = useContext(NotificationContext);
  const { config } = React.useContext(ConfigContext);

  const usePlusSignInEmailAddress =
    config.test.usePlusSignInEmailAddress.enabled;

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    clearErrors();

    return () => {
      clearErrors();
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    clearErrors();
    if (searchParams.get("code")) {
      clear();
      history.replace(pathname);
    }

    const formData = new FormData(e.currentTarget);

    const email = formData.get("email")?.toString();
    const password = formData.get("password")?.toString();

    if (!email) {
      setValidationError(
        "email",
        intl.formatMessage(messages.loginFormFieldEmpty)
      );

      return;
    }

    if (!password) {
      setValidationError(
        "password",
        intl.formatMessage(messages.loginFormFieldEmpty)
      );

      return;
    }

    if (!validateEmail(email, usePlusSignInEmailAddress)) {
      setValidationError(
        "email",
        intl.formatMessage(messages.loginFormEmailInvalid)
      );

      return;
    }

    if (!isLoading) {
      onSubmit({ email, password });
    }
  };

  const handleEmailFocus = () => {
    clearValidationError("email");
  };

  const handlePasswordFocus = () => {
    clearValidationError("password");
  };

  const emailAddressFormFieldElement: ReactElement = (
    <FormField
      className="email-field"
      label={intl.formatMessage(messages.loginFormEmail)}
      required
      error={getValidationError("email")}
      htmlFor="email"
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Textfield
          placeholder={intl.formatMessage(messages.loginFormEmailPlaceholder)}
          outline
          id="email"
          name="email"
          required
          onFocus={handleEmailFocus}
          defaultValue={prefilledEmail}
          autoComplete="email"
        />
      </HeapPHIDecorator>
    </FormField>
  );

  const passwordFormFieldElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.loginFormPassword)}
      required
      error={getValidationError("password")}
      htmlFor="password"
      withPadding
    >
      <PasswordField
        placeholder={intl.formatMessage(messages.loginFormPasswordPlaceholder)}
        outline
        id="password"
        name="password"
        required
        onFocus={handlePasswordFocus}
        autoComplete="current-password"
      />
    </FormField>
  );

  return (
    <Form className="login-form" onSubmit={handleSubmit} noValidate>
      {emailAddressFormFieldElement}
      {passwordFormFieldElement}
      <Button type="submit" raised>
        {intl.formatMessage(messages.loginFormContinueWithEmail)}
      </Button>
    </Form>
  );
};

export default LoginForm;
