import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Form, Textfield } from "@natera/form";
import {
  ClearErrors,
  GetValidationError,
  SetValidationError,
} from "@natera/platform/lib/hooks/useError";
import { FormField } from "@app/components";
import { DrawRequestScreens } from "@app/pages/public/drawRequest/drawRequest";
import { PersonalInfoData } from "@app/pages/public/drawRequest/personalInformation/stepScreens/nameScreen";
import { messages } from "@app/components/forms/publicMpForms";

interface PublicMpPersonalInfoFormProps {
  personalInfoData?: PersonalInfoData;
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
  isLoading?: boolean;
  onSubmit: (profileData: PersonalInfoData) => void;
  setValidationError: SetValidationError;
  getValidationError: GetValidationError;
  clearErrors: ClearErrors;
}

const PublicMpNameForm: FC<PublicMpPersonalInfoFormProps> = ({
  personalInfoData,
  setScheduleScreen,
  isLoading,
  onSubmit,
  setValidationError,
  getValidationError,
  clearErrors,
}) => {
  const intl = useIntl();

  const [names, setNames] = useState({
    firstName: personalInfoData?.firstName ?? "",
    middleName: personalInfoData?.middleName ?? "",
    lastName: personalInfoData?.lastName ?? "",
    alternativeLastName: personalInfoData?.alternativeLastName ?? "",
  });

  const [profileFormData, setProfileFormData] = useState<FormData>();

  const handleNamesChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setNames((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

  const handleNamesUpdate: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setNames((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.trim(),
    }));

  const getProfileFormData = (formData: FormData) => {
    const firstName = formData.get("firstName")?.toString().trim();
    const middleName = formData.get("middleName")?.toString().trim();
    const lastName = formData.get("lastName")?.toString().trim();
    const alternativeLastName = formData
      .get("alternativeLastName")
      ?.toString()
      .trim();

    return {
      firstName,
      middleName,
      lastName,
      alternativeLastName,
    };
  };

  const validateProfileFormData = async (formData: FormData) => {
    const profileFormData = getProfileFormData(formData);

    const userForm = [
      { title: "firstName", value: profileFormData.firstName },
      { title: "lastName", value: profileFormData.lastName },
    ];

    for (const field of userForm) {
      if (!field.value) {
        return setValidationError(
          field.title,
          intl.formatMessage(messages.setupProfileFormRequired)
        );
      }
    }

    if (!profileFormData.firstName) {
      setValidationError(
        "firstName",
        intl.formatMessage(messages.setupProfileFormInvalidFirstName)
      );

      return;
    }

    if (!profileFormData.lastName) {
      setValidationError(
        "lastName",
        intl.formatMessage(messages.setupProfileFormInvalidLastName)
      );

      return;
    }

    return profileFormData;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    clearErrors();

    const formData = new FormData(e.currentTarget);

    setProfileFormData(formData);

    const validatedProfileFormData = await validateProfileFormData(formData);

    if (validatedProfileFormData) {
      onSubmit({
        firstName: validatedProfileFormData.firstName,
        middleName: validatedProfileFormData.middleName,
        lastName: validatedProfileFormData.lastName,
        alternativeLastName: validatedProfileFormData.alternativeLastName,
      });
    }
  };

  useEffect(() => {
    if (profileFormData) {
      validateProfileFormData(profileFormData);
    }
  }, [intl.locale]);

  const handleBack = () => {
    setScheduleScreen(DrawRequestScreens.WELCOME_SCREEN);
  };

  return (
    <Form
      className="public-mp-personal-information-form__container"
      noValidate
      onSubmit={handleSubmit}
      data-testid="nameForm"
    >
      <FormField
        label={intl.formatMessage(messages.setupProfileFormFirstName)}
        required
        error={getValidationError("firstName")}
        htmlFor="firstName"
        withPadding
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <Textfield
            placeholder={intl.formatMessage(
              messages.setupProfileFormFirstNamePlaceholder
            )}
            id="firstName"
            name="firstName"
            value={names.firstName}
            onChange={handleNamesChange}
            onBlur={handleNamesUpdate}
            outline
            required
            maxLength={255}
          />
        </HeapPHIDecorator>
      </FormField>
      <FormField
        label={intl.formatMessage(messages.setupProfileFormMiddleName)}
        error={getValidationError("middleName")}
        htmlFor="middleName"
        withPadding
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <Textfield
            placeholder={intl.formatMessage(
              messages.setupProfileFormMiddleNamePlaceholder
            )}
            id="middleName"
            name="middleName"
            value={names.middleName}
            onChange={handleNamesChange}
            onBlur={handleNamesUpdate}
            outline
            maxLength={255}
          />
        </HeapPHIDecorator>
      </FormField>
      <FormField
        label={intl.formatMessage(messages.setupProfileFormLastName)}
        required
        error={getValidationError("lastName")}
        htmlFor="lastName"
        withPadding
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <Textfield
            placeholder={intl.formatMessage(
              messages.setupProfileFormLastNamePlaceholder
            )}
            id="lastName"
            name="lastName"
            value={names.lastName}
            onChange={handleNamesChange}
            onBlur={handleNamesUpdate}
            outline
            required
            maxLength={255}
          />
        </HeapPHIDecorator>
      </FormField>
      <FormField
        label={intl.formatMessage(messages.setupProfileFormAlternativeLastName)}
        error={getValidationError("alternativeLastName")}
        htmlFor="alternativeLastName"
        withPadding
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <Textfield
            placeholder={intl.formatMessage(
              messages.setupProfileFormAlternativeLastNamePlaceholder
            )}
            id="alternativeLastName"
            name="alternativeLastName"
            value={names.alternativeLastName}
            onChange={handleNamesChange}
            onBlur={handleNamesUpdate}
            outline
            maxLength={255}
          />
        </HeapPHIDecorator>
      </FormField>

      <div className="stepper-form-buttons">
        <Button type="submit" raised loading={isLoading}>
          {intl.formatMessage(messages.setupProfileFormNext)}
        </Button>

        <Button onClick={handleBack} outlined loading={isLoading}>
          {intl.formatMessage(messages.publicMpPersonalInfoPageBackButton)}
        </Button>
      </div>
    </Form>
  );
};

export default PublicMpNameForm;
