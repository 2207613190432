import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/form";
import { SimpleOrderContext } from "@app/provider";

import "./patientAcknowledgmentDialog.scss";

const messages = defineMessages({
  patientAcknowledgmentDialogTitle: {
    id: "patientAcknowledgmentDialogTitle",
    defaultMessage: "Are you sure you want to cancel your test?",
  },
  patientAcknowledgmentDialogContinueCancellingButton: {
    id: "patientAcknowledgmentDialogContinueCancellingButton",
    defaultMessage: "Continue With Cancelling Test",
  },
  patientAcknowledgmentDialogBackToPatientAcknowledgment: {
    id: "patientAcknowledgmentDialogBackToPatientAcknowledgment",
    defaultMessage: "Back To Completing Test",
  },
});

const PatientAcknowledgmentDialog: FC<{
  onContinueCancelling: () => void;
  onClose: () => void;
}> = ({ onContinueCancelling, onClose }) => {
  const intl = useIntl();

  const { cancelSimpleOrderIsLoading } = React.useContext(SimpleOrderContext);

  return (
    <div className="patient-acknowledgment-dialog">
      <Dialog
        title={intl.formatMessage(messages.patientAcknowledgmentDialogTitle)}
        showCrossButton
        actions={
          <div className="patient-acknowledgment-dialog__actions">
            <Button
              raised
              onClick={onContinueCancelling}
              loading={cancelSimpleOrderIsLoading}
            >
              {intl.formatMessage(
                messages.patientAcknowledgmentDialogContinueCancellingButton
              )}
            </Button>
            <Button outlined onClick={onClose}>
              {intl.formatMessage(
                messages.patientAcknowledgmentDialogBackToPatientAcknowledgment
              )}
            </Button>
          </div>
        }
        onClose={onClose}
      >
        <></>
      </Dialog>
    </div>
  );
};

export default PatientAcknowledgmentDialog;
