import { CompanySelector } from "@app/components/companySelector";
import { Button, Form } from "@natera/form";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { HEAP_EVENTS } from "@app/provider/types";
import { getTestNameForHeap } from "@app/utils";
import { OrderContext } from "@app/provider/order";
import { Payor } from "@app/provider/payor";

const messages = defineMessages({
  selectInsuranceCompanyFormButtonNext: {
    id: "selectInsuranceCompanyFormButtonNext",
    defaultMessage: "Next",
  },
  selectInsuranceCompanyFormButtonBack: {
    id: "selectInsuranceCompanyFormButtonBack",
    defaultMessage: "Back",
  },
});

type Props = {
  selectedCompany?: Payor;
  companies: Payor[];
  onSelect: (company: Payor) => void;
  onSubmit: (company: Payor) => void;
  goBack: () => void;
  isFirstMultipleStep?: boolean;
  isSecondMultipleStep?: boolean;
};

const SelectInsuranceCompanyForm: React.FC<Props> = ({
  selectedCompany,
  companies,
  onSelect,
  onSubmit,
  goBack,
  isFirstMultipleStep,
  isSecondMultipleStep,
}) => {
  const intl = useIntl();
  const { orderData } = React.useContext(OrderContext);
  const orderUid = orderData?.uid;
  const limsId = orderData?.clinic?.limsId;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!selectedCompany) {
      return;
    }

    onSubmit(selectedCompany);
    heap.track(
      isFirstMultipleStep
        ? HEAP_EVENTS.upp_simpleorder_firstinsurance
        : isSecondMultipleStep
        ? HEAP_EVENTS.upp_simpleorder_secondinsurance
        : HEAP_EVENTS.upp_simpleorder_insurance,
      {
        tests_ordered: getTestNameForHeap(orderData),
        lims_clinic_id: limsId,
        order_uid: orderUid,
        insurance: selectedCompany.displayName,
      }
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      buttons={
        <>
          <Button raised type="submit" disabled={!selectedCompany}>
            {intl.formatMessage(messages.selectInsuranceCompanyFormButtonNext)}
          </Button>
          <Button onClick={goBack}>
            {intl.formatMessage(messages.selectInsuranceCompanyFormButtonBack)}
          </Button>
        </>
      }
    >
      <CompanySelector
        value={selectedCompany}
        onChange={onSelect}
        companies={companies}
      />
    </Form>
  );
};

export default SelectInsuranceCompanyForm;
