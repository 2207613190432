import React, { FC, ReactElement, useState } from "react";
import { ExpansionPanel } from "@natera/platform/lib/components/expansionPanel";
import { capitalizeFirstLetter } from "@app/utils";
import "./paymentForm.scss";

export interface ITest {
  id: number;
  name: string;
  description: string;
}

interface PaymentDetailsProps {
  title: string;
  tests: ITest[];
  amount: number;
}

const PaymentDetails: FC<PaymentDetailsProps> = ({ title, tests, amount }) => {
  const [isExpanded, setExpanded] = useState(true);
  const handleClick = (expanded: boolean) => {
    setExpanded(expanded);
  };

  const paymentDetailsLabel: ReactElement = (
    <div className="label">
      <div>{title}</div>
      {!isExpanded && <div>${amount}</div>}
    </div>
  );

  const paymentDetailsDescription: ReactElement = (
    <div className="description__columns">
      <div className="description">
        {tests.map((test: ITest) => (
          <div className="description__block" key={test.id}>
            <div className="description__header">
              <div className="description__header__name">
                {capitalizeFirstLetter(test.name)}
              </div>
            </div>
            <div className="description__detail">{test.description}</div>
          </div>
        ))}
      </div>
      <div className="description__amonut">{`$${amount}`}</div>
    </div>
  );

  return (
    <div className="payment-details-container">
      <ExpansionPanel
        defaultExpanded={isExpanded}
        onClick={handleClick}
        label={paymentDetailsLabel}
      >
        {paymentDetailsDescription}
      </ExpansionPanel>
    </div>
  );
};

export default PaymentDetails;
