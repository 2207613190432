import React from "react";

import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/form";
import { defineMessages, useIntl } from "react-intl";

import "./kitDialog.scss";

const messages = defineMessages({
  kitSuccessDialogHeader: {
    id: "kitSuccessDialogHeader",
    defaultMessage: "Kit is on its way!",
  },
  kitSuccessDialogDescription: {
    id: "kitSuccessDialogDescription",
    defaultMessage:
      "We’ll be sending the kit to the address you provided. Now, let’s schedule your sample draw.",
  },
  kitSuccessDialogButtonContinue: {
    id: "kitSuccessDialogButtonContinue",
    defaultMessage: "Continue",
  },
});

type Props = {
  onClose: () => void;
};

const KitSuccessDialog: React.FC<Props> = ({ onClose }) => {
  const intl = useIntl();

  return (
    <Dialog
      className="mobile-phlebotomy-modal"
      onClose={onClose}
      showCrossButton
      title={intl.formatMessage(messages.kitSuccessDialogHeader)}
      actions={
        <Button raised onClick={onClose}>
          {intl.formatMessage(messages.kitSuccessDialogButtonContinue)}
        </Button>
      }
    >
      <div className="mobile-phlebotomy-modal__container">
        <p>{intl.formatMessage(messages.kitSuccessDialogDescription)}</p>
      </div>
    </Dialog>
  );
};

export default KitSuccessDialog;
