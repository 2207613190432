import React from "react";
import { useParams } from "react-router-dom";
import R from "ramda";
import { LoadingContext } from "@natera/platform/lib/components/context";
import { defineMessages, useIntl } from "react-intl";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { TestDetailsContext, TestResultContext } from "@app/provider";
import { routes } from "@app/routing";
import { Notification, VideoPlayer } from "@app/components";
import { TestType } from "@app/provider/testData/types";
import { getHeapVideoContentType } from "@app/utils";
import { HeapVideoOrderData } from "@app/provider/types";

import "./supplementalVideo.scss";

const messages = defineMessages({
  supplementalVideoErrorText: {
    id: "supplementalVideoErrorText",
    defaultMessage: "We're sorry. Something went wrong.",
  },
  supplementalVideoThumbnailAltText: {
    id: "supplementalVideoThumbnailAltText",
    defaultMessage: "Educational video",
  },
});

const SupplementalVideo: React.FC = () => {
  const intl = useIntl();
  const { orderUid, testUid, uid } = useParams<{
    orderUid: string;
    testUid: string;
    uid: string;
  }>();
  const [url, setUrl] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");

  const {
    getSupplementalMaterials,
    getSupplementMaterialError,
    getSupplementMaterialIsLoading,
  } = React.useContext(TestResultContext);

  const { getTestDetails } = React.useContext(TestDetailsContext);

  const [heapVideoOrderData, setHeapVideoOrderData] = React.useState<
    HeapVideoOrderData
  >();

  React.useEffect(() => {
    const testDetails = getTestDetails();

    if (testDetails) {
      setHeapVideoOrderData({
        order_uid: testDetails.orderUid,
        lims_clinic_id: testDetails.clinic?.limsId,
      });
    }
  }, []);

  const { testResult } = React.useContext(TestResultContext);

  const getUrl = async () => {
    const material = await getSupplementalMaterials(orderUid, testUid, uid);

    if (material && material.url) {
      setUrl(R.last(material.url.split("/")) || "");
      setTitle(material.documentName);
    }
  };

  React.useEffect(() => {
    getUrl();
  }, []);

  const { supplementalMaterials } = React.useContext(TestResultContext);
  const [videoName, setVideoName] = React.useState<string>();
  const isHorizonOrPanorama =
    testResult?.testType === TestType.HORIZON ||
    testResult?.testType === TestType.PANORAMA;

  React.useEffect(() => {
    setVideoName(
      supplementalMaterials?.url ? supplementalMaterials.url : undefined
    );
  }, [supplementalMaterials]);

  return (
    <div className="supplemental-video-page">
      <BlueHeaderContent
        blueHeaderTitle={title}
        crossHeaderRoute={routes.testResultDetailsPage(orderUid, testUid)}
        fullScreen
      >
        <LoadingContext isLoading={getSupplementMaterialIsLoading}>
          {getSupplementMaterialError && (
            <Notification type="error" role="alert">
              {intl.formatMessage(messages.supplementalVideoErrorText)}
            </Notification>
          )}
          {url && (
            <VideoPlayer
              id={url}
              thumbnailAltText={intl.formatMessage(
                messages.supplementalVideoThumbnailAltText
              )}
              heapVideoContentType={getHeapVideoContentType(
                videoName,
                isHorizonOrPanorama
              )}
              heapVideoOrderData={heapVideoOrderData}
            />
          )}
        </LoadingContext>
      </BlueHeaderContent>
    </div>
  );
};

export default SupplementalVideo;
