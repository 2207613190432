import React, { createContext, FC, useState, ReactNode } from "react";

export interface PreBillContextProps {
  orderUid: string | null;
  patientUid: string | null;
  setOrderUid: (orderUid: string) => void;
  setPatientUid: (patientUid: string) => void;
}

export const PreBillContext = createContext<PreBillContextProps>({
  orderUid: null,
  patientUid: null,
  setOrderUid: () => undefined,
  setPatientUid: () => undefined,
});

interface PreBillProviderProps {
  children: ReactNode;
}

const PreBillProvider: FC<PreBillProviderProps> = ({ children }) => {
  const [orderUid, setOrderUid] = useState<string | null>(null);
  const [patientUid, setPatientUid] = useState<string | null>(null);

  return (
    <PreBillContext.Provider
      value={{
        orderUid,
        setOrderUid,
        patientUid,
        setPatientUid,
      }}
    >
      {children}
    </PreBillContext.Provider>
  );
};

export default PreBillProvider;
