import React from "react";
import Svg from "@natera/material/lib/svg";
import "./informationalActionCard.scss";

interface InformationalActionCardProps {
  icon: string;
  title: string;
  text: string;
  onClick?: () => void;
}

const InformationalActionCard: React.FC<InformationalActionCardProps> = ({
  icon,
  title,
  text,
  onClick,
}) => {
  return (
    <div className="informational-action-card__box" onClick={onClick}>
      <div className="informational-action-card__box-icon">
        <Svg content={icon} />
      </div>
      <div className="informational-action-card__box-content">
        <div className="informational-action-card__box-content__title">
          {title}
        </div>
        <div className="informational-action-card__box-content__text">
          {text}
        </div>
      </div>
    </div>
  );
};

export default InformationalActionCard;
