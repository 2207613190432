import {
  GetSetupIntentResponse,
  GetOrderEstimateResponse,
  ListActiveOrdersForPatient,
  GuestValidationInput,
  SendPaymentChoiceInput,
} from "@app/preBill/models";
import { useContext } from "react";
import { ConfigContext, PreBillContext, UppAuthContext } from "@app/provider";
import PreBillService from "@app/service/preBill";
import { useLazyQuery } from "@apollo/client";
import {
  PreBillErrorResponse,
  SendSurveyAnswerInput,
  SurveyAnswerResponse,
} from "../models/apiControllerModel";

enum PreBillApiRequests {
  GET = "GET",
  POST = "POST",
}

type HeadersType = {
  "Content-Type": string;
  Authorization?: string;
};

export type UsePreBillApiControllerType = {
  getSetupIntent: () => Promise<GetSetupIntentResponse>;
  getOrderEstimate: () => Promise<GetOrderEstimateResponse>;
  listActiveOrdersForPatient: () => Promise<ListActiveOrdersForPatient>;
  guestValidation: (input: GuestValidationInput) => Promise<void>;
  sendPaymentChoice: (input: SendPaymentChoiceInput) => Promise<void>;
  sendSurveyAnswer: (
    input: SendSurveyAnswerInput
  ) => Promise<SurveyAnswerResponse>;
};

const usePreBillApiController = (): UsePreBillApiControllerType => {
  const {
    config: {
      preBill: { executionPrefix, region, stageName },
    },
  } = useContext(ConfigContext);
  const { orderUid } = useContext(PreBillContext);
  const { profile } = useContext(UppAuthContext);

  const [getPreBillAccessTokenQuery] = useLazyQuery(
    PreBillService.getPreBillAccessToken(),
    {
      fetchPolicy: "no-cache",
    }
  );

  const BASE_API_URL = `https://${executionPrefix}.execute-api.${region}.amazonaws.com/${stageName}`;

  const getHeaders = async (): Promise<HeadersType> => {
    const headers: HeadersType = {
      "Content-Type": "application/json;charset=UTF-8",
    };
    const response = await getPreBillAccessTokenQuery();
    const token = response.data?.getPreBillAccessToken.accessToken;
    if (token) {
      headers.Authorization = token;
    }
    return headers;
  };

  const fetchWithHandling = async <T>(
    url: string,
    options: RequestInit
  ): Promise<T> => {
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorData = await response.json();
      throw errorData as PreBillErrorResponse;
    }

    const responseBody = await response.text();
    if (!responseBody) {
      return {} as T;
    }

    return JSON.parse(responseBody) as T;
  };

  const getSetupIntent = async (): Promise<GetSetupIntentResponse> => {
    const headers = await getHeaders();
    return fetchWithHandling<GetSetupIntentResponse>(
      `${BASE_API_URL}/orders/${orderUid}/setup-intent`,
      { method: PreBillApiRequests.GET, headers }
    );
  };

  const getOrderEstimate = async (): Promise<GetOrderEstimateResponse> => {
    const headers = await getHeaders();
    return fetchWithHandling<GetOrderEstimateResponse>(
      `${BASE_API_URL}/orders/${orderUid}/estimate`,
      { method: PreBillApiRequests.GET, headers }
    );
  };

  const listActiveOrdersForPatient = async (): Promise<
    ListActiveOrdersForPatient
  > => {
    const headers = await getHeaders();
    return fetchWithHandling<ListActiveOrdersForPatient>(
      `${BASE_API_URL}/orders/active-orders?patientId=${profile?.patientUID}`,
      { method: PreBillApiRequests.GET, headers }
    );
  };

  const guestValidation = async (
    input: GuestValidationInput
  ): Promise<void> => {
    const headers = await getHeaders();
    return fetchWithHandling<void>(
      `${BASE_API_URL}/orders/${orderUid}/guest-validation`,
      {
        method: PreBillApiRequests.POST,
        headers,
        body: JSON.stringify(input),
      }
    );
  };

  const sendPaymentChoice = async (
    input: SendPaymentChoiceInput
  ): Promise<void> => {
    const headers = await getHeaders();
    return fetchWithHandling<void>(
      `${BASE_API_URL}/orders/${orderUid}/payment-choice`,
      {
        method: PreBillApiRequests.POST,
        headers,
        body: JSON.stringify(input),
      }
    );
  };

  const sendSurveyAnswer = async (
    input: SendSurveyAnswerInput
  ): Promise<SurveyAnswerResponse> => {
    const headers = await getHeaders();
    return fetchWithHandling<SurveyAnswerResponse>(
      `${BASE_API_URL}/orders/${orderUid}/patient-survey-choice`,
      {
        method: PreBillApiRequests.POST,
        headers,
        body: JSON.stringify(input),
      }
    );
  };

  return {
    getSetupIntent,
    getOrderEstimate,
    listActiveOrdersForPatient,
    guestValidation,
    sendPaymentChoice,
    sendSurveyAnswer,
  };
};

export default usePreBillApiController;
