import React, { createContext, FC } from "react";

import { ErrorBoundary } from "@natera/platform/lib/components/context";
import { ErrorController } from "@natera/platform/lib/hooks";

export const Context = createContext<ErrorController>({
  setError: () => false,
  getError: () => undefined,
  getErrorMessage: () => undefined,
  setValidationErrors: () => false,
  setValidationErrorMap: () => false,
  setValidationError: () => false,
  getValidationError: () => undefined,
  clearValidationError: () => undefined,
  clearErrors: () => undefined,
  hasError: () => false,
  getValidationErrorAtIndex: () => () => undefined,
});

interface ErrorProviderProps {
  controller: ErrorController;
}

const ErrorProvider: FC<ErrorProviderProps> = ({ children, controller }) => (
  <Context.Provider value={controller}>
    <ErrorBoundary renderLink={() => children}>{children}</ErrorBoundary>
  </Context.Provider>
);

export default ErrorProvider;
