import React from "react";
import { Step, Stepper } from "@natera/stepper";
import { LoadingContext } from "@natera/platform/lib/components/context";
import { BillingJourneyInformation } from "../billingEstimateStepper/steps";
import { PriorAuthorizationNextSteps } from "./steps";

import "./../../main.scss";

export enum PriorAuthorizationStepsType {
  BILLING_JOURNEY_INFORMATION = "BILLING_JOURNEY_INFORMATION",
  PRIOR_AUTHORIZATION_INFORMATION = "PRIOR_AUTHORIZATION_INFORMATION",
}

const PriorAuthorizationStepper: React.FC = () => {
  return (
    <LoadingContext isLoading={false}>
      <Stepper
        initialStep={PriorAuthorizationStepsType.BILLING_JOURNEY_INFORMATION}
        progressStepper
        className="stepper"
      >
        <Step stepId={PriorAuthorizationStepsType.BILLING_JOURNEY_INFORMATION}>
          <BillingJourneyInformation />
        </Step>
        <Step
          stepId={PriorAuthorizationStepsType.PRIOR_AUTHORIZATION_INFORMATION}
        >
          <PriorAuthorizationNextSteps />
        </Step>
      </Stepper>
    </LoadingContext>
  );
};

export default PriorAuthorizationStepper;
