import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import Tooltip from "@natera/material/lib/tooltip";
import { Button, DateInput, Form, Textfield } from "@natera/form";
import {
  ClearErrors,
  GetValidationError,
  SetValidationError,
} from "@natera/platform/lib/hooks/useError";
import { usePhoneValidation } from "@natera/platform/lib/hooks";
import { FormField, PhoneField } from "@app/components";
import {
  getFormattedDOB,
  getPhoneValidationErrorMessage,
  isPatientMinor,
  validateZipCode,
  weekDayFormatter,
} from "@app/utils";
import { SetupProfileData } from "@app/provider/profile/types";
import { UserContext } from "@app/provider";

export const messages = defineMessages({
  setupProfileFormFirstName: {
    id: "setupProfileFormFirstName",
    defaultMessage: "First Name",
  },
  setupProfileFormMiddleName: {
    id: "setupProfileFormMiddleName",
    defaultMessage: "Middle Name / Initial",
  },
  setupProfileFormLastName: {
    id: "setupProfileFormLastName",
    defaultMessage: "Last Name",
  },
  setupProfileFormAlternativeLastName: {
    id: "setupProfileFormAlternativeLastName",
    defaultMessage: "Alternate Last Name (Optional)",
  },
  setupProfileFormDateOfBirth: {
    id: "setupProfileFormDateOfBirth",
    defaultMessage: "Date of Birth",
  },
  setupProfileFormMobileNumber: {
    id: "setupProfileFormMobileNumber",
    defaultMessage: "Mobile Number",
  },
  setupProfileFormZipCode: {
    id: "setupProfileFormZipCode",
    defaultMessage: "Zip Code",
  },
  setupProfileFormFirstNamePlaceholder: {
    id: "setupProfileFormFirstNamePlaceholder",
    defaultMessage: "Enter Your First Name",
  },
  setupProfileFormMiddleNamePlaceholder: {
    id: "setupProfileFormMiddleNamePlaceholder",
    defaultMessage: "Enter Your Middle Name",
  },
  setupProfileFormLastNamePlaceholder: {
    id: "setupProfileFormLastNamePlaceholder",
    defaultMessage: "Enter Your Last Name",
  },
  setupProfileFormAlternativeLastNamePlaceholder: {
    id: "setupProfileFormAlternativeLastNamePlaceholder",
    defaultMessage: "Enter Your Alternate Last Name",
  },
  setupProfileFormDateOfBirthPlaceholder: {
    id: "setupProfileFormDateOfBirthPlaceholder",
    defaultMessage: "MM/DD/YYYY",
  },
  setupProfileFormZipCodePlaceholder: {
    id: "setupProfileFormZipCodePlaceholder",
    defaultMessage: "Enter Your Zip Code",
  },
  setupProfileFormNext: {
    id: "setupProfileFormNext",
    defaultMessage: "Next",
  },
  setupProfileFormInvalidFirstName: {
    id: "setupProfileFormInvalidFirstName",
    defaultMessage: "Invalid First Name",
  },
  setupProfileFormInvalidLastName: {
    id: "setupProfileFormInvalidLastName",
    defaultMessage: "Invalid Last Name",
  },
  setupProfileFormInvalidDateOfBirth: {
    id: "setupProfileFormInvalidDateOfBirth",
    defaultMessage: "Invalid Date of Birth",
  },
  setupProfileFormUnder18DateOfBirth: {
    id: "setupProfileFormUnder18DateOfBirth",
    defaultMessage:
      "The patient portal does not currently have test information for patients under 18 years old. Please reach out to your provider, or contact support@natera.com, for information on tests for patients under 18.",
  },
  setupProfileFormInvalidPhoneNumber: {
    id: "setupProfileFormInvalidPhoneNumber",
    defaultMessage: "Please enter a valid phone number.",
  },
  setupProfileFormUSPhoneNumber: {
    id: "setupProfileFormUSPhoneNumber",
    defaultMessage: "You must enter a US based number to create an account",
  },
  setupProfileFormInvalidZipCode: {
    id: "setupProfileFormInvalidZipCode",
    defaultMessage: "Please enter a valid zip code.",
  },
  setupProfileFormZipTooltip: {
    id: "setupProfileFormZipTooltip",
    defaultMessage:
      "Required format: 5-digit number, or 9-digit number with a dash after the 5th digit XXXXX or XXXXX-XXXX",
  },
  setupProfileFormRequired: {
    id: "setupProfileFormRequired",
    defaultMessage: "This information is required.",
  },
  dateOfBirthPlaceholder: {
    id: "dateOfBirthPlaceholder",
    defaultMessage: "MM/DD/YYYY",
  },
});

interface SetupProfileFormProps {
  initialProfileData?: SetupProfileData;
  isLoading?: boolean;
  onSubmit: (profileData: SetupProfileData) => void;
  setValidationError: SetValidationError;
  getValidationError: GetValidationError;
  clearErrors: ClearErrors;
}

const SetupProfileForm: FC<SetupProfileFormProps> = ({
  initialProfileData,
  isLoading,
  onSubmit,
  setValidationError,
  getValidationError,
  clearErrors,
}) => {
  const intl = useIntl();

  const { validatePhoneNumber: validateFromPS } = React.useContext(UserContext);
  const phoneValidation = usePhoneValidation();

  const [names, setNames] = useState({
    firstName: initialProfileData?.firstName ?? "",
    middleName: initialProfileData?.middleName ?? "",
    lastName: initialProfileData?.lastName ?? "",
    alternativeLastName: initialProfileData?.alternativeLastName ?? "",
  });

  const [profileFormData, setProfileFormData] = useState<FormData>();

  const handleNamesChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setNames((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

  const handleNamesUpdate: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setNames((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value.trim(),
    }));

  const getZipCodeDefaultValue = () =>
    initialProfileData?.addresses?.length
      ? initialProfileData.addresses[0].zipCode
      : "";

  const getProfileFormData = (formData: FormData) => {
    const firstName = formData.get("firstName")?.toString().trim();
    const middleName = formData.get("middleName")?.toString().trim();
    const lastName = formData.get("lastName")?.toString().trim();
    const alternativeLastName = formData
      .get("alternativeLastName")
      ?.toString()
      .trim();
    const dateOfBirth = formData.get("dateOfBirth")?.toString();
    const mobileNumber = formData.get("mobileNumber")?.toString();
    const zipCode = formData.get("zipCode")?.toString();

    return {
      firstName,
      middleName,
      lastName,
      alternativeLastName,
      dateOfBirth,
      mobileNumber,
      zipCode,
    };
  };

  const validateProfileFormData = async (formData: FormData) => {
    const profileFormData = getProfileFormData(formData);

    const userForm = [
      { title: "firstName", value: profileFormData.firstName },
      { title: "lastName", value: profileFormData.lastName },
      { title: "dateOfBirth", value: profileFormData.dateOfBirth },
      { title: "mobileNumber", value: profileFormData.mobileNumber },
      { title: "zipCode", value: profileFormData.zipCode },
    ];

    for (const field of userForm) {
      if (!field.value) {
        return setValidationError(
          field.title,
          intl.formatMessage(messages.setupProfileFormRequired)
        );
      }
    }

    if (!profileFormData.firstName) {
      setValidationError(
        "firstName",
        intl.formatMessage(messages.setupProfileFormInvalidFirstName)
      );

      return;
    }

    if (!profileFormData.lastName) {
      setValidationError(
        "lastName",
        intl.formatMessage(messages.setupProfileFormInvalidLastName)
      );

      return;
    }

    if (!profileFormData.dateOfBirth) {
      setValidationError(
        "dateOfBirth",
        intl.formatMessage(messages.setupProfileFormInvalidDateOfBirth)
      );

      return;
    }

    if (!profileFormData.zipCode) {
      setValidationError(
        "zipCode",
        intl.formatMessage(messages.setupProfileFormRequired)
      );

      return;
    }

    const dOB = new Date(getFormattedDOB(profileFormData.dateOfBirth));

    if (isPatientMinor(dOB)) {
      setValidationError(
        "dateOfBirth",
        intl.formatMessage(messages.setupProfileFormUnder18DateOfBirth)
      );

      return;
    }

    if (profileFormData.mobileNumber) {
      const phoneValidationErrorMessage = await getPhoneValidationErrorMessage({
        isMandatory: true,
        mobileNumber: profileFormData.mobileNumber,
        intl,
        phoneValidation,
        validateFromPS,
      });

      if (phoneValidationErrorMessage) {
        setValidationError("mobileNumber", phoneValidationErrorMessage);
        return;
      }
    }

    if (profileFormData.zipCode && !validateZipCode(profileFormData.zipCode)) {
      setValidationError(
        "zipCode",
        intl.formatMessage(messages.setupProfileFormInvalidZipCode)
      );
      return;
    }

    return profileFormData;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    clearErrors();

    const formData = new FormData(e.currentTarget);

    setProfileFormData(formData);

    const validatedProfileFormData = await validateProfileFormData(formData);

    if (validatedProfileFormData) {
      onSubmit({
        firstName: validatedProfileFormData.firstName,
        middleName: validatedProfileFormData.middleName,
        lastName: validatedProfileFormData.lastName,
        alternativeLastName: validatedProfileFormData.alternativeLastName,
        dateOfBirth: validatedProfileFormData.dateOfBirth,
        phone: validatedProfileFormData.mobileNumber,
        addresses: [{ zipCode: validatedProfileFormData.zipCode! }],
      });
    }
  };

  useEffect(() => {
    if (profileFormData) {
      validateProfileFormData(profileFormData);
    }
  }, [intl.locale]);

  return (
    <Form
      className="setup-profile-form__container"
      noValidate
      onSubmit={handleSubmit}
      data-testid="setupProfileForm"
    >
      <FormField
        label={intl.formatMessage(messages.setupProfileFormFirstName)}
        required
        error={getValidationError("firstName")}
        htmlFor="firstName"
        withPadding
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <Textfield
            placeholder={intl.formatMessage(
              messages.setupProfileFormFirstNamePlaceholder
            )}
            id="firstName"
            name="firstName"
            value={names.firstName}
            onChange={handleNamesChange}
            onBlur={handleNamesUpdate}
            outline
            required
            maxLength={255}
          />
        </HeapPHIDecorator>
      </FormField>
      <FormField
        label={intl.formatMessage(messages.setupProfileFormMiddleName)}
        error={getValidationError("middleName")}
        htmlFor="middleName"
        withPadding
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <Textfield
            placeholder={intl.formatMessage(
              messages.setupProfileFormMiddleNamePlaceholder
            )}
            id="middleName"
            name="middleName"
            value={names.middleName}
            onChange={handleNamesChange}
            onBlur={handleNamesUpdate}
            outline
            maxLength={255}
          />
        </HeapPHIDecorator>
      </FormField>
      <FormField
        label={intl.formatMessage(messages.setupProfileFormLastName)}
        required
        error={getValidationError("lastName")}
        htmlFor="lastName"
        withPadding
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <Textfield
            placeholder={intl.formatMessage(
              messages.setupProfileFormLastNamePlaceholder
            )}
            id="lastName"
            name="lastName"
            value={names.lastName}
            onChange={handleNamesChange}
            onBlur={handleNamesUpdate}
            outline
            required
            maxLength={255}
          />
        </HeapPHIDecorator>
      </FormField>
      <FormField
        label={intl.formatMessage(messages.setupProfileFormAlternativeLastName)}
        error={getValidationError("alternativeLastName")}
        htmlFor="alternativeLastName"
        withPadding
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <Textfield
            placeholder={intl.formatMessage(
              messages.setupProfileFormAlternativeLastNamePlaceholder
            )}
            id="alternativeLastName"
            name="alternativeLastName"
            value={names.alternativeLastName}
            onChange={handleNamesChange}
            onBlur={handleNamesUpdate}
            outline
            maxLength={255}
          />
        </HeapPHIDecorator>
      </FormField>
      <FormField
        label={intl.formatMessage(messages.setupProfileFormDateOfBirth)}
        required
        error={getValidationError("dateOfBirth")}
        withPadding
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <DateInput
            id="date"
            name="dateOfBirth"
            maxDate={new Date()}
            placeholder={intl.formatMessage(messages.dateOfBirthPlaceholder)}
            required
            outline
            formatWeekdayName={weekDayFormatter}
            defaultDate={
              initialProfileData?.dateOfBirth
                ? new Date(getFormattedDOB(initialProfileData.dateOfBirth))
                : undefined
            }
          />
        </HeapPHIDecorator>
      </FormField>
      <PhoneField
        getValidationError={getValidationError}
        withPadding
        currentPhone={initialProfileData?.phone}
      />
      <FormField
        label={intl.formatMessage(messages.setupProfileFormZipCode)}
        required
        error={getValidationError("zipCode")}
        htmlFor="zipCode"
      >
        <Tooltip
          content={intl.formatMessage(messages.setupProfileFormZipTooltip)}
        >
          <div>
            <HeapPHIDecorator protectAttr={["value"]} noWrap={true}>
              <Textfield
                placeholder={intl.formatMessage(
                  messages.setupProfileFormZipCodePlaceholder
                )}
                id="zipCode"
                name="zipCode"
                required
                outline
                defaultValue={getZipCodeDefaultValue()}
              />
            </HeapPHIDecorator>
          </div>
        </Tooltip>
      </FormField>
      <Button type="submit" raised loading={isLoading}>
        {intl.formatMessage(messages.setupProfileFormNext)}
      </Button>
    </Form>
  );
};

export default SetupProfileForm;
