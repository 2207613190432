import React, {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from "react";
import { Step, Stepper } from "@natera/stepper";

import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import {
  AdditionalInstructionsStep,
  AppointmentTimeStep,
  LabList,
  SampleDrawMPAddressStep,
  SampleDrawPreferenceStep,
  TimeslotsStep,
  ZipSelectorStep,
} from "@app/components/sampleDraw/steps";

import { TestType } from "@app/provider/testData/types";
import { Address, KitContext, TestDetailsContext } from "@app/provider";
import { BusinessUnits, DrawRequestFlow } from "@app/provider/drawRequest";

import "./sampleDraw.scss";

export enum DRAW_PREFERENCE_TO_SHOW {
  lab = "lab",
  mobilePhlebotomy = "mobilePhlebotomy",
}

export enum SAMPLE_DRAW_STEP_IDS {
  PREFERENCE = "PREFERENCE",
  LAB1 = "LAB1",
  LAB2 = "LAB2",
  MP1 = "MP1",
  MP2 = "MP2",
  MP3 = "MP3",
  MP4 = "MP4",
}

interface StepsData {
  stepId: string;
  text?: string;
  content: ReactElement;
}

export type MobilePhlebotomyStepperProps = {
  onBackPreferenceStep: () => void;
  onBackAppointmentTimeStep?: () => void;
  onBackKitShippingStep?: () => void;
  handleCreateDrawRequestError?: () => void;
  testType?: TestType;
  orderUid?: string;
  testUid?: string;
  profileAddresses?: Address[];
  token?: string;
  isPublicRequest?: boolean;
  flow?: DrawRequestFlow;
};

const MobilePhlebotomyStepper: FC<MobilePhlebotomyStepperProps> = ({
  profileAddresses,
  onBackPreferenceStep,
  onBackAppointmentTimeStep,
  onBackKitShippingStep,
  handleCreateDrawRequestError,
  isPublicRequest,
  flow,
  ...props
}) => {
  const [sampleDrawPreferenceView, setSampleDrawPreferenceView] = useState<
    DRAW_PREFERENCE_TO_SHOW
  >(DRAW_PREFERENCE_TO_SHOW.lab);

  const labStepsData: StepsData[] = [
    {
      stepId: SAMPLE_DRAW_STEP_IDS.LAB1,
      content: (
        <>
          <StepperHeaderProgress />
          <ZipSelectorStep />
        </>
      ),
    },
    {
      stepId: SAMPLE_DRAW_STEP_IDS.LAB2,
      content: (
        <>
          <StepperHeaderProgress />
          <LabList />
        </>
      ),
    },
  ];

  const mobilePhlebotomyStepsData: StepsData[] = [
    {
      stepId: SAMPLE_DRAW_STEP_IDS.MP1,
      content: (
        <SampleDrawMPAddressStep
          profileAddresses={profileAddresses}
          onBackKitShippingStep={onBackKitShippingStep}
          isPublicMpFlow={isPublicRequest}
          flow={flow}
        />
      ),
    },
    {
      stepId: SAMPLE_DRAW_STEP_IDS.MP2,
      content: <TimeslotsStep />,
    },
    {
      stepId: SAMPLE_DRAW_STEP_IDS.MP3,
      content: (
        <AdditionalInstructionsStep
          {...props}
          handleCreateDrawRequestError={handleCreateDrawRequestError}
          isPublicRequest={isPublicRequest}
        />
      ),
    },
    {
      stepId: SAMPLE_DRAW_STEP_IDS.MP4,
      content: (
        <AppointmentTimeStep
          onBack={onBackAppointmentTimeStep}
          isPublicRequest={isPublicRequest}
          {...props}
        />
      ),
    },
  ];

  const { skipLabStep } = useContext(KitContext);
  const { getTestDetails } = useContext(TestDetailsContext);
  const testDetails = getTestDetails();

  const isSingleEmpowerTest =
    testDetails?.testType === TestType.EMPOWER && !testDetails?.isComboOrder;

  const isWomenHealthTest =
    testDetails?.businessUnit &&
    testDetails?.businessUnit == BusinessUnits.WOMENS_HEALTH;

  const labStepVisible =
    !skipLabStep || isSingleEmpowerTest || isWomenHealthTest;

  const [sampleDrawPreferenceSteps, setSampleDrawPreferenceSteps] = useState<
    StepsData[]
  >(labStepVisible ? labStepsData : mobilePhlebotomyStepsData);

  const handleSelectSampleDrawPreference = useCallback(
    (drawPreferenceType: DRAW_PREFERENCE_TO_SHOW) => {
      setSampleDrawPreferenceView(drawPreferenceType);
      setSampleDrawPreferenceSteps(
        drawPreferenceType === DRAW_PREFERENCE_TO_SHOW.lab
          ? labStepsData
          : mobilePhlebotomyStepsData
      );
    },
    [sampleDrawPreferenceView]
  );

  const steps: ReactElement = (
    <>
      {sampleDrawPreferenceSteps.map((step) => (
        <Step key={step.stepId} stepId={step.stepId}>
          {step.content}
        </Step>
      ))}
    </>
  );

  const initialStep = labStepVisible
    ? SAMPLE_DRAW_STEP_IDS.PREFERENCE
    : sampleDrawPreferenceSteps[0].stepId;

  return (
    <Stepper initialStep={initialStep} progressStepper>
      {labStepVisible && (
        <Step stepId={SAMPLE_DRAW_STEP_IDS.PREFERENCE}>
          <SampleDrawPreferenceStep
            handleSelectSampleDrawPreference={handleSelectSampleDrawPreference}
            onBack={onBackPreferenceStep}
          />
        </Step>
      )}
      {steps}
    </Stepper>
  );
};

export default MobilePhlebotomyStepper;
