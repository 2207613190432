import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/material/lib/button";
import AccountBgImage from "@app/preBill/assets/images/account_bg.png";
import { useTokenizedLinkSignup } from "@app/preBill/hooks";
import "./accountInfoBox.scss";

const messages = defineMessages({
  accountInfoBoxAccountTitle: {
    id: "accountInfoBoxAccountTitle",
    defaultMessage: "Don’t have an account yet?",
  },
  accountInfoBoxAccountDescription: {
    id: "accountInfoBoxAccountDescription",
    defaultMessage:
      "In Natera’s Patient Portal, you can access your test result and useful resources any time.",
  },
  accountInfoBoxAccountCreateButton: {
    id: "accountInfoBoxAccountCreateButton",
    defaultMessage: "Create account",
  },
});

const AccountInfoBox: React.FC = () => {
  const intl = useIntl();
  const { redirectToTokenizedSignup, isLoading } = useTokenizedLinkSignup();

  return (
    <div className="account-info-box">
      <div className="account-info-box__title">
        {intl.formatMessage(messages.accountInfoBoxAccountTitle)}
      </div>
      <div className="account-info-box__description">
        {intl.formatMessage(messages.accountInfoBoxAccountDescription)}
      </div>
      <div className="account-info-box__button">
        <Button
          className="account-info-box__button__create"
          outlined
          onClick={redirectToTokenizedSignup}
          loading={isLoading}
        >
          {intl.formatMessage(messages.accountInfoBoxAccountCreateButton)}
        </Button>
      </div>
      <img
        className="account-info-box__image"
        src={AccountBgImage}
        alt={intl.formatMessage(messages.accountInfoBoxAccountCreateButton)}
      />
    </div>
  );
};

export default AccountInfoBox;
