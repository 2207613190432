import * as React from "react";
import NonCoveredServiceStepper from "@app/preBill/components/nonCoveredServiceStepper";

const NonCoveredServicePage: React.FC = () => {
  return (
    <>
      <NonCoveredServiceStepper />
    </>
  );
};

export default NonCoveredServicePage;
