import React, { FC } from "react";

import { Compose } from "@natera/platform/lib/components/compose";
import {
  SignUpProvider,
  PasswordPolicyProvider,
  VerificationProvider,
  ResetPasswordProvider,
} from "@app/provider";
import { Header } from "@app/components";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { routes } from "@app/routing";
import { PreBillContextWrapper } from "@app/preBill/preBillWrapper";

import "./main.scss";

const Main: FC = ({ children }) => {
  const location = useLocation();
  const isFullWidth = [
    routes.scheduleBloodDraw,
    routes.guestLogin,
    routes.nonCoveredService,
    routes.billingEstimate,
    routes.outOfNetwork,
    routes.priorAuthorization,
    routes.noLongerAvailable,
    routes.insuranceNoLongerAvailable,
    routes.drawRequest,
  ].includes(location.pathname);

  return (
    <>
      <Header isWidthLimited={!isFullWidth} />
      <main
        className={classNames("public-main", {
          "public-main--full-width": isFullWidth,
        })}
      >
        <Compose
          components={[
            SignUpProvider,
            PasswordPolicyProvider,
            VerificationProvider,
            ResetPasswordProvider,
            PreBillContextWrapper,
          ]}
        >
          {children}
        </Compose>
      </main>
    </>
  );
};

export default Main;
