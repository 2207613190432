import React, { FC, useContext, useEffect, useState } from "react";
import { StepperContext } from "@natera/stepper";
import { Button, Form } from "@natera/form";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { messages } from "../index";
import { messages as formMessages } from "@app/components/forms/publicMpForms";
import { useIntl } from "react-intl";
import { InfoCard, InfoCardField, SpinnerView } from "@app/components";
import { convertPhoneNumberToDisplayValue } from "@app/utils/convertPhoneNumber";
import { PersonalInfoSteps } from "../personalInformation";
import { DrawRequestScreens } from "../../drawRequest";
import {
  ConfigContext,
  IntlContext,
  PublicMpStepperDataContext,
} from "@app/provider";
import { PublicMpStepperData } from "@app/provider/publicMpStepperData";
import {
  ContactInfoData,
  DobData,
  PersonalInfoData,
  ZipCodeData,
} from "./index";
import ReCAPTCHA from "react-google-recaptcha";

import "./confirmationScreen.scss";

interface PersonalInfoStepperData {
  dobData?: DobData;
  nameData?: PersonalInfoData;
  zipCodeData?: ZipCodeData;
  contactInfoData?: ContactInfoData;
}

type StepData = Partial<PersonalInfoStepperData>;

type PersonalInformationProps = {
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
};

const ConfirmationScreen: FC<PersonalInformationProps> = ({
  setScheduleScreen,
}) => {
  const intl = useIntl();

  const { config } = React.useContext(ConfigContext);
  const { currentLanguage } = useContext(IntlContext);

  const [recaptchaIsLoading, setRecaptchaIsLoading] = useState(true);

  const { goBack, getData } = useContext(StepperContext);
  const { savePublicMpStepperData, setRecaptchaValue } = useContext(
    PublicMpStepperDataContext
  );

  const allPersonalInfoSteps = Object.values(PersonalInfoSteps).filter(
    (step) => step !== PersonalInfoSteps.CONFIRMATION_SCREEN
  );

  const profileData: PublicMpStepperData = allPersonalInfoSteps.reduce(
    (acc, step) => {
      const stepData = getData(step) as StepData;
      const stepDataValue = Object.values(stepData)?.[0];

      return { ...acc, ...stepDataValue };
    },
    {} as PublicMpStepperData
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    savePublicMpStepperData(profileData);
    setScheduleScreen(DrawRequestScreens.MATCH_ORDER_SCREEN);
  };

  const displayDateOfBirth = () => {
    const dOB = profileData.dateOfBirth;
    return dOB
      ? new Date(dOB).toLocaleDateString("en-US", {
          timeZone: "UTC",
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      : "";
  };

  const handleAsyncScriptOnLoad = () => {
    setRecaptchaIsLoading(false);
  };

  const handleBack = () => {
    goBack();
  };

  useEffect(() => {
    setRecaptchaValue(null);
    if (!config.recaptcha?.siteKey) {
      setRecaptchaIsLoading(false);
    }
  }, []);

  const [verified, setVerified] = useState<boolean>(!config.recaptcha?.siteKey);

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  useEffect(() => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  }, [currentLanguage]);

  const onChange = () => {
    if (recaptchaRef.current) {
      setVerified(Boolean(recaptchaRef.current.getValue()));
      setRecaptchaValue(
        recaptchaRef.current.getValue() ? recaptchaRef.current.getValue() : null
      );
    }
  };

  return (
    <>
      <StepperHeaderProgress />

      <StepperContent
        title={intl.formatMessage(
          messages.publicMpPersonalInformationConfirmationTitle
        )}
      >
        <SpinnerView isLoading={recaptchaIsLoading} />
        <Form className="confirmation-screen-form" onSubmit={handleSubmit}>
          <InfoCard>
            <InfoCardField
              label={intl.formatMessage(
                formMessages.setupProfileReviewInfoFirstName
              )}
              value={profileData.firstName}
            />
            <InfoCardField
              label={intl.formatMessage(
                formMessages.setupProfileFormMiddleName
              )}
              value={
                profileData.middleName ||
                intl.formatMessage(
                  formMessages.setupProfileReviewInfoNotApplicable
                )
              }
            />
            <InfoCardField
              label={intl.formatMessage(
                formMessages.setupProfileReviewInfoLastName
              )}
              value={profileData.lastName}
            />
            <InfoCardField
              label={intl.formatMessage(
                formMessages.setupProfileReviewInfoDateOfBirth
              )}
              value={displayDateOfBirth()}
            />
            <InfoCardField
              label={intl.formatMessage(
                formMessages.publicMpContactInfoFormEmailField
              )}
              value={profileData.email}
            />

            <InfoCardField
              label={intl.formatMessage(
                formMessages.setupProfileReviewInfoMobileNumber
              )}
              value={convertPhoneNumberToDisplayValue(profileData.mobileNumber)}
            />
            <InfoCardField
              label={intl.formatMessage(
                formMessages.setupProfileReviewInfoZipCode
              )}
              value={profileData.zipCode}
            />
          </InfoCard>

          {config.recaptcha?.siteKey && (
            <ReCAPTCHA
              key={currentLanguage}
              className="recaptcha"
              hl={currentLanguage}
              asyncScriptOnLoad={handleAsyncScriptOnLoad}
              sitekey={config.recaptcha.siteKey}
              ref={recaptchaRef}
              onChange={onChange}
            />
          )}

          <div className="stepper-form-buttons">
            <Button type="submit" disabled={!verified} raised>
              {intl.formatMessage(
                formMessages.setupProfileReviewInfoConfirmBtn
              )}
            </Button>

            <Button onClick={handleBack} outlined>
              {intl.formatMessage(
                formMessages.publicMpPersonalInfoPageBackButton
              )}
            </Button>
          </div>
        </Form>
      </StepperContent>
    </>
  );
};

export default ConfirmationScreen;
