import React, { FC, ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";
import { LinkButton } from "@natera/form";
import classNames from "classnames";
import { Link } from "@natera/navigation";

import "./infoCard.scss";

const messages = defineMessages({
  infoCardEditButtonText: {
    id: "infoCardEditButtonText",
    defaultMessage: "Edit",
  },
});

interface Props {
  titleText?: string;
  titleEditButtonLinkRoute?: string;
  notification?: ReactNode;
  className?: string;
}

const InfoCard: FC<Props> = ({
  titleText,
  titleEditButtonLinkRoute,
  notification,
  className,
  children,
}) => {
  const intl = useIntl();

  return (
    <div className={classNames("info-card", className)}>
      {notification ? (
        <div className="info-card--notification">{notification}</div>
      ) : null}
      {titleText && (
        <div className="info-card--header">
          <h4>{titleText}</h4>
          {titleEditButtonLinkRoute && (
            <LinkButton
              className="info-card--edit-button"
              to={titleEditButtonLinkRoute}
              renderLink={(props) => <Link {...props} />}
              outlined
            >
              {intl.formatMessage(messages.infoCardEditButtonText)}
            </LinkButton>
          )}
        </div>
      )}
      {children && <div className="info-card--content">{children}</div>}
    </div>
  );
};

export default InfoCard;
