import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useErrorController } from "@natera/platform/lib/hooks";
import { validateZipCode } from "@app/utils";
import { messages } from "./messages";
import { Form } from "@natera/form";
import { Address } from "@app/provider/profile";
import {
  StreetAddressField,
  ApartmentUnitField,
  CityField,
  StateField,
  ZipCodeField,
} from "@app/components/forms/addressForm/formElements";

interface Props {
  address?: Address;
  onSubmit: (address: Partial<Address>) => void;
  actions: React.ReactNode;
}

const AddressForm: FC<Props> = ({ address, onSubmit, actions }) => {
  const intl = useIntl();

  const {
    clearErrors,
    getValidationError,
    setValidationError,
  } = useErrorController();

  const streetAddress = address?.street1 || "";
  const apartmentUnit = address?.street2 || "";

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    clearErrors();

    const formData = new FormData(event.currentTarget);

    const streetAddress = formData.get("streetAddress")?.toString();
    const apartmentUnit = formData.get("apartmentUnit")?.toString();
    const city = formData.get("city")?.toString();
    const state = formData.get("state")?.toString();
    const zipCode = formData.get("zipCode")?.toString();

    const addressForm = [
      { title: "streetAddress", value: streetAddress && streetAddress.trim() },
      { title: "city", value: city && city.trim() },
      { title: "state", value: state },
    ];

    for (const field of addressForm) {
      if (!field.value) {
        setValidationError(
          field.title,
          intl.formatMessage(messages.addressFormRequired)
        );
        return;
      }
    }

    if (!zipCode || !validateZipCode(zipCode)) {
      setValidationError(
        "zipCode",
        intl.formatMessage(messages.addressFormInvalidZipCode)
      );
      return;
    }

    const newAddress: Partial<Address> = {
      city,
      state,
      street1: streetAddress,
      street2: apartmentUnit,
      zipCode,
    };
    if (address) {
      newAddress.uid = address.uid;
    }
    onSubmit(newAddress);
  };

  return (
    <Form
      noValidate
      className="info-card-form"
      data-testid="addressForm"
      onSubmit={handleSubmit}
      buttons={actions}
    >
      <StreetAddressField
        getValidationError={getValidationError}
        streetAddress={streetAddress}
      />
      <ApartmentUnitField
        getValidationError={getValidationError}
        apartmentUnit={apartmentUnit}
      />
      <CityField
        defaultValue={address?.city}
        getValidationError={getValidationError}
      />
      <StateField
        defaultValue={address?.state}
        getValidationError={getValidationError}
      />
      <ZipCodeField
        defaultValue={address?.zipCode}
        getValidationError={getValidationError}
      />
    </Form>
  );
};

export default AddressForm;
