import React, { FC, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  IntlContext,
  Language,
  LanguageKeys,
  UppAuthContext,
  UserContext,
} from "@app/provider";
import { defineMessages, useIntl } from "react-intl";
import { routes } from "@app/routing";
import { MaterialOption, MaterialSelect } from "@natera/material/lib/select";
import "./languageSelector.scss";

const messages = defineMessages({
  languageSelectorMenuAriaLabel: {
    id: "languageSelectorMenuAriaLabel",
    defaultMessage: "Select language",
  },
  languageSelectorMenuItemEnglishAriaLabel: {
    id: "languageSelectorMenuItemEnglishAriaLabel",
    defaultMessage: "English",
  },
  languageSelectorMenuItemSpanishAriaLabel: {
    id: "languageSelectorMenuItemSpanishAriaLabel",
    defaultMessage: "Spanish",
  },
});

const LanguageSelector: FC = () => {
  const intl = useIntl();
  const location = useLocation();

  const { updateUser, updateUserLanguageNonAuth } = useContext(UserContext);
  const { profile } = useContext(UppAuthContext);
  const { changeLanguage, currentLanguage } = useContext(IntlContext);
  const { signUpEmail } = useContext(UppAuthContext);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const isMPGuestPage = location.pathname === routes.scheduleBloodDraw;
  const isOnActivationPage = location.pathname === routes.activation;

  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleFocus = () => setIsExpanded(true);
  const handleBlur = () => setIsExpanded(false);

  const switchLanguage = (code: Language) => () => {
    if (!isMPGuestPage) {
      if (isOnActivationPage) {
        updateUserLanguageNonAuth({ email: signUpEmail, language: code });
      } else if (profile) {
        updateUser({
          language: code,
        });
      }
    }
    changeLanguage(code);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      event.preventDefault();
      setIsExpanded(false);
      selectRef.current?.blur();
    }
  };

  const getItemLabel = (code: LanguageKeys) => {
    return code === "EN"
      ? intl.formatMessage(messages.languageSelectorMenuItemEnglishAriaLabel)
      : intl.formatMessage(messages.languageSelectorMenuItemSpanishAriaLabel);
  };

  return (
    <MaterialSelect
      ref={selectRef}
      className="custom-select"
      value={currentLanguage}
      aria-label={intl.formatMessage(messages.languageSelectorMenuAriaLabel)}
      onKeyDown={handleKeyDown}
      onValueChange={(value) => {
        if (value && value !== currentLanguage) {
          switchLanguage(value as Language)();
        }
      }}
      role="combobox"
      aria-expanded={isExpanded}
      aria-controls="language-dropdown-list"
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {Object.entries(Language).map(([key, value]) => (
        <MaterialOption
          key={key}
          value={value}
          role="option"
          aria-label={getItemLabel(key as LanguageKeys)}
        >
          {key}
        </MaterialOption>
      ))}
    </MaterialSelect>
  );
};
export default LanguageSelector;
