import React, { FC, ReactElement } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ConfigContext, DrawRequestContext, UserContext } from "@app/provider";
import { WelcomeWrapper } from "./welcomeWrapper";
import { ScheduleBloodDrawScreens } from "../scheduleBloodDraw";
import { HeapPHIDecorator } from "@app/components/analytics";
import { useErrorController } from "@natera/platform/lib/hooks";
import { Button, DateInput, Form } from "@natera/form";
import { FormField, LinkHeap, PhoneField } from "@app/components";
import CalendarIcon from "@assets/svg/icons/calendar.svg";
import { LoadingContext } from "@natera/platform/lib/components/context";

import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";
import { TestCardStatus } from "@app/provider/testData/types";
import { weekDayFormatter } from "@app/utils";

import "./welcomeScheduleBloodDraw.scss";

const messages = defineMessages({
  scheduleBloodDrawDobDescription_1: {
    id: "scheduleBloodDrawDobDescription_1",
    defaultMessage: "Let’s get started with your test.",
  },
  scheduleBloodDrawDobDescription_2: {
    id: "scheduleBloodDrawDobDescription_2",
    defaultMessage:
      "A test order in your name has been placed. Please enter your date of birth to retrieve your order.",
  },
  scheduleBloodDrawDobDateOfBirth: {
    id: "scheduleBloodDrawDobDateOfBirth",
    defaultMessage: "Date of Birth",
  },
  scheduleBloodDrawDobPlaceholder: {
    id: "scheduleBloodDrawDobPlaceholder",
    defaultMessage: "MM/DD/YYYY",
  },
  scheduleBloodDrawDobInvalidDateOfBirth: {
    id: "scheduleBloodDrawDobInvalidDateOfBirth",
    defaultMessage: "Incorrect date of birth. Please re-enter",
  },
  scheduleBloodDrawDobInvalidPhoneNumber: {
    id: "scheduleBloodDrawDobInvalidPhoneNumber",
    defaultMessage: "Please enter a valid phone number",
  },
  scheduleBloodDrawDobVerify: {
    id: "scheduleBloodDrawDobVerify",
    defaultMessage: "Verify",
  },
  scheduleBloodDrawDobHavingIssues: {
    id: "scheduleBloodDrawDobHavingIssues",
    defaultMessage: "Having issues with verification?",
  },
  scheduleBloodDrawDobContactUs: {
    id: "scheduleBloodDrawDobContactUs",
    defaultMessage: "Contact Us",
  },
});

interface WelcomeDobVerificationScreenProps {
  token: string;
  setBloodDrawScreen: React.Dispatch<
    React.SetStateAction<ScheduleBloodDrawScreens>
  >;
}

type HandleValidateDateOfBirth = ({
  dateOfBirth,
  phone,
}: {
  dateOfBirth?: string;
  phone?: string;
}) => Promise<boolean>;
type HandleValidatePhone = (phoneNumber?: string) => Promise<boolean>;

const ATTEMPTS = 5;

const WelcomeDobVerificationScreen: FC<WelcomeDobVerificationScreenProps> = ({
  token,
  setBloodDrawScreen,
}) => {
  const intl = useIntl();
  const {
    getValidationError,
    clearErrors,
    setValidationError,
  } = useErrorController();

  const { config } = React.useContext(ConfigContext);

  const {
    verifySampleDrawWithoutLogging,
    isLoading,
    checkPhoneIsRequired,
    checkPhoneIsRequiredLoading,
  } = React.useContext(DrawRequestContext);

  const { validatePhoneNumber } = React.useContext(UserContext);

  const [isPhoneRequired, setPhoneRequired] = React.useState<boolean>(false);

  const attemptsLeftRef = React.useRef<number>(ATTEMPTS);

  const handleCheckPhoneIsRequired = async (token: string) => {
    const isPhoneRequired = await checkPhoneIsRequired(token);
    if (isPhoneRequired?.isRequired) {
      setPhoneRequired(true);
    }
  };

  const handleValidatePhone: HandleValidatePhone = async (phoneNumber) => {
    if (!isPhoneRequired) {
      return true;
    }

    if (!phoneNumber) {
      setValidationError(
        "mobileNumber",
        intl.formatMessage(messages.scheduleBloodDrawDobInvalidPhoneNumber)
      );

      return false;
    }

    const checkPhone = await validatePhoneNumber(phoneNumber);

    if (!checkPhone?.isValid) {
      setValidationError(
        "mobileNumber",
        intl.formatMessage(messages.scheduleBloodDrawDobInvalidPhoneNumber)
      );

      return false;
    }

    return true;
  };

  const handleValidateDateOfBirth: HandleValidateDateOfBirth = async ({
    dateOfBirth,
    phone,
  }) => {
    if (!dateOfBirth) {
      setValidationError(
        "dateOfBirth",
        intl.formatMessage(messages.scheduleBloodDrawDobInvalidDateOfBirth)
      );

      return false;
    }

    try {
      const userIdentity = await verifySampleDrawWithoutLogging(
        token,
        dateOfBirth,
        phone
      );

      if (!userIdentity?.profileInfo.isValid) {
        setValidationError(
          "dateOfBirth",
          intl.formatMessage(messages.scheduleBloodDrawDobInvalidDateOfBirth)
        );

        attemptsLeftRef.current--;
        if (attemptsLeftRef.current < 1) {
          setBloodDrawScreen(
            ScheduleBloodDrawScreens.WELCOME_DOB_VERIFY_FAILED_SCREEN
          );
        }
        return false;
      }

      const testCardStatus = userIdentity.sampleInfo.sample?.testCardStatus;
      if (
        userIdentity.sampleInfo.isDrawRequested &&
        (testCardStatus === TestCardStatus.APPT_REQUESTED ||
          testCardStatus === TestCardStatus.APPT_CONFIRMED)
      ) {
        setBloodDrawScreen(ScheduleBloodDrawScreens.VIEW_BLOOD_DRAW_DETAILS);
        return false;
      }

      return true;
    } catch (error) {
      setBloodDrawScreen(
        ScheduleBloodDrawScreens.SCHEDULE_BLOOD_DRAW_FAILED_SCREEN
      );
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearErrors();

    const formData = new FormData(e.currentTarget);
    const dateOfBirth = formData.get("dateOfBirth")?.toString();
    const phoneNumber = formData.get("mobileNumber")?.toString();

    const isPhoneValidated = await handleValidatePhone(phoneNumber);
    if (!isPhoneValidated) {
      return;
    }

    const isDateOfBirthValidated = await handleValidateDateOfBirth({
      dateOfBirth,
      phone: phoneNumber,
    });
    if (!isDateOfBirthValidated) {
      return;
    }

    setBloodDrawScreen(ScheduleBloodDrawScreens.PERSONAL_INFO_SCREEN);
  };

  const formElement: ReactElement = (
    <Form noValidate onSubmit={handleSubmit}>
      {isPhoneRequired && (
        <PhoneField getValidationError={getValidationError} />
      )}
      <FormField
        label={intl.formatMessage(messages.scheduleBloodDrawDobDateOfBirth)}
        required
        error={getValidationError("dateOfBirth")}
        htmlFor="date"
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <DateInput
            id="date"
            name="dateOfBirth"
            maxDate={new Date()}
            required
            outline
            placeholder={intl.formatMessage(
              messages.scheduleBloodDrawDobPlaceholder
            )}
            trailingIcon={CalendarIcon}
            data-testid="dateOfBirth"
            formatWeekdayName={weekDayFormatter}
            floating
          />
        </HeapPHIDecorator>
      </FormField>
      <Button type="submit" raised loading={isLoading}>
        {intl.formatMessage(messages.scheduleBloodDrawDobVerify)}
      </Button>
    </Form>
  );

  React.useEffect(() => {
    handleCheckPhoneIsRequired(token);
  }, []);

  return (
    <LoadingContext isLoading={checkPhoneIsRequiredLoading}>
      <WelcomeWrapper>
        <div className="schedule-blood-draw__content-description">
          <b>
            {intl.formatMessage(messages.scheduleBloodDrawDobDescription_1)}
          </b>
          <br />
          {intl.formatMessage(messages.scheduleBloodDrawDobDescription_2)}
        </div>
        <div className="schedule-blood-draw__form-container">
          {formElement}
          <p>
            {intl.formatMessage(messages.scheduleBloodDrawDobHavingIssues)}
            <br />
            <LinkHeap
              target="_blank"
              key="privacyPolicy"
              rel="noopener noreferrer"
              to={{ pathname: config.links.CONTACT_US }}
              heapEventName={HEAP_EVENTS.upp_click_contactnatera}
              heapEventProps={{ location: HeapEventLocation.mp_dob_page }}
            >
              {intl.formatMessage(messages.scheduleBloodDrawDobContactUs)}
            </LinkHeap>
          </p>
        </div>
      </WelcomeWrapper>
    </LoadingContext>
  );
};

export default WelcomeDobVerificationScreen;
