import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { InfoCard } from "@natera/patient";
import { TestResultDocument } from "@app/provider/testResult";
import { convertDate } from "@app/utils";
import { Button } from "@natera/form";

import ContactIcon from "@assets/svg/icons/contact.svg";

import "./myResultCard.scss";

const messages = defineMessages({
  myResultCardTitle: {
    id: "myResultCardTitle",
    defaultMessage: "My Result",
  },
  myResultCardView: {
    id: "myResultCardView",
    defaultMessage: "View",
  },
});

interface MyResultCardProps {
  doc: TestResultDocument;
  openResultPdf: (documentUid: string) => Promise<void>;
}

const MyResultCard: FC<MyResultCardProps> = ({ doc, openResultPdf }) => {
  const intl = useIntl();

  const releasedDate = doc.releasedToPatientDate ?? doc.releasedToProviderDate;
  const cardDate = releasedDate ? convertDate(releasedDate) : undefined;

  return (
    <>
      {doc.document && (
        <>
          <div className="my-result-card-wrapper">
            <InfoCard
              key={doc.document.uid}
              vertical
              icon={ContactIcon}
              contentAside={
                <Button
                  onClick={() => {
                    openResultPdf(doc.document?.uid ?? "");
                  }}
                  outlined
                >
                  {intl.formatMessage(messages.myResultCardView)}
                </Button>
              }
            >
              <div className="my-result-card">
                <div className="my-result-card__title">
                  {intl.formatMessage(messages.myResultCardTitle)}
                </div>
                <div className="my-result-card__description">{cardDate}</div>
              </div>
            </InfoCard>
          </div>
        </>
      )}
    </>
  );
};

export default MyResultCard;
