import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  ErrorProvider,
  IntlContext,
  PreBillContext,
  UppAuthContext,
} from "@app/provider";
import { useErrorController } from "@natera/platform/lib/hooks";
import DateOfBirthVerification from "@app/preBill/components/dateOfBirthVerification";
import { Link } from "@natera/navigation";
import acknowledgements from "@etc/acknowledgements.json";
import { useHistory } from "react-router-dom";
import { useQuery, isValidLanguageValue } from "@app/utils";
import { useTokenizedLinks } from "@app/hooks";
import { LinkSourceData } from "@app/hooks/useTokenizedLinks";
import "./guestLogin.scss";
import { routes } from "@app/routing";
import {
  GuestValidationInput,
  Channel,
  PreBillErrorResponse,
  PRE_BILL_ERROR_CODES,
  ClosingStatus,
} from "@app/preBill/models";
import { PreBillAPIContext } from "@app/preBill/provider";
import DOBValidationErrorNotification from "@app/preBill/components/DOBValidationErrorNotification/dobValidationErrorNotification";
import { SpinnerView } from "@app/components";

const messages = defineMessages({
  guestLoginPageTitle: {
    id: "guestLoginPageTitle",
    defaultMessage: "Review your billing update",
  },
  guestLoginPageSubtitle: {
    id: "guestLoginPageSubtitle",
    defaultMessage: "Please enter your date of birth.",
  },
  guestLoginDateOfBirthVerificationIssues: {
    id: "guestLoginDateOfBirthVerificationIssues",
    defaultMessage: "Need help? {contactUs}",
  },
  contactUsText: {
    id: "contactUsText",
    defaultMessage: "Contact Us",
  },
  guestLoginCookies: {
    id: "guestLoginCookies",
    defaultMessage:
      "This site uses cookies to enhance your experience. Any cookies preferences selected on Natera.com will not apply.",
  },
  guestLoginAcknowledgements: {
    id: "guestLoginAcknowledgements",
    defaultMessage:
      "By continuing you accept our {termsOfUse}, {privacyPolicy} and {privacyPractices}",
  },
  guestLoginTermsOfUse: {
    id: "guestLoginTermsOfUse",
    defaultMessage: "Terms of Use",
  },
  guestLoginPrivacyPolicy: {
    id: "guestLoginPrivacyPolicy",
    defaultMessage: "Privacy Policy",
  },
  guestLoginPrivacyPractices: {
    id: "guestLoginPrivacyPractices",
    defaultMessage: "Notice of Privacy Practices",
  },
});

const GuestLogin: FC = () => {
  const intl = useIntl();
  const errorController = useErrorController();
  const history = useHistory();
  const { changeLanguage, currentLanguage } = useContext(IntlContext);
  const { orderUid, setOrderUid, setPatientUid } = useContext(PreBillContext);
  const { guestValidation, getOrderEstimate } = useContext(PreBillAPIContext);
  const { profile } = useContext(UppAuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [spinnerLoading, setSpinnerLoading] = useState<boolean>(false);
  const [
    showDOBValidationBoxError,
    setShowDOBValidationBoxError,
  ] = React.useState<boolean>(false);
  const query = useQuery();
  const token = query.get("token");
  const { getOrderUid } = useTokenizedLinks();

  if (!token) {
    history.replace(routes.home);
  }

  const getOrderUidFromToken = async (token: string) => {
    const payload = await getOrderUid(token);
    if (payload) {
      setOrderUid(payload.orderUid);
      setUserLanguage(payload.sourceData);
    }
  };

  const setUserLanguage = async (source: LinkSourceData) => {
    const language = source?.language;
    if (
      language &&
      currentLanguage !== language &&
      isValidLanguageValue(language)
    ) {
      changeLanguage(language);
    }
  };

  useEffect(() => {
    if (token) {
      getOrderUidFromToken(token);
    }
  }, [token]);

  useEffect(() => {
    if (orderUid && profile) {
      handleOrderEstimate();
    }
  }, [orderUid, profile]);

  const title: ReactElement = (
    <section className="guest-login-page__container__title">
      <h2>{intl.formatMessage(messages.guestLoginPageTitle)}</h2>
      <span>{intl.formatMessage(messages.guestLoginPageSubtitle)}</span>
    </section>
  );

  const handleGuestValidation = async (
    data: GuestValidationInput
  ): Promise<void> => {
    setLoading(true);

    await guestValidation(data)
      .then(() => handleOrderEstimate())
      .catch((error: PreBillErrorResponse) => {
        if (error.code === PRE_BILL_ERROR_CODES.DOB_VALIDATION) {
          setShowDOBValidationBoxError(true);
        } else if (error.code === PRE_BILL_ERROR_CODES.ORDER_NOT_EXPIRED) {
          if (error.closingStatus === ClosingStatus.INSURANCE) {
            history.push(routes.insuranceNoLongerAvailable);
          } else {
            history.push(routes.noLongerAvailable);
          }
        } else {
          console.log("Guest validation failed", error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDateOfBirthSubmit = (date: GuestValidationInput) => {
    const guestData: GuestValidationInput = {
      month: date.month,
      day: date.day,
      year: date.year,
    };

    handleGuestValidation(guestData);
  };

  const handleOrderEstimate = async (): Promise<void> => {
    setSpinnerLoading(true);
    try {
      const response = await getOrderEstimate();
      setLoading(false);
      setSpinnerLoading(false);
      setPatientUid(response.patient.uid);
      handleRedirection(response.channel);
    } catch (error) {
      setLoading(false);
      setSpinnerLoading(false);
      //TO DO show error message
      console.log("Get Order Estimation Failed", error);
    }
  };

  const handleRedirection = (channel: string) => {
    switch (channel) {
      case Channel.INN:
        history.push(routes.billingEstimate);
        break;
      case Channel.NCS:
        history.push(routes.nonCoveredService);
        break;
      case Channel.OON:
        history.push(routes.outOfNetwork);
        break;
      case Channel.PTPA:
        history.push(routes.priorAuthorization);
        break;
      default:
        history.push(routes.guestLogin);
    }
  };

  return (
    <div className="guest-login-page">
      {profile ? (
        <SpinnerView isLoading={spinnerLoading} />
      ) : (
        <div className="guest-login-page__container">
          {title}
          {showDOBValidationBoxError && <DOBValidationErrorNotification />}
          <ErrorProvider controller={errorController}>
            <DateOfBirthVerification
              loading={loading}
              onSubmit={handleDateOfBirthSubmit}
            />
          </ErrorProvider>
          <p className="guest-login-page__container__bottom-text">
            {intl.formatMessage(
              messages.guestLoginDateOfBirthVerificationIssues,
              {
                contactUs: (
                  <Link
                    target="_blank"
                    key="privacyPolicy"
                    rel="noreferrer"
                    to={{ pathname: acknowledgements.links.contactUs }}
                  >
                    {intl.formatMessage(messages.contactUsText)}
                  </Link>
                ),
              }
            )}
          </p>
          <div className="guest-login-page__container__footer">
            <p className="guest-login-page__container__footer-cookies">
              {intl.formatMessage(messages.guestLoginCookies)}
            </p>
            <p className="guest-login-page__container__footer-terms">
              {intl.formatMessage(messages.guestLoginAcknowledgements, {
                termsOfUse: (
                  <a
                    href={acknowledgements.links.termsOfUse}
                    key="termsOfUse"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage(messages.guestLoginTermsOfUse)}
                  </a>
                ),
                privacyPolicy: (
                  <a
                    href={acknowledgements.links.privacyPolicy}
                    key="privacyPolicy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage(messages.guestLoginPrivacyPolicy)}
                  </a>
                ),
                privacyPractices: (
                  <a
                    href={acknowledgements.links.noticeOfPrivacyPractices}
                    key="privacyPractices"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {intl.formatMessage(messages.guestLoginPrivacyPractices)}
                  </a>
                ),
              })}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default GuestLogin;
