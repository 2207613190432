import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Button, Form } from "@natera/form";
import { DrawRequestContext, OrderRenewalContext } from "@app/provider";
import { AppointmentAddress } from "@app/components/appointmentAddress";
import { AppointmentTimeslots } from "@app/components/appointmentTimeslots";

import "./appointmentTimeConfirmationStep.scss";
import { StepperContent } from "@app/components/ui/layout";
import RenewalBanner from "@app/components/renewalBanner";

const messages = defineMessages({
  appointmentTimeConfirmationTitle: {
    id: "appointmentTimeConfirmationTitle",
    defaultMessage: "Mobile Blood Draw",
  },
  appointmentTimeConfirmationDescriptionContent: {
    id: "appointmentTimeConfirmationDescriptionContent",
    defaultMessage:
      "You will get a call from the scheduling team to confirm your appointment 24-48 hours prior to your first selected time slot.",
  },
  appointmentTimeConfirmationModifyAppointment: {
    id: "appointmentTimeConfirmationModifyAppointment",
    defaultMessage: "Change Appointment",
  },
  appointmentTimeConfirmationBackHome: {
    id: "appointmentTimeConfirmationBackHome",
    defaultMessage: "Home",
  },
});

interface Props {
  handleModifyAppointment: () => void;
  onBack?: () => void;
  isPublicRequest?: boolean;
}

const AppointmentTimeConfirmationStep: FC<Props> = ({
  handleModifyAppointment,
  onBack,
  isPublicRequest,
}) => {
  const intl = useIntl();
  const { drawRequestData: drawRequest, selectedTimeZone } = useContext(
    DrawRequestContext
  );
  const { renewalInfo, openRenewalDialog } = React.useContext(
    OrderRenewalContext
  );
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    handleModifyAppointment();
  };

  const renderRenewalBanner = () =>
    renewalInfo?.hasRenewal &&
    renewalInfo?.isLastSignateraOrder && (
      <RenewalBanner
        isMobilePhlebotomyPage
        openRenewalDialog={openRenewalDialog}
        limsId={renewalInfo?.clinicLimsId}
        orderUid={renewalInfo?.renewalOrderUid}
      />
    );

  return (
    <div className="stepper__container">
      <StepperContent
        title={intl.formatMessage(messages.appointmentTimeConfirmationTitle)}
        subTitle={intl.formatMessage(
          messages.appointmentTimeConfirmationDescriptionContent
        )}
      >
        <Form
          onSubmit={handleSubmit}
          noValidate
          buttons={
            <>
              {!isPublicRequest && (
                <Button type="submit" outlined>
                  {intl.formatMessage(
                    messages.appointmentTimeConfirmationModifyAppointment
                  )}
                </Button>
              )}
              {onBack && (
                <Button raised onClick={onBack}>
                  {intl.formatMessage(
                    messages.appointmentTimeConfirmationBackHome
                  )}
                </Button>
              )}
            </>
          }
        >
          <div className="content">
            {drawRequest && (
              <AppointmentTimeslots
                drawRequest={drawRequest}
                selectedTimeZone={selectedTimeZone}
                isConfirmationStep
              />
            )}
            {drawRequest && (
              <AppointmentAddress
                shippingAddress={drawRequest.shippingAddress}
              />
            )}
            {renderRenewalBanner()}
          </div>
        </Form>
      </StepperContent>
    </div>
  );
};
export default AppointmentTimeConfirmationStep;
