import React, { FC, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/form";
import "./insuranceConfirmationDialog.scss";

const messages = defineMessages({
  insuranceConfirmationDialogTitle: {
    id: "insuranceConfirmationDialogTitle",
    defaultMessage: "Continue with insurace?",
  },
  insuranceConfirmationDialogDesc: {
    id: "insuranceConfirmationDialogDesc",
    defaultMessage:
      "By confirming this, we will proceed with billing your insurance and your final bill may be much higher.",
  },
  insuranceConfirmationDialogYes: {
    id: "insuranceConfirmationDialogYes",
    defaultMessage: "Yes",
  },
  insuranceConfirmationDialogNo: {
    id: "insuranceConfirmationDialogNo",
    defaultMessage: "No",
  },
});

interface InsuranceConfirmationDialogProps {
  onClickYes: () => Promise<void>;
  onCloseDialog: () => void;
}

const InsuranceConfirmationDialog: FC<InsuranceConfirmationDialogProps> = ({
  onClickYes,
  onCloseDialog,
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClickYes = async () => {
    try {
      setLoading(true);
      await onClickYes();
    } finally {
      setLoading(false);
      onCloseDialog();
    }
  };

  return (
    <Dialog
      className="insurance-confirmation-dialog"
      title={intl.formatMessage(messages.insuranceConfirmationDialogTitle)}
      actions={
        <div className="insurance-confirmation-dialog__footer">
          <Button raised loading={loading} onClick={handleClickYes}>
            {intl.formatMessage(messages.insuranceConfirmationDialogYes)}
          </Button>
          <Button outlined onClick={onCloseDialog}>
            {intl.formatMessage(messages.insuranceConfirmationDialogNo)}
          </Button>
        </div>
      }
      onClose={onCloseDialog}
      showCrossButton
    >
      <div className="insurance-confirmation-dialog__content">
        <span>
          {intl.formatMessage(messages.insuranceConfirmationDialogDesc)}
        </span>
      </div>
    </Dialog>
  );
};

export default InsuranceConfirmationDialog;
