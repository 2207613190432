import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIntl, defineMessages } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { useErrorController } from "@natera/platform/lib/hooks";

import { ResultCodes } from "@app/service/resultCodes";
import { LinkHeap, Progress } from "@app/components";
import {
  ConfigContext,
  ErrorProvider,
  ResetPasswordContext,
} from "@app/provider";
import {
  default as ResetPasswordForm,
  emailValidationFieldName,
} from "./resetPasswordForm";
import { validateEmail } from "@app/utils";
import { routes } from "@app/routing";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";
import acknowledgements from "@etc/acknowledgements.json";
import "./resetPassword.scss";

const messages = defineMessages({
  resendPasswordTitle: {
    id: "resendPasswordTitle",
    defaultMessage: "Forgot Password?",
  },
  resendPasswordInstructions: {
    id: "resendPasswordInstructions",
    defaultMessage:
      "No worries. We will send you an email with reset instructions.",
  },
  resendPasswordInvalidEmailError: {
    id: "resendPasswordInvalidEmailError",
    defaultMessage: "Please enter a valid email address. (username@domain)",
  },
  resendPasswordEmailNofFoundError: {
    id: "resendPasswordEmailNofFoundError",
    defaultMessage: "Email address not found.",
  },
  resendPasswordInvalidProviderTypeError: {
    id: "resendPasswordInvalidProviderTypeError",
    defaultMessage: "Unable to reset password. {support}",
  },
  resendPasswordContactSupport: {
    id: "resendPasswordContactSupport",
    defaultMessage: "Contact Support",
  },
  resendPasswordEmailIsLockedOut: {
    id: "resendPasswordEmailIsLockedOut",
    defaultMessage:
      "Your account has been locked due to multiple failed login attempts. {unlockLink}",
  },
  resendPasswordEmailUnlock: {
    id: "resendPasswordEmailUnlock",
    defaultMessage: "Unlock Account.",
  },
});

const ResetPassword: FC = () => {
  const { config } = React.useContext(ConfigContext);

  const {
    resetPassword,
    resetPasswordIsLoading,
    resetPasswordData,
    resetPasswordError,
    clearResetPasswordError,
  } = useContext(ResetPasswordContext);

  const usePlusSignInEmailAddress =
    config.test.usePlusSignInEmailAddress.enabled;

  const [email, setEmail] = useState("");
  const intl = useIntl();
  const history = useHistory();
  const errorController = useErrorController();

  useEffect(() => {
    if (resetPasswordError === ResultCodes.NOT_FOUND_ERROR) {
      errorController.setValidationError(
        emailValidationFieldName,
        intl.formatMessage(messages.resendPasswordEmailNofFoundError)
      );
    }
  }, [resetPasswordError]);

  useEffect(() => {
    if (resetPasswordData?.email && email && !resetPasswordIsLoading) {
      history.replace(routes.sendEmail);
    }
  }, [resetPasswordData, resetPasswordIsLoading]);

  const handleSubmit = (email: string) => {
    clearResetPasswordError();
    errorController.clearValidationError(emailValidationFieldName);

    if (!validateEmail(email, usePlusSignInEmailAddress)) {
      errorController.setValidationError(
        emailValidationFieldName,
        intl.formatMessage(messages.resendPasswordInvalidEmailError)
      );
    } else {
      resetPassword(email);
    }
  };

  const getErrorMessage = () => {
    switch (resetPasswordError) {
      case ResultCodes.USER_IS_LOCKED_OUT:
        return intl.formatMessage(messages.resendPasswordEmailIsLockedOut, {
          unlockLink: (
            <Link to={routes.unlock}>
              {intl.formatMessage(messages.resendPasswordEmailUnlock)}
            </Link>
          ),
        });
      case ResultCodes.INVALID_PROVIDER_TYPE:
        return intl.formatMessage(
          messages.resendPasswordInvalidProviderTypeError,
          {
            support: (
              <LinkHeap
                target="_blank"
                key="privacyPolicy"
                rel="noreferrer"
                to={{ pathname: acknowledgements.links.contactUs }}
                heapEventName={HEAP_EVENTS.upp_click_contactnatera}
                heapEventProps={{
                  location: HeapEventLocation.reset_password_error,
                }}
              >
                {intl.formatMessage(messages.resendPasswordContactSupport)}
              </LinkHeap>
            ),
          }
        );
      default:
        return "";
    }
  };

  const handleChange = (emailValue: string) => {
    clearResetPasswordError();
    errorController.clearValidationError(emailValidationFieldName);
    setEmail(emailValue);
  };

  const title: ReactElement = (
    <section className="title__container">
      <h1>{intl.formatMessage(messages.resendPasswordTitle)}</h1>
      <p>{intl.formatMessage(messages.resendPasswordInstructions)}</p>
    </section>
  );

  const resetPasswordForm: ReactElement = (
    <section className="form__container">
      <ErrorProvider controller={errorController}>
        <ResetPasswordForm
          onSubmit={handleSubmit}
          onChangeEmail={handleChange}
          helpText={resetPasswordError ? getErrorMessage() : undefined}
        />
      </ErrorProvider>
    </section>
  );

  return (
    <article className="reset-password__container">
      <Progress className="reset-password-progress" value={50} />
      {title}
      {resetPasswordForm}
    </article>
  );
};

export default ResetPassword;
