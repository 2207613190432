import React, { FC, ReactElement, useContext, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import { DateRange as RangeModifier } from "@natera/material/lib/date";
import { TestHistoryContext } from "@app/provider";
import DayRangePicker from "@app/components/dayRangePicker";
import { handleKeyDownByRef } from "@app/utils/handleKeyDown";

import "./dateRange.scss";

const messages = defineMessages({
  selectDateRange: {
    id: "selectDateRange",
    defaultMessage: "Select date range",
  },
});

interface Props {
  content?: ReactElement;
  actions?: ReactElement;
  handleClose?: () => undefined;
  rangeDate: (date$: RangeModifier) => void;
}

const DateRange: FC<Props> = ({ rangeDate }) => {
  const intl = useIntl();
  const [day, setDay] = React.useState<RangeModifier | undefined>(undefined);
  const { getTestHistory, reset } = useContext(TestHistoryContext);

  const divRef = useRef<HTMLDivElement | null>(null);

  const onSelectRange = (date$: RangeModifier) => {
    setDay(date$);
    rangeDate(date$);

    if (date$) {
      date$.from?.setHours(0, 0, 0, 0);
      date$.to?.setHours(23, 59, 59, 999);
      getTestHistory(date$.from, date$.to, 0);
    } else {
      reset();
      getTestHistory();
    }
  };

  return (
    <div
      ref={divRef}
      className="date-range"
      onKeyDown={handleKeyDownByRef(divRef)}
    >
      <DayRangePicker
        name="from"
        value={
          day?.from && day.to
            ? `${day.from.toLocaleDateString(
                "en-US"
              )} - ${day.to.toLocaleDateString("en-US")}`
            : `${intl.formatMessage(messages.selectDateRange)}`
        }
        onSelectRange={onSelectRange}
      />
    </div>
  );
};

export default DateRange;
