import React, { FC } from "react";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/form";

import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  successModalCreateSuccessMessage: {
    id: "successModalCreateSuccessMessage",
    defaultMessage: "Your request has been submitted",
  },
  successModalModifySuccessMessage: {
    id: "successModalModifySuccessMessage",
    defaultMessage: "Your request has been updated",
  },
  successModalSuccessMessageSubmit: {
    id: "successModalSuccessMessageSubmit",
    defaultMessage: "See Details",
  },
});

export interface SuccessModalProps {
  isAfterModify: boolean;
  handleCloseDialog: () => void;
}

const SuccessModal: FC<SuccessModalProps> = ({
  isAfterModify,
  handleCloseDialog,
}) => {
  const intl = useIntl();

  const handleClick = () => handleCloseDialog();

  return (
    <Dialog
      className="mobile-phlebotomy-modal"
      onClose={handleClick}
      showCrossButton
      title={
        isAfterModify
          ? intl.formatMessage(messages.successModalModifySuccessMessage)
          : intl.formatMessage(messages.successModalCreateSuccessMessage)
      }
      actions={
        <Button raised onClick={handleClick}>
          {intl.formatMessage(messages.successModalSuccessMessageSubmit)}
        </Button>
      }
    >
      <></>
    </Dialog>
  );
};

export default SuccessModal;
