import Svg from "@natera/material/lib/svg";
import { Button } from "@natera/form";
import React, { FC, useContext, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { LoadingContext } from "@natera/platform/lib/components/context";
import { Link } from "@natera/navigation";

import { TestCardStatus, TestType } from "@app/provider/testData/types";
import {
  ConfigContext,
  NotificationContext,
  ProfileContext,
  TestResultContext,
  ViewResultContext,
} from "@app/provider";
import { routes } from "@app/routing";
import { openNewWindow } from "@app/utils";
import SubCard from "@app/components/subCard/subCard";
import { getResultReleasedAtMessage } from "@app/utils/getResultReleasedAtMessage";
import useTestResultsTexts from "@app/hooks/useTestResultsTexts";
import SupplementalMaterialsForWH from "./supplementalMaterialsForWH";
import { HEAP_EVENTS, HeapEventLocation } from "@app/provider/types";
import { messages as headerMessages } from "@app/components/ui/layout/blueHeader/blueHeader";
import PreTestInfo from "./preTestInfo";
import { getProductLink } from "@app/utils/getProductLink";
import { TestDetailsContext } from "@app/provider/testData";
import { NevaWrapper } from "@app/neva/nevaWrapper";
import { GenderOption } from "@app/provider/testResult";
import MyResultCard from "@app/components/myResultCard";

import Message from "@assets/svg/icons/message2.svg";
import Arrow from "@assets/svg/icons/arrow.svg";
import PortalsComputerSupport from "@assets/svg/portals_computer_support.svg";

import DownloadableResultsGuideSubCard from "../downloadableResultsGuide/downloadableResultsGuideSubCard";
import "./testResultDetails.scss";

const messages = defineMessages({
  received: {
    id: "received",
    defaultMessage: "Received",
  },
  receivedOn: {
    id: "receivedOn",
    defaultMessage: "Received on {date}",
  },
  viewResults: {
    id: "viewResults",
    defaultMessage: "View results",
  },
  additionalDetails: {
    id: "additionalDetails",
    defaultMessage: "Additional details",
  },
  exploreRelatedResources: {
    id: "exploreRelatedResources",
    defaultMessage: "Explore related resources",
  },
  moreAbout: {
    id: "moreAboutProduct",
    defaultMessage: "More about\n {product}",
  },
  scheduleSession: {
    id: "scheduleSession",
    defaultMessage: "Schedule a genetic information session",
  },
  shareFetalSexButton: {
    id: "shareFetalSexButton",
    defaultMessage: "Share fetal sex with someone else",
  },
  backToResultDetails: {
    id: "backToResultDetails",
    defaultMessage: "Results Detail",
  },
  myResults: {
    id: "myResults",
    defaultMessage: "My Results",
  },
});

interface HistoryWithState {
  from?: string;
}

const TestResultDetailsPage: FC = () => {
  const intl = useIntl();
  const history = useHistory<HistoryWithState | undefined>();
  const { config } = React.useContext(ConfigContext);
  const {
    isLoading,
    getTestDetails,
    getOrderUid,
    getTestUid,
    refetchTest,
  } = useContext(TestDetailsContext);
  const {
    testResult,
    getTestResult,
    getTestResultError,
    isLoading: isLoadingResults,
  } = useContext(TestResultContext);
  const { addNotification } = useContext(NotificationContext);

  const { openResultPage, openShareFetalSexPage } = useContext(
    ViewResultContext
  );

  const testResultsTexts = useTestResultsTexts();

  const testCard = getTestDetails();
  const testUid = getTestUid();
  const orderUid = getOrderUid();

  const { isLoading: isLoadingProfile } = React.useContext(ProfileContext);

  const testTitle =
    testResult?.testType &&
    testResultsTexts.getTestResultDetailsTitle(testResult?.testType);
  const testSubTitle =
    testResult?.testType &&
    testResultsTexts.getTestResultDetailsSubTitle(testResult?.testType);

  useEffect(() => {
    if (orderUid && testUid && !getTestResultError && !isLoadingResults) {
      getTestResult(orderUid, testUid);
    }
  }, []);

  useEffect(() => {
    if (getTestResultError) {
      addNotification({ type: "error" });
    }
  }, [getTestResultError]);

  if (!testResult || !testCard) {
    return null;
  }

  const onScheduleSession = () => {
    const link =
      testResult.testType === TestType.ALTERA ||
      testResult.testType === TestType.SIGNATERA
        ? `${config.links.SCHEDULE_SESSION_ONCOLOGY}`
        : `${config.links.PATIENT_PORTAL_LINK}${config.links.SCHEDULE_SESSION}`;

    openNewWindow(link);
    heap.track(HEAP_EVENTS.upp_click_gis, {
      location: HeapEventLocation.resultDetailPage,
    });
  };

  const openResultPdf = async (documentUid: string) => {
    let testDetails = testCard;
    if (testCard?.status === TestCardStatus.RESULT_READY) {
      testDetails = await refetchTest();
    }
    testDetails &&
      openResultPage({ ...testDetails, latestResultDocumentUid: documentUid });
  };

  const backButtonRoute = () => {
    if (history.location.state?.from) {
      return history.location.state.from;
    } else {
      return routes.home;
    }
  };

  const resultReleasedAt = getResultReleasedAtMessage(
    testCard,
    intl,
    messages.receivedOn,
    messages.received
  );

  const displayShareFetalSexButton =
    testResult.testType === TestType.PANORAMA &&
    testResult.releasedToPatientDate &&
    !testResult.caPnsSupplemental &&
    testResult?.fetalSex &&
    [GenderOption.FEMALE, GenderOption.MALE].includes(testResult.fetalSex);

  const onShareFetalSexClick = () => {
    openShareFetalSexPage(testCard);
    heap.track(HEAP_EVENTS.upp_fetalsexreporting_click, {
      lims_clinic_id: testCard.clinic?.limsId,
      order_uid: testCard.orderUid,
    });
  };

  const documentsToDisplay = testResult.resultDocuments;

  return (
    <LoadingContext
      isLoading={isLoading || isLoadingResults || isLoadingProfile}
    >
      <div
        className="result-details-container"
        data-testid="result-details-page"
      >
        <div className="result-header">
          <div className="result-header-title">
            <Link
              to={backButtonRoute}
              role="button"
              aria-label={intl.formatMessage(headerMessages.blueHeaderBack)}
            >
              <Svg content={Arrow} />
            </Link>
            <span>{intl.formatMessage(messages.backToResultDetails)}</span>
          </div>
        </div>
        <section className="result-card">
          <div className="result-card-header">
            <h2 className="result-test-title">{testTitle}</h2>
            <h3 className="result-sub-title">{testSubTitle}</h3>
            <h3 className="result-sub-title">{resultReleasedAt}</h3>
          </div>
          <div className="result-card-main">
            <h3>{intl.formatMessage(messages.myResults)}</h3>
            {documentsToDisplay && (
              <>
                {documentsToDisplay.map((doc) => {
                  return (
                    <MyResultCard
                      key={doc.document?.uid}
                      doc={doc}
                      openResultPdf={openResultPdf}
                    />
                  );
                })}
              </>
            )}
          </div>
          <div className="result-card-footer">
            <SupplementalMaterialsForWH testResult={testResult} />
            <h3>{intl.formatMessage(messages.exploreRelatedResources)}</h3>
            <div className="row">
              <Link
                to={{
                  pathname: getProductLink({
                    testResult,
                    productLinks: config.links.product,
                  }),
                }}
                target="_blank"
                className="link-button sub-card"
              >
                <SubCard
                  icon={PortalsComputerSupport}
                  title={intl.formatMessage(messages.moreAbout, {
                    product: testTitle,
                  })}
                />
              </Link>
              <PreTestInfo testResult={testResult} />
              <DownloadableResultsGuideSubCard testResult={testResult} />
            </div>

            <div className="button-container">
              {testResult.testType !== TestType.PROSPERA && (
                <Button
                  onClick={onScheduleSession}
                  icon={Message}
                  className="btn-schedule-session"
                  outlined
                >
                  {intl.formatMessage(messages.scheduleSession)}
                </Button>
              )}
              {displayShareFetalSexButton && (
                <Button
                  onClick={onShareFetalSexClick}
                  className="btn-share-fetal-sex"
                  outlined
                >
                  {intl.formatMessage(messages.shareFetalSexButton)}
                </Button>
              )}
            </div>
          </div>
        </section>
      </div>
      <NevaWrapper />
    </LoadingContext>
  );
};
export default TestResultDetailsPage;
