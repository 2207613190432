import * as React from "react";
import { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useVideo } from "@app/hooks";
import { Button } from "@natera/form";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { routes } from "@app/routing";
import { VideoType } from "@app/components/videoPlayer";
import { Notification, VideoPlayer } from "@app/components";
import { HeapVideoContentType, HeapVideoOrderData } from "@app/provider/types";
import { Link } from "@natera/navigation";
import "./videoPage.scss";
import { TestCardContext, TestCardProvider } from "@app/provider";
import { getSalivaTestCards } from "@app/utils/getSalivaTestCards";
import { LoadingContext } from "@natera/platform/lib/components/context";

const messages = defineMessages({
  videoNextBtnTitle: {
    id: "videoNextBtnTitle",
    defaultMessage: "Next",
  },
  videoPageNotification: {
    id: "videoPageNotification",
    defaultMessage: "There is no such video",
  },
  videoPageNotificationLink: {
    id: "videoPageNotificationLink",
    defaultMessage: "Go back",
  },
});

const VideoPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const videoController = useVideo();
  const { getPrimaryTestCards, primaryTestCardsLoading } = useContext(
    TestCardContext
  );

  const { videoType, orderUid } = useParams() as {
    videoType: VideoType;
    orderUid?: string;
  };

  const videoInfo = videoController.getVideoInfo(videoType);

  const [noVideo, setNoVideo] = React.useState(false);

  const [showVideo, setShowVideo] = React.useState(false);

  const [heapVideoOrderData, setHeapVideoOrderData] = React.useState<
    HeapVideoOrderData
  >();

  React.useEffect(() => {
    (async () => {
      if (!Object.values(VideoType).includes(videoType)) {
        setNoVideo(true);
        return;
      }

      if (videoType === VideoType.SALIVA) {
        const testCards = await getPrimaryTestCards();
        const salivaTestCards = getSalivaTestCards(testCards);
        if (salivaTestCards.length) {
          const salivaTestCard = salivaTestCards[0];
          setHeapVideoOrderData({
            order_uid: salivaTestCard.orderUid,
            lims_clinic_id: salivaTestCard.clinic?.limsId,
          });
        }
      }

      setShowVideo(true);
    })();
  }, [videoType]);

  const handleNext = () => {
    if (orderUid) {
      history.push(routes.simpleOrderPage(orderUid));
    }
  };

  const heapVideoContentType = React.useMemo(() => {
    switch (videoType) {
      case VideoType.HORIZON:
        return HeapVideoContentType.horizonSimpleOrder;
      case VideoType.PANORAMA:
        return HeapVideoContentType.panoramaSimpleOrder;
      case VideoType.SALIVA:
        return HeapVideoContentType.sampleCollectionSaliva;
      default:
        return;
    }
  }, [videoType]);

  return (
    <div className="video-page">
      {!noVideo && videoInfo ? (
        <BlueHeaderContent
          blueHeaderTitle={videoInfo.pageTitle}
          crossHeaderRoute={routes.home}
          fullScreen
        >
          <section className="container">
            <LoadingContext isLoading={primaryTestCardsLoading}>
              {showVideo && (
                <div className="video-content">
                  <VideoPlayer
                    id={videoInfo.videoHashId}
                    thumbnailAltText={videoInfo.thumbnailAltText}
                    heapVideoContentType={heapVideoContentType}
                    heapVideoOrderData={heapVideoOrderData}
                  />
                  <div className="video-description">
                    <h3 className="video-description__title">
                      {videoInfo.videoDescTitle}
                    </h3>
                    <p className="video-description__text">
                      {videoInfo.videoDescText}
                    </p>
                  </div>
                </div>
              )}
            </LoadingContext>
            <div className="video-btns">
              {videoInfo.next && (
                <Button raised onClick={handleNext}>
                  {intl.formatMessage(messages.videoNextBtnTitle)}
                </Button>
              )}
            </div>
          </section>
        </BlueHeaderContent>
      ) : (
        <div className="notification">
          <Notification className="notification-msg" type="note">
            <p>{intl.formatMessage(messages.videoPageNotification)}</p>
            <Link to={routes.home}>
              {intl.formatMessage(messages.videoPageNotificationLink)}
            </Link>
          </Notification>
        </div>
      )}
    </div>
  );
};

const VideoPage$: React.FC = () => {
  return (
    <TestCardProvider>
      <VideoPage />
    </TestCardProvider>
  );
};

export default VideoPage$;
