import React, { FC, useEffect, useState, useMemo, useContext } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useIntl } from "react-intl";
import { ConfigContext, Language } from "@app/provider";
import { PreBillAPIContext } from "@app/preBill/provider";
import { SpinnerView } from "@app/components";
import PaymentForm from "../payment-form";

interface PaymentContainerProps {
  onSave: (status: "success" | "failure") => void;
  onBack: () => void;
}

const PaymentContainer: FC<PaymentContainerProps> = ({ onSave, onBack }) => {
  const { config } = useContext(ConfigContext);
  const { getSetupIntent } = useContext(PreBillAPIContext);
  const publishableKey = config.preBill.stripeKey;
  const { locale } = useIntl();
  const [clientSecret, setClientSecret] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const currentLocale = locale === "es" ? Language.ES : Language.EN;

  const stripePromise = useMemo(
    () => loadStripe(publishableKey, { locale: currentLocale }),
    [currentLocale]
  );

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await getSetupIntent();
        setClientSecret(response.client_secret);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    };

    fetchClientSecret();
  }, [locale]);

  const elementsOptions = useMemo(() => ({ clientSecret }), [clientSecret]);

  return (
    <>
      <SpinnerView isLoading={loading} />
      {clientSecret && (
        <Elements
          stripe={stripePromise}
          options={elementsOptions}
          key={currentLocale}
        >
          <PaymentForm onBack={onBack} onSave={onSave} />
        </Elements>
      )}
    </>
  );
};

export default PaymentContainer;
