import React from "react";
import "./ncsPaymentStep.scss";
import PaymentContainer from "@app/preBill/components/paymentContainer/paymentContainer";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { defineMessages, useIntl } from "react-intl";
import { StepperContext } from "@natera/stepper";
import { NonCoveredServiceStepsType } from "../../nonCoveredServiceStepper";

const messages = defineMessages({
  ncsPaymentTitle: {
    id: "ncsPaymentTitle",
    defaultMessage: "Provide payment method",
  },
});

interface NcsPaymentProps {
  onPaymentResult: (status: "success" | "failure") => void;
}

const NcsPayment: React.FC<NcsPaymentProps> = ({ onPaymentResult }) => {
  const { formatMessage } = useIntl();
  const { goBack, resolve, reset, goAt } = React.useContext(StepperContext);

  const handleBackButtonClick = () => {
    reset();
    goBack();
  };

  const handleSaveButtonClick = (status: "success" | "failure") => {
    onPaymentResult(status);
    resolve("step 3 resolved");
    if (status === "success") goAt(NonCoveredServiceStepsType.NCS_SUCCESS);
    else goAt(NonCoveredServiceStepsType.NCS_FAILURE);
  };

  return (
    <>
      <StepperHeaderProgress />
      <div className="ncs-payment">
        <StepperContent title={formatMessage(messages.ncsPaymentTitle)}>
          <PaymentContainer
            onBack={handleBackButtonClick}
            onSave={handleSaveButtonClick}
          />
        </StepperContent>
      </div>
    </>
  );
};

export default NcsPayment;
