import React, { useState } from "react";
import { useIntl } from "react-intl";
import { HeapPHIDecorator } from "@app/components/analytics";
import STATES from "@etc/states.json";
import { messages } from "../messages";
import { Option, Select } from "@natera/form";
import { GetValidationError } from "@natera/platform/lib/hooks/useError";
import { FormField } from "@app/components";

const StateField: React.FC<{
  getValidationError: GetValidationError;
  defaultValue?: string;
}> = ({ getValidationError, defaultValue }) => {
  const intl = useIntl();
  const [selectedState, setSelectedState] = useState<string | undefined>(
    defaultValue
  );

  const handleValueChange = (value: string) => {
    setSelectedState(value);
  };

  return (
    <FormField
      title={intl.formatMessage(messages.addressFormState)}
      label={intl.formatMessage(messages.addressFormState)}
      error={getValidationError("state")}
      required
      id="state"
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Select
          outlined
          required
          labelId="state"
          name="state"
          data-testid="state"
          role="combobox"
          placeholder={intl.formatMessage(messages.addressFormStatePlaceholder)}
          value={selectedState}
          onValueChange={handleValueChange}
          floating={true}
        >
          {STATES.map((item) => (
            <Option key={item.abbreviation} value={item.abbreviation}>
              {item.name}
            </Option>
          ))}
        </Select>
      </HeapPHIDecorator>
    </FormField>
  );
};

export { StateField };
