import React, { useState } from "react";
import { Step, Stepper } from "@natera/stepper";
import { LoadingContext } from "@natera/platform/lib/components/context";
import {
  NcsInformation,
  NcsCostInformation,
  NcsPaymentConclusion,
} from "./steps";
import NcsPayment from "./steps/ncsPaymentStep/ncsPaymentStep";
import { NcsPaymentFailure } from "./steps/ncsPaymentFailure";
import { BillingNextStep } from "../billingEstimateStepper/steps";
import { PaymentChoice } from "@app/preBill/models";

import "./../../main.scss";

export enum NonCoveredServiceStepsType {
  NCS_INFORMATION = "NCS_INFORMATION",
  NCS_PAYMENT_DETAILS = "NCS_PAYMENT_DETAILS",
  NCS_PAYMENT_METHOD = "NCS_PAYMENT_METHOD",
  NCS_SUCCESS = "NCS_SUCCES",
  NCS_FAILURE = "NCS_FAILURE",
  BILLING_NEXT_STEPS = "BILLING_NEXT_STEPS",
}

const NonCoveredServiceStepper: React.FC = () => {
  const [paymentStatus, setPaymentStatus] = useState<"success" | "failure">(
    "success"
  );
  const [paymentSelect, setPaymentSelect] = useState<
    PaymentChoice.CASHPAY | PaymentChoice.INSURANCE
  >(PaymentChoice.CASHPAY);

  return (
    <LoadingContext isLoading={false}>
      <Stepper
        initialStep={NonCoveredServiceStepsType.NCS_INFORMATION}
        progressStepper
        className="ncs-stepper stepper"
      >
        <Step stepId={NonCoveredServiceStepsType.NCS_INFORMATION}>
          <NcsInformation />
        </Step>
        <Step stepId={NonCoveredServiceStepsType.NCS_PAYMENT_DETAILS}>
          <NcsCostInformation
            onPaymentSelect={(
              value: PaymentChoice.CASHPAY | PaymentChoice.INSURANCE
            ) => setPaymentSelect(value)}
          />
        </Step>
        {paymentSelect === PaymentChoice.INSURANCE && (
          <Step stepId={NonCoveredServiceStepsType.BILLING_NEXT_STEPS}>
            <BillingNextStep />
          </Step>
        )}
        {paymentSelect === PaymentChoice.CASHPAY && (
          <Step stepId={NonCoveredServiceStepsType.NCS_PAYMENT_METHOD}>
            <NcsPayment
              onPaymentResult={(status: "success" | "failure") =>
                setPaymentStatus(status)
              }
            />
          </Step>
        )}
        {paymentSelect === PaymentChoice.CASHPAY &&
          paymentStatus === "success" && (
            <Step stepId={NonCoveredServiceStepsType.NCS_SUCCESS}>
              <NcsPaymentConclusion />
            </Step>
          )}
        {paymentSelect === PaymentChoice.CASHPAY &&
          paymentStatus === "failure" && (
            <Step stepId={NonCoveredServiceStepsType.NCS_FAILURE}>
              <NcsPaymentFailure />
            </Step>
          )}
      </Stepper>
    </LoadingContext>
  );
};

export default NonCoveredServiceStepper;
