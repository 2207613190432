import React, { FC, useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { StepperContext } from "@natera/stepper";
import { Button, Checkbox } from "@natera/form";
import { useDialog } from "@natera/platform/lib/hooks";
import PatientAcknowledgmentDialog from "@app/components/patientAcknowledgmentDialog";
import { routes } from "@app/routing";
import { Order } from "@app/provider/order";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { Steps } from "../../simpleOrderStepper";
import {
  PatientAcknowledgmentStepResolve,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import AcknowledgmentContent from "@app/components/simpleOrder/acknowledgment/acknowledgmentContent";
import { SimpleOrderContext } from "@app/provider";
import { getTestNameForHeap } from "@app/utils";
import { CancelSimpleOrderTestDialogFail } from "@app/components";

import "@app/components/simpleOrder/acknowledgment/acknowledgment.scss";

const acknowledgmentContentMessages = defineMessages({
  patientAcknowledgmentContentParagraph1: {
    id: "patientAcknowledgmentContentParagraph1",
    defaultMessage:
      "I have been informed of and understand the details of tests ordered herein for me by my health care provider, including the risks, benefits and alternative and I have consented to testing.",
  },
  patientAcknowledgmentContentParagraph2: {
    id: "patientAcknowledgmentContentParagraph2",
    defaultMessage:
      "I understand that the test results may inform me of a genetic condition that is present in myself or my partner that my require follow-up.",
  },
  patientAcknowledgmentContentParagraph3: {
    id: "patientAcknowledgmentContentParagraph3",
    defaultMessage:
      "I also understand that negative results do not rule out the possibility of a genetic condition in the fetus, myself and/or my partner.",
  },
  patientAcknowledgmentContentParagraph4: {
    id: "patientAcknowledgmentContentParagraph4",
    defaultMessage:
      'I authorize Natera or other provider to share the information on this form and my test results with my health insurer/health plan ("plan") on my behalf, with all my benefits of my plan made payable directly to Natera or other provider.',
  },
  patientAcknowledgmentContentParagraph5: {
    id: "patientAcknowledgmentContentParagraph5",
    defaultMessage:
      'I understand that I am responsible for any amount not paid by my plan directly to Natera for test ordered, including, without limitation, a) any copayments, deductibles or amounts deemed "patient responsibility" or b) any amount paid to me by my plan.',
  },
  patientAcknowledgmentContentParagraph6: {
    id: "patientAcknowledgmentContentParagraph6",
    defaultMessage:
      "This testing will not be covered by my plan if it is outside of the plan's coverage guidelines or deemed not medically necessary (e.g. where prior authorization is required but not obtained) and I will be responsible for the cost of such testing per test, discount may apply.",
  },
  patientAcknowledgmentContentParagraph7: {
    id: "patientAcknowledgmentContentParagraph7",
    defaultMessage:
      "I assign to Natera the right appeal on my behalf negative coverage decisions made by my plan and to assert of rights and claims reserved to me as the beneficiary thereof.",
  },
  patientAcknowledgmentContentParagraph8: {
    id: "patientAcknowledgmentContentParagraph8",
    defaultMessage:
      "The information obtained from my tests may be used in scientific publication or presentation, but my specific identity will not be revealed.",
  },
  patientAcknowledgmentContentParagraph9: {
    id: "patientAcknowledgmentContentParagraph9",
    defaultMessage:
      "Natera may reach out to my healthcare provider to obtain more information regarding clinical correlation and confirmatory testing.",
  },
});

const messages = defineMessages({
  patientAcknowledgmentTitle: {
    id: "patientAcknowledgmentTitle",
    defaultMessage: "Patient Acknowledgment:",
  },
  patientAcknowledgmentSubTitle: {
    id: "patientAcknowledgmentSubTitle",
    defaultMessage: "Proceed with the test",
  },
  patientAcknowledgmentFooterDisagreeButton: {
    id: "patientAcknowledgmentFooterDisagreeButton",
    defaultMessage: "Disagree and cancel test",
  },
  patientAcknowledgmentFooterAgreeButton: {
    id: "patientAcknowledgmentFooterAgreeButton",
    defaultMessage: "Agree and continue to signature",
  },
  patientAcknowledgmentFooterBackButton: {
    id: "patientAcknowledgmentFooterBackButton",
    defaultMessage: "Back",
  },
  patientAcknowledgmentFooterCheckboxText: {
    id: "patientAcknowledgmentFooterCheckboxText",
    defaultMessage:
      "I permit Natera to use my samples for research and development; Otherwise, my samples will be discarded within 60 days of testing.",
  },
});

const newYorkStateAbbreviation = "NY";

export interface PatientAcknowledgmentProps {
  orderData: Order;
  goBack: () => void;
  resolve: (value: PatientAcknowledgmentStepResolve) => void;
}

const PatientAcknowledgment: FC<PatientAcknowledgmentProps> = ({
  orderData,
  goBack,
  resolve,
}) => {
  const intl = useIntl();
  const { getData } = React.useContext(StepperContext);
  const { getSubStepData } = useContext(SimpleOrderStepperContext);
  const {
    cancelSimpleOrder,
    cancelSimpleOrderData,
    cancelSimpleOrderError,
  } = React.useContext(SimpleOrderContext);

  const cancelSimpleOrderTestDialogFail = useDialog(
    CancelSimpleOrderTestDialogFail
  );

  const limsId = orderData?.clinic?.limsId?.toString();
  const testNameForHeap = getTestNameForHeap(orderData);
  const history = useHistory();
  const initValue = getSubStepData(
    Steps.ACKNOWLEDGMENT
  ) as PatientAcknowledgmentStepResolve;

  const initConsentNYResident = initValue?.consentNYResident ?? false;

  const [consentNYResident, setConsentNYResident] = useState<boolean>(
    initConsentNYResident
  );

  const patientAcknowledgmentDialog = useDialog(PatientAcknowledgmentDialog);

  const clinicState = orderData.clinic?.address?.state;
  const patientState = getData(Steps.KIT_SHIPPING_DETAILS)?.address?.state;

  const showConsentNYResidentCheckbox =
    clinicState === newYorkStateAbbreviation ||
    patientState === newYorkStateAbbreviation;

  const handleCheckboxClick = () => {
    setConsentNYResident(!consentNYResident);
  };

  const onDisagreeClick = () => {
    patientAcknowledgmentDialog.open({
      onContinueCancelling: () => {
        limsId && cancelSimpleOrder(testNameForHeap, limsId);
      },
      onClose: patientAcknowledgmentDialog.close,
    });
  };

  const onAgreeClick = () => {
    const acknowledgmentText = document.getElementById("acknowledgment-info")
      ?.innerText;
    resolve({ consentNYResident, acknowledgmentText });
  };

  React.useEffect(() => {
    if (cancelSimpleOrderError) {
      openCancelSimpleOrderTestDialogFail();
    }
  }, [cancelSimpleOrderError]);

  React.useEffect(() => {
    if (cancelSimpleOrderData?.success) {
      history.push(routes.home);
    }
  }, [cancelSimpleOrderData]);

  const openCancelSimpleOrderTestDialogFail = () => {
    patientAcknowledgmentDialog.close();
    cancelSimpleOrderTestDialogFail.open({
      onClose: () => {
        cancelSimpleOrderTestDialogFail.close();
      },
    });
  };

  const handlePreviousStep = () => {
    goBack();
  };

  return (
    <div className="acknowledgment">
      {patientAcknowledgmentDialog.getDialog()}
      {cancelSimpleOrderTestDialogFail.getDialog()}
      <StepperHeaderProgress />
      <StepperContent
        title={intl.formatMessage(messages.patientAcknowledgmentTitle)}
        subTitle={intl.formatMessage(messages.patientAcknowledgmentSubTitle)}
      >
        <AcknowledgmentContent
          actions={
            <>
              <Button outlined onClick={onDisagreeClick}>
                {intl.formatMessage(
                  messages.patientAcknowledgmentFooterDisagreeButton
                )}
              </Button>
              <Button raised onClick={onAgreeClick}>
                {intl.formatMessage(
                  messages.patientAcknowledgmentFooterAgreeButton
                )}
              </Button>
              <Button onClick={handlePreviousStep}>
                {intl.formatMessage(
                  messages.patientAcknowledgmentFooterBackButton
                )}
              </Button>
            </>
          }
          checkbox={
            showConsentNYResidentCheckbox && (
              <>
                <hr />
                <div className="acknowledgment__container__checkbox">
                  <Checkbox
                    id="patient-acknowledgment-checkbox"
                    className="acknowledgment__container__checkbox-box"
                    checked={consentNYResident}
                    onChange={handleCheckboxClick}
                  />
                  <span className="acknowledgment__container__checkbox-label">
                    {intl.formatMessage(
                      messages.patientAcknowledgmentFooterCheckboxText
                    )}
                  </span>
                </div>
              </>
            )
          }
        >
          <>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.patientAcknowledgmentContentParagraph1
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.patientAcknowledgmentContentParagraph2
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.patientAcknowledgmentContentParagraph3
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.patientAcknowledgmentContentParagraph4
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.patientAcknowledgmentContentParagraph5
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.patientAcknowledgmentContentParagraph6
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.patientAcknowledgmentContentParagraph7
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.patientAcknowledgmentContentParagraph8
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.patientAcknowledgmentContentParagraph9
              )}
            </p>
          </>
        </AcknowledgmentContent>
      </StepperContent>
    </div>
  );
};

export default PatientAcknowledgment;
