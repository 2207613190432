import { HttpService } from "@natera/platform/lib/service/http";

declare global {
  interface Navigator {
    msSaveBlob?: (blob: unknown, defaultName?: string) => boolean;
  }
}

class FileService extends HttpService {
  public getFile(url?: string): Promise<File> {
    return this.fetchFile(url);
  }

  public retrieveProtectedFile(
    url: string,
    body: { password: string }
  ): Promise<File> {
    return this.fetchFile(
      url,
      {},
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      }
    );
  }

  public download(blobObj: File, fileName: string): void {
    // IE
    if (navigator && navigator.msSaveBlob) {
      navigator.msSaveBlob(
        blobObj,
        blobObj.name || fileName + this.getExtension(blobObj.type)
      );
      return;
    }

    const url = window.URL.createObjectURL(blobObj);
    const a = document.createElement("a");
    a.href = url;
    a.download = blobObj.name || fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  public downloadStatic(url: string, fileName: string): void {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private getExtension(mimeType: string): string {
    let fileExtension = "";
    if (mimeType.indexOf("application") === 0) {
      fileExtension = `.${mimeType.substr(12)}`;
    }
    return fileExtension;
  }
}

export default FileService;
