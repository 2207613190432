import React, { FC, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Dialog } from "@natera/material/lib/dialog";
import { Button, Checkbox } from "@natera/form";
import { NotificationContext, SignUpContext, UserContext } from "@app/provider";
import acknowledgements from "@etc/acknowledgements.json";
import "./acknowledgmentsDialog.scss";

const messages = defineMessages({
  acknowledgementsDialogTitle: {
    id: "acknowledgementsDialogTitle",
    defaultMessage: "Acknowledgements",
  },
  acknowledgementsDialogAcception: {
    id: "acknowledgementsDialogAcception",
    defaultMessage:
      "I've read and accepted Natera's {termsOfUse}, {privacyPolicy}, and {noticeOfPrivacyPractices}.",
  },

  acknowledgementsDialogCookies: {
    id: "acknowledgementsDialogCookies",
    defaultMessage:
      "This site uses cookies to enhance your experience. Any cookies preferences selected on {nateraMainPage} will not apply.",
  },

  acknowledgementsDialogNateraMainPageLink: {
    id: "acknowledgementsDialogNateraMainPageLink",
    defaultMessage: "Natera.com",
  },

  acknowledgementsDialogTermsOfUse: {
    id: "acknowledgementsDialogTermsOfUse",
    defaultMessage: "Terms of Use",
  },
  acknowledgementsDialogPrivacyPolicy: {
    id: "acknowledgementsDialogPrivacyPolicy",
    defaultMessage: "Privacy Policy",
  },
  acknowledgementsDialogNoticeOfPrivacyPractices: {
    id: "acknowledgementsDialogNoticeOfPrivacyPractices",
    defaultMessage: "Notice of Privacy Practices",
  },
  acknowledgementsDialogAgreeAndProceed: {
    id: "acknowledgementsDialogAgreeAndProceed",
    defaultMessage: "Agree & Proceed",
  },
});

export enum ACKNOWLEDGMENT_FLOW {
  ACCOUNT = "ACCOUNT",
  GUEST = "GUEST",
}

export interface AcknowledgmentsDialogProps {
  userEmail: string;
  onAgreeAcknowledgements: () => void;
  onCloseDialog: () => void;
  flow: ACKNOWLEDGMENT_FLOW;
}

const AcknowledgementsDialog: FC<AcknowledgmentsDialogProps> = ({
  userEmail,
  onAgreeAcknowledgements,
  onCloseDialog,
  flow,
}) => {
  const intl = useIntl();
  const { addNotification, clear } = useContext(NotificationContext);
  const { signUpByPasswordIsLoading } = useContext(SignUpContext);
  const {
    addPatientAcknowledgementLoading,
    addPatientAcknowledgement,
  } = useContext(UserContext);

  const [checkboxValue, setCheckboxValue] = useState(false);
  const [dialogKey, setDialogKey] = useState(0);

  const handleSetCheckboxValue = () =>
    setCheckboxValue((oldCheckboxValue) => !oldCheckboxValue);
  useEffect(() => {
    setDialogKey((prevDialogKey) => prevDialogKey + 1);
  }, [checkboxValue]);

  const generatePatientAcknowledgementText = () => {
    const termsOfUseLink = `(${acknowledgements.links.termsOfUse})`;
    const privacyPolicyLink = `(${acknowledgements.links.privacyPolicy})`;
    const noticeOfPrivacyPracticesLink = `(${acknowledgements.links.noticeOfPrivacyPractices})`;

    const acknowledgementText = intl.formatMessage(
      messages.acknowledgementsDialogAcception,
      {
        termsOfUse: `${intl.formatMessage(
          messages.acknowledgementsDialogTermsOfUse
        )} ${termsOfUseLink}`,
        privacyPolicy: `${intl.formatMessage(
          messages.acknowledgementsDialogPrivacyPolicy
        )} ${privacyPolicyLink}`,
        noticeOfPrivacyPractices: `${intl.formatMessage(
          messages.acknowledgementsDialogNoticeOfPrivacyPractices
        )} ${noticeOfPrivacyPracticesLink}`,
      }
    );

    const acknowledgementTextCookies = intl.formatMessage(
      messages.acknowledgementsDialogCookies,
      {
        nateraMainPage: intl.formatMessage(
          messages.acknowledgementsDialogNateraMainPageLink
        ),
      }
    );

    return `${acknowledgementText}\n${acknowledgementTextCookies}`;
  };

  const onSubmit = async () => {
    try {
      if (flow !== ACKNOWLEDGMENT_FLOW.GUEST) {
        await addPatientAcknowledgement({
          email: userEmail,
          accepted: true,
          acknowledgementText: generatePatientAcknowledgementText(),
          consentTimestamp: new Date().toISOString(),
        });
      }
      onAgreeAcknowledgements();
    } catch (error) {
      addNotification({
        type: "error",
      });
    }
  };

  const handleClose = () => {
    clear();
    onCloseDialog();
  };

  return (
    <div className="acknowledgments-dialog">
      <Dialog
        key={dialogKey}
        title={intl.formatMessage(messages.acknowledgementsDialogTitle)}
        showCrossButton
        actions={
          <Button
            raised={true}
            disabled={!checkboxValue}
            loading={
              signUpByPasswordIsLoading || addPatientAcknowledgementLoading
            }
            onClick={onSubmit}
            data-testid="acknowledgementAgreeAndProceed"
          >
            {intl.formatMessage(messages.acknowledgementsDialogAgreeAndProceed)}
          </Button>
        }
        onClose={handleClose}
      >
        <div className="acknowledgement-dialog__content">
          <div className="acknowledgement-dialog__content__wrapper">
            <Checkbox
              checked={checkboxValue}
              onChange={handleSetCheckboxValue}
              data-testid="acknowledgementCheckbox"
            />
            <span>
              {intl.formatMessage(messages.acknowledgementsDialogAcception, {
                termsOfUse: (
                  <a
                    href={acknowledgements.links.termsOfUse}
                    target="_blank"
                    key="termsOfUse"
                    rel="noreferrer"
                  >
                    {intl.formatMessage(
                      messages.acknowledgementsDialogTermsOfUse
                    )}
                  </a>
                ),
                privacyPolicy: (
                  <a
                    href={acknowledgements.links.privacyPolicy}
                    target="_blank"
                    key="privacyPolicy"
                    rel="noreferrer"
                  >
                    {intl.formatMessage(
                      messages.acknowledgementsDialogPrivacyPolicy
                    )}
                  </a>
                ),
                noticeOfPrivacyPractices: (
                  <a
                    href={acknowledgements.links.noticeOfPrivacyPractices}
                    target="_blank"
                    key="noticeOfPrivacyPractices"
                    rel="noreferrer"
                  >
                    {intl.formatMessage(
                      messages.acknowledgementsDialogNoticeOfPrivacyPractices
                    )}
                  </a>
                ),
              })}
            </span>
          </div>

          <span className="acknowledgement-dialog__content__cookies">
            {intl.formatMessage(messages.acknowledgementsDialogCookies, {
              nateraMainPage: (
                <a
                  href={acknowledgements.links.nateraMainPage}
                  target="_blank"
                  key="nateraMainPage"
                  rel="noreferrer"
                >
                  {intl.formatMessage(
                    messages.acknowledgementsDialogNateraMainPageLink
                  )}
                </a>
              ),
            })}
          </span>
        </div>
      </Dialog>
    </div>
  );
};

export default AcknowledgementsDialog;
