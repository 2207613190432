export enum PreBillTestTypes {
  PANORAMA = "PANORAMA",
  HORIZON = "HORIZON",
  EMPOWER = "EMPOWER",
  PANORAMA_HORIZON = "PANORAMA_HORIZON",
}

export enum Channel {
  PTPA = "PTPA",
  NCS_V2 = "NCS_V2",
  NCS = "NCS",
  OON = "OON",
  INN = "INN",
}

export enum PaymentChoice {
  CASHPAY = "cashpay",
  INSURANCE = "insurance",
}

export enum ClosingStatus {
  CASHPAY = "CASHPAY",
  INSURANCE = "INSURANCE",
}

type Patient = {
  firstName: string;
  description: string;
  uid: string;
};

export type GetOrderEstimateResponse = {
  patient: Patient;
  testType: PreBillTestTypes[];
  channel: Channel;
  insuranceCompanyName: string;
  insuranceEstimateDate: string;
  totalInsuranceCost?: number;
  totalInsuranceCopay?: number;
  totalPrePayAmountProposed?: number;
  tokenExpireDate?: string;
  oonAverageOopMin?: number;
  oonAverageOopMax?: number;
  totalInsuranceRemainingDeductible?: number;
};

export type GetSetupIntentResponse = {
  customer: string;
  client_secret: string;
};

export interface TestTypeInformation {
  testType: string;
  description: string;
}

export interface ActiveOrder {
  testTypeInformation: TestTypeInformation[];
  tokenizedLink: string;
  lastOrderSeen: string | null;
  tokenExpireDate: string;
}

export interface ListActiveOrdersForPatient {
  activeOrders: ActiveOrder[];
}

export type GuestValidationInput = {
  day: number;
  month: number;
  year: number;
};

export type SendPaymentChoiceInput = {
  stripeCustomerId?: string;
  paymentMethodId?: string;
  paymentChoice: PaymentChoice;
};

export enum InsuranceSurveyAnswerType {
  YES = "Yes",
  NO = "No",
}

export type SendSurveyAnswerInput = {
  question: string;
  answer: InsuranceSurveyAnswerType;
};

export type SurveyAnswerResponse = { message: string };

export enum PRE_BILL_ERROR_CODES {
  ORDER_NOT_FOUND = "order_not_found",
  ORDER_NOT_EXPIRED = "order_expired",
  PATIENT_NOT_FOUND = "patient_info_not_found",
  DOB_VALIDATION = "dob_mismatch",
}

export type PreBillErrorResponse = {
  closingStatus?: ClosingStatus;
  code: PRE_BILL_ERROR_CODES;
  message: string;
};
