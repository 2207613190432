import React, { FC } from "react";
import classnames from "classnames";
import { FormField as BaseFormField, FormFieldProps } from "@natera/form";

import "./formField.scss";

interface CustomFormFieldProps extends FormFieldProps {
  withPadding?: boolean;
}

const FormField: FC<CustomFormFieldProps> = ({
  className,
  withPadding,
  ...rest
}) => {
  const fieldClassName = classnames(className, "upp-form-field", {
    "upp-form-field--with-padding": withPadding,
  });

  return <BaseFormField className={fieldClassName} {...rest} />;
};

export default FormField;
