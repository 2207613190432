import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Tooltip from "@natera/material/lib/tooltip";
import { Button, Form, Textfield } from "@natera/form";
import {
  ClearErrors,
  GetValidationError,
  SetValidationError,
} from "@natera/platform/lib/hooks/useError";
import { FormField } from "@app/components";
import { validateZipCode } from "@app/utils";
import { StepperContext } from "@natera/stepper";
import { ZipCodeData } from "@app/pages/public/drawRequest/personalInformation/stepScreens/zipCodeScreen";
import { messages } from "@app/components/forms/publicMpForms";

interface PublicMpZipCodeFormProps {
  zipCodeData?: ZipCodeData;
  isLoading?: boolean;
  onSubmit: (zipCodeData: ZipCodeData) => void;
  setValidationError: SetValidationError;
  getValidationError: GetValidationError;
  clearErrors: ClearErrors;
}

const PublicMpZipCodeForm: FC<PublicMpZipCodeFormProps> = ({
  zipCodeData,
  isLoading,
  onSubmit,
  setValidationError,
  getValidationError,
  clearErrors,
}) => {
  const intl = useIntl();

  const { goBack } = React.useContext(StepperContext);

  const [profileFormData, setProfileFormData] = useState<FormData>();

  const getZipCodeFormData = (formData: FormData) => {
    const zipCode = formData.get("zipCode")?.toString();

    return {
      zipCode,
    };
  };

  const validateProfileFormData = async (formData: FormData) => {
    const profileFormData = getZipCodeFormData(formData);

    const userForm = [{ title: "zipCode", value: profileFormData.zipCode }];

    for (const field of userForm) {
      if (!field.value) {
        return setValidationError(
          field.title,
          intl.formatMessage(messages.setupProfileFormRequired)
        );
      }
    }
    if (!profileFormData.zipCode) {
      setValidationError(
        "zipCode",
        intl.formatMessage(messages.setupProfileFormRequired)
      );

      return;
    }

    if (profileFormData.zipCode && !validateZipCode(profileFormData.zipCode)) {
      setValidationError(
        "zipCode",
        intl.formatMessage(messages.setupProfileFormInvalidZipCode)
      );
      return;
    }

    return profileFormData;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    clearErrors();

    const formData = new FormData(e.currentTarget);

    setProfileFormData(formData);

    const validatedProfileFormData = await validateProfileFormData(formData);

    if (validatedProfileFormData) {
      onSubmit({
        zipCode: validatedProfileFormData.zipCode,
      });
    }
  };

  useEffect(() => {
    if (profileFormData) {
      validateProfileFormData(profileFormData);
    }
  }, [intl.locale]);

  const handleBack = () => {
    goBack();
  };

  return (
    <Form
      className="public-mp-personal-information-form__container"
      noValidate
      onSubmit={handleSubmit}
    >
      <FormField
        label={intl.formatMessage(messages.setupProfileFormZipCode)}
        required
        error={getValidationError("zipCode")}
        htmlFor="zipCode"
        withPadding
      >
        <Tooltip
          content={intl.formatMessage(messages.setupProfileFormZipTooltip)}
        >
          <div>
            <HeapPHIDecorator protectAttr={["value"]} noWrap={true}>
              <Textfield
                placeholder={intl.formatMessage(
                  messages.setupProfileFormZipCodePlaceholder
                )}
                id="zipCode"
                name="zipCode"
                required
                outline
                defaultValue={zipCodeData?.zipCode}
              />
            </HeapPHIDecorator>
          </div>
        </Tooltip>
      </FormField>

      <div className="stepper-form-buttons">
        <Button type="submit" raised loading={isLoading}>
          {intl.formatMessage(messages.setupProfileFormNext)}
        </Button>

        <Button onClick={handleBack} outlined loading={isLoading}>
          {intl.formatMessage(messages.publicMpPersonalInfoPageBackButton)}
        </Button>
      </div>
    </Form>
  );
};

export default PublicMpZipCodeForm;
