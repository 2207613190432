import { Grid, GridCell, GridRow } from "@natera/material/lib/layout";
import { Button, Form, FormField, Textfield } from "@natera/form";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useErrorController } from "@natera/platform/lib/hooks";

const messages = defineMessages({
  provideGovernmentIssuedFormEnterNumber: {
    id: "provideGovernmentIssuedFormEnterNumber",
    defaultMessage: "Enter government-issued insurance number",
  },
  provideGovernmentIssuedFormReEnterNumber: {
    id: "provideGovernmentIssuedFormReEnterNumber",
    defaultMessage: "Re-enter government-issued insurance number",
  },
  provideGovernmentIssuedFormPlaceholder: {
    id: "provideGovernmentIssuedFormPlaceholder",
    defaultMessage: "Enter insurance number",
  },
  provideGovernmentIssuedFormRequired: {
    id: "provideGovernmentIssuedFormRequired",
    defaultMessage: "This information is required.",
  },
  provideGovernmentIssuedFormMatch: {
    id: "provideGovernmentIssuedFormMatch",
    defaultMessage: "Insurance numbers must match",
  },
  provideGovernmentIssuedFormInvalid: {
    id: "provideGovernmentIssuedFormInvalid",
    defaultMessage: "Please enter a valid insurance number",
  },
  provideGovernmentIssuedFormButtonNext: {
    id: "provideGovernmentIssuedFormButtonNext",
    defaultMessage: "Next",
  },
  provideGovernmentIssuedFormButtonBack: {
    id: "provideGovernmentIssuedFormButtonBack",
    defaultMessage: "Back",
  },
});

type Props = {
  defaultValue?: string;
  onSubmit: (insuranceNumber: string) => void;
  goBack: () => void;
};

const ProvideGovernmentIssuedForm: React.FC<Props> = ({
  defaultValue,
  onSubmit,
  goBack,
}) => {
  const intl = useIntl();
  const errorController = useErrorController();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    errorController.clearErrors();

    const formData = new FormData(event.currentTarget);

    const insuranceNumber = formData.get("insuranceNumber")?.toString();
    const reInsuranceNumber = formData.get("reInsuranceNumber")?.toString();

    if (!insuranceNumber) {
      errorController.setValidationError(
        "insuranceNumber",
        intl.formatMessage(messages.provideGovernmentIssuedFormRequired)
      );

      return;
    }

    if (insuranceNumber || reInsuranceNumber) {
      const isInvalidInsuranceNumber =
        insuranceNumber && insuranceNumber !== insuranceNumber.trim();

      if (isInvalidInsuranceNumber) {
        errorController.setValidationError(
          "insuranceNumber",
          intl.formatMessage(messages.provideGovernmentIssuedFormInvalid)
        );
      }

      const isInvalidReInsuranceNumber =
        reInsuranceNumber && reInsuranceNumber !== reInsuranceNumber.trim();

      if (isInvalidReInsuranceNumber) {
        errorController.setValidationError(
          "reInsuranceNumber",
          intl.formatMessage(messages.provideGovernmentIssuedFormInvalid)
        );
      }

      if (isInvalidInsuranceNumber || isInvalidReInsuranceNumber) return;
    }

    if (!reInsuranceNumber || insuranceNumber !== reInsuranceNumber) {
      errorController.setValidationError(
        "insuranceNumber",
        intl.formatMessage(messages.provideGovernmentIssuedFormMatch)
      );

      errorController.setValidationError(
        "reInsuranceNumber",
        intl.formatMessage(messages.provideGovernmentIssuedFormMatch)
      );

      return;
    }

    onSubmit(insuranceNumber);
  };

  return (
    <Form
      noValidate
      onSubmit={handleSubmit}
      buttons={
        <>
          <Button type="submit" raised>
            {intl.formatMessage(messages.provideGovernmentIssuedFormButtonNext)}
          </Button>
          <Button onClick={goBack}>
            {intl.formatMessage(messages.provideGovernmentIssuedFormButtonBack)}
          </Button>
        </>
      }
    >
      <Grid>
        <GridRow>
          <GridCell span={12}>
            <FormField
              title={intl.formatMessage(
                messages.provideGovernmentIssuedFormEnterNumber
              )}
              label={intl.formatMessage(
                messages.provideGovernmentIssuedFormEnterNumber
              )}
              aria-label={intl.formatMessage(
                messages.provideGovernmentIssuedFormEnterNumber
              )}
              error={errorController.getValidationError("insuranceNumber")}
              required
              htmlFor="insuranceNumber"
            >
              <Textfield
                id="insuranceNumber"
                name="insuranceNumber"
                type="text"
                placeholder={intl.formatMessage(
                  messages.provideGovernmentIssuedFormPlaceholder
                )}
                defaultValue={defaultValue}
                outline
                required
              />
            </FormField>
          </GridCell>
          <GridCell span={12}>
            <FormField
              title={intl.formatMessage(
                messages.provideGovernmentIssuedFormReEnterNumber
              )}
              label={intl.formatMessage(
                messages.provideGovernmentIssuedFormReEnterNumber
              )}
              error={errorController.getValidationError("reInsuranceNumber")}
              aria-label={intl.formatMessage(
                messages.provideGovernmentIssuedFormReEnterNumber
              )}
              required
              htmlFor="reInsuranceNumber"
            >
              <Textfield
                id="reInsuranceNumber"
                name="reInsuranceNumber"
                placeholder={intl.formatMessage(
                  messages.provideGovernmentIssuedFormPlaceholder
                )}
                defaultValue={defaultValue}
                outline
                required
              />
            </FormField>
          </GridCell>
        </GridRow>
      </Grid>
    </Form>
  );
};

export default ProvideGovernmentIssuedForm;
