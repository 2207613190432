import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/form";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { Steps } from "../../simpleOrderStepper";

import "@app/components/simpleOrder/acknowledgment/acknowledgment.scss";
import {
  PartnerInfoStepResolve,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import AcknowledgmentContent from "@app/components/simpleOrder/acknowledgment/acknowledgmentContent";
import { StepperContext } from "@natera/stepper";
import { HEAP_EVENTS } from "@app/provider/types";
import { getTestNameForHeap } from "@app/utils";
import { OrderContext } from "@app/provider/order";

const acknowledgmentContentMessages = defineMessages({
  partnerAcknowledgmentContentParagraph1: {
    id: "partnerAcknowledgmentContentParagraph1",
    defaultMessage:
      "I authorize Natera to disclose the results of my test to my Partner.",
  },
  partnerAcknowledgmentContentParagraph2: {
    id: "partnerAcknowledgmentContentParagraph2",
    defaultMessage:
      "This Authorization will expire upon release of the test results to my partner.",
  },
  partnerAcknowledgmentContentParagraph3: {
    id: "partnerAcknowledgmentContentParagraph3",
    defaultMessage: "I also understand that:",
  },
  partnerAcknowledgmentContentParagraph4: {
    id: "partnerAcknowledgmentContentParagraph4",
    defaultMessage:
      "I have the right to revoke this Authorization at any time before it expires by giving written notice of my revocation to Privacy@Natera.com. The revocation will not apply to PHI that has already been released.",
  },
  partnerAcknowledgmentContentParagraph5: {
    id: "partnerAcknowledgmentContentParagraph5",
    defaultMessage: "This Authorization is voluntary.",
  },
  partnerAcknowledgmentContentParagraph6: {
    id: "partnerAcknowledgmentContentParagraph6",
    defaultMessage:
      "I am not required to sign this Authorization and Natera will not condition treatment, payment for services, or eligibility for services on whether I sign this Authorization.",
  },
  partnerAcknowledgmentContentParagraph7: {
    id: "partnerAcknowledgmentContentParagraph7",
    defaultMessage:
      "Natera does not control what the Recipient of the PHI does with the PHI Natera will disclose pursuant to this Authorization. The PHI disclosed pursuant to this Authorization may no longer be protected by HIPAA or other applicable privacy or confidentiality laws and could be re-disclosed by the Recipient.",
  },
  partnerAcknowledgmentContentParagraph8: {
    id: "partnerAcknowledgmentContentParagraph8",
    defaultMessage: "I have a right to receive a copy of this Authorization.",
  },
});

const messages = defineMessages({
  partnerAcknowledgmentTitle: {
    id: "partnerAcknowledgmentTitle",
    defaultMessage: "Partner Acknowledgment: ",
  },
  partnerAcknowledgmentSubTitle: {
    id: "partnerAcknowledgmentSubTitle",
    defaultMessage: "Share my test results with my partner",
  },
  partnerAcknowledgmentFooterDisagreeButton: {
    id: "partnerAcknowledgmentFooterDisagreeButton",
    defaultMessage: "Disagree And Continue",
  },
  partnerAcknowledgmentFooterAgreeButton: {
    id: "partnerAcknowledgmentFooterAgreeButton",
    defaultMessage: "Agree And Continue To Signature",
  },
  partnerAcknowledgmentFooterBackButton: {
    id: "partnerAcknowledgmentFooterBackButton",
    defaultMessage: "Back",
  },
});

export interface PartnerAcknowledgmentProps {
  resolve: (subStepData: PartnerInfoStepResolve) => void;
  goBack: () => void;
}

const PartnerAcknowledgment: FC<PartnerAcknowledgmentProps> = ({
  goBack,
  resolve,
}) => {
  const intl = useIntl();
  const { getSubStepData } = React.useContext(SimpleOrderStepperContext);
  const { resolve: resolveMainStep } = React.useContext(StepperContext);

  const { orderData } = React.useContext(OrderContext);
  const orderUid = orderData?.uid;
  const limsId = orderData?.clinic?.limsId;

  const subStepData = getSubStepData(
    Steps.PARTNER_INFO
  ) as PartnerInfoStepResolve;

  const onDisagreeClick = () => {
    resolveMainStep({
      partner: subStepData.partner,
      sharingConsentAgreement: false,
    });
    heap.track(HEAP_EVENTS.upp_simpleorder_partnerconsentno, {
      tests_ordered: getTestNameForHeap(orderData),
      lims_clinic_id: limsId,
      order_uid: orderUid,
    });
  };

  const onAgreeClick = () => {
    const acknowledgmentText = document.getElementById("acknowledgment-info")
      ?.innerText;
    resolve({
      ...subStepData,
      sharingConsentAgreement: true,
      acknowledgmentText,
    });
  };

  const handlePreviousStep = () => {
    goBack();
  };

  return (
    <div className="acknowledgment">
      <StepperHeaderProgress />
      <StepperContent
        title={intl.formatMessage(messages.partnerAcknowledgmentTitle)}
        subTitle={intl.formatMessage(messages.partnerAcknowledgmentSubTitle)}
      >
        <AcknowledgmentContent
          actions={
            <>
              <Button outlined onClick={onDisagreeClick}>
                {intl.formatMessage(
                  messages.partnerAcknowledgmentFooterDisagreeButton
                )}
              </Button>
              <Button raised onClick={onAgreeClick}>
                {intl.formatMessage(
                  messages.partnerAcknowledgmentFooterAgreeButton
                )}
              </Button>
              <Button onClick={handlePreviousStep}>
                {intl.formatMessage(
                  messages.partnerAcknowledgmentFooterBackButton
                )}
              </Button>
            </>
          }
        >
          <>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.partnerAcknowledgmentContentParagraph1
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.partnerAcknowledgmentContentParagraph2
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.partnerAcknowledgmentContentParagraph3
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.partnerAcknowledgmentContentParagraph4
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.partnerAcknowledgmentContentParagraph5
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.partnerAcknowledgmentContentParagraph6
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.partnerAcknowledgmentContentParagraph7
              )}
            </p>
            <p>
              {intl.formatMessage(
                acknowledgmentContentMessages.partnerAcknowledgmentContentParagraph8
              )}
            </p>
          </>
        </AcknowledgmentContent>
      </StepperContent>
    </div>
  );
};

export default PartnerAcknowledgment;
