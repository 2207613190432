import React from "react";
import { useHistory } from "react-router-dom";

import { ConfigContext } from "@app/provider";
import { useQuery } from "@app/utils";
import { LoadingContext } from "@natera/platform/lib/components/context";

export const billingPath = "/billing";
export const pricingGeneticTestingPath = "/pricing/genetic_testing";

const RedirectLPP: React.FC = () => {
  const { config } = React.useContext(ConfigContext);
  const query = useQuery();
  const history = useHistory();

  React.useEffect(() => {
    const path = history.location.pathname;
    const queryString = query.toString();
    const urlQuery = queryString ? `?${queryString}` : "";

    const legacyPpRoute = config.patientPortal.url;

    let redirectToUrl;
    switch (path) {
      case billingPath:
        redirectToUrl = config.links.WOMENS_HEALTH_PRICING_BILLING;
        break;
      default:
        redirectToUrl = `${legacyPpRoute}${path}`;
    }

    redirectToUrl += urlQuery;

    window.location.replace(`${redirectToUrl}`);
  }, []);

  return <LoadingContext isLoading />;
};

export default RedirectLPP;
