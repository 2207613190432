import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { HeapPHIDecorator } from "@app/components/analytics";
import { messages } from "../messages";
import { Textfield } from "@natera/form";
import { GetValidationError } from "@natera/platform/lib/hooks/useError";
import { ConfigContext } from "@app/provider";
import { FormField } from "@app/components";

const ApartmentUnitField: React.FC<{
  getValidationError: GetValidationError;
  apartmentUnit: string;
}> = ({ getValidationError, apartmentUnit }) => {
  const intl = useIntl();
  const { config } = useContext(ConfigContext);

  return (
    <FormField
      label={intl.formatMessage(messages.addressFormApartmentUnit)}
      error={getValidationError("apartmentUnit")}
      htmlFor="apartmentUnit"
      withPadding
    >
      <HeapPHIDecorator protectAttr={["value"]}>
        <Textfield
          placeholder={intl.formatMessage(
            messages.addressFormApartmentUnitPlaceholder
          )}
          id="apartmentUnit"
          name="apartmentUnit"
          data-testid="apartmentUnit"
          defaultValue={apartmentUnit}
          outline
          maxLength={config.TEXT_FIELD_MAX_LENGTH}
        />
      </HeapPHIDecorator>
    </FormField>
  );
};

export { ApartmentUnitField };
