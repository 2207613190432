import {
  ParsedTestCard,
  ProsperaOrganType,
} from "@app/provider/testData/types";
import { TestTitle } from "@app/components/common";
import { Link } from "@natera/navigation";
import { routes } from "@app/routing";
import { getTestCardLinkAriaLabel } from "@app/utils";
import Svg from "@natera/material/lib/svg";
import arrowRightIcon from "@assets/svg/icons/link-arrow.svg";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import heartIcon from "@assets/svg/icons/organs/heart.svg";
import kidneysIcon from "@assets/svg/icons/organs/kidneys.svg";
import lungsIcon from "@assets/svg/icons/organs/lungs.svg";

interface Props {
  testCard: ParsedTestCard;
  leftSideClickable?: boolean;
}

const TestCardTitle: FC<Props> = ({ testCard, leftSideClickable }) => {
  const intl = useIntl();

  const title = useMemo(() => <TestTitle testType={testCard.testType} />, [
    testCard.testType,
  ]);

  const renderOrganSvg = (organ: string) => {
    switch (organ) {
      case ProsperaOrganType.HEART:
        return <Svg content={heartIcon} />;
      case ProsperaOrganType.KIDNEY:
      case ProsperaOrganType.SPK:
        return <Svg content={kidneysIcon} />;
      case ProsperaOrganType.LUNG:
        return <Svg content={lungsIcon} />;
      default:
        return;
    }
  };

  return (
    <>
      {leftSideClickable ? (
        <Link
          to={routes.testDetailsPage(testCard.orderUid, testCard.uid)}
          className="upp-test-card-title"
          aria-label={getTestCardLinkAriaLabel(
            testCard.testType,
            testCard.organType,
            intl
          )}
        >
          {testCard.organType && renderOrganSvg(testCard.organType)}
          <span>{title}</span>
          <Svg
            className="upp-test-card-title__arrow-svg"
            content={arrowRightIcon}
          />
        </Link>
      ) : (
        <div className="upp-test-card-title">
          {testCard.organType && renderOrganSvg(testCard.organType)}
          <span>{title}</span>
        </div>
      )}
    </>
  );
};

export default TestCardTitle;
