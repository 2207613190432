import { DocumentNode, gql } from "@apollo/client";

class SpecificDatesService {
  public static getSpecificDates = (): DocumentNode => gql`
    query GetSpecificDates($types: [SpecificDateEnum!]!) {
      getSpecificDates(types: $types)
    }
  `;
}

export default SpecificDatesService;
