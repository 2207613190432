import React, { FC, ReactElement, useContext, useState } from "react";
import {
  Form,
  FormField,
  Button,
  Textfield,
  Option,
  Select,
} from "@natera/form";
import { defineMessages, useIntl } from "react-intl";
import { ConfigContext } from "@app/provider";
import { useErrorController } from "@natera/platform/lib/hooks";
import "./dateOfBirthVerification.scss";
import { GuestValidationInput } from "@app/preBill/models";

const messages = defineMessages({
  dateOfBirthVerificationMonthLabel: {
    id: "dateOfBirthVerificationMonthLabel",
    defaultMessage: "Month",
  },
  dateOfBirthVerificationDayLabel: {
    id: "dateOfBirthVerificationDayLabel",
    defaultMessage: "Day",
  },
  dateOfBirthVerificationYearLabel: {
    id: "dateOfBirthVerificationYearLabel",
    defaultMessage: "Year",
  },
  dateOfBirthVerificationVerify: {
    id: "dateOfBirthVerificationVerify",
    defaultMessage: "Verify",
  },
  dateOfBirthVerificationInvalidDay: {
    id: "dateOfBirthVerificationInvalidDay",
    defaultMessage: "Invalid Day",
  },
  dateOfBirthVerificationInvalidMonth: {
    id: "dateOfBirthVerificationInvalidMonth",
    defaultMessage: "Invalid Month",
  },
  dateOfBirthVerificationInvalidYear: {
    id: "dateOfBirthVerificationInvalidYear",
    defaultMessage: "Invalid Year",
  },
});

interface DateOfBirthProps {
  loading: boolean;
  onSubmit: (data: GuestValidationInput) => void;
}

const DateOfBirthVerification: FC<DateOfBirthProps> = ({
  loading,
  onSubmit,
}) => {
  const intl = useIntl();
  const { config } = useContext(ConfigContext);
  const {
    getValidationError,
    clearErrors,
    setValidationError,
  } = useErrorController();

  const [selectedMonth, setSelectedMonth] = useState<number>(0);
  const [selectedDay, setSelectedDay] = useState<number>(0);

  const createOptions = (value: number) =>
    Array.from({ length: value }, (_, i) => `${i + 1}`);

  const getDaysInMonth = (month: number) => {
    if (month === 2) {
      return 29;
    }
    return [4, 6, 9, 11].includes(month) ? 30 : 31;
  };

  const validateDay = (day: string, month: number, year: string): boolean => {
    const dayNumber = parseInt(day, 10);
    const yearNumber = parseInt(year, 10);

    if (month !== 2) return true;

    const isLeapYear =
      yearNumber % 4 === 0 &&
      (yearNumber % 100 !== 0 || yearNumber % 400 === 0);

    if (dayNumber === 29 && !isLeapYear) {
      setValidationError(
        "dateOfBirthDay",
        intl.formatMessage(messages.dateOfBirthVerificationInvalidDay)
      );
      return false;
    }

    return true;
  };

  const validateYear = (value: string) => {
    const yearNumber = parseInt(value, 10);
    const currentYear = new Date().getFullYear();
    if (
      value.length !== config.TEXT_FIELD_YEAR_MAX_LENGTH ||
      isNaN(yearNumber) ||
      yearNumber < 1900 ||
      yearNumber > currentYear
    ) {
      setValidationError(
        "dateOfBirthYear",
        intl.formatMessage(messages.dateOfBirthVerificationInvalidYear)
      );
      return false;
    }
    return true;
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const cleanedValue = inputValue.replace(/\D/g, "");
    const limitedValue = cleanedValue.slice(
      0,
      config.TEXT_FIELD_YEAR_MAX_LENGTH
    );

    event.target.value = limitedValue;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    clearErrors();

    const formData = new FormData(event.currentTarget);
    const month = formData.get("dateOfBirthMonth")?.toString();
    const day = formData.get("dateOfBirthDay")?.toString();
    const year = formData.get("dateOfBirthYear")?.toString();

    const setFieldError = (
      field: string,
      messageKey: keyof typeof messages
    ) => {
      setValidationError(field, intl.formatMessage(messages[messageKey]));
    };

    if (!month) {
      setFieldError("dateOfBirthMonth", "dateOfBirthVerificationInvalidMonth");
      return;
    }

    if (!day) {
      setFieldError("dateOfBirthDay", "dateOfBirthVerificationInvalidDay");
      return;
    }

    if (!year || !validateYear(year)) {
      setFieldError("dateOfBirthYear", "dateOfBirthVerificationInvalidYear");
      return;
    }

    if (!validateDay(day, parseInt(month, 10), year)) {
      setFieldError("dateOfBirthDay", "dateOfBirthVerificationInvalidDay");
      return;
    }

    const dateOfBirth: GuestValidationInput = {
      month: parseInt(month, 10),
      day: parseInt(day, 10),
      year: parseInt(year, 10),
    };

    onSubmit(dateOfBirth);
  };

  const monthSelectElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.dateOfBirthVerificationMonthLabel)}
      error={getValidationError("dateOfBirthMonth")}
      htmlFor="dateOfBirthMonth"
    >
      <Select
        id="dateOfBirthMonth"
        name="dateOfBirthMonth"
        data-testid="dateOfBirthMonth"
        placeholder={intl.formatMessage(
          messages.dateOfBirthVerificationMonthLabel
        )}
        required
        outlined
        value={selectedMonth}
        onValueChange={(value) => {
          setSelectedMonth(Number(value));
        }}
      >
        {createOptions(12).map((value) => (
          <Option key={value} value={value}>
            {value}
          </Option>
        ))}
      </Select>
    </FormField>
  );

  const daySelectElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.dateOfBirthVerificationDayLabel)}
      error={getValidationError("dateOfBirthDay")}
      htmlFor="dateOfBirthDay"
    >
      <Select
        id="dateOfBirthDay"
        name="dateOfBirthDay"
        placeholder="DD"
        data-testid="dateOfBirthDay"
        required
        outlined
        value={selectedDay}
        onValueChange={(value) => {
          setSelectedDay(Number(value));
        }}
      >
        {createOptions(getDaysInMonth(selectedMonth)).map((value) => (
          <Option key={value} value={value}>
            {value}
          </Option>
        ))}
      </Select>
    </FormField>
  );

  const yearTextElement: ReactElement = (
    <FormField
      label={intl.formatMessage(messages.dateOfBirthVerificationYearLabel)}
      error={getValidationError("dateOfBirthYear")}
      htmlFor="dateOfBirthYear"
    >
      <Textfield
        placeholder="YYYY"
        type="text"
        id="dateOfBirthYear"
        maxLength={config.TEXT_FIELD_YEAR_MAX_LENGTH}
        name="dateOfBirthYear"
        data-testid="dateOfBirthYear"
        defaultValue=""
        outline
        onChange={handleYearChange}
        fullWidth
      />
    </FormField>
  );

  return (
    <div className="date-of-birth-form">
      <section className="form__container">
        <Form
          onSubmit={handleSubmit}
          buttons={
            <Button type="submit" raised loading={loading}>
              {intl.formatMessage(messages.dateOfBirthVerificationVerify)}
            </Button>
          }
        >
          <div className="date-of-birth-form-row">
            {monthSelectElement}
            {daySelectElement}
          </div>
          {yearTextElement}
        </Form>
      </section>
    </div>
  );
};

export default DateOfBirthVerification;
