import { StepperContent } from "@app/components/ui/layout/stepperContent";
import React, { useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { StepperContext } from "@natera/stepper";
import Tooltip from "@natera/material/lib/tooltip";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { Button } from "@natera/form";
import { Link } from "@natera/navigation";
import acknowledgements from "@etc/acknowledgements.json";
import FinancialAssistanceIcon from "@app/preBill/assets/svg/financial-assistance.svg";
import CashIcon from "@app/preBill/assets/svg/cash.svg";
import "./billingCostInformation.scss";
import { InformationalActionCard } from "@app/preBill/components/informationalActionCard";
import { PreBillAPIContext, ConfigContext } from "@app/provider";
import { calculateOOPCost } from "@app/preBill/utils";
import { openNewWindow } from "@app/utils";
import { getFormattedLocalizeDate } from "@app/utils";
import { IntlContext } from "@app/provider";
import { getTestName } from "@app/preBill/utils/getTestName";
import { PaymentChoice, SendPaymentChoiceInput } from "@app/preBill/models";
import { BillingEstimateStepsType } from "../../billingEstimateStepper";

const messages = defineMessages({
  billingCostInformationTitle: {
    id: "billingCostInformationTitle",
    defaultMessage: "Your {testName} cost estimate",
  },
  billingCostInformationGotItButton: {
    id: "billingCostInformationGotItButton",
    defaultMessage: "Got it",
  },
  billingCostInformationBackButton: {
    id: "billingCostInformationBackButton",
    defaultMessage: "Back",
  },
  billingCostInformationEstimateTitle: {
    id: "billingCostInformationEstimateTitle",
    defaultMessage: "Estimated out-of-pocket cost",
  },
  billingCostInformationEstimateTitleTooltip: {
    id: "billingCostInformationEstimateTitleTooltip",
    defaultMessage:
      "An estimate of the amount that you may have to pay on your own for health care costs. The estimate is made before your health plan has processed a claim for that service.",
  },
  billingCostInformationEstimateInformation: {
    id: "billingCostInformationEstimateInformation",
    defaultMessage: "{cost} will be applied toward your {deductible}.",
  },
  billingCostInformationEstimateTitleInsuranceStatus: {
    id: "billingCostInformationEstimateTitleInsuranceStatus",
    defaultMessage: "Insurance status",
  },
  billingCostInformationEstimateInformationInsurance: {
    id: "billingCostInformationEstimateInformationInsurance",
    defaultMessage: "In-network",
  },
  billingCostInformationEstimateInformationInsuranceTooltip: {
    id: "billingCostInformationEstimateInformationInsuranceTooltip",
    defaultMessage:
      "Being 'In-Network' means your health insurance has an agreement with Natera, which helps lower the cost of your services.",
  },
  billingCostInformationEstimateTitleInsurancePlan: {
    id: "billingCostInformationEstimateTitleInsurancePlan",
    defaultMessage: "Insurance plan",
  },
  billingCostInformationEstimateTitleDate: {
    id: "billingCostInformationEstimateTitleDate",
    defaultMessage: "Estimate date",
  },
  billingCostInformationEstimateTitleDateTooltip: {
    id: "billingCostInformationEstimateTitleDateTooltip",
    defaultMessage:
      "This estimate reflects your benefits as of the date we requested them from your health plan. Future services may impact your deductible, coinsurance, or copay, which could change your final out-of-pocket costs.",
  },
  billingCostInformationText: {
    id: "billingCostInformationText",
    defaultMessage:
      "This amount is not final, as future and pending claims submitted to your insurance plan may affect the estimate. If you have any questions, {contactUs}.",
  },
  billingCostInformationcontactUsText: {
    id: "contactUsText",
    defaultMessage: "Contact Us",
  },
  otherPaymentOptionsSectionTitle: {
    id: "otherPaymentOptionsSectionTitle",
    defaultMessage: "Other payment options",
  },
  financialAssistanceTitle: {
    id: "financialAssistanceTitle",
    defaultMessage: "Learn about financial assistance",
  },
  financialAssistanceText: {
    id: "financialAssistanceText",
    defaultMessage:
      "You may qualify for Natera's Compassionate Care Program based on your financial situation.",
  },
  payCashTitle: {
    id: "payCashTitle",
    defaultMessage: "Pay cash now",
  },
  payCashText: {
    id: "payCashText",
    defaultMessage: "If you prefer to not use your insurance.",
  },
  deductibleText: {
    id: "deductibleText",
    defaultMessage: "deductible",
  },
});

interface BillingCostProps {
  onPaymentSelect: (
    paymentSelect: PaymentChoice.CASHPAY | PaymentChoice.INSURANCE
  ) => void;
}

const BillingCostInformation: React.FC<BillingCostProps> = ({
  onPaymentSelect,
}) => {
  const intl = useIntl();
  const { currentLanguage } = useContext(IntlContext);
  const { goBack, resolve, goAt, reset } = React.useContext(StepperContext);
  const {
    apiProviderState: { orderEstimate },
    sendPaymentChoice,
  } = useContext(PreBillAPIContext);
  const { config } = useContext(ConfigContext);
  const [testName, setTestName] = useState<string>("");

  useEffect(() => {
    setTestName(getTestName(orderEstimate?.testType, intl));
  }, [orderEstimate?.testType, intl]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFinancialAssistance = () => {
    openNewWindow(`${config.preBill.compassionate_care_link}`);
  };

  const handleGotIt = async () => {
    const payload: SendPaymentChoiceInput = {
      paymentChoice: PaymentChoice.INSURANCE,
    };
    try {
      await sendPaymentChoice(payload);
      onPaymentSelect(PaymentChoice.INSURANCE);
      resolve({});
      goAt(BillingEstimateStepsType.BILLING_END_INFORMATION);
    } catch (error) {
      console.error("Error sending payment choice:", error);
    }
  };

  const handleBack = () => {
    reset();
    goBack();
  };

  const calculateDeductible = (): number => {
    if (!orderEstimate) return 0;

    const {
      totalInsuranceCost = 0,
      totalInsuranceCopay = 0,
      totalInsuranceRemainingDeductible = 0,
    } = orderEstimate;

    const caseDeductible = Math.max(
      totalInsuranceCost - totalInsuranceCopay,
      0
    );
    const appliedDeductible = Math.min(
      caseDeductible,
      totalInsuranceRemainingDeductible
    );

    return Math.round(appliedDeductible / 100);
  };

  const handleCashPayButton = () => {
    onPaymentSelect(PaymentChoice.CASHPAY);
    resolve({});
    goAt(BillingEstimateStepsType.INN_CASH_PAY);
  };

  return (
    <>
      <StepperHeaderProgress />
      <div className="billing-cost-information">
        <StepperContent
          title={intl.formatMessage(messages.billingCostInformationTitle, {
            testName: testName,
          })}
        >
          <div className="billing-cost-information__estimate">
            <div className="billing-cost-information__estimate-section">
              <div className="billing-cost-information__estimate-section__title">
                <span className="billing-cost-information__estimate-section__header">
                  {intl.formatMessage(
                    messages.billingCostInformationEstimateTitle
                  )}
                </span>
                <Tooltip
                  content={intl.formatMessage(
                    messages.billingCostInformationEstimateTitleTooltip
                  )}
                >
                  <div className="billing-cost-estimate-tooltip">?</div>
                </Tooltip>
              </div>
              <span className="billing-cost-information__estimate-section__cost">
                {calculateOOPCost(orderEstimate?.totalInsuranceCost as number)}
              </span>
              <span className="billing-cost-information__estimate-section__information">
                {intl.formatMessage(
                  messages.billingCostInformationEstimateInformation,
                  {
                    cost: (
                      <span className="billing-cost-information__estimate-section__information-bold">
                        {`$${calculateDeductible()}`}
                      </span>
                    ),
                    deductible: (
                      <span className="billing-cost-information__estimate-section__information-bold">
                        {intl.formatMessage(messages.deductibleText)}
                      </span>
                    ),
                  }
                )}
              </span>
            </div>
            <div className="billing-cost-information__estimate-section">
              <div className="billing-cost-information__estimate-section__title">
                <span className="billing-cost-information__estimate-section__header">
                  {intl.formatMessage(
                    messages.billingCostInformationEstimateTitleInsuranceStatus
                  )}
                </span>
                <Tooltip
                  content={intl.formatMessage(
                    messages.billingCostInformationEstimateInformationInsuranceTooltip
                  )}
                >
                  <div className="billing-cost-estimate-tooltip">?</div>
                </Tooltip>
              </div>
              <span className="billing-cost-information__estimate-section__information">
                {intl.formatMessage(
                  messages.billingCostInformationEstimateInformationInsurance
                )}
              </span>
            </div>
            <div className="billing-cost-information__estimate-section">
              <div className="billing-cost-information__estimate-section__title">
                <span className="billing-cost-information__estimate-section__header">
                  {intl.formatMessage(
                    messages.billingCostInformationEstimateTitleInsurancePlan
                  )}
                </span>
              </div>
              <span className="billing-cost-information__estimate-section__information">
                {orderEstimate?.insuranceCompanyName}
              </span>
            </div>
            <div className="billing-cost-information__estimate-section">
              <div className="billing-cost-information__estimate-section__title">
                <span className="billing-cost-information__estimate-section__header">
                  {intl.formatMessage(
                    messages.billingCostInformationEstimateTitleDate
                  )}
                </span>
                <Tooltip
                  content={intl.formatMessage(
                    messages.billingCostInformationEstimateTitleDateTooltip
                  )}
                >
                  <div className="billing-cost-estimate-tooltip">?</div>
                </Tooltip>
              </div>
              <span className="billing-cost-information__estimate-section__information">
                {getFormattedLocalizeDate(
                  orderEstimate?.insuranceEstimateDate,
                  currentLanguage
                )}
              </span>
            </div>
          </div>
          <div
            className="billing-cost-information__text"
            data-testid="billing-cost-information__text"
          >
            {intl.formatMessage(messages.billingCostInformationText, {
              contactUs: (
                <Link
                  target="_blank"
                  key="contactUs"
                  rel="noreferrer"
                  to={{ pathname: acknowledgements.links.contactUs }}
                  className="link"
                >
                  {intl.formatMessage(
                    messages.billingCostInformationcontactUsText
                  )}
                </Link>
              ),
            })}
          </div>
        </StepperContent>
        <div className="stepper__actions">
          <Button raised onClick={handleGotIt}>
            {intl.formatMessage(messages.billingCostInformationGotItButton)}
          </Button>
          <Button outlined onClick={handleBack}>
            {intl.formatMessage(messages.billingCostInformationBackButton)}
          </Button>
        </div>
        <div className="other-payment-section">
          <h3 className="other-payment-section__title">
            {intl.formatMessage(messages.otherPaymentOptionsSectionTitle)}
          </h3>
          <InformationalActionCard
            icon={FinancialAssistanceIcon}
            title={intl.formatMessage(messages.financialAssistanceTitle)}
            text={intl.formatMessage(messages.financialAssistanceText)}
            onClick={handleFinancialAssistance}
          />
          <InformationalActionCard
            icon={CashIcon}
            title={intl.formatMessage(messages.payCashTitle)}
            text={intl.formatMessage(messages.payCashText)}
            onClick={handleCashPayButton}
          />
        </div>
      </div>
    </>
  );
};

export default BillingCostInformation;
