import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/form";
import UpArrow from "@assets/svg/icons/up-arrow.svg";
import Svg from "@natera/material/lib/svg";
import { DrawRequestScreens } from "../drawRequest";
import { BloodDrawContext } from "@app/provider";
import { ZipSelector } from "@app/components/forms";
import { ZipValidationErrors } from "@app/components/forms/zipSelectorForm/zipSelectorForm";
import { isValidZip } from "@app/utils";

import "./publicDrawWelcome.scss";

const messages = defineMessages({
  publicDrawWelcomeInfoTitle: {
    id: "publicDrawWelcomeInfoTitle",
    defaultMessage: "Welcome! What is your preferred way to get a blood draw?",
  },
  publicDrawWelcomeInfoDetails: {
    id: "publicDrawWelcomeInfoDetails",
    defaultMessage:
      "Visit a Natera-approved lab near you or request a draw at a time and location of your choosing. Natera will cover the cost of your draw either way.",
  },
  publicDrawWelcomeSearchButton: {
    id: "publicDrawWelcomeSearchButton",
    defaultMessage: "Show labs near me",
  },
  publicDrawWelcomeDividerText: {
    id: "publicDrawWelcomeDividerText",
    defaultMessage: "OR",
  },
  publicDrawWelcomeMobileDrawHeader: {
    id: "publicDrawWelcomeMobileDrawHeader",
    defaultMessage: "Mobile Blood Draw",
  },
  publicDrawWelcomeMobileDrawText: {
    id: "publicDrawWelcomeMobileDrawText",
    defaultMessage:
      "You can have someone come to your desired location to perform your blood draw.",
  },
});

type PublicDrawWelcomeProps = {
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
  onOpenAcknowledgementsDialog: () => void;
};

const PublicDrawWelcome: FC<PublicDrawWelcomeProps> = ({
  setScheduleScreen,
  onOpenAcknowledgementsDialog,
}) => {
  const intl = useIntl();
  const { zipCode, setZipCode } = useContext(BloodDrawContext);

  const [
    zipValidationErrorKey,
    setZipValidationErrorKey,
  ] = React.useState<ZipValidationErrors | null>(null);

  const handleSearch = () => {
    setZipValidationErrorKey(null);

    if (!zipCode) {
      setZipValidationErrorKey(ZipValidationErrors.EMPTY);
      return;
    }

    if (isValidZip(zipCode)) {
      setScheduleScreen(DrawRequestScreens.KIT_REQ_FORM_CONFIRMATION_SCREEN);
    } else {
      setZipValidationErrorKey(ZipValidationErrors.INVALID_ZIP_CODE);
    }
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setZipValidationErrorKey(null);
    setZipCode(event.target.value);
  };

  return (
    <div className="public-draw-welcome">
      <div className="public-draw-welcome__content">
        <div className="public-draw-welcome-info">
          <h1 className="public-draw-welcome-info-title">
            {intl.formatMessage(messages.publicDrawWelcomeInfoTitle)}
          </h1>
          <p className="public-draw-welcome-info-details">
            {intl.formatMessage(messages.publicDrawWelcomeInfoDetails)}
          </p>
        </div>
        <div className="lab-search">
          <div className="form">
            <div className="form-zip-selector">
              <ZipSelector
                zipCode={zipCode}
                onChange={onChange}
                zipValidationErrorKey={zipValidationErrorKey}
              />
            </div>
            <div className="form-miles-selector">
              {/* TODO: Zip Selector UPP-3658 */}
            </div>
          </div>
          <Button raised={true} onClick={handleSearch}>
            {intl.formatMessage(messages.publicDrawWelcomeSearchButton)}
          </Button>
        </div>
        <div className="public-draw-welcome__divider">OR</div>
        <div
          className="mobile-draw-card"
          onClick={onOpenAcknowledgementsDialog}
        >
          <div className="mobile-draw-card-header">
            <div>
              {intl.formatMessage(messages.publicDrawWelcomeMobileDrawHeader)}
            </div>
            <Svg content={UpArrow} />
          </div>
          <p className="mobile-draw-card-content">
            {intl.formatMessage(messages.publicDrawWelcomeMobileDrawText)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PublicDrawWelcome;
