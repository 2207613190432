import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/form";
import "./skipKitShippingDialog.scss";

const messages = defineMessages({
  skipKitShippingDialogTitle: {
    id: "skipKitShippingDialogTitle",
    defaultMessage: "Contact your clinic",
  },
  skipKitShippingDialogDescription: {
    id: "skipKitShippingDialogDescription",
    defaultMessage:
      "Your blood draw specialist will have a kit ready for you at the time of your draw. We will not ship you a kit due to your location.",
  },
  skipKitShippingDialogContinue: {
    id: "skipKitShippingDialogskipKitShippingDone",
    defaultMessage: "Continue",
  },
});

interface SkipKitShippingDialogProps {
  onClick: () => void;
  onClose: () => void;
}

const SkipKitShippingDialog: FC<SkipKitShippingDialogProps> = ({
  onClick,
  onClose,
}) => {
  const intl = useIntl();

  const handleDone = () => {
    onClick();
  };

  return (
    <div className="skip-kit-shipping-dialog">
      <Dialog
        showCrossButton
        title={intl.formatMessage(messages.skipKitShippingDialogTitle)}
        actions={
          <Button raised onClick={handleDone}>
            {intl.formatMessage(messages.skipKitShippingDialogContinue)}
          </Button>
        }
        onClose={onClose}
      >
        <ul className="skip-kit-shipping-dialog__content">
          <li>
            {intl.formatMessage(messages.skipKitShippingDialogDescription)}
          </li>
        </ul>
      </Dialog>
    </div>
  );
};

export default SkipKitShippingDialog;
