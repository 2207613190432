import { Grid, GridCell, GridRow } from "@natera/material/lib/layout";
import { Button, Form } from "@natera/form";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  PaymentInfo,
  PaymentType,
} from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { InsuranceEstimationData } from "./insuranceEstimationData";
import { InsuranceEstimationInformation } from "./insuranceEstimationInformation";
import FinancialAidCard from "@app/components/financialAidCard";

import { HEAP_EVENTS } from "@app/provider/types";
import { getTestNameForHeap } from "@app/utils";
import { OrderContext } from "@app/provider/order";

import "./insuranceCostEstimationForm.scss";

const messages = defineMessages({
  insuranceCostEstimationFormButtonNext: {
    id: "insuranceCostEstimationFormButtonNext",
    defaultMessage: "Next",
  },
  insuranceCostEstimationFormButtonBack: {
    id: "insuranceCostEstimationFormButtonBack",
    defaultMessage: "Back",
  },
  insuranceCostEstimationFormCardTitle: {
    id: "insuranceCostEstimationFormCardTitle",
    defaultMessage: "Financial access programs",
  },
  insuranceCostEstimationFormCardDescription: {
    id: "insuranceCostEstimationFormCardDescription",
    defaultMessage:
      "If you meet certain income criteria*, you could be eligible for a reduced patient responsibility rate.",
  },
  insuranceCostEstimationFormCardNote: {
    id: "insuranceCostEstimationFormCardNote",
    defaultMessage: "*Based on Federal poverty thresholds.",
  },
});

type Props = {
  paymentMethodData: PaymentInfo;
  onSubmit: (data: unknown) => void;
  goBack: () => void;
};

const InsuranceCostEstimationForm: React.FC<Props> = ({
  paymentMethodData,
  onSubmit,
  goBack,
}) => {
  const intl = useIntl();
  const { orderData } = React.useContext(OrderContext);
  const orderUid = orderData?.uid;
  const limsId = orderData?.clinic?.limsId;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onSubmit(paymentMethodData);

    heap.track(HEAP_EVENTS.upp_simpleorder_costestimate, {
      tests_ordered: getTestNameForHeap(orderData),
      lims_clinic_id: limsId,
      order_uid: orderUid,
    });
  };

  const showFinancialAccessProgramsCard = () =>
    paymentMethodData.paymentMethod === PaymentType.COMMERCIAL ||
    (paymentMethodData.paymentMethod === PaymentType.MULTIPLE &&
      paymentMethodData.paymentMethodInfo.first.paymentMethod ===
        PaymentType.COMMERCIAL &&
      paymentMethodData.paymentMethodInfo.second.paymentMethod ===
        PaymentType.COMMERCIAL);

  return (
    <Form
      className="insurance-cost-estimation-form"
      onSubmit={handleSubmit}
      buttons={
        <>
          <Button raised type="submit">
            {intl.formatMessage(messages.insuranceCostEstimationFormButtonNext)}
          </Button>
          <Button onClick={goBack}>
            {intl.formatMessage(messages.insuranceCostEstimationFormButtonBack)}
          </Button>
        </>
      }
    >
      <Grid>
        <GridRow>
          <GridCell span={12}>
            <InsuranceEstimationData paymentMethodData={paymentMethodData} />
            {paymentMethodData.paymentMethod === PaymentType.MULTIPLE && (
              <>
                <hr />
                <InsuranceEstimationData
                  paymentMethodData={paymentMethodData}
                  isSecondInsurance
                />
              </>
            )}
            <hr />
            <InsuranceEstimationInformation
              paymentMethodData={paymentMethodData}
            />
            {showFinancialAccessProgramsCard() && (
              <FinancialAidCard
                title={intl.formatMessage(
                  messages.insuranceCostEstimationFormCardTitle
                )}
                description={intl.formatMessage(
                  messages.insuranceCostEstimationFormCardDescription
                )}
                note={intl.formatMessage(
                  messages.insuranceCostEstimationFormCardNote
                )}
                limsClinicId={limsId}
              />
            )}
          </GridCell>
        </GridRow>
      </Grid>
    </Form>
  );
};

export default InsuranceCostEstimationForm;
