import { StepperContent } from "@app/components/ui/layout/stepperContent";
import React, { useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Address, KitContext, ProfileContext } from "@app/provider";
import { getListOfAddresses } from "@app/utils";
import { StepperContext } from "@natera/stepper";
import SampleDrawMPAddAddress from "./sampleDrawMPAddAddress";
import SampleDrawMPSelectAddress from "./sampleDrawMPSelectAddress";
import {
  AddressFormType,
  POBoxErrorNotification,
} from "@app/components/poBoxErrorNotification/poBoxErrorNotification";
import { isPOBoxOrPMB } from "@app/utils/isPOBoxOrPMB";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { DrawRequestFlow } from "@app/provider/drawRequest";

const messages = defineMessages({
  sampleDrawMPAddressStepTitle: {
    id: "sampleDrawMPAddressStepTitle",
    defaultMessage: "Mobile Blood Draw",
  },
  sampleDrawMPAddressStepDescriptionTitle: {
    id: "sampleDrawMPAddressStepDescriptionTitle",
    defaultMessage: "Where would you like to have your blood drawn?",
  },
  sampleDrawMPAddressStepDescriptionContent: {
    id: "sampleDrawMPAddressStepDescriptionContent",
    defaultMessage: "Please select or add an address.",
  },
});

export enum ADDRESS_VIEW_TYPE {
  CURRENT = "CURRENT",
  NEW = "NEW",
}

interface SampleDrawMPAddressStepProps {
  profileAddresses?: Address[];
  onBackKitShippingStep?: () => void;
  isPublicMpFlow?: boolean;
  flow?: DrawRequestFlow;
}

const SampleDrawMPAddressStep: React.FC<SampleDrawMPAddressStepProps> = ({
  profileAddresses,
  onBackKitShippingStep,
  isPublicMpFlow,
  flow,
}) => {
  const intl = useIntl();

  const { resolve, goBack } = useContext(StepperContext);
  const { getProfileData: profile } = useContext(ProfileContext);
  const { skipKitShipping, skipLabStep } = useContext(KitContext);

  const addresses: Address[] = getListOfAddresses(
    profileAddresses ? profileAddresses : profile?.addresses ?? []
  );

  const hasAddresses = addresses.length > 0;

  const onAddNewAddress = () => setSelectedAddressView(ADDRESS_VIEW_TYPE.NEW);
  const onCurrentAddress = () =>
    setSelectedAddressView(ADDRESS_VIEW_TYPE.CURRENT);

  const onBack = () => {
    if (flow === DrawRequestFlow.ACCOUNT && !skipLabStep) {
      goBack();
    } else {
      skipKitShipping && onBackKitShippingStep
        ? onBackKitShippingStep()
        : goBack();
    }
  };
  const onBackForNewAddress = hasAddresses ? onCurrentAddress : onBack;

  const [selectedAddressView, setSelectedAddressView] = useState<
    ADDRESS_VIEW_TYPE
  >(hasAddresses ? ADDRESS_VIEW_TYPE.CURRENT : ADDRESS_VIEW_TYPE.NEW);
  const [showPOBoxError, setShowPOBoxError] = useState<boolean>(false);

  const onSumbit = async (address: Partial<Address>) => {
    if (isPOBoxOrPMB(address.street1, address?.street2)) {
      setShowPOBoxError(true);
      return;
    }
    if (!address.countryCode) {
      address.countryCode = "US";
    }
    resolve({ address });
  };

  useEffect(() => {
    setShowPOBoxError(false);
  }, [selectedAddressView]);

  return (
    <>
      {showPOBoxError && (
        <POBoxErrorNotification formType={AddressFormType.MOBILE_BLOOD_DRAW} />
      )}
      <StepperHeaderProgress />
      <StepperContent
        title={intl.formatMessage(messages.sampleDrawMPAddressStepTitle)}
        subTitle={intl.formatMessage(
          messages.sampleDrawMPAddressStepDescriptionTitle
        )}
        description={intl.formatMessage(
          messages.sampleDrawMPAddressStepDescriptionContent
        )}
      >
        <>
          {selectedAddressView === ADDRESS_VIEW_TYPE.CURRENT ? (
            <SampleDrawMPSelectAddress
              addresses={addresses}
              onAddNewAddress={onAddNewAddress}
              onSubmit={onSumbit}
              onBack={isPublicMpFlow ? undefined : onBack}
            />
          ) : (
            <SampleDrawMPAddAddress
              onSubmit={onSumbit}
              onBack={onBackForNewAddress}
            />
          )}
        </>
      </StepperContent>
    </>
  );
};

export default SampleDrawMPAddressStep;
