import React from "react";
import { defineMessages, useIntl } from "react-intl";
import classNames from "classnames";
import "./billingUpdatesPageNotificationCard.scss";
import { PreBillTestTypes } from "@app/preBill/models";
import { capitalizeFirstLetter } from "@app/utils";

const messages = defineMessages({
  billingUpdatesNotificationReviewBy: {
    id: "billingUpdatesNotificationReviewBy",
    defaultMessage: "Review by",
  },
  billingUpdatesNotificationPanoromaTestDescription: {
    id: "billingUpdatesNotificationPanoromaTestDescription",
    defaultMessage: "Noninvasive prenatal testing (NIPT)",
  },
  billingUpdatesNotificationHorizonTestDescription: {
    id: "billingUpdatesNotificationHorizonTestDescription",
    defaultMessage: "Advanced carrier screening",
  },
  billingUpdatesNotificationEmpowerTestDescription: {
    id: "billingUpdatesNotificationEmpowerTestDescription",
    defaultMessage: "Hereditary cancer screening",
  },
});

export interface NotificationProps {
  notification: {
    id: string;
    testType: string;
    date: string | null;
    isRead: boolean;
    tokenizedLink: string;
  };
  onClick: () => void;
}

const BillingUpdatesPageNotificationCard = ({
  notification,
  onClick,
}: NotificationProps): JSX.Element => {
  const intl = useIntl();

  const descriptions = {
    [PreBillTestTypes.PANORAMA]: intl.formatMessage(
      messages.billingUpdatesNotificationPanoromaTestDescription
    ),
    [PreBillTestTypes.HORIZON]: intl.formatMessage(
      messages.billingUpdatesNotificationHorizonTestDescription
    ),
    [PreBillTestTypes.EMPOWER]: intl.formatMessage(
      messages.billingUpdatesNotificationEmpowerTestDescription
    ),
    [PreBillTestTypes.PANORAMA_HORIZON]: `${intl.formatMessage(
      messages.billingUpdatesNotificationPanoromaTestDescription
    )} & ${intl.formatMessage(
      messages.billingUpdatesNotificationHorizonTestDescription
    )}`,
  };

  return (
    <div
      data-testid="billing-updates-notification"
      className={classNames("billing-updates-notification", {
        "billing-updates-notification--read": notification.isRead,
      })}
      onClick={onClick}
    >
      <div className="billing-updates-notification__icon-container">
        {!notification.isRead && (
          <div
            data-testid="notification-icon"
            className="billing-updates-notification__icon"
          />
        )}
      </div>
      <div className="billing-updates-notification__content">
        <p className="billing-updates-notification__text">
          {`${capitalizeFirstLetter(notification.testType)} - ${
            descriptions[
              notification.testType.toLocaleUpperCase() as PreBillTestTypes
            ] ||
            intl.formatMessage(
              messages.billingUpdatesNotificationPanoromaTestDescription
            )
          }`}
        </p>
        {notification.date && (
          <p className="billing-updates-notification__date">
            {intl.formatMessage(messages.billingUpdatesNotificationReviewBy)} [
            {notification.date}]
          </p>
        )}
      </div>
    </div>
  );
};

export default BillingUpdatesPageNotificationCard;
