import { StepperContent } from "@app/components/ui/layout/stepperContent";
import React, { useContext, useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { StepperContext } from "@natera/stepper";
import Tooltip from "@natera/material/lib/tooltip";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { SpinnerView } from "@app/components";
import { Button } from "@natera/form";
import FinancialAssistanceIcon from "@app/preBill/assets/svg/financial-assistance.svg";
import "./ncsCostInformation.scss";
import { InformationalActionCard } from "@app/preBill/components/informationalActionCard";
import { IntlContext } from "@app/provider";
import { getFormattedLocalizeDate } from "@app/utils";
import { PreBillAPIContext, ConfigContext } from "@app/provider";
import { openNewWindow } from "@app/utils";
import { PaymentChoice, SendPaymentChoiceInput } from "@app/preBill/models";
import { NonCoveredServiceStepsType } from "../../nonCoveredServiceStepper";
import { getTestName } from "@app/preBill/utils/getTestName";
import { useDialog } from "@natera/platform/lib/hooks";
import InsuranceConfirmationDialog from "@app/preBill/components/insuranceConfirmationDialog/insuranceConfirmationDialog";

const messages = defineMessages({
  ncsCostInformationTitle: {
    id: "ncsCostInformationTitle",
    defaultMessage: "Your {testName} payment details",
  },
  ncsCostInformationPayNowButton: {
    id: "ncsCostInformationPayNowButton",
    defaultMessage: "Pay now",
  },
  ncsCostInformationEstimateTitle: {
    id: "ncsCostInformationEstimateTitle",
    defaultMessage: "{testName} prompt-pay cash price",
  },
  ncsCostInformationEstimateTitleTooltip: {
    id: "ncsCostInformationEstimateTitleTooltip",
    defaultMessage:
      "Natera offers a discounted prompt-pay cash price for services not covered by insurance to ensure affordability.",
  },
  ncsCostInformationEstimateTitleInsuranceStatus: {
    id: "ncsCostInformationEstimateTitleInsuranceStatus",
    defaultMessage: "Insurance status",
  },
  ncsCostInformationEstimateInformationInsurance: {
    id: "ncsCostInformationEstimateInformationInsurance",
    defaultMessage: "Non-covered service",
  },

  ncsCostInformationEstimateTitleInsurancePlan: {
    id: "ncsCostInformationEstimateTitleInsurancePlan",
    defaultMessage: "Insurance plan",
  },
  ncsCostInformationMakePaymentTitle: {
    id: "ncsCostInformationMakePaymentTitle",
    defaultMessage: "Make payment for prompt-pay cash price by",
  },
  ncsCostInformationTextFirst: {
    id: "ncsCostInformationTextFirst",
    defaultMessage:
      "To take advantage of the prompt-pay cash price, you'll need to pay by {estimateDate}. You can choose to pay now or apply for financial assistance using the link below.",
  },
  ncsCostInformationTextSecond: {
    id: "ncsCostInformationTextSecond",
    defaultMessage:
      "When you choose our prompt-pay cash price, we will not bill your insurance plan and you will not owe any additional amount.",
  },
  ncsCostInformationTextTitle: {
    id: "ncsCostInformationTextTitle",
    defaultMessage: "What’s next?",
  },
  otherPaymentOptionsSectionTitle: {
    id: "otherPaymentOptionsSectionTitle",
    defaultMessage: "Other payment options",
  },
  financialAssistanceTitle: {
    id: "financialAssistanceTitle",
    defaultMessage: "Learn about financial assistance",
  },
  financialAssistanceText: {
    id: "financialAssistanceText",
    defaultMessage:
      "You may qualify for Natera's Compassionate Care Program based on your financial situation.",
  },
  continueWithInsuranceTitle: {
    id: "continueWithInsuranceTitle",
    defaultMessage: "Continue with insurance",
  },
  billingCostInformationEstimateTitleDate: {
    id: "billingCostInformationEstimateTitleDate",
    defaultMessage: "Estimate date",
  },
  billingCostInformationEstimateTitleDateTooltip: {
    id: "billingCostInformationEstimateTitleDateTooltip",
    defaultMessage:
      "This estimate reflects your benefits as of the date we requested them from your health plan. Future services may impact your deductible, coinsurance, or copay, which could change your final out-of-pocket costs.",
  },
});

interface NcsCostProps {
  onPaymentSelect: (
    paymentSelect: PaymentChoice.CASHPAY | PaymentChoice.INSURANCE
  ) => void;
}

const NcsCostInformation: React.FC<NcsCostProps> = ({ onPaymentSelect }) => {
  const intl = useIntl();
  const { resolve, goAt } = React.useContext(StepperContext);
  const { currentLanguage } = useContext(IntlContext);
  const {
    apiProviderState: { orderEstimate },
    sendPaymentChoice,
  } = useContext(PreBillAPIContext);
  const { config } = useContext(ConfigContext);
  const [testName, setTestName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const insuranceConfirmationDialog = useDialog(InsuranceConfirmationDialog);

  useEffect(() => {
    setTestName(getTestName(orderEstimate?.testType, intl));
  }, [orderEstimate?.testType, intl]);

  const handleFinancialAssistance = () => {
    openNewWindow(`${config.preBill.compassionate_care_link}`);
  };

  const handleContinueWithInsurance = async () => {
    insuranceConfirmationDialog.open({
      onClickYes: handleConfirmInsurance,
      onCloseDialog: () => insuranceConfirmationDialog.close(),
    });
  };

  const handleConfirmInsurance = async () => {
    const payload: SendPaymentChoiceInput = {
      paymentChoice: PaymentChoice.INSURANCE,
    };
    try {
      setLoading(true);
      await sendPaymentChoice(payload);
      onPaymentSelect(PaymentChoice.INSURANCE);
      resolve({});
      goAt(NonCoveredServiceStepsType.BILLING_NEXT_STEPS);
    } catch (error) {
      setLoading(false);
      console.error("Error sending payment choice:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    onPaymentSelect(PaymentChoice.CASHPAY);
    resolve("Step 2 resolved");
    goAt(NonCoveredServiceStepsType.NCS_PAYMENT_METHOD);
  };

  return (
    <>
      {insuranceConfirmationDialog.getDialog()}
      <SpinnerView isLoading={loading} />
      <StepperHeaderProgress />
      <div className="ncs-cost-information">
        <StepperContent
          title={intl.formatMessage(messages.ncsCostInformationTitle, {
            testName: testName,
          })}
        >
          <div className="ncs-cost-information__estimate">
            <div className="ncs-cost-information__estimate-section">
              <div
                className="ncs-cost-information__estimate-section__title"
                data-testid="ncs-cost-information__estimate"
              >
                <span
                  className="ncs-cost-information__estimate-section__header"
                  data-testid="ncs-cost-information__estimate-header"
                >
                  {intl.formatMessage(
                    messages.ncsCostInformationEstimateTitle,
                    {
                      testName: testName,
                    }
                  )}
                </span>
                <Tooltip
                  content={intl.formatMessage(
                    messages.ncsCostInformationEstimateTitleTooltip
                  )}
                >
                  <div className="ncs-cost-estimate-tooltip">?</div>
                </Tooltip>
              </div>
              <span className="ncs-cost-information__estimate-section__cost">
                $
                {Math.floor(
                  (orderEstimate?.totalPrePayAmountProposed as number) / 100
                ) || 0}
              </span>
            </div>
            <div className="ncs-cost-information__estimate-section">
              <div
                className="ncs-cost-information__estimate-section__title"
                data-testid="ncs-cost-information__insurance"
              >
                <span
                  className="ncs-cost-information__estimate-section__header"
                  data-testid="ncs-cost-information__insurance-header"
                >
                  {intl.formatMessage(
                    messages.ncsCostInformationEstimateTitleInsuranceStatus
                  )}
                </span>
              </div>
              <span className="ncs-cost-information__estimate-section__information">
                {intl.formatMessage(
                  messages.ncsCostInformationEstimateInformationInsurance
                )}
              </span>
            </div>
            <div className="ncs-cost-information__estimate-section">
              <div
                className="ncs-cost-information__estimate-section__title"
                data-testid="ncs-cost-information__insurance2"
              >
                <span
                  className="ncs-cost-information__estimate-section__header"
                  data-testid="ncs-cost-information__insurance-header2"
                >
                  {intl.formatMessage(
                    messages.ncsCostInformationEstimateTitleInsurancePlan
                  )}
                </span>
              </div>
              <span className="ncs-cost-information__estimate-section__information">
                {orderEstimate?.insuranceCompanyName}
              </span>
            </div>
            <div className="ncs-cost-information__estimate-section">
              <div
                className="ncs-cost-information__estimate-section__title"
                data-testid="ncs-cost-information__estimate-date"
              >
                <span
                  className="ncs-cost-information__estimate-section__header"
                  data-testid="ncs-cost-information__estimate-date-header"
                >
                  {intl.formatMessage(
                    messages.billingCostInformationEstimateTitleDate
                  )}
                </span>
                <Tooltip
                  content={intl.formatMessage(
                    messages.billingCostInformationEstimateTitleDateTooltip
                  )}
                >
                  <div className="ncs-cost-estimate-tooltip">?</div>
                </Tooltip>
              </div>
              <span className="ncs-cost-information__estimate-section__information">
                {getFormattedLocalizeDate(
                  orderEstimate?.insuranceEstimateDate,
                  currentLanguage
                )}
              </span>
            </div>
            <div className="ncs-cost-information__estimate-section">
              <div
                className="ncs-cost-information__estimate-section__title"
                data-testid="ncs-cost-information__payment-date"
              >
                <span
                  className="ncs-cost-information__estimate-section__header"
                  data-testid="ncs-cost-information__payment-date-header"
                >
                  {intl.formatMessage(
                    messages.ncsCostInformationMakePaymentTitle
                  )}
                </span>
              </div>
              <span className="ncs-cost-information__estimate-section__information">
                {getFormattedLocalizeDate(
                  orderEstimate?.tokenExpireDate,
                  currentLanguage
                )}
              </span>
            </div>
          </div>
          <div
            className="ncs-cost-information__text"
            data-testid="ncs-cost-information__info-text"
          >
            <span
              className="ncs-cost-information__text__title"
              data-testid="ncs-cost-information_info-text-title"
            >
              {intl.formatMessage(messages.ncsCostInformationTextTitle)}
            </span>
            <span className="ncs-cost-information__text__info">
              {intl.formatMessage(messages.ncsCostInformationTextFirst, {
                estimateDate: getFormattedLocalizeDate(
                  orderEstimate?.tokenExpireDate,
                  currentLanguage,
                  true
                ),
              })}
            </span>
            <span className="ncs-cost-information__text__info">
              {intl.formatMessage(messages.ncsCostInformationTextSecond)}
            </span>
          </div>
        </StepperContent>
        <div className="stepper__actions">
          <Button
            raised
            onClick={handleSubmit}
            data-testid="ncs-cost-information__pay-button"
          >
            {intl.formatMessage(messages.ncsCostInformationPayNowButton)}
          </Button>
        </div>
        <div className="other-payment-section">
          <h3 className="other-payment-section__title">
            {intl.formatMessage(messages.otherPaymentOptionsSectionTitle)}
          </h3>
          <InformationalActionCard
            icon={FinancialAssistanceIcon}
            title={intl.formatMessage(messages.financialAssistanceTitle)}
            text={intl.formatMessage(messages.financialAssistanceText)}
            onClick={handleFinancialAssistance}
          />
          <InformationalActionCard
            icon={FinancialAssistanceIcon}
            title={intl.formatMessage(messages.continueWithInsuranceTitle)}
            text={""}
            onClick={handleContinueWithInsurance}
          />
        </div>
      </div>
    </>
  );
};

export default NcsCostInformation;
