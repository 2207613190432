import React from "react";
import { defineMessages, useIntl } from "react-intl";
import Notification from "@app/components/notification";
import Svg from "@natera/material/lib/svg";
import InsurancaProcessIcon from "@app/preBill/assets/svg/insurance-process.svg";
import "./whatNextSection.scss";

const messages = defineMessages({
  whatsNextTitle: {
    id: "whatsNextTitle",
    defaultMessage: "What's next?",
  },
  whatsNextDescription: {
    id: "whatsNextDescription",
    defaultMessage: "Here's a reminder on the insurance process:",
  },
  whatsNextStepTitle: {
    id: "whatsNextStepTitle",
    defaultMessage: "Step {stepNumber}:",
  },
  whatsNextStepDescription1: {
    id: "whatsNextStepDescription1",
    defaultMessage:
      "Natera will submit your claim. If your claim is denied, we may appeal.",
  },
  whatsNextStepDescription2: {
    id: "whatsNextStepDescription2",
    defaultMessage:
      "You will receive an Explanation of Benefits (EOB) from your insurance plan.",
  },
  whatsNextStepDescription3: {
    id: "whatsNextStepDescription3",
    defaultMessage:
      "We will send you the final bill once the claim is finalized, which may be several months after the test is completed. We ask you to pay within 21 days and you'll have financial assistance options available.",
  },
  whatsNextStepNotificationTitle: {
    id: "whatsNextStepNotificationTitle",
    defaultMessage: "An EOB is not a bill",
  },
  whatsNextStepNotificationDescription: {
    id: "whatsNextStepNotificationDescription",
    defaultMessage:
      "It provides a breakdown of covered services you received. This will reflect our list price, which is often different than what you will owe. The EOB will likely not reflect what you'll pay Natera.",
  },
});

interface WhatsNextStepProps {
  stepNumber: number;
  description: string;
  notification?: { title: string; description: string };
}

const WhatsNextStep: React.FC<WhatsNextStepProps> = ({
  stepNumber,
  description,
  notification,
}) => {
  const intl = useIntl();

  return (
    <div className="whats-next-section__step">
      <span className="whats-next-section__step__header">
        {intl.formatMessage(messages.whatsNextStepTitle, {
          stepNumber,
        })}
      </span>
      <span className="whats-next-section__step__content">{description}</span>
      {notification && (
        <Notification type="info" titleElem={notification.title}>
          {notification.description}
        </Notification>
      )}
    </div>
  );
};

const WhatsNextSection: React.FC = () => {
  const intl = useIntl();

  const steps = [
    {
      stepNumber: 1,
      description: intl.formatMessage(messages.whatsNextStepDescription1),
    },
    {
      stepNumber: 2,
      description: intl.formatMessage(messages.whatsNextStepDescription2),
      notification: {
        title: intl.formatMessage(messages.whatsNextStepNotificationTitle),
        description: intl.formatMessage(
          messages.whatsNextStepNotificationDescription
        ),
      },
    },
    {
      stepNumber: 3,
      description: intl.formatMessage(messages.whatsNextStepDescription3),
    },
  ];

  return (
    <div className="whats-next-section">
      <h3 className="whats-next-section__title">
        {intl.formatMessage(messages.whatsNextTitle)}
      </h3>
      <div className="whats-next-section__icon-container">
        <Svg content={InsurancaProcessIcon} />
      </div>
      <p className="whats-next-section__description">
        {intl.formatMessage(messages.whatsNextDescription)}
      </p>
      {steps.map((step) => (
        <WhatsNextStep
          key={step.stepNumber}
          stepNumber={step.stepNumber}
          description={step.description}
          notification={step.notification}
        />
      ))}
    </div>
  );
};

export default WhatsNextSection;
