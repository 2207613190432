import React, { FC } from "react";
import { useIntl } from "react-intl";
import { InfoCard } from "@natera/patient";
import { Button } from "@natera/form";
import { useDialog } from "@natera/platform/lib/hooks";
import { CompassionateCareDialog } from "@app/components";
import CardParams from "./secondaryActionCardParams";
import { SecondaryCard } from "@app/provider/testData/types";
import "./secondaryActionCard.scss";

interface SecondaryActionCardProps {
  card: SecondaryCard;
}

const SecondaryActionCard: FC<SecondaryActionCardProps> = ({ card }) => {
  const intl = useIntl();
  const compassionateCareDialog = useDialog(CompassionateCareDialog);

  const compassionateCareAction = () => {
    compassionateCareDialog.open({
      onCloseDialog: () => compassionateCareDialog.close(),
    });
  };

  const params = CardParams({ compassionateCareAction, card });

  const cardParam = params[card.type];

  return (
    <>
      {compassionateCareDialog.getDialog()}
      <div className="sec-action-card-wrapper">
        <InfoCard
          icon={cardParam.icon || ""}
          contentAside={cardParam.contentAside}
          vertical={!!cardParam.contentAside}
        >
          <div className="sec-action-card">
            <div data-testid="card-title" className="sec-action-card__title">
              {card.title}
            </div>
            <div
              data-testid="card-description"
              className="sec-action-card__description"
            >
              {card.description}
            </div>
            <div className="sec-action-card__btn">
              <Button outlined onClick={cardParam.btn.action}>
                {intl.formatMessage(cardParam.btn.title)}
              </Button>
            </div>
          </div>
        </InfoCard>
      </div>
    </>
  );
};

export default SecondaryActionCard;
