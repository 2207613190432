import React from "react";
import { useIntl, defineMessages } from "react-intl";
import { Button } from "@natera//form";
import { Link } from "@natera/navigation";
import "./ncsPaymentFailure.scss";
import PaymentFailureIcon from "@app/preBill/assets/svg/payment-failure.svg";
import acknowledgements from "@etc/acknowledgements.json";
import Svg from "@natera/material/lib/svg";
import { StepperContext } from "@natera/stepper";

const messages = defineMessages({
  paymentFailureMessage: {
    id: "paymentFailureMessage",
    defaultMessage:
      "Your payment couldn't be completed. Please check your information or try a different payment method.",
  },
  ncsPaymentFailureNeedHelp: {
    id: "ncsPaymentFailureNeedHelp",
    defaultMessage: "Need help?",
  },
  ncsPaymentFailureContactUs: {
    id: "ncsPaymentFailureContactUs",
    defaultMessage: "Contact Us",
  },
  gotItButton: {
    id: "gotItButton",
    defaultMessage: "Got It",
  },
});

const NcsPaymentFailure: React.FC = () => {
  const intl = useIntl();
  const { goBack, reset } = React.useContext(StepperContext);

  const handleGotItClick = () => {
    reset();
    goBack();
  };

  return (
    <div className="ncs-payment-failure-page">
      <div className="ncs-payment-failure-page__content">
        <div className="ncs-payment-failure-page__icon-container">
          <Svg content={PaymentFailureIcon} />
        </div>
        <p
          className="ncs-payment-failure-page__message"
          data-testid="ncsPaymentFailureMessage"
        >
          {intl.formatMessage(messages.paymentFailureMessage)}
        </p>
        <p
          className="ncs-payment-failure-page__help-text"
          data-testid="ncsPaymentFailureHelpText"
        >
          {intl.formatMessage(messages.ncsPaymentFailureNeedHelp)}{" "}
          <Link
            to={{ pathname: acknowledgements.links.contactUs }}
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage(messages.ncsPaymentFailureContactUs)}
          </Link>
        </p>
        <Button
          raised
          onClick={handleGotItClick}
          className="ncs-payment-failure-page__button"
        >
          {intl.formatMessage(messages.gotItButton)}
        </Button>
      </div>
    </div>
  );
};

export default NcsPaymentFailure;
