import Svg from "@natera/material/lib/svg";
import { defineMessages, useIntl } from "react-intl";
import React from "react";

import { TestResultContext } from "@app/provider";
import { routes } from "@app/routing";

import Document from "@assets/svg/icons/document.svg";
import Play from "@assets/svg/icons/play.svg";
import "./supplementalButton.scss";
import { Link } from "@natera/navigation";

export enum SupplementalType {
  VIDEO = "VIDEO",
  RESOURCE = "RESOURCE",
}

type Props = {
  type: SupplementalType;
  uid: string;
  index: number;
  title?: string;
  single?: boolean;
};

const messages = defineMessages({
  supplementalButtonMoreAboutResult: {
    id: "supplementalButtonMoreAboutResult",
    defaultMessage: "More about your result",
  },
  supplementalButtonMoreAboutResultPart: {
    id: "supplementalButtonMoreAboutResultPart",
    defaultMessage: "More about your result Part {part}",
  },
});

const SupplementalButton: React.FC<Props> = ({
  type,
  uid,
  index,
  title,
  single,
}) => {
  const intl = useIntl();
  const { testResult } = React.useContext(TestResultContext);

  if (!testResult) {
    return null;
  }

  const getSupplementalMaterialLink = () => {
    let supplementalMaterialLink;
    if (type === SupplementalType.RESOURCE) {
      supplementalMaterialLink = routes.supplementalPdfPage(
        testResult.orderUid,
        testResult.testUid,
        uid
      );
    } else {
      supplementalMaterialLink = routes.supplementalVideoPage(
        testResult.orderUid,
        testResult.testUid,
        uid
      );
    }
    return supplementalMaterialLink;
  };

  const getTitle = () => {
    if (title) {
      return title;
    }

    if (single) {
      return intl.formatMessage(messages.supplementalButtonMoreAboutResult);
    }

    return intl.formatMessage(messages.supplementalButtonMoreAboutResultPart, {
      part: index + 1,
    });
  };

  return (
    <Link
      to={getSupplementalMaterialLink()}
      target="_self"
      className="link-button"
    >
      <div className="supplemental__button">
        <div className="icon">
          <Svg content={type === SupplementalType.RESOURCE ? Document : Play} />
        </div>
        <div className="title">{getTitle()}</div>
      </div>
    </Link>
  );
};

export default SupplementalButton;
