import React, { FC, useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button, Form, Textarea } from "@natera/form";
import { StepperContext } from "@natera/stepper";
import {
  DrawRequestContext,
  HeapAnalyticDataContext,
  IntlContext,
  ProfileContext,
  TestDetailsContext,
  UppAuthContext,
} from "@app/provider";
import { FormField } from "@app/components";
import { SAMPLE_DRAW_STEP_IDS } from "@app/pages/private/sampleDraw/sampleDraw";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import * as R from "ramda";
import { HEAP_EVENTS, HeapProps, ShippingAddress } from "@app/provider/types";
import { StepperContent } from "@app/components/ui/layout";

import { TestType } from "@app/provider/testData/types";
import { getParamsForHeapMPApptFailureEvent } from "@app/utils";

import "./additionalInstructionsStep.scss";

const messages = defineMessages({
  additionalInstructionsStepTitle: {
    id: "additionalInstructionsStepTitle",
    defaultMessage: "Mobile Blood Draw",
  },
  additionalInstructionsStepDescriptionTitle: {
    id: "additionalInstructionsStepDescriptionTitle",
    defaultMessage:
      "Would you like to add any comments or instructions for your blood draw appointment?",
  },
  additionalInstructionsFormLabel: {
    id: "additionalInstructionsFormLabel",
    defaultMessage: "Comments",
  },
  additionalInstructionsFormInputPlaceholder: {
    id: "additionalInstructionsFormInputPlaceholder",
    defaultMessage:
      "Examples: Look for the green house, ring doorbell at the side door, use code #104 to open the parking gate, etc.",
  },
  additionalInstructionsSubmitAppointmentRequest: {
    id: "additionalInstructionsSubmitAppointmentRequest",
    defaultMessage: "Submit",
  },
  additionalInstructionsBack: {
    id: "additionalInstructionsBack",
    defaultMessage: "Back",
  },
});

type AdditionalInstructionsStepProps = {
  handleCreateDrawRequestError?: () => void;
  testType?: TestType;
  orderUid?: string;
  testUid?: string;
  token?: string;
  isPublicRequest?: boolean;
};

const AdditionalInstructionsStep: FC<AdditionalInstructionsStepProps> = ({
  handleCreateDrawRequestError,
  ...props
}) => {
  const intl = useIntl();
  const [additionalInstructions, setAdditionalInstructions] = useState<string>(
    ""
  );
  const [loading, setLoading] = useState<boolean>(false);

  const { getTestDetails } = useContext(TestDetailsContext);
  const { updatePatientLanguage } = useContext(ProfileContext);
  const { currentLanguage } = useContext(IntlContext);

  const testDetails = getTestDetails();

  const {
    createDrawRequest,
    openCreateDrawErrorDialog,
    verifiedSampleDrawData,
  } = useContext(DrawRequestContext);

  const { profile } = React.useContext(UppAuthContext);

  const { drawRequestDataForHeapEventData } = useContext(
    HeapAnalyticDataContext
  );

  const { getData, resolve, goBack } = useContext(StepperContext);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const shippingAddress = getData(SAMPLE_DRAW_STEP_IDS.MP1)?.address;

    const timeSlots = getData(SAMPLE_DRAW_STEP_IDS.MP2)?.timeSlots;
    const timeZone = getData(SAMPLE_DRAW_STEP_IDS.MP2)?.timeZone;

    try {
      await updatePatientLanguage(currentLanguage, props.token);
      await createDrawRequest({
        timeZone,
        shippingAddress: R.omit(["uid"], shippingAddress) as ShippingAddress,
        timeSlots,
        notes: additionalInstructions,
        ...props,
      });

      resolve({});
    } catch {
      const heapEventProps: HeapProps = getParamsForHeapMPApptFailureEvent(
        Boolean(props.isPublicRequest),
        profile,
        {
          verifiedSampleDrawData,
          testDetails,
          drawRequestDataForHeapEventData,
        }
      );

      if (props.isPublicRequest) {
        heapEventProps.identity =
          verifiedSampleDrawData?.profileInfo.profile?.uid ?? "";
      }

      heap.track(HEAP_EVENTS.upp_sampledraw_mp_appt_failure, heapEventProps);

      handleCreateDrawRequestError
        ? handleCreateDrawRequestError()
        : openCreateDrawErrorDialog();
    } finally {
      setLoading(false);
    }
  };

  const handleBackButtonClick = () => goBack();

  const handleChangeAdditionalInstructions: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) =>
    event.target.value.length <= 255 &&
    setAdditionalInstructions(event.target.value);

  return (
    <>
      <StepperHeaderProgress />
      <StepperContent
        title={intl.formatMessage(messages.additionalInstructionsStepTitle)}
        subTitle={intl.formatMessage(
          messages.additionalInstructionsStepDescriptionTitle
        )}
      >
        <Form
          className="additionalInstructionsForm"
          noValidate
          onSubmit={handleSubmit}
          buttons={
            <>
              <Button
                type="submit"
                data-testid="submit"
                raised
                loading={loading}
              >
                {intl.formatMessage(
                  messages.additionalInstructionsSubmitAppointmentRequest
                )}
              </Button>
              <Button onClick={handleBackButtonClick}>
                {intl.formatMessage(messages.additionalInstructionsBack)}
              </Button>
            </>
          }
        >
          <FormField
            label={intl.formatMessage(messages.additionalInstructionsFormLabel)}
            htmlFor="additional-instructions"
          >
            <Textarea
              data-testid="additional-instructions-text"
              id="additional-instructions"
              name="additional-instructions-text"
              placeholder={intl.formatMessage(
                messages.additionalInstructionsFormInputPlaceholder
              )}
              className="notes"
              onChange={handleChangeAdditionalInstructions}
              value={additionalInstructions}
              outline
            />
          </FormField>
        </Form>
      </StepperContent>
    </>
  );
};
export default AdditionalInstructionsStep;
