import { StepperContent } from "@app/components/ui/layout/stepperContent";
import React, { useContext, useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { StepperContext } from "@natera/stepper";
import Tooltip from "@natera/material/lib/tooltip";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { Button } from "@natera/form";
import FinancialAssistanceIcon from "@app/preBill/assets/svg/financial-assistance.svg";
import "./oonCostInformation.scss";
import { InformationalActionCard } from "@app/preBill/components/informationalActionCard";
import { PreBillAPIContext, ConfigContext } from "@app/provider";
import { openNewWindow } from "@app/utils";
import { getFormattedLocalizeDate } from "@app/utils";
import { IntlContext } from "@app/provider";
import { getTestName } from "@app/preBill/utils/getTestName";
import { PaymentChoice, SendPaymentChoiceInput } from "@app/preBill/models";
import { OutOfNetworkStepsType } from "../../outOfNetworkStepper";

const messages = defineMessages({
  oonCostInformationTitle: {
    id: "oonCostInformationTitle",
    defaultMessage: "Your {testName} cost estimate",
  },
  oonCostInformationSubtitle: {
    id: "oonCostInformationSubtitle",
    defaultMessage:
      "Natera is <b>not</b> in-network with your insurance plan. As a result, we're unable to provide an accurate cost estimate for your test.",
  },
  oonCostInformationEstimateTitle: {
    id: "oonCostInformationEstimateTitle",
    defaultMessage: "Average out-of-pocket cost",
  },
  oonCostInformationEstimateTitleTooltip: {
    id: "oonCostInformationEstimateTitleTooltip",
    defaultMessage:
      "This estimate is based on average costs for patients with a similar plan type and benefits who have met their deductible. This costs may by in the form of a deductible, coinsurance, copay or any combination.",
  },
  oonCostInformationStatusTitle: {
    id: "oonCostInformationStatusTitle",
    defaultMessage: "Status",
  },
  oonCostInformationStatusValue: {
    id: "oonCostInformationStatusValue",
    defaultMessage: "Out-of-Network",
  },
  oonCostInformationInsurancePlanTitle: {
    id: "oonCostInformationInsurancePlanTitle",
    defaultMessage: "Insurance plan",
  },
  oonCostInformationText: {
    id: "oonCostInformationText",
    defaultMessage: "We'll continue with submitting your claim to insurance.",
  },
  oonCostInformationGotItButton: {
    id: "oonCostInformationGotItButton",
    defaultMessage: "Got it",
  },
  oonCostInformationBackButton: {
    id: "oonCostInformationBackButton",
    defaultMessage: "Back",
  },
  otherPaymentOptionsSectionTitle: {
    id: "otherPaymentOptionsSectionTitle",
    defaultMessage: "Other payment options",
  },
  financialAssistanceTitle: {
    id: "financialAssistanceTitle",
    defaultMessage: "Learn about financial assistance",
  },
  financialAssistanceText: {
    id: "financialAssistanceText",
    defaultMessage:
      "You may qualify for Natera's Compassionate Care Program based on your financial situation.",
  },
  payCashNowTitle: {
    id: "payCashNowTitle",
    defaultMessage: "Pay cash now",
  },
  payCashNowText: {
    id: "payCashNowText",
    defaultMessage: "If you prefer to not use your insurance.",
  },
  billingCostInformationEstimateTitleDate: {
    id: "billingCostInformationEstimateTitleDate",
    defaultMessage: "Estimate date",
  },
  billingCostInformationEstimateTitleDateTooltip: {
    id: "billingCostInformationEstimateTitleDateTooltip",
    defaultMessage:
      "This estimate reflects your benefits as of the date we requested them from your health plan. Future services may impact your deductible, coinsurance, or copay, which could change your final out-of-pocket costs.",
  },
});

interface OonCostProps {
  onPaymentSelect: (
    paymentSelect: PaymentChoice.CASHPAY | PaymentChoice.INSURANCE
  ) => void;
}

const OonCostInformation: React.FC<OonCostProps> = ({ onPaymentSelect }) => {
  const intl = useIntl();
  const { goBack, resolve, goAt, reset } = React.useContext(StepperContext);
  const { currentLanguage } = useContext(IntlContext);
  const {
    apiProviderState: { orderEstimate },
    sendPaymentChoice,
  } = useContext(PreBillAPIContext);
  const { config } = useContext(ConfigContext);
  const [testName, setTestName] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTestName(getTestName(orderEstimate?.testType, intl));
  }, [orderEstimate?.testType, intl]);

  const createOopCostText = () => {
    if (!orderEstimate?.oonAverageOopMin || !orderEstimate?.oonAverageOopMax)
      return "$0";
    const oopCostText = `$${Math.floor(
      (orderEstimate?.oonAverageOopMin as number) / 100
    )} - $${Math.floor((orderEstimate?.oonAverageOopMax as number) / 100)}`;
    return oopCostText;
  };

  const handleGotIt = async () => {
    const payload: SendPaymentChoiceInput = {
      paymentChoice: PaymentChoice.INSURANCE,
    };
    try {
      await sendPaymentChoice(payload);
      onPaymentSelect(PaymentChoice.INSURANCE);
      resolve({});
      goAt(OutOfNetworkStepsType.BILLING_END_INFORMATION);
    } catch (error) {
      console.error("Error sending payment choice:", error);
    }
  };

  const handleBack = () => {
    reset();
    goBack();
  };

  const handleFinancialAssistance = () => {
    openNewWindow(`${config.preBill.compassionate_care_link}`);
  };

  const formattedSubtitle = intl.formatMessage(
    messages.oonCostInformationSubtitle,
    { b: (...chunks) => <b>{chunks}</b> }
  );

  const handleCashPayButton = () => {
    onPaymentSelect(PaymentChoice.CASHPAY);
    resolve({});
    goAt(OutOfNetworkStepsType.OON_CASH_PAY);
  };

  return (
    <>
      <StepperHeaderProgress />
      <div className="oon-cost-information">
        <StepperContent
          title={intl.formatMessage(messages.oonCostInformationTitle, {
            testName: testName,
          })}
        >
          <p className="oon-cost-information__subtitle">{formattedSubtitle}</p>
          <div className="oon-cost-information__estimate">
            <div className="oon-cost-information__estimate-section">
              <div className="oon-cost-information__estimate-section__title">
                <span className="oon-cost-information__estimate-section__header">
                  {intl.formatMessage(messages.oonCostInformationEstimateTitle)}
                </span>
                <Tooltip
                  content={intl.formatMessage(
                    messages.oonCostInformationEstimateTitleTooltip
                  )}
                >
                  <div className="oon-cost-estimate-tooltip">?</div>
                </Tooltip>
              </div>
              <span className="oon-cost-information__estimate-section__cost">
                {createOopCostText() || `$0`}
              </span>
            </div>
            <div className="oon-cost-information__estimate-section">
              <div className="oon-cost-information__estimate-section__title">
                <span className="oon-cost-information__estimate-section__header">
                  {intl.formatMessage(messages.oonCostInformationStatusTitle)}
                </span>
              </div>
              <span className="oon-cost-information__estimate-section__information">
                {intl.formatMessage(messages.oonCostInformationStatusValue)}
              </span>
            </div>
            <div className="oon-cost-information__estimate-section">
              <div className="oon-cost-information__estimate-section__title">
                <span className="oon-cost-information__estimate-section__header">
                  {intl.formatMessage(
                    messages.oonCostInformationInsurancePlanTitle
                  )}
                </span>
              </div>
              <span className="oon-cost-information__estimate-section__information">
                {orderEstimate?.insuranceCompanyName}
              </span>
            </div>
            <div className="oon-cost-information__estimate-section">
              <div className="oon-cost-information__estimate-section__title">
                <span className="oon-cost-information__estimate-section__header">
                  {intl.formatMessage(
                    messages.billingCostInformationEstimateTitleDate
                  )}
                </span>
                <Tooltip
                  content={intl.formatMessage(
                    messages.billingCostInformationEstimateTitleDateTooltip
                  )}
                >
                  <div className="oon-cost-estimate-tooltip">?</div>
                </Tooltip>
              </div>
              <span className="oon-cost-information__estimate-section__information">
                {getFormattedLocalizeDate(
                  orderEstimate?.insuranceEstimateDate,
                  currentLanguage
                )}
              </span>
            </div>
          </div>
          <div className="oon-cost-information__text">
            <span className="oon-cost-information__text__info">
              {intl.formatMessage(messages.oonCostInformationText)}
            </span>
          </div>
        </StepperContent>
        <div className="stepper__actions">
          <Button
            raised
            onClick={handleGotIt}
            data-testid="oon-cost-information__got-it-button"
          >
            {intl.formatMessage(messages.oonCostInformationGotItButton)}
          </Button>
          <Button
            onClick={handleBack}
            data-testid="oon-cost-information__back-button"
            className="back-button"
          >
            {intl.formatMessage(messages.oonCostInformationBackButton)}
          </Button>
        </div>
        <div className="oon-cost-information__divider" />
        <div className="other-payment-section">
          <h3 className="other-payment-section__title">
            {intl.formatMessage(messages.otherPaymentOptionsSectionTitle)}
          </h3>
          <InformationalActionCard
            icon={FinancialAssistanceIcon}
            title={intl.formatMessage(messages.financialAssistanceTitle)}
            text={intl.formatMessage(messages.financialAssistanceText)}
            onClick={handleFinancialAssistance}
          />
          <InformationalActionCard
            icon={FinancialAssistanceIcon}
            title={intl.formatMessage(messages.payCashNowTitle)}
            text={intl.formatMessage(messages.payCashNowText)}
            onClick={handleCashPayButton}
          />
        </div>
      </div>
    </>
  );
};

export default OonCostInformation;
