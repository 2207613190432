import { PreBillTestTypes } from "../models";
import { defineMessages, IntlShape } from "react-intl";
import { capitalizeFirstLetter } from "@app/utils";

const messages = defineMessages({
  comboOrderTestNameItemsAnd: {
    id: "comboOrderTestNameItemsAnd",
    defaultMessage: "and",
  },
});

export const getTestName = (
  tests: (PreBillTestTypes | string)[] | undefined,
  intl: IntlShape
): string => {
  if (tests) {
    const lowerCaseTestNames: string[] = [];
    tests.forEach((test) => {
      lowerCaseTestNames.push(capitalizeFirstLetter(test) as string);
    });

    if (lowerCaseTestNames?.length > 1) {
      const testName =
        lowerCaseTestNames.slice(0, -1).join(", ") +
        ` ${intl.formatMessage(messages.comboOrderTestNameItemsAnd)} ` +
        lowerCaseTestNames.slice(-1);
      return testName;
    }
    return `${lowerCaseTestNames[0]}`;
  }
  return "";
};
