import { Language } from "@app/provider";
import { TokenizedLinkAction } from "./useTokenizedLinks";
import { useLazyQuery } from "@apollo/client";
import TokenizedLinksService from "@app/service/tokenizedLinks";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { routes } from "@app/routing";

export type NotificationTemplateName = PreBillNotificationTemplate;

export enum PreBillNotificationTemplate {
  PRE_BILL_COMM = "PRE_BILL_COMM",
  PRE_BILL_REMINDER = "PRE_BILL_REMINDER",
}

export type GeneratedTokenizedLinkData =
  | SignUpTokenizedLinkData
  | ScheduleDrawTokenizedLinkData
  | PostBillGuestLoginTokenizedLinkData
  | PreBillGuestLoginTokenizedLinkData;

export type BaseTokenizedLinkData = {
  language: Language;
  notificationType?: NotificationTemplateName;
};

export type SignUpTokenizedLinkData = BaseTokenizedLinkData & {
  patientUid: string;
};

export type ScheduleDrawTokenizedLinkData = BaseTokenizedLinkData & {
  orderUid: string;
};

export type PostBillGuestLoginTokenizedLinkData = BaseTokenizedLinkData & {
  orderUid: string;
  patientUid: string;
  lineItemUid: string;
};

export type PreBillGuestLoginTokenizedLinkData = BaseTokenizedLinkData & {
  orderUid: string;
};

export type GeneratedTokenizedLink = {
  action: TokenizedLinkAction;
  data: GeneratedTokenizedLinkData;
  shortened: boolean;
};

export type GetGeneratedTokenizedLinkPayload = {
  link: string;
};

type GeneratedTokenizedLinksController = {
  isLoading: boolean;
  getTokenizedLink: (
    getGeneratedTokenizedLink: GeneratedTokenizedLink
  ) => Promise<GetGeneratedTokenizedLinkPayload | undefined>;
};

type UseGeneratedTokenizedLink = () => GeneratedTokenizedLinksController;

const useGeneratedTokenizedLink: UseGeneratedTokenizedLink = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [getGenratedTokenizedLink] = useLazyQuery<{
    getGeneratedTokenizedLink: GetGeneratedTokenizedLinkPayload;
  }>(TokenizedLinksService.getGeneratedTokenizedLink());

  const getTokenizedLink = async (
    generatedTokenizedLink: GeneratedTokenizedLink
  ) => {
    setLoading(true);

    const response = await getGenratedTokenizedLink({
      variables: {
        generatedTokenizedLink,
      },
    });
    const payload = response.data?.getGeneratedTokenizedLink;
    if (!payload) {
      history.replace(routes.home);
      return;
    }

    return payload;
  };

  return {
    isLoading,
    getTokenizedLink,
  };
};

export default useGeneratedTokenizedLink;
