import React, { FC, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { FlowHeader, SpinnerView } from "@app/components";
import {
  DrawRequestContext,
  KitContext,
  OrderRenewalContext,
} from "@app/provider";
import { TestType } from "@app/provider/testData/types";
import { ResultCodes } from "@app/service/resultCodes";
import { useDialog } from "@natera/platform/lib/hooks";
import DrawRequestDialog from "@app/components/drawRequestDialog";
import { TestDetailsContext } from "@app/provider/testData";
import { SampleDrawKitStep } from "@app/components/sampleDraw/steps";

import "./sampleDraw.scss";
import MobilePhlebotomyStepper from "./mobilePhlebotomyStepper";
import { useHistory } from "react-router-dom";
import { routes } from "@app/routing";
import { ComboOrderModal } from "@app/components/sampleDraw/dialogs";
import TimingInfoDialog from "@app/components/sampleDraw/steps/kit/timingInfoDialog";
import { capitalizeFirstLetter } from "@app/utils";
import { TestDetailsRecord } from "@app/provider/types";
import { DrawRequestFlow } from "@app/provider/drawRequest";

const messages = defineMessages({
  sampleDrawLabTitle: {
    id: "sampleDrawLabTitle",
    defaultMessage: "Lab Locator",
  },
  sampleDrawCannotBeRequestedTitle: {
    id: "sampleDrawCannotBeRequestedTitle",
    defaultMessage: "We're sorry.",
  },
  sampleDrawCannotBeRequestedDesc: {
    id: "sampleDrawCannotBeRequestedDesc",
    defaultMessage: "Mobile phlebotomy cannot be requested for this test",
  },
  sampleDrawNotFound: {
    id: "sampleDrawNotFound",
    defaultMessage: "Test not found",
  },
});

const CreateMobilePhlebotomy: FC = () => {
  const intl = useIntl();
  const history = useHistory<{
    fromSimpleOrderFlow?: boolean;
    fromTokenizedLink?: boolean;
  }>();
  const {
    checkSampleDraw,
    isLoading: DrawRequestLoading,
    comboOrders,
  } = useContext(DrawRequestContext);
  const { getRenewalInfo, isLoading: OrderRenewalLoading } = React.useContext(
    OrderRenewalContext
  );
  const { setSkipKitShipping, setSkipLabStep } = React.useContext(KitContext);

  const drawRequestDialog = useDialog(DrawRequestDialog);
  const comboOrderModal = useDialog(ComboOrderModal);
  const timingDialog = useDialog(TimingInfoDialog);
  const { getTestDetails, getOrderUid, getTestUid } = useContext(
    TestDetailsContext
  );

  const [kitShippingNeeded, setKitShippingNeeded] = useState<boolean>(false);
  const [isPossibleToCreate, setIsPossibleToCreate] = useState(false);

  const [isKitShippingStep, setIsKitShippingStep] = useState(false);

  const [testDetailsRecord, setTestDetailsRecord] = useState<
    TestDetailsRecord
  >();
  const [orderUid, setOrderUid] = useState<string>();
  const [testUid, setTestUid] = useState<string>();

  const testType = testDetailsRecord?.testType;
  const isOncologyOrder = testDetailsRecord?.isOncologyOrder;

  useEffect(() => {
    setTestDetailsRecord(getTestDetails());
    setOrderUid(getOrderUid());
    setTestUid(getTestUid());
  }, []);

  useEffect(() => {
    if (isOncologyOrder) {
      const closeTimingDialogAndRedirect = () => {
        timingDialog.close();
        history.push(routes.home);
      };

      timingDialog.open({
        onClose: closeTimingDialogAndRedirect,
        onContinue: timingDialog.close,
      });
    }
  }, [isOncologyOrder]);

  useEffect(() => {
    if (comboOrders.length > 1) {
      const orderTypes = comboOrders.map((parsedTestCard) =>
        capitalizeFirstLetter(parsedTestCard.testType)
      );
      comboOrderModal.open({ orderTypes, onClose: comboOrderModal.close });
    }
  }, [comboOrders.length]);

  const handleKitShippingStepPassed = async () => {
    setIsKitShippingStep(false);
  };

  const handleBackKitStep = async () => {
    const isFromTokenizedLink = history.location.state?.fromTokenizedLink;

    if (isFromTokenizedLink) {
      history.push(routes.home);
    } else {
      history.length > 1 ? history.goBack() : history.push(routes.home);
    }
  };
  const handleBackPreferenceStep = () => {
    history.location.state?.fromSimpleOrderFlow || history.length <= 1
      ? history.push(routes.home)
      : history.goBack();
  };

  const handleBackAppointmentTimeStep = () => {
    history.replace(routes.home);
  };

  const handleBackKitShippingStep = async () => {
    if (!kitShippingNeeded) {
      history.push(routes.home);
    }
    setIsKitShippingStep(kitShippingNeeded);
  };

  const handleCheckSample = async (orderUid: string, testUid: string) => {
    try {
      const response = await checkSampleDraw(orderUid, testUid);
      if (response?.isValid) {
        setKitShippingNeeded(response.needsKitShippingStep);
        setSkipLabStep(response.skipLabStep);
        setIsPossibleToCreate(true);
      }
      setSkipKitShipping(!response?.needsKitShippingStep);
    } catch (error) {
      switch (error.code) {
        case ResultCodes.TEST_NOT_FOUND:
          drawRequestDialog.open({
            title: intl.formatMessage(messages.sampleDrawNotFound),
          });
          break;
        case ResultCodes.CANNOT_CREATE_DRAW_REQUEST:
          drawRequestDialog.open({
            title: intl.formatMessage(
              messages.sampleDrawCannotBeRequestedTitle
            ),
            description: intl.formatMessage(
              messages.sampleDrawCannotBeRequestedDesc
            ),
          });
          break;
      }
    }
  };

  useEffect(() => {
    setIsKitShippingStep(kitShippingNeeded);
  }, [kitShippingNeeded]);

  useEffect(() => {
    if (orderUid && testUid) {
      handleCheckSample(orderUid, testUid);
      if (testType === TestType.SIGNATERA) {
        getRenewalInfo({ orderUid });
      }
    }
  }, [orderUid, testUid]);

  const isLoading = DrawRequestLoading || OrderRenewalLoading;

  return (
    <>
      <SpinnerView isLoading={isLoading} />
      {!isPossibleToCreate ? (
        <>{drawRequestDialog.getDialog()}</>
      ) : (
        <>
          <FlowHeader />
          {isKitShippingStep ? (
            <div className="progress-stepper">
              <div className="progress-stepper__header" />
              <section className="progress-stepper__section">
                <SampleDrawKitStep
                  onSubmit={handleKitShippingStepPassed}
                  onBack={handleBackKitStep}
                />
              </section>
            </div>
          ) : (
            <MobilePhlebotomyStepper
              onBackPreferenceStep={handleBackPreferenceStep}
              onBackAppointmentTimeStep={handleBackAppointmentTimeStep}
              onBackKitShippingStep={handleBackKitShippingStep}
              flow={DrawRequestFlow.ACCOUNT}
            />
          )}
          {timingDialog.getDialog()}
          {comboOrderModal.getDialog()}
        </>
      )}
    </>
  );
};

export default CreateMobilePhlebotomy;
