import React, {
  FC,
  useContext,
  useMemo,
  createContext,
  useEffect,
  useRef,
} from "react";

import { RuntimeConfigurationContext } from "@natera/platform/lib/provider/runtimeConfiguration";
import { OktaAuthOptions } from "@natera/platform/lib/service/session";

import { ConfigContext, IntlContext } from "@app/provider";
import StorageServiceBuilder from "@app/service/storage";
import SessionService from "@app/service/session";
import FileService from "@app/service/file";

export const Context = createContext<ServiceProvider>({
  storageServiceBuilder: {} as StorageServiceBuilder,
  sessionService: {
    getToken: () => Promise.reject(),
  } as SessionService,
  fileService: {} as FileService,
} as ServiceProvider);

interface ServiceProvider {
  storageServiceBuilder: StorageServiceBuilder;
  sessionService: SessionService;
  fileService: FileService;
}

const ServiceProvider: FC = ({ children }) => {
  const { currentLanguage } = useContext(IntlContext);
  const runtimeConfigurationService = useContext(RuntimeConfigurationContext);
  const configService = useContext(ConfigContext);
  const oktaConfig = configService.getConfig("okta") || {};
  const sessionServiceRef = useRef<SessionService>(
    new SessionService(oktaConfig as OktaAuthOptions)
  );

  useEffect(() => {
    sessionServiceRef.current.getAuthClient().setHeaders({
      "Accept-Language": currentLanguage,
    });
  }, [currentLanguage]);

  const httpServicesArgs: [
    typeof configService,
    typeof sessionServiceRef.current,
    typeof runtimeConfigurationService
  ] = [configService, sessionServiceRef.current, runtimeConfigurationService];

  const storageServiceBuilder = new StorageServiceBuilder();

  const httpServices = useMemo(
    () => ({
      configService: configService,
      fileService: new FileService(...httpServicesArgs),
    }),
    [sessionServiceRef.current]
  );

  return (
    <Context.Provider
      value={{
        ...httpServices,
        storageServiceBuilder,
        sessionService: sessionServiceRef.current,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ServiceProvider;
