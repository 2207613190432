import React, { FC } from "react";
import { Dialog } from "@natera/material/lib/dialog";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/form";
import "./cancelAppointmentDialog.scss";

const messages = defineMessages({
  cancelAppointmentDialogTitle: {
    id: "cancelAppointmentDialogTitle",
    defaultMessage: "Would you like to cancel your appointment?",
  },
  cancelAppointmentDialogContent: {
    id: "cancelAppointmentDialogContent",
    defaultMessage: "Your blood draw appointment will be cancelled",
  },
  cancelAppointmentDialogCancelAppointmentButton: {
    id: "cancelAppointmentDialogCancelAppointmentButton",
    defaultMessage: "Cancel Appointment",
  },
  cancelAppointmentDialogKeepAppointmentButton: {
    id: "cancelAppointmentDialogKeepAppointmentButton",
    defaultMessage: "Keep Appointment",
  },
});

interface CancelAppointmentDialogProps {
  onClickCancel: () => Promise<void>;
  onClose: () => void;
}

const CancelAppointmentDialog: FC<CancelAppointmentDialogProps> = ({
  onClickCancel,
  onClose,
}) => {
  const intl = useIntl();

  const [cancelLoading, setCancelLoading] = React.useState<boolean>(false);

  const header = intl.formatMessage(messages.cancelAppointmentDialogTitle);

  const content = (
    <p>{intl.formatMessage(messages.cancelAppointmentDialogContent)}</p>
  );

  const handleCancel = async () => {
    try {
      setCancelLoading(true);
      await onClickCancel();
    } finally {
      setCancelLoading(false);
      onClose();
    }
  };

  const actions = (
    <>
      <Button onClick={handleCancel} outlined loading={cancelLoading}>
        {intl.formatMessage(
          messages.cancelAppointmentDialogCancelAppointmentButton
        )}
      </Button>
      <Button onClick={onClose} raised disabled={cancelLoading}>
        {intl.formatMessage(
          messages.cancelAppointmentDialogKeepAppointmentButton
        )}
      </Button>
    </>
  );

  return (
    <Dialog
      className="cancel-appointment-dialog"
      onClose={onClose}
      title={header}
      showCrossButton
      actions={actions}
    >
      <div className="cancel-appointment-dialog__content">{content}</div>
    </Dialog>
  );
};

export default CancelAppointmentDialog;
