import React, { createContext } from "react";
import { useLazyQuery } from "@apollo/client";
import SpecificDatesService from "@app/service/specificDates";

type GetSpecificDates = () => Promise<string[] | undefined>;
export interface SpecificDatesController {
  isLoading: boolean;
  specificDates?: Date[];
  getSpecificDates: GetSpecificDates;
}

export const SpecificDatesContext = createContext<SpecificDatesController>({
  isLoading: false,
  specificDates: undefined,
  getSpecificDates: () => Promise.reject(),
});

export enum SpecificDateEnum {
  BLACKOUT = "BLACKOUT",
}

type SpecificDatesResponse = {
  getSpecificDates: string[];
};

SpecificDatesContext.displayName = "SpecificDatesContext";
const SpecificDatesProvider: React.FC = ({ children }) => {
  const [specificDates, setSpecificDates] = React.useState<Date[]>([]);

  const [getSpecificDates, { loading: isLoading }] = useLazyQuery<
    SpecificDatesResponse
  >(SpecificDatesService.getSpecificDates(), { fetchPolicy: "network-only" });

  const handleGetSpecificDates = async () => {
    const response = await getSpecificDates({
      variables: {
        types: [SpecificDateEnum.BLACKOUT],
      },
    });

    if (response.data?.getSpecificDates) {
      const specificDates = response.data.getSpecificDates.map(
        (dateStr: string) => new Date(dateStr)
      );

      setSpecificDates(specificDates);
    }

    return response.data?.getSpecificDates;
  };

  React.useEffect(() => {
    handleGetSpecificDates();
  }, []);

  return (
    <SpecificDatesContext.Provider
      value={{
        isLoading,
        specificDates,
        getSpecificDates: handleGetSpecificDates,
      }}
    >
      {children}
    </SpecificDatesContext.Provider>
  );
};

export default SpecificDatesProvider;
