export const calculateOOPCost = (totalCost: number): string | undefined => {
  if (!totalCost) return;

  const min = totalCost - 5000;
  const max = totalCost + 5000;

  const roundedMin = Math.floor(min / 100);
  const roundedMax = Math.floor(max / 100);

  return `$${roundedMin} - $${roundedMax}`;
};
