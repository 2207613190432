import { PreBillAPIProvider, PreBillProvider } from "@app/provider";
import React, { ReactNode } from "react";

interface PreBillContextWrapperProps {
  children: ReactNode;
}

export const PreBillContextWrapper: React.FC<PreBillContextWrapperProps> = ({
  children,
}) => {
  return (
    <PreBillProvider>
      <PreBillAPIProvider>{children}</PreBillAPIProvider>
    </PreBillProvider>
  );
};
