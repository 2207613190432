import { AddressForm } from "@app/components/forms";
import { Address } from "@app/provider";
import { Button } from "@natera/form";
import React from "react";
import { defineMessages, useIntl } from "react-intl";

export const messages = defineMessages({
  sampleDrawMPAddAddressNextButtonText: {
    id: "sampleDrawMPAddAddressNextButtonText",
    defaultMessage: "Add",
  },
  sampleDrawMPAddAddressBackButtonText: {
    id: "sampleDrawMPAddAddressBackButtonText",
    defaultMessage: "Back",
  },
});

type Props = {
  onSubmit: (address: Partial<Address>) => void;
  onBack?: () => void;
};

const SampleDrawMPAddAddress: React.FC<Props> = ({ onSubmit, onBack }) => {
  const intl = useIntl();

  return (
    <AddressForm
      onSubmit={onSubmit}
      actions={
        <>
          <Button type="submit" data-testid="submit" raised>
            {intl.formatMessage(messages.sampleDrawMPAddAddressNextButtonText)}
          </Button>
          {onBack && (
            <Button type="reset" data-testid="cancel" onClick={onBack}>
              {intl.formatMessage(
                messages.sampleDrawMPAddAddressBackButtonText
              )}
            </Button>
          )}
        </>
      }
    />
  );
};

export default SampleDrawMPAddAddress;
