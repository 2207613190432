import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/material/lib/button";
import { PreBillAPIContext } from "@app/provider";
import {
  SendSurveyAnswerInput,
  InsuranceSurveyAnswerType,
} from "@app/preBill/models/apiControllerModel";
import YesIcon from "@app/preBill/assets/svg/yes-icon.svg";
import NoIcon from "@app/preBill/assets/svg/no-icon.svg";
import "./surveySection.scss";

const messages = defineMessages({
  surveyTitle: {
    id: "surveyTitle",
    defaultMessage: "Was this information helpful?",
  },
  surveyYesButton: {
    id: "surveyYesButton",
    defaultMessage: "Yes",
  },
  surveyNoButton: {
    id: "surveyNoButton",
    defaultMessage: "No",
  },
});

const SurveySection: React.FC = () => {
  const intl = useIntl();
  const { sendSurveyAnswer } = useContext(PreBillAPIContext);

  const handleSurveyResponse = async (
    answer: InsuranceSurveyAnswerType
  ): Promise<void> => {
    const sendSurverAnswerData: SendSurveyAnswerInput = {
      question: intl.formatMessage(messages.surveyTitle),
      answer: answer,
    };
    try {
      await sendSurveyAnswer(sendSurverAnswerData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="survey-section">
      <div className="survey-section__container">
        <div className="survey-section__title">
          {intl.formatMessage(messages.surveyTitle)}
        </div>
        <div className="survey-section__button">
          <Button
            className="survey-section__button__yes"
            outlined
            icon={YesIcon}
            onClick={() => handleSurveyResponse(InsuranceSurveyAnswerType.YES)}
          >
            {intl.formatMessage(messages.surveyYesButton)}
          </Button>

          <Button
            className="survey-section__button__no"
            outlined
            icon={NoIcon}
            onClick={() => handleSurveyResponse(InsuranceSurveyAnswerType.NO)}
          >
            {intl.formatMessage(messages.surveyNoButton)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SurveySection;
