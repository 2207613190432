import { defineMessages, useIntl } from "react-intl";
import React from "react";
import { Button, Form } from "@natera/form";
import { PatientEthnicityInput } from "@app/components/selectEthnicity/ethnicityStep";
import {
  EthnicityContext,
  EthnicityController,
  EthnicitySelect as BaseEthnicitySelect,
} from "@natera/patient";
import { Ethnicity, PatientEthnicityOutput } from "@app/provider/profile/types";
import { HEAP_EVENTS } from "@app/provider/types";
import { getTestNameForHeap } from "@app/utils";
import { OrderContext } from "@app/provider/order";

const messages = defineMessages({
  selectEthnicityNext: {
    id: "selectEthnicityNext",
    defaultMessage: "Next",
  },
  selectEthnicityBack: {
    id: "selectEthnicityBack",
    defaultMessage: "Back",
  },
  ethnicityAfricanAmericanDescription: {
    id: "ethnicityAfricanAmericanDescription",
    defaultMessage: "African American / Black",
  },
  ethnicityHispanicDescription: {
    id: "ethnicityHispanicDescription",
    defaultMessage: "Hispanic / Latin American",
  },
  ethnicityMediterraneanDescription: {
    id: "ethnicityMediterraneanDescription",
    defaultMessage: "Mediterranean",
  },
  ethnicityCaucasianDescription: {
    id: "ethnicityCaucasianDescription",
    defaultMessage: "Caucasian / Non-Hispanic White",
  },
  ethnicityAshkenaziDescription: {
    id: "ethnicityAshkenaziDescription",
    defaultMessage: "Ashkenazi Jewish",
  },
  ethnicitySephardicDescription: {
    id: "ethnicitySephardicDescription",
    defaultMessage: "Sephardic Jewish",
  },
  ethnicityFrenchCanadianDescription: {
    id: "ethnicityFrenchCanadianDescription",
    defaultMessage: "French Canadian",
  },
  ethnicityEastAsianDescription: {
    id: "ethnicityEastAsianDescription",
    defaultMessage: "East Asian",
  },
  ethnicitySoutheastAsianDescription: {
    id: "ethnicitySoutheastAsianDescription",
    defaultMessage: "Southeast Asian",
  },
  ethnicitySouthAsianDescription: {
    id: "ethnicitySouthAsianDescription",
    defaultMessage: "South Asian",
  },
  ethnicityOtherDescription: {
    id: "ethnicityOtherDescription",
    defaultMessage: "Other",
  },
});

type Props = {
  defaultValue?: string[];
  profileEthnicities: PatientEthnicityOutput | undefined;
  onSubmit: (value: PatientEthnicityInput) => void;
  goBack: () => void;
  loading?: boolean;
};

export const submitEthnicities = (
  onSubmit: (value: PatientEthnicityInput) => void,
  checkedEthnicities: string[] | undefined,
  ethnicities: Ethnicity[]
): void => {
  const ethnicityInput: PatientEthnicityInput = {
    codes: checkedEthnicities!,
    subEthnicity: "",
  };

  const ethnicitiesList = ethnicities?.map((value) => value.code);
  const customEthnicity = checkedEthnicities?.find(
    (ethnicity) => !ethnicitiesList?.includes(ethnicity)
  );

  if (customEthnicity) {
    checkedEthnicities?.push("OTH");
    checkedEthnicities?.splice(checkedEthnicities?.indexOf(customEthnicity), 1);
    ethnicityInput.subEthnicity = customEthnicity;
  }
  onSubmit(ethnicityInput);
};

export const getCheckedPatientEthnicitiesWithSubethnicity = (
  profileEthnicities: PatientEthnicityOutput | undefined
): string[] => {
  const checkedEthnicities = profileEthnicities?.ethnicityTypes.map(
    (item) => item.code
  );
  if (profileEthnicities?.subEthnicity) {
    checkedEthnicities?.push(profileEthnicities?.subEthnicity);
    const othEthnicity = checkedEthnicities?.find((value) => value == "OTH");
    if (othEthnicity) {
      checkedEthnicities?.splice(checkedEthnicities.indexOf(othEthnicity), 1);
    }
  }
  return checkedEthnicities ? checkedEthnicities : [];
};

const EthnicityForm: React.FC<Props> = ({
  profileEthnicities,
  defaultValue,
  onSubmit,
  goBack,
  loading,
}) => {
  const intl = useIntl();
  const ethnicities = [
    {
      code: "AFA",
      description: intl.formatMessage(
        messages.ethnicityAfricanAmericanDescription
      ),
      descriptionForHeap: "black",
    },
    {
      code: "HIS",
      description: intl.formatMessage(messages.ethnicityHispanicDescription),
      descriptionForHeap: "hispanic",
    },
    {
      code: "MED",
      description: intl.formatMessage(
        messages.ethnicityMediterraneanDescription
      ),
      descriptionForHeap: "mediterranean",
    },
    {
      code: "CAU",
      description: intl.formatMessage(messages.ethnicityCaucasianDescription),
      descriptionForHeap: "caucasian",
    },
    {
      code: "AJW",
      description: intl.formatMessage(messages.ethnicityAshkenaziDescription),
      descriptionForHeap: "ashkenazi_jewish",
    },
    {
      code: "SJW",
      description: intl.formatMessage(messages.ethnicitySephardicDescription),
      descriptionForHeap: "sephardic_jewish",
    },
    {
      code: "CAN",
      description: intl.formatMessage(
        messages.ethnicityFrenchCanadianDescription
      ),
      descriptionForHeap: "french_canadian",
    },
    {
      code: "EAS",
      description: intl.formatMessage(messages.ethnicityEastAsianDescription),
      descriptionForHeap: "east_asian",
    },
    {
      code: "SEA",
      description: intl.formatMessage(
        messages.ethnicitySoutheastAsianDescription
      ),
      descriptionForHeap: "southeast_asian",
    },
    {
      code: "SAS",
      description: intl.formatMessage(messages.ethnicitySouthAsianDescription),
      descriptionForHeap: "south_asian",
    },
  ];

  const patientEthnicityCodes = getCheckedPatientEthnicitiesWithSubethnicity(
    profileEthnicities
  );

  const [checkedEthnicities, setCheckedEthnicities] = React.useState<
    string[] | undefined
  >(defaultValue ? defaultValue : patientEthnicityCodes);
  const isOtherEthnicities = (value: string) => {
    return !ethnicities.some((ethnicity) => ethnicity.code === value);
  };
  const { orderData } = React.useContext(OrderContext);
  const orderUid = orderData?.uid;
  const limsId = orderData?.clinic?.limsId;

  const ethnicityCheckboxes = ethnicities.map((ethnicity) => ({
    ...ethnicity,
    checked: checkedEthnicities?.includes(ethnicity.code),
  }));

  const controller: EthnicityController = {
    getEthnicities: () => ethnicityCheckboxes,
    isOtherEthnicities,
  };

  const handleCheckboxGroup = (values: string[]) =>
    setCheckedEthnicities(values);

  const getEthnicitiesForHeap = (
    checkedEthnicities: string[] | undefined,
    ethnicities: {
      code: string;
      description: string;
      descriptionForHeap: string;
    }[]
  ) => {
    if (!checkedEthnicities || checkedEthnicities.length === 0) {
      return "noneselected";
    }

    const checkedCode = checkedEthnicities.find((item) => item);
    const foundEthnicity = ethnicities.find(
      (ethnicity) => ethnicity.code === checkedCode
    );
    const description = foundEthnicity?.descriptionForHeap;

    return checkedEthnicities.length > 1
      ? "more_than_one"
      : checkedEthnicities.length === 1
      ? description
      : "";
  };

  const onSubmitCheckedEthnicities: React.FormEventHandler<HTMLFormElement> = (
    e
  ) => {
    e.preventDefault();
    submitEthnicities(onSubmit, checkedEthnicities, ethnicities);
    heap.track(HEAP_EVENTS.upp_simpleorder_ethinicity, {
      tests_ordered: getTestNameForHeap(orderData),
      lims_clinic_id: limsId,
      order_uid: orderUid,
      ethnicity: getEthnicitiesForHeap(checkedEthnicities, ethnicities),
    });
  };

  return (
    <Form
      noValidate
      onSubmit={onSubmitCheckedEthnicities}
      buttons={
        <>
          <Button type="submit" raised loading={loading}>
            {intl.formatMessage(messages.selectEthnicityNext)}
          </Button>
          <Button onClick={goBack} disabled={loading}>
            {intl.formatMessage(messages.selectEthnicityBack)}
          </Button>
        </>
      }
    >
      <div
        className="sample-draw-preference-list"
        data-testid={"select-ethnicity"}
      >
        <EthnicityContext.Provider value={controller}>
          <BaseEthnicitySelect
            onChangeValue={handleCheckboxGroup}
            value={checkedEthnicities}
          />
        </EthnicityContext.Provider>
      </div>
    </Form>
  );
};

export default EthnicityForm;
