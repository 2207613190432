import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/form";
import Dialog from "@natera/material/lib/dialog";

interface Props {
  closeDialog: () => void;
  update: () => void;
}

const messages = defineMessages({
  updateCommunicationDialogTitle: {
    id: "updateCommunicationDialogTitle",
    defaultMessage: "Your communication preferences will be updated",
  },
  updateCommunicationDialogCancelButton: {
    id: "updateCommunicationDialogCancelButton",
    defaultMessage: "Cancel",
  },
  updateCommunicationDialogUpdateButton: {
    id: "updateCommunicationDialogUpdateButton",
    defaultMessage: "Yes, Continue With Update",
  },
  updateCommunicationDialogCloseButton: {
    id: "updateCommunicationDialogCloseButton",
    defaultMessage: "Close",
  },
});

const UpdateCommunicationDialog: FC<Props> = ({ closeDialog, update }) => {
  const intl = useIntl();

  return (
    <Dialog
      className="update-communication-dialog"
      onClose={closeDialog}
      showCrossButton
      title={intl.formatMessage(messages.updateCommunicationDialogTitle)}
      actions={
        <>
          <Button onClick={closeDialog} outlined>
            {intl.formatMessage(messages.updateCommunicationDialogCancelButton)}
          </Button>

          <Button onClick={update} raised>
            {intl.formatMessage(messages.updateCommunicationDialogUpdateButton)}
          </Button>
        </>
      }
    >
      <></>
    </Dialog>
  );
};

export default UpdateCommunicationDialog;
