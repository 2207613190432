import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import SearchCloudCircleIcon from "@assets/svg/search-cloud-circle.svg";
import { Svg } from "@natera/material/lib/svg";
import "./noLongerAvailable.scss";

const messages = defineMessages({
  noLongerAvailablePageTitle: {
    id: "noLongerAvailablePageTitle",
    defaultMessage: "Billing update no longer available",
  },
});

const NoLongerAvailable: FC = () => {
  const intl = useIntl();

  return (
    <div className="no-longer-available-page">
      <div className="no-longer-available-page__container">
        <Svg
          className="no-longer-available-page__container__icon"
          content={SearchCloudCircleIcon}
        />
        <div className="no-longer-available-page__container__title">
          {intl.formatMessage(messages.noLongerAvailablePageTitle)}
        </div>
      </div>
    </div>
  );
};

export default NoLongerAvailable;
