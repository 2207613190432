import { PaymentType } from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { RadioButton, RadioGroup } from "@natera/form";
import React from "react";
import { useIntl } from "react-intl";
import { getPaymentMethodLabel } from "./getPaymentMethodLabel";
import classNames from "classnames";

import "./paymentMethod.scss";

type Props = {
  value?: PaymentType;
  onChange: (value: PaymentType) => void;
};

const PaymentMethod: React.FC<Props> = ({ value, onChange }) => {
  const intl = useIntl();

  const paymentTypes = Object.values(PaymentType);

  return (
    <RadioGroup name="paymentMethodList" value={value} onChange={onChange}>
      {paymentTypes.map((paymentType, index) => (
        <RadioButton
          id={paymentType}
          key={paymentType}
          data-testid={paymentType}
          className={classNames("stepper__item payment_method", {
            first_item: index === 0,
            last_item: index === paymentTypes.length - 1,
          })}
          label={getPaymentMethodLabel(paymentType, intl)}
          value={paymentType}
        />
      ))}
    </RadioGroup>
  );
};

export default PaymentMethod;
