import { DateRange } from "@natera/mobile-phlebotomy/lib";

export const getDisabledSlots = (
  availableTo: Date,
  additionDates: Date[] = []
): DateRange[] => {
  const getDisabledSlotDateRange = (date: Date): DateRange[] => {
    return [
      {
        start: new Date(date.setUTCHours(9, 0, 0, 0)),
        end: new Date(date.setUTCHours(12, 0, 0, 0)),
      },
      {
        start: new Date(date.setUTCHours(12, 0, 0, 0)),
        end: new Date(date.setUTCHours(15, 0, 0, 0)),
      },
      {
        start: new Date(date.setUTCHours(15, 0, 0, 0)),
        end: new Date(date.setUTCHours(18, 0, 0, 0)),
      },
    ];
  };

  availableTo.setUTCHours(0, 0, 0, 0);

  let day = availableTo.getUTCDay();
  const disabledSlots: DateRange[] = [];
  const SUNDAY_NUMBER = 6;

  while (day <= SUNDAY_NUMBER) {
    const disabledDate = new Date(availableTo);
    disabledDate.setUTCDate(disabledDate.getUTCDate() + SUNDAY_NUMBER - day);

    disabledSlots.push(...getDisabledSlotDateRange(disabledDate));

    day++;
  }
  additionDates.forEach((date) => {
    disabledSlots.push(...getDisabledSlotDateRange(date));
  });

  return disabledSlots;
};
