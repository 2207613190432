import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { routes } from "@app/routing";
import { GridCell, GridRow } from "@natera/material/lib/layout";
import { Button, Form, Textfield } from "@natera/form";
import { ConfigContext, ProfileContext, UserContext } from "@app/provider";
import { ResultCodes } from "@app/service/resultCodes";
import { useDialog } from "@natera/platform/lib/hooks";
import UpdateEmailDialog from "./updateEmailDialog";
import { validateEmail } from "@app/utils";

import "./updateEmail.scss";
import MailIcon from "@assets/svg/icons/mail.svg";
import { BlueHeader, FormField, Notification } from "@app/components";

const messages = defineMessages({
  updateEmailBackButton: {
    id: "updateEmailBackButton",
    defaultMessage: "Contact Details",
  },
  updateEmailTitle: {
    id: "updateEmailTitle",
    defaultMessage: "Email",
  },
  updateEmailInputLabel: {
    id: "updateEmailInputLabel",
    defaultMessage: "Email Address",
  },
  updateEmailCancelButton: {
    id: "updateEmailCancelButton",
    defaultMessage: "Cancel",
  },
  updateEmailUpdateButon: {
    id: "updateEmailUpdateButon",
    defaultMessage: "Update",
  },
  updateEmailNotification: {
    id: "updateEmailNotification",
    defaultMessage: "A verification link will be sent to this email address.",
  },
  updateEmailInvalidError: {
    id: "updateEmailInvalidError",
    defaultMessage: "Please enter a valid email address.",
  },
  updateEmailAlreadyInUse: {
    id: "updateEmailAlreadyInUse",
    defaultMessage: "This email address is already in use.",
  },
});

const UpdateEmail: FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const { config } = React.useContext(ConfigContext);
  const { getProfileData: profile } = useContext(ProfileContext);
  const { updateEmail, updateEmailError } = useContext(UserContext);
  const updateEmailDialog = useDialog(UpdateEmailDialog);
  const [emailInputError, setEmailInputError] = useState("");

  const usePlusSignInEmailAddress =
    config.test.usePlusSignInEmailAddress.enabled;

  const closeDialog = useCallback(() => updateEmailDialog.close(), [
    updateEmailDialog,
  ]);

  const update = (newEmail: string) => {
    updateEmail(newEmail).then((resp) => {
      if (resp?.newEmail) {
        updateEmailDialog.close();
        history.replace(routes.resendUpdateEmail);
      }
    });
  };

  const submit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = e.currentTarget;

    if (validateEmail(formData.email.value, usePlusSignInEmailAddress)) {
      updateEmailDialog.open({
        closeDialog,
        update: () => update(formData.email.value),
      });
    } else {
      setEmailInputError(intl.formatMessage(messages.updateEmailInvalidError));
    }
  };

  const goToContactDetails = () => {
    history.replace(routes.contactDetails);
  };

  useEffect(() => {
    if (updateEmailError?.code === ResultCodes.EMAIL_ALREADY_IN_USE) {
      updateEmailDialog.close();
      setEmailInputError(intl.formatMessage(messages.updateEmailAlreadyInUse));
    }
  }, [updateEmailError]);

  if (!profile) {
    return null;
  }

  return (
    <>
      {updateEmailDialog.getDialog()}
      <div className="update-email">
        <BlueHeader
          arrowRoute={routes.contactDetails}
          title={intl.formatMessage(messages.updateEmailBackButton)}
        />
        <GridRow className="update-email__form">
          <GridCell span={12}>
            <div className="update-email__form__title">
              {intl.formatMessage(messages.updateEmailTitle)}
            </div>
            <Form className="update-email-form" onSubmit={submit}>
              <FormField
                label={intl.formatMessage(messages.updateEmailInputLabel)}
                error={emailInputError}
                required
                htmlFor="email"
                withPadding
              >
                <Textfield
                  defaultValue={profile.email}
                  outline
                  required
                  id="email"
                  type="email"
                  name="email"
                  onChange={() => setEmailInputError("")}
                  onInvalid={() =>
                    setEmailInputError(
                      intl.formatMessage(messages.updateEmailInvalidError)
                    )
                  }
                  autoComplete="email"
                />
              </FormField>
              <Notification
                className="update-email-notification"
                icon={MailIcon}
                type="note"
              >
                {intl.formatMessage(messages.updateEmailNotification)}
              </Notification>

              <div className="buttons-container">
                <Button type="button" onClick={goToContactDetails} outlined>
                  {intl.formatMessage(messages.updateEmailCancelButton)}
                </Button>

                <Button type="submit" raised>
                  {intl.formatMessage(messages.updateEmailUpdateButon)}
                </Button>
              </div>
            </Form>
          </GridCell>
        </GridRow>
      </div>
    </>
  );
};

export default UpdateEmail;
