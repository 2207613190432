import React, { useContext } from "react";

import { AccountInfoBox } from "@app/preBill/components/accountInfoBox";
import { FAQInfoText } from "@app/preBill/components/faqInfoText";
import { WhatsNextSection } from "@app/preBill/components/whatNextSection";
import { SurveySection } from "@app/preBill/components/surveySection";
import { NoLongerAvailableCard } from "@app/preBill/components/noLongerAvailableCard";
import { UserContext } from "@app/provider";

import "./insuranceNoLongerAvailable.scss";

const InsuranceNoLongerAvailable: React.FC = () => {
  const { uppUser } = useContext(UserContext);

  return (
    <>
      <div className="insurance-no-longer-available-page">
        <NoLongerAvailableCard />
        <WhatsNextSection />
        <SurveySection />
        {!uppUser && <AccountInfoBox />}
        <FAQInfoText />
      </div>
    </>
  );
};

export default InsuranceNoLongerAvailable;
