import React, { createContext, FC, useEffect, useMemo } from "react";
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { ConfigContext, ServiceContext } from "@app/provider";
import {
  ErrorBoundary,
  LoadingContext,
} from "@natera/platform/lib/components/context";
import { useResource } from "@natera/platform/lib/hooks";
import { ConfigServiceInterface } from "@natera/platform/lib/service/config";
import UppClient from "@app/service/apollo";

interface UnifiedPatientPortalContext {
  configService: ConfigServiceInterface;
}

export const Context = createContext<UnifiedPatientPortalContext>({
  configService: { getConfig: () => undefined },
});

const UnifiedPatientPortalServerProvider: FC = ({ children }) => {
  const configService = React.useContext(ConfigContext);
  const { sessionService } = React.useContext(ServiceContext);

  const patientClient = useMemo(
    () => new UppClient(configService, sessionService),
    [configService, sessionService]
  );

  const clientResource = useResource<ApolloClient<NormalizedCacheObject>>({
    load: patientClient.getApolloClient,
  });
  const client = clientResource.getResource();

  useEffect(() => {
    clientResource.load();
  }, []);

  if (!client) {
    return null;
  }

  return (
    <ErrorBoundary renderLink={() => children}>
      <Context.Provider
        value={{
          configService,
        }}
      >
        <LoadingContext isLoading={clientResource.isLoading()}>
          <ApolloProvider client={client}>{children}</ApolloProvider>
        </LoadingContext>
      </Context.Provider>
    </ErrorBoundary>
  );
};

export default UnifiedPatientPortalServerProvider;
