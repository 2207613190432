import React, { FC, useContext } from "react";
import classNames from "classnames";
import { Link } from "@natera/navigation";
import { IconButton } from "@natera/material/lib/button";
import Svg from "@natera/material/lib/svg";
import { UppAuthContext } from "@app/provider";
import LanguageSelector from "@app/components/languageSelector";
import Logo from "@assets/svg/natera-portal-logo.svg";
import MenuSvg from "@assets/svg/icons/menu.svg";
import { defineMessages, useIntl } from "react-intl";

import "./header.scss";

const messages = defineMessages({
  mainMenuButton: {
    id: "mainMenuButton",
    defaultMessage: "Main menu",
  },
  mainMenuNateraHomeLogo: {
    id: "mainMenuNateraHomeLogo",
    defaultMessage: "Natera home",
  },
});

interface HeaderProps {
  isWidthLimited?: boolean;
  isBurgerVisible?: boolean;
  toggleMenu?: () => void;
}

const Header: FC<HeaderProps> = ({
  isWidthLimited,
  isBurgerVisible,
  toggleMenu,
}) => {
  const { profile } = useContext(UppAuthContext);
  const intl = useIntl();

  return (
    <header
      id="header"
      className={classNames("header", {
        header_limited: isWidthLimited,
      })}
    >
      <div className="header-wrapper">
        <div className="header__logo">
          <Link
            to="/"
            aria-label={intl.formatMessage(messages.mainMenuNateraHomeLogo)}
          >
            <Svg content={Logo} role="img" />
          </Link>
        </div>

        <>
          <div
            className={classNames("header__menu", {
              "header__menu-burger-hidden": !isBurgerVisible || isWidthLimited,
            })}
          >
            {profile && isBurgerVisible && toggleMenu && (
              <div className="header__menu-content">
                <IconButton
                  onClick={toggleMenu}
                  aria-label={intl.formatMessage(messages.mainMenuButton)}
                >
                  {MenuSvg}
                </IconButton>
              </div>
            )}
          </div>

          <div className="header__language">
            <LanguageSelector />
          </div>
        </>
      </div>
    </header>
  );
};

export default Header;
