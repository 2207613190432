import React from "react";
import { Button, Form, RadioButton, RadioGroup } from "@natera/form";
import { defineMessages, useIntl } from "react-intl";
import * as R from "ramda";

import {
  ChannelType,
  ChannelValue,
  Communication,
} from "@app/provider/profile/types";

const messages = defineMessages({
  updateCommunicationFormHowReceiveUpdates: {
    id: "updateCommunicationFormHowReceiveUpdates",
    defaultMessage:
      "Tell us how you'd like to receive important updates about your test, including your results.",
  },
  updateCommunicationFormEmail: {
    id: "updateCommunicationFormEmail",
    defaultMessage: "Email",
  },
  updateCommunicationFormSms: {
    id: "updateCommunicationFormSms",
    defaultMessage: "SMS",
  },
  updateCommunicationFormEmailOptInTitle: {
    id: "updateCommunicationFormEmailOptInTitle",
    defaultMessage: "Opt-in to receive Email communications",
  },
  updateCommunicationFormSmsOptInTitle: {
    id: "updateCommunicationFormSmsOptInTitle",
    defaultMessage: "Opt-in to receive SMS communications",
  },
  updateCommunicationFormEmailOptInDescription: {
    id: "updateCommunicationFormEmailOptInDescription",
    defaultMessage:
      "By selecting email, I understand and agree I may be contacted by email for treatment options, billing/collection matters, and health-related products, service or studies; I may opt out at any time.",
  },
  updateCommunicationFormSmsOptInDescription: {
    id: "updateCommunicationFormSmsOptInDescription",
    defaultMessage:
      "By selecting SMS, I understand and agree I may be contacted by text message, telephone dialing systems, or computer assisted technology for treatment options, billing/collection matters, and health-related products, service or studies; I may opt out at any time.",
  },
  updateCommunicationFormEmailOptOutTitle: {
    id: "updateCommunicationFormEmailOptOutTitle",
    defaultMessage: "Opt-out from Email communications",
  },
  updateCommunicationFormSmsOptOutTitle: {
    id: "updateCommunicationFormSmsOptOutTitle",
    defaultMessage: "Opt-out from SMS communications",
  },
  updateCommunicationFormEmailOptOutDescription: {
    id: "updateCommunicationFormEmailOptOutDescription",
    defaultMessage: "You will not be contacted regarding test-related updates.",
  },
  updateCommunicationFormSmsOptOutDescription: {
    id: "updateCommunicationFormSmsOptOutDescription",
    defaultMessage: "You will not be contacted regarding test-related updates.",
  },
  updateCommunicationFormUpdateButon: {
    id: "updateCommunicationFormUpdateButon",
    defaultMessage: "Update",
  },
  updateCommunicationFormCancelButton: {
    id: "updateCommunicationFormCancelButton",
    defaultMessage: "Cancel",
  },
});

export type CommunicationsPreferencesFormProps = {
  communicationData?: Communication[];
  onSubmit: (communications: Communication[]) => void;
  onCancel?: () => void;
  showCancelButton?: boolean;
  descriptionMessage?: string;
  submitBtnMessage?: string;
};

const CommunicationsPreferencesForm: React.FC<CommunicationsPreferencesFormProps> = ({
  communicationData,
  onSubmit,
  onCancel,
  showCancelButton = true,
  descriptionMessage,
  submitBtnMessage,
}) => {
  const intl = useIntl();

  const getInitialCommunication = (type: ChannelType) => {
    if (!communicationData) return undefined;
    return R.find<Communication>(R.propEq("channelType", type))(
      communicationData
    );
  };

  const getInititalEmail = () => {
    const emailCommunication = getInitialCommunication(ChannelType.EMAIL);
    return emailCommunication ? emailCommunication.value : ChannelValue.UNKNOWN;
  };

  const getInititalSms = () => {
    const smsCommunication = getInitialCommunication(ChannelType.SMS);
    return smsCommunication ? smsCommunication.value : ChannelValue.UNKNOWN;
  };

  const [communicationEmail, setCommunicationEmail] = React.useState<
    ChannelValue
  >(ChannelValue.UNKNOWN);

  const [communicationSms, setCommunicationSms] = React.useState<ChannelValue>(
    ChannelValue.UNKNOWN
  );

  React.useEffect(() => {
    setCommunicationEmail(getInititalEmail());
    setCommunicationSms(getInititalSms());
  }, [communicationData]);

  const handleEmailCommunicationSelect = (emailCommunication: ChannelValue) =>
    setCommunicationEmail(emailCommunication);

  const handleSmsCommunicationSelect = (smsCommunication: ChannelValue) =>
    setCommunicationSms(smsCommunication);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const email = formData.get("emailCommunication") as
      | ChannelValue
      | undefined;
    const sms = formData.get("smsCommunication") as ChannelValue | undefined;

    const updatedCommunicationPreferences: Communication[] = [
      {
        channelType: ChannelType.EMAIL,
        value: email ? ChannelValue[email] : ChannelValue.UNKNOWN,
      },
      {
        channelType: ChannelType.SMS,
        value: sms ? ChannelValue[sms] : ChannelValue.UNKNOWN,
      },
    ];

    onSubmit(updatedCommunicationPreferences);
  };

  return (
    <Form
      className="info-card-form update-communication-form"
      noValidate
      onSubmit={handleSubmit}
      buttons={
        <>
          <Button
            type="submit"
            data-testid="submit"
            raised
            disabled={
              communicationEmail === ChannelValue.UNKNOWN ||
              communicationSms === ChannelValue.UNKNOWN
            }
          >
            {submitBtnMessage ??
              intl.formatMessage(messages.updateCommunicationFormUpdateButon)}
          </Button>
          {showCancelButton && (
            <Button
              type="reset"
              data-testid="cancel"
              onClick={onCancel}
              outlined
            >
              {intl.formatMessage(messages.updateCommunicationFormCancelButton)}
            </Button>
          )}
        </>
      }
    >
      <div className="update-communication-form__description">
        {descriptionMessage ??
          intl.formatMessage(messages.updateCommunicationFormHowReceiveUpdates)}
      </div>
      <section className="update-communication-form__content">
        <div className="communication-content__item">
          <h4>{intl.formatMessage(messages.updateCommunicationFormEmail)}</h4>
          <RadioGroup
            name="emailCommunication"
            value={communicationEmail}
            onChange={handleEmailCommunicationSelect}
          >
            {
              <>
                <RadioButton
                  key={ChannelValue.OPT_IN}
                  data-testid="emailOptIn"
                  label={
                    <>
                      <h4 className="communication__item-title">
                        {intl.formatMessage(
                          messages.updateCommunicationFormEmailOptInTitle
                        )}
                      </h4>
                      <div className="communication__item-content">
                        {intl.formatMessage(
                          messages.updateCommunicationFormEmailOptInDescription
                        )}
                      </div>
                    </>
                  }
                  value={ChannelValue.OPT_IN}
                />
                <RadioButton
                  key={ChannelValue.OPT_OUT}
                  data-testid="emailOptOut"
                  label={
                    <>
                      <h4 className="communication__item-title">
                        {intl.formatMessage(
                          messages.updateCommunicationFormEmailOptOutTitle
                        )}
                      </h4>
                      <div className="communication__item-content">
                        {intl.formatMessage(
                          messages.updateCommunicationFormEmailOptOutDescription
                        )}
                      </div>
                    </>
                  }
                  value={ChannelValue.OPT_OUT}
                />
              </>
            }
          </RadioGroup>
        </div>
        <div className="communication-content__item">
          <h4>{intl.formatMessage(messages.updateCommunicationFormSms)}</h4>
          <RadioGroup
            name="smsCommunication"
            value={communicationSms}
            onChange={handleSmsCommunicationSelect}
          >
            {
              <>
                <RadioButton
                  key={ChannelValue.OPT_IN}
                  data-testid="smsOptIn"
                  label={
                    <>
                      <h4 className="communication__item-title">
                        {intl.formatMessage(
                          messages.updateCommunicationFormSmsOptInTitle
                        )}
                      </h4>
                      <div className="communication__item-content">
                        {intl.formatMessage(
                          messages.updateCommunicationFormSmsOptInDescription
                        )}
                      </div>
                    </>
                  }
                  value={ChannelValue.OPT_IN}
                />
                <RadioButton
                  key={ChannelValue.OPT_OUT}
                  data-testid="smsOptOut"
                  label={
                    <>
                      <h4 className="communication__item-title">
                        {intl.formatMessage(
                          messages.updateCommunicationFormSmsOptOutTitle
                        )}
                      </h4>
                      <div className="communication__item-content">
                        {intl.formatMessage(
                          messages.updateCommunicationFormSmsOptOutDescription
                        )}
                      </div>
                    </>
                  }
                  value={ChannelValue.OPT_OUT}
                />
              </>
            }
          </RadioGroup>
        </div>
      </section>
    </Form>
  );
};

export default CommunicationsPreferencesForm;
