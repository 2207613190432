import React, { useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "@natera/navigation";
import { Svg } from "@natera/material/lib/svg";
import billingUpdatesIcon from "@assets/svg/icons/billing-updates.svg";
import linkArrowIcon from "@assets/svg/icons/link-arrow.svg";
import { routes } from "@app/routing";
import { PreBillAPIContext } from "@app/preBill/provider";
import "./billingUpdatesCard.scss";

const messages = defineMessages({
  billingUpdatesText: {
    id: "billingUpdatesText",
    defaultMessage: "You have new billing updates",
  },
});

const BillingUpdatesCard: React.FC = () => {
  const intl = useIntl();
  const [activeOrdersCount, setActiveOrdersCount] = useState<number>(0);
  const { listActiveOrdersForPatient } = useContext(PreBillAPIContext);

  useEffect(() => {
    let mounted = true;

    const loadActiveOrders = async () => {
      try {
        const response = await listActiveOrdersForPatient();

        if (!mounted) return;

        setActiveOrdersCount(response.activeOrders.length);
      } catch (error) {
        console.error("Failed to load active orders:", error);
      }
    };

    loadActiveOrders();

    return () => {
      mounted = false;
    };
  }, []);

  if (activeOrdersCount === 0) {
    return null;
  }

  return (
    <div
      className="billing-updates-card-container"
      data-testid="billing-updates-card-container"
    >
      <Link
        to={routes.billingUpdates}
        className="billing-updates-card"
        data-testid="billing-updates-card"
      >
        <Svg
          className="billing-updates-card__icon"
          content={billingUpdatesIcon}
          data-testid="billing-updates-icon"
        />
        <span
          className="billing-updates-card__text"
          data-testid="billing-updates-text"
        >
          {intl.formatMessage(messages.billingUpdatesText)} ({activeOrdersCount}
          )
        </span>
        <Svg
          className="billing-updates-card__arrow"
          content={linkArrowIcon}
          data-testid="billing-updates-arrow"
        />
      </Link>
    </div>
  );
};

export default BillingUpdatesCard;
