import React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  Commercial,
  Government,
  MultipleInsuranceData,
  PaymentInfo,
  PaymentType,
} from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { FormField } from "@natera/form";

import "./insuranceEstimationData.scss";

const messages = defineMessages({
  insuranceEstimationDataInsuranceCompanyLabel: {
    id: "insuranceEstimationDataInsuranceCompanyLabel",
    defaultMessage: "Insurance company",
  },
  insuranceEstimationDataGovernmentInsuranceLabel: {
    id: "insuranceEstimationDataGovernmentInsuranceLabel",
    defaultMessage: "Government insurance",
  },
  insuranceEstimationDataFirstInsuranceTypeLabel: {
    id: "insuranceEstimationDataFirstInsuranceTypeLabel",
    defaultMessage: "First insurance type",
  },
  insuranceEstimationDataSecondInsuranceTypeLabel: {
    id: "insuranceEstimationDataSecondInsuranceTypeLabel",
    defaultMessage: "Second insurance type",
  },
  insuranceEstimationDataMemberIdLabel: {
    id: "insuranceEstimationDataMemberIdLabel",
    defaultMessage: "Government-issued Insurance Number",
  },
  insuranceEstimationDataMemberIdLabelCommercial: {
    id: "insuranceEstimationDataMemberIdLabelCommercial",
    defaultMessage: "Member ID",
  },
});

export type InsuranceEstimationDataProps = {
  paymentMethodData: PaymentInfo;
  isSecondInsurance?: boolean;
};

const InsuranceEstimationData: React.FC<InsuranceEstimationDataProps> = ({
  paymentMethodData,
  isSecondInsurance,
}) => {
  const intl = useIntl();

  const getInsuranceLabel = () => {
    if (paymentMethodData.paymentMethod === PaymentType.COMMERCIAL) {
      return intl.formatMessage(
        messages.insuranceEstimationDataInsuranceCompanyLabel
      );
    }

    if (paymentMethodData.paymentMethod === PaymentType.GOVERNMENT_ISSUED) {
      return intl.formatMessage(
        messages.insuranceEstimationDataGovernmentInsuranceLabel
      );
    }

    if (paymentMethodData.paymentMethod === PaymentType.MULTIPLE) {
      return intl.formatMessage(
        isSecondInsurance
          ? messages.insuranceEstimationDataSecondInsuranceTypeLabel
          : messages.insuranceEstimationDataFirstInsuranceTypeLabel
      );
    }
  };

  const getInsuranceName = () => {
    if (paymentMethodData.paymentMethod === PaymentType.COMMERCIAL) {
      return paymentMethodData.paymentMethodInfo.companyName;
    }

    if (paymentMethodData.paymentMethod === PaymentType.GOVERNMENT_ISSUED) {
      return paymentMethodData.paymentMethodInfo.companyName;
    }

    if (paymentMethodData.paymentMethod === PaymentType.MULTIPLE) {
      const multipleStepData = (paymentMethodData as MultipleInsuranceData)
        .paymentMethodInfo;

      return isSecondInsurance
        ? multipleStepData.second.paymentMethodInfo.companyName
        : multipleStepData.first.paymentMethodInfo.companyName;
    }
  };

  const getMemberId = (data: Government | Commercial) => {
    return data.paymentMethod === PaymentType.COMMERCIAL
      ? data.paymentMethodInfo.memberId
      : data.paymentMethodInfo.insuranceNumber;
  };

  const getInsuranceMemberId = () => {
    if (paymentMethodData.paymentMethod === PaymentType.COMMERCIAL) {
      return getMemberId(paymentMethodData);
    }

    if (paymentMethodData.paymentMethod === PaymentType.GOVERNMENT_ISSUED) {
      return getMemberId(paymentMethodData);
    }

    if (paymentMethodData.paymentMethod === PaymentType.MULTIPLE) {
      const multipleStepData = (paymentMethodData as MultipleInsuranceData)
        .paymentMethodInfo;

      return isSecondInsurance
        ? getMemberId(multipleStepData.second)
        : getMemberId(multipleStepData.first);
    }
  };

  const getInsuranceLabelMemberId = () => {
    const isCommercialPaymentMethod =
      paymentMethodData.paymentMethod === PaymentType.COMMERCIAL;
    const isFirstPaymentCommercial =
      paymentMethodData.paymentMethod === PaymentType.MULTIPLE &&
      paymentMethodData.paymentMethodInfo.first.paymentMethod ===
        PaymentType.COMMERCIAL &&
      !isSecondInsurance;
    const isSecondPaymentCommercial =
      paymentMethodData.paymentMethod === PaymentType.MULTIPLE &&
      paymentMethodData.paymentMethodInfo.second.paymentMethod ===
        PaymentType.COMMERCIAL &&
      isSecondInsurance;

    if (
      isCommercialPaymentMethod ||
      isFirstPaymentCommercial ||
      isSecondPaymentCommercial
    ) {
      return intl.formatMessage(
        messages.insuranceEstimationDataMemberIdLabelCommercial
      );
    } else {
      return intl.formatMessage(messages.insuranceEstimationDataMemberIdLabel);
    }
  };

  return (
    <section className="insurance-estimation-data">
      <FormField label={getInsuranceLabel()}>
        <span className="content">{getInsuranceName()}</span>
      </FormField>
      <FormField label={getInsuranceLabelMemberId()}>
        <span className="content">{getInsuranceMemberId()}</span>
      </FormField>
    </section>
  );
};

export default InsuranceEstimationData;
