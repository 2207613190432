import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/form";
import "./partnerInfoProvidingDialog.scss";

const messages = defineMessages({
  partnerInfoDialogTitle: {
    id: "partnerInfoDialogTitle",
    defaultMessage: "Why is it important to provide partner information?",
  },
  partnerInfoDialogContentProviding: {
    id: "partnerInfoDialogContentProviding",
    defaultMessage:
      "Providing partner information is highly recommended as it will allow Natera to coordinate partner testing if requested by your medical provider.",
  },
  partnerInfoDialogContentPleaseFillOut: {
    id: "partnerInfoDialogContentPleaseFillOut",
    defaultMessage: "Please fill out the following information:",
  },
  partnerInfoDialogContentFirsName: {
    id: "partnerInfoDialogContentFirsName",
    defaultMessage: "Your partner's first name",
  },
  partnerInfoDialogContenLastName: {
    id: "partnerInfoDialogContenLastName",
    defaultMessage: "Your partner's last name",
  },
  partnerInfoDialogContentDOB: {
    id: "partnerInfoDialogContentDOB",
    defaultMessage: "Your partner's date of birth",
  },
  partnerInfoDialogContentPhoneNumber: {
    id: "partnerInfoDialogContentPhoneNumber",
    defaultMessage: "Your partner's phone number",
  },
  partnerInfoDialogContinueBtn: {
    id: "partnerInfoDialogContinueBtn",
    defaultMessage: "Continue Without Providing Partner Details",
  },
  partnerInfoDialogBackBtn: {
    id: "partnerInfoDialogBackBtn",
    defaultMessage: "Back To Enter Partner Details",
  },
});

interface PartnerInfoProvidingDialogProps {
  onClose: () => void;
  onContinue: () => Promise<void>;
}

const PartnerInfoProvidingDialog: FC<PartnerInfoProvidingDialogProps> = ({
  onClose,
  onContinue,
}) => {
  const intl = useIntl();

  return (
    <div className="partner-info-providing-dialog">
      <Dialog
        title={
          <span>{intl.formatMessage(messages.partnerInfoDialogTitle)}</span>
        }
        actions={
          <>
            <Button raised onClick={onClose}>
              {intl.formatMessage(messages.partnerInfoDialogBackBtn)}
            </Button>
            <Button outlined onClick={onContinue}>
              {intl.formatMessage(messages.partnerInfoDialogContinueBtn)}
            </Button>
          </>
        }
        onClose={onClose}
        showCrossButton
      >
        <section className="partner-info-providing-dialog__content">
          <p>
            {intl.formatMessage(messages.partnerInfoDialogContentProviding)}
          </p>
          <div>
            <p>
              {intl.formatMessage(
                messages.partnerInfoDialogContentPleaseFillOut
              )}
            </p>
            <ul>
              <li>
                {intl.formatMessage(messages.partnerInfoDialogContentFirsName)}
              </li>
              <li>
                {intl.formatMessage(messages.partnerInfoDialogContenLastName)}
              </li>
              <li>
                {intl.formatMessage(messages.partnerInfoDialogContentDOB)}
              </li>
              <li>
                {intl.formatMessage(
                  messages.partnerInfoDialogContentPhoneNumber
                )}
              </li>
            </ul>
          </div>
        </section>
      </Dialog>
    </div>
  );
};

export default PartnerInfoProvidingDialog;
