export const isPOBoxOrPMB = (
  address_1?: string | null,
  address_2?: string | null
): boolean => {
  const address = address_2 ? address_1 + ` ${address_2}` : address_1 || "";
  const patterns = [
    /\bPO Box\b/i, // " PO Box " with boundaries to avoid matching "PO Boxing"
    /\bP\.O\. Box\b/i, // " P.O. Box "
    /\bP0 Box\b/i, // " P-Zero Box" variant
    /\bP\.0\. Box\b/i, // " P.0. Box" variant
    /\bP\.0 Box\b/i, // " P.0 Box " variant
    /\bBox-\d+\b/i, // "Box-122"
    /\bBox\d+\b/i, // "Box122"
    /\bP O Box\d+\b/i, // "P O Box125"
    /\bP\. O\. Box\b/i, // "P. O. Box"
    /\bP\.O \d+\b/i, // "P.O 123"
    /\bP\.O\.B \d+\b/i, // "P.O.B 123"
    /\bPO \d+\b/i, // "PO 123"
    /\bPO-Box\b/i, // "PO-Box"
    /\bPOBOX\d+\b/i, // "POBOX123"
    /\bP\.O\.B\.\b/i, // "P.O.B."
    /\bP\.O\.B\b/i, // "P.O.B"
    /\bP\.OB\b/i, // "P.OB"
    /\bPO\.B\b/i, // "PO.B"
    /\bP\.OB\d+\b/i, // "P.OB123"
    /\bPO\.B\d+\b/i, // "PO.B123"
    /\bP\.OB-\d+\b/i, // "P.OB-123"
    /\bPO\.B-\d+\b/i, // "PO.B-123"
    /\bP\.O\.B\. \d+\b/i, // "P.O.B. 123"
    /\bP0B-\d+\b/i, // "P0B-122"
    /\bP0B\b/i, // "P0B"
    /\bP0B\d+\b/i, // "P0B123"
    /\bB0x-\d+\b/i, // "B0x-122"
    /\bB0x\d+\b/i, // "B0x122"
    /\bP 0 Box\d+\b/i, // "P 0 Box125"
    /\bP\. 0\. Box\b/i, // "P. 0. Box"
    /\bP\.0 \d+\b/i, // "P.0 123"
    /\bP\.0\.B \d+\b/i, // "P.0.B 123"
    /\bP\.0B\b/i, // "P.0B"
    /\bP0\.B\b/i, // "P0.B"
    /\bP\.0B\d+\b/i, // "P.0B123"
    /\bP0\.B\d+\b/i, // "P0.B123"
    /\bP\.0B-\d+\b/i, // "P.0B-123"
    /\bP0\.B-\d+\b/i, // "P0.B-123"
    /\bP0 \d+\b/i, // "P0 123"
    /\bP0-Box\b/i, // "P0-Box"
    /\bP0BOX\d+\b/i, // "P0BOX123"
    /\bP\.0\.B\.\b/i, // "P.0.B."
    /\bP\.0\.B\b/i, // "P.0.B"
    /\bP\.0\.B\. \d+\b/i, // "P.0.B. 123"
    /\bPOB\b/i, // any " POB " content
    /\bPOB-\d+\b/i, // "POB-123"
    /\bPOB\d+/i, // any " POB12324" content
    /\bPMB\b/i, // any " PMB " content
    /\bPMB\d+/i, // any " PMB34234" content
    /\bBox \d+/i, // any "Box 34234" content
    /# \d+\b/, // Matches the "# " identifier followed by a number
    /#\d+\b/, // Matches the "#" identifier followed by a number
    /\bpost office box\b/i, // "post office box"
    /\bPost \d+\b/i, // "Post 123"
    /\bPost\d+\b/i, // "Post123"
    /\bb0x\b/i, // "b0x"
    /\b-b0x\b/i, // "-b0x"
    /\b-box\b/i, // "-box"
    /\bbox\d+\b/i, // "box123"
    /\bb0x\d+\b/i, // "b0x123"
    /\bpo\b/i, // "po"
    /\bpo\d+\b/i, // "po123"
    /\bpo-\d+\b/i, // "po-123"
    /\bp0\b/i, // "p0"
    /\bp0\d+\b/i, // "p0123"
    /\bp0-\d+\b/i, // "p0-123"
    /\bpobox\b/i, // "pobox"
    /\bp0box\b/i, // "p0box"
    /\bpob0x\b/i, // "pob0x"
    /\bp0b0x\b/i, // "p0b0x"
    /\bp0-b0x\b/i, // "p0-b0x"
    /\bpo-b0x\b/i, // "po-b0x"
    /\bp0-b0x\b/i, // "p0-b0x"
    /\bpobox\d+\b/i, // "pobox123"
    /\bp0box\d+\b/i, // "p0box123"
    /\bpob0x\d+\b/i, // "pob0x123"
    /\bp0b0x\d+\b/i, // "p0b0x123"
  ];
  return patterns.some((pattern) => pattern.test(address));
};
