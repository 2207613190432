import React, { FC, useContext, useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "@natera/form";
import { SpinnerView } from "@app/components";
import { PaymentElement } from "@stripe/react-stripe-js";
import { defineMessages, useIntl } from "react-intl";
import PaymentDetails from "./paymentDetails";
import { PreBillAPIContext } from "@app/provider";
import { ITest } from "./paymentDetails";
import "./paymentForm.scss";
import {
  PreBillTestTypes,
  PaymentChoice,
  SendPaymentChoiceInput,
} from "@app/preBill/models";

const messages = defineMessages({
  paymentFormSummaryTitle: {
    id: "paymentFormSummaryTitle",
    defaultMessage: "Order Summary",
  },
  paymentFormPanoromaTestDescription: {
    id: "paymentFormPanoromaTestDescription",
    defaultMessage: "Noninvasive prenatal testing (NIPT)",
  },
  paymentFormHorizonTestDescription: {
    id: "paymentFormHorizonTestDescription",
    defaultMessage: "Advanced carrier screening",
  },
  paymentFormEmpowerTestDescription: {
    id: "paymentFormEmpowerTestDescription",
    defaultMessage: "Hereditary cancer screening",
  },
  paymentFormSaveButton: {
    id: "paymentFormSaveButton",
    defaultMessage: "Pay Now",
  },
  paymentFormBackButton: {
    id: "paymentFormBackButton",
    defaultMessage: "Back",
  },
});

interface PaymentFormProps {
  onSave: (status: "success" | "failure") => void;
  onBack: () => void;
}

const PaymentForm: FC<PaymentFormProps> = ({ onSave, onBack }) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    apiProviderState: { orderEstimate },
  } = useContext(PreBillAPIContext);
  const [tests, setTests] = useState<ITest[]>([]);
  const stripe = useStripe();
  const elements = useElements();
  const {
    apiProviderState: { customerSetupIntent },
    sendPaymentChoice,
  } = useContext(PreBillAPIContext);

  useEffect(() => {
    formTestsList();
  }, [orderEstimate?.testType]);

  const formTestsList = () => {
    const testsList = orderEstimate?.testType?.map(
      (testName: PreBillTestTypes, index: number) => {
        const descriptions = {
          [PreBillTestTypes.PANORAMA]: formatMessage(
            messages.paymentFormPanoromaTestDescription
          ),
          [PreBillTestTypes.HORIZON]: formatMessage(
            messages.paymentFormHorizonTestDescription
          ),
          [PreBillTestTypes.EMPOWER]: formatMessage(
            messages.paymentFormEmpowerTestDescription
          ),
          [PreBillTestTypes.PANORAMA_HORIZON]: `${formatMessage(
            messages.paymentFormPanoromaTestDescription
          )} & ${formatMessage(messages.paymentFormHorizonTestDescription)}`,
        };

        const test: ITest = {
          id: index + 1,
          name: testName,
          description:
            descriptions[testName.toUpperCase() as PreBillTestTypes] ||
            formatMessage(messages.paymentFormPanoromaTestDescription),
        };
        return test;
      }
    );
    testsList && setTests(testsList);
  };

  const handleSaveButtonClick = async () => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });
    if (error) {
      onSave("failure");
    } else {
      const paymentChoicePayload: SendPaymentChoiceInput = {
        stripeCustomerId: customerSetupIntent?.customer as string,
        paymentMethodId: setupIntent?.payment_method as string,
        paymentChoice: PaymentChoice.CASHPAY,
      };
      try {
        await sendPaymentChoice(paymentChoicePayload);
        onSave("success");
      } catch (error) {
        console.error("Error sending payment choice:", error);
        onSave("failure");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <SpinnerView isLoading={loading} />
      <div className="payment-form__container">
        <PaymentDetails
          title={formatMessage(messages.paymentFormSummaryTitle)}
          tests={tests}
          amount={
            Math.floor(
              (orderEstimate?.totalPrePayAmountProposed as number) / 100
            ) || 0
          }
        />
        <div className="payment-form__body">
          <PaymentElement />
        </div>
        <div className="stepper__actions">
          <Button
            id="ncs-payment__save-button"
            className="stepper_actions_button"
            onClick={handleSaveButtonClick}
            raised={true}
          >
            {formatMessage(messages.paymentFormSaveButton)}
          </Button>
          <Button
            id="ncs-payment__back-button"
            className="stepper_actions_button"
            onClick={onBack}
            raised={false}
            outlined={false}
          >
            {formatMessage(messages.paymentFormBackButton)}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
