import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";
import { useVideo } from "@app/hooks";
import { Button } from "@natera/form";
import { BlueHeaderContent } from "@app/components/ui/layout";
import { routes } from "@app/routing";
import { VideoType } from "@app/components/videoPlayer";
import { Notification, VideoPlayer } from "@app/components";
import { HeapVideoContentType, HeapVideoOrderData } from "@app/provider/types";
import { Link } from "@natera/navigation";
import VideoStepper from "@app/components/videoStepper";
import { OrderContext } from "@app/provider";
import "./aboutYourTest.scss";

const messages = defineMessages({
  aboutTestPageTitle: {
    id: "aboutTestPageTitle",
    defaultMessage: "About Your Test",
  },
  aboutTestNextBtnTitle: {
    id: "aboutTestNextBtnTitle",
    defaultMessage: "Next",
  },
  aboutTestPageNotification: {
    id: "aboutTestPageNotification",
    defaultMessage: "There is no such video",
  },
  aboutTestPageNotificationLink: {
    id: "aboutTestPageNotificationLink",
    defaultMessage: "Go back",
  },
  comboOrderNoteTitle: {
    id: "comboOrderNoteTitle",
    defaultMessage: "Combo order",
  },
  comboOrderNoteContent: {
    id: "comboOrderNoteContent",
    defaultMessage:
      "Your healthcare provider has placed an order for two tests on your behalf. You will be completing the order for both tests. {boldContent}",
  },
  comboOrderNoteBoldContent: {
    id: "comboOrderNoteBoldContent",
    defaultMessage:
      "There are two videos, use the arrow to navigate to the next one.",
  },
});

export enum UrlVideoType {
  HORIZON = "HORIZON",
  PANORAMA = "PANORAMA",
  COMBO_ORDER = "COMBO_ORDER",
}

const COMBO_VIDEOS = [VideoType.HORIZON, VideoType.PANORAMA];

const AboutYourTestPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const videoController = useVideo();

  const { orderData } = React.useContext(OrderContext);

  const { videoType, orderUid } = useParams() as {
    videoType: UrlVideoType;
    orderUid?: string;
  };

  const [currentVideo, setCurrentVideo] = React.useState<VideoType>();

  const [heapVideoOrderData, setHeapVideoOrderData] = React.useState<
    HeapVideoOrderData
  >();

  const isComboOrder = videoType === UrlVideoType.COMBO_ORDER;

  const videoInfo = currentVideo && videoController.getVideoInfo(currentVideo);

  React.useEffect(() => {
    if (Object.values(UrlVideoType).includes(videoType)) {
      switch (videoType) {
        case UrlVideoType.COMBO_ORDER:
          setCurrentVideo(VideoType.HORIZON);
          break;

        case UrlVideoType.HORIZON:
          setCurrentVideo(VideoType.HORIZON);
          break;

        case UrlVideoType.PANORAMA:
          setCurrentVideo(VideoType.PANORAMA);
          break;
      }
    }
  }, [videoType]);

  React.useEffect(() => {
    if (orderData) {
      setHeapVideoOrderData({
        order_uid: orderData.uid,
        lims_clinic_id: orderData.clinic?.limsId,
      });
    }
  }, [orderData]);

  const handleNext = () => {
    if (orderUid) {
      history.push(routes.simpleOrderPage(orderUid));
    }
  };

  const getHeapVideoContentType = () => {
    switch (currentVideo) {
      case VideoType.HORIZON:
        return HeapVideoContentType.horizonSimpleOrder;
      case VideoType.PANORAMA:
        return HeapVideoContentType.panoramaSimpleOrder;
      default:
        return;
    }
  };

  return (
    <div className="about-your-test-page">
      {currentVideo && videoInfo && (
        <BlueHeaderContent
          blueHeaderTitle={intl.formatMessage(messages.aboutTestPageTitle)}
          crossHeaderRoute={routes.home}
          fullScreen
        >
          <section className="container">
            {isComboOrder && (
              <Notification
                titleElem={intl.formatMessage(messages.comboOrderNoteTitle)}
                className="combo-order-banner"
                type="note"
              >
                <div className="combo-order-banner-content">
                  {intl.formatMessage(messages.comboOrderNoteContent, {
                    boldContent: (
                      <b>
                        {intl.formatMessage(messages.comboOrderNoteBoldContent)}
                      </b>
                    ),
                  })}
                </div>
              </Notification>
            )}
            <div className="video-content">
              {isComboOrder ? (
                <div className="combo-order">
                  <VideoStepper
                    videoTypes={COMBO_VIDEOS}
                    currentVideo={currentVideo}
                    setCurrentVideo={setCurrentVideo}
                    videoController={videoController}
                    heapVideoOrderData={heapVideoOrderData}
                  />
                </div>
              ) : (
                <VideoPlayer
                  id={videoInfo.videoHashId}
                  thumbnailAltText={videoInfo.thumbnailAltText}
                  heapVideoContentType={getHeapVideoContentType()}
                  heapVideoOrderData={heapVideoOrderData}
                />
              )}
              <div className="video-description">
                <h3 className="video-description__title">
                  {videoInfo.videoDescTitle}
                </h3>
                <p className="video-description__text">
                  {videoInfo.videoDescText}
                </p>
              </div>
            </div>

            <div className="video-btns">
              {videoInfo.next && (
                <Button raised onClick={handleNext}>
                  {intl.formatMessage(messages.aboutTestNextBtnTitle)}
                </Button>
              )}
            </div>
          </section>
        </BlueHeaderContent>
      )}
      {!currentVideo && (
        <div className="notification">
          <Notification
            titleElem={intl.formatMessage(messages.aboutTestPageNotification)}
            className="notification-msg"
            type="note"
          >
            <Link to={routes.home}>
              {intl.formatMessage(messages.aboutTestPageNotificationLink)}
            </Link>
          </Notification>
        </div>
      )}
    </div>
  );
};

export default AboutYourTestPage;
