import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import { Button } from "@natera/material/lib/button";

import { StepperContext } from "@natera/stepper";
import { BloodDrawContext, UppAuthContext } from "@app/provider";
import { routes } from "@app/routing";
import LabItem from "./labItem";
import { StepperContent } from "@app/components/ui/layout";
import { Form } from "@natera/form";

const messages = defineMessages({
  sampleDrawLabTitle: {
    id: "sampleDrawLabTitle",
    defaultMessage: "Lab Locator",
  },
  labCollectionLabListDescription: {
    id: "labCollectionLabListDescription",
    defaultMessage: "Please call the lab to request an appointment.",
  },
  labCollectionLabListDone: {
    id: "labCollectionLabListDone",
    defaultMessage: "Done",
  },
  labCollectionLabListBack: {
    id: "labCollectionLabListBack",
    defaultMessage: "Back To Search",
  },
});

const LabList: FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const { profile } = useContext(UppAuthContext);
  const { bloodDrawSites } = useContext(BloodDrawContext);
  const { goBack } = useContext(StepperContext);

  const done: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    history.replace(routes.home);
  };

  return (
    <div className="lab-list__container">
      <StepperContent
        title={intl.formatMessage(messages.sampleDrawLabTitle)}
        subTitle={intl.formatMessage(messages.labCollectionLabListDescription)}
      >
        <Form
          onSubmit={done}
          noValidate
          buttons={
            <>
              {profile && (
                <Button type="submit" raised>
                  {intl.formatMessage(messages.labCollectionLabListDone)}
                </Button>
              )}
              <Button onClick={goBack}>
                {intl.formatMessage(messages.labCollectionLabListBack)}
              </Button>
            </>
          }
        >
          <div className="lab-list__container__list">
            {bloodDrawSites?.map((bloodDrawSite) => (
              <LabItem bloodDrawSite={bloodDrawSite} key={bloodDrawSite.id} />
            ))}
          </div>
        </Form>
      </StepperContent>
    </div>
  );
};

export default LabList;
