import PayorsTypeAhead from "@app/components/payorsTypeAhead";
import { PayorProvider } from "@app/provider";
import { Payor } from "@app/provider/payor";
import { Button, Form, FormField } from "@natera/form";
import { StepperContext } from "@natera/stepper";
import { defineMessages, useIntl } from "react-intl";
import React, { useContext } from "react";
import { payorFieldId } from "@app/components/payorsTypeAhead/payorsTypeAhead";
import { useErrorController } from "@natera/platform/lib/hooks";

import "./enterOtherInsuranceCompanyForm.scss";
import {
  MultiplePaymentMethodType,
  PaymentMethodType,
  SimpleOrderStepperContext,
} from "@app/provider/simpleOrder/SimpleOrderStepperProvider";
import { Steps } from "@app/pages/private/simpleOrder";
import {
  getPaymentMethodInfoSubStepsData,
  isCommercialOrGovernmentInsuranceFirst,
  isCommercialOrGovernmentInsuranceSecond,
} from "@app/utils";

const messages = defineMessages({
  enterOtherInsuranceCompanyFormPayorFieldTitle: {
    id: "enterOtherInsuranceCompanyFormPayorFieldTitle",
    defaultMessage: "Enter insurance company if not listed",
  },
  enterOtherInsuranceCompanyFormPayorFieldError: {
    id: "enterOtherInsuranceCompanyFormPayorFieldError",
    defaultMessage: "This information is required.",
  },
  enterOtherInsuranceCompanyFormButtonNext: {
    id: "enterOtherInsuranceCompanyFormButtonNext",
    defaultMessage: "Next",
  },
  enterOtherInsuranceCompanyFormButtonBack: {
    id: "enterOtherInsuranceCompanyFormButtonBack",
    defaultMessage: "Back",
  },
  insuranceCompanyOtherText: {
    id: "insuranceCompanyOtherText",
    defaultMessage: "Other",
  },
});

type Props = {
  onSubmit: (company: Payor) => void;
  goBack: () => void;
};

const EnterOtherInsuranceCompanyForm: React.FC<Props> = ({
  onSubmit,
  goBack,
}) => {
  const intl = useIntl();
  const errorController = useErrorController();
  const { getCurrentStep } = React.useContext(StepperContext);

  const { getSubStepData } = useContext(SimpleOrderStepperContext);

  const subStepsData = getSubStepData(Steps.PAYMENT_METHOD) as
    | PaymentMethodType
    | MultiplePaymentMethodType;

  const currentStepId = getCurrentStep()?.stepId;

  const isInsuranceFirstStep = isCommercialOrGovernmentInsuranceFirst(
    currentStepId
  );
  const isInsuranceSecondStep = isCommercialOrGovernmentInsuranceSecond(
    currentStepId
  );

  const getInitialPayor = () => {
    const data = getPaymentMethodInfoSubStepsData(
      subStepsData,
      isInsuranceFirstStep,
      isInsuranceSecondStep
    );

    if (!data?.otherCompanyName || !data?.otherCompanyGroupId) {
      return undefined;
    }

    return {
      displayName: data.otherCompanyName,
      groupId: data.otherCompanyGroupId,
    };
  };

  const [selectedPayor, setSelectedPayor] = React.useState<Payor | undefined>(
    getInitialPayor()
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!selectedPayor) {
      errorController.setValidationError(
        payorFieldId,
        intl.formatMessage(
          messages.enterOtherInsuranceCompanyFormPayorFieldError
        )
      );
      return;
    }

    onSubmit(selectedPayor);
  };

  return (
    <Form
      className="enter-other-commercial-form"
      onSubmit={handleSubmit}
      buttons={
        <>
          <Button raised type="submit">
            {intl.formatMessage(
              messages.enterOtherInsuranceCompanyFormButtonNext
            )}
          </Button>
          <Button onClick={goBack}>
            {intl.formatMessage(
              messages.enterOtherInsuranceCompanyFormButtonBack
            )}
          </Button>
        </>
      }
    >
      <div className="selected-other">
        {intl.formatMessage(messages.insuranceCompanyOtherText)}
      </div>
      <FormField
        title={intl.formatMessage(
          messages.enterOtherInsuranceCompanyFormPayorFieldTitle
        )}
        label={intl.formatMessage(
          messages.enterOtherInsuranceCompanyFormPayorFieldTitle
        )}
        aria-label={intl.formatMessage(
          messages.enterOtherInsuranceCompanyFormPayorFieldTitle
        )}
        error={errorController.getValidationError(payorFieldId)}
        htmlFor={payorFieldId}
      >
        <PayorProvider>
          <PayorsTypeAhead
            selectedPayor={selectedPayor}
            onSelect={setSelectedPayor}
            errorController={errorController}
          />
        </PayorProvider>
      </FormField>
    </Form>
  );
};

export default EnterOtherInsuranceCompanyForm;
