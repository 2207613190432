import React, { useState } from "react";
import { Step, Stepper } from "@natera/stepper";
import { LoadingContext } from "@natera/platform/lib/components/context";

import BillingJourneyInformation from "./steps/billingJourneyInformation/billingJourneyInformation";
import BillingCostInformation from "./steps/billingCostInformation/billingCostInformation";
import BillingNextStep from "./steps/billingNextSteps/billingNextSteps";
import { PaymentChoice } from "@app/preBill/models";
import {
  NcsPayment,
  NcsPaymentConclusion,
  NcsPaymentFailure,
} from "../nonCoveredServiceStepper/steps";
import { NonCoveredServiceStepsType } from "../nonCoveredServiceStepper/nonCoveredServiceStepper";

import "./../../main.scss";

export enum BillingEstimateStepsType {
  BILLING_JOURNEY_INFORMATION = "BILLING_JOURNEY_INFORMATION",
  BILLING_COST_INFORMATION = "BILLING_COST_INFORMATION",
  BILLING_END_INFORMATION = "BILLING_END_INFORMATION",
  INN_CASH_PAY = "INN_CASH_PAY",
}

const BillingEstimateStepper: React.FC = () => {
  const [paymentStatus, setPaymentStatus] = useState<"success" | "failure">(
    "success"
  );
  const [paymentSelect, setPaymentSelect] = useState<
    PaymentChoice.CASHPAY | PaymentChoice.INSURANCE
  >(PaymentChoice.INSURANCE);

  return (
    <LoadingContext isLoading={false}>
      <Stepper
        initialStep={BillingEstimateStepsType.BILLING_JOURNEY_INFORMATION}
        progressStepper
        className="billing-estimate-stepper stepper"
      >
        <Step stepId={BillingEstimateStepsType.BILLING_JOURNEY_INFORMATION}>
          <BillingJourneyInformation />
        </Step>
        <Step stepId={BillingEstimateStepsType.BILLING_COST_INFORMATION}>
          <BillingCostInformation
            onPaymentSelect={(
              value: PaymentChoice.CASHPAY | PaymentChoice.INSURANCE
            ) => setPaymentSelect(value)}
          />
        </Step>
        {paymentSelect === PaymentChoice.INSURANCE && (
          <Step stepId={BillingEstimateStepsType.BILLING_END_INFORMATION}>
            <BillingNextStep />
          </Step>
        )}
        {paymentSelect === PaymentChoice.CASHPAY && (
          <Step stepId={BillingEstimateStepsType.INN_CASH_PAY}>
            <NcsPayment
              onPaymentResult={(status: "success" | "failure") =>
                setPaymentStatus(status)
              }
            />
          </Step>
        )}
        {paymentSelect === PaymentChoice.CASHPAY &&
          paymentStatus === "success" && (
            <Step stepId={NonCoveredServiceStepsType.NCS_SUCCESS}>
              <NcsPaymentConclusion />
            </Step>
          )}
        {paymentSelect === PaymentChoice.CASHPAY &&
          paymentStatus === "failure" && (
            <Step stepId={NonCoveredServiceStepsType.NCS_FAILURE}>
              <NcsPaymentFailure />
            </Step>
          )}
      </Stepper>
    </LoadingContext>
  );
};

export default BillingEstimateStepper;
