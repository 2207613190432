import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/form";
import Svg from "@natera/material/lib/svg";
import Results from "@assets/svg/icons/results.svg";
import { ParsedTestCard, TestCardStatus } from "@app/provider/testData/types";
import { ViewResultContext } from "@app/provider";
import { getResultReleasedAtMessage } from "@app/utils/getResultReleasedAtMessage";
import "./myResultsSection.scss";

const messages = defineMessages({
  myResultsTitle: {
    id: "myResultsTitle",
    defaultMessage: "My Results",
  },
  myResultsReceived: {
    id: "myResultsReceived",
    defaultMessage: "Received",
  },
  myResultsReceivedOn: {
    id: "myResultsReceivedOn",
    defaultMessage: "Received on {date}",
  },
  myResultsView: {
    id: "myResultsView",
    defaultMessage: "View Your Results",
  },
});

interface MyResultsProps {
  testCard: ParsedTestCard;
}

const MyResultsSection: FC<MyResultsProps> = ({ testCard }) => {
  const intl = useIntl();
  const { openResultPage, openTestResultDetailsPage } = useContext(
    ViewResultContext
  );

  const resultReleasedAt = getResultReleasedAtMessage(
    testCard,
    intl,
    messages.myResultsReceivedOn,
    messages.myResultsReceived
  );

  const viewResults = () =>
    testCard.status === TestCardStatus.RESULT_READY
      ? openResultPage(testCard)
      : openTestResultDetailsPage(testCard);

  return (
    <div className="my-results">
      <div className="my-results-wrp">
        <Svg content={Results} />
        <div className="my-results-info">
          <span className="my-results-info-title">
            {intl.formatMessage(messages.myResultsTitle)}
          </span>
          <div className="my-results-info-details">{resultReleasedAt}</div>
        </div>
      </div>
      <Button className="my-results-btn" raised onClick={viewResults}>
        {intl.formatMessage(messages.myResultsView)}
      </Button>
    </div>
  );
};

export default MyResultsSection;
