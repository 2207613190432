import React, { useState } from "react";
import OonCostInformation from "./steps/oonCostInformation/oonCostInformation";
import { BillingJourneyInformation } from "../billingEstimateStepper/steps";
import { BillingNextStep } from "../billingEstimateStepper/steps";
import { PaymentChoice } from "@app/preBill/models";
import {
  NcsPayment,
  NcsPaymentConclusion,
  NcsPaymentFailure,
} from "../nonCoveredServiceStepper/steps";
import { Step, Stepper } from "@natera/stepper";
import { NonCoveredServiceStepsType } from "../nonCoveredServiceStepper/nonCoveredServiceStepper";

import "./../../main.scss";

export enum OutOfNetworkStepsType {
  BILLING_JOURNEY_INFORMATION = "BILLING_JOURNEY_INFORMATION",
  OON_COST_INFORMATION = "OON_COST_INFORMATION",
  BILLING_END_INFORMATION = "BILLING_END_INFORMATION",
  OON_CASH_PAY = "OON_CASH_PAY",
}

const OutOfNetworkStepper: React.FC = () => {
  const [paymentStatus, setPaymentStatus] = useState<"success" | "failure">(
    "success"
  );
  const [paymentSelect, setPaymentSelect] = useState<
    PaymentChoice.CASHPAY | PaymentChoice.INSURANCE
  >(PaymentChoice.INSURANCE);

  return (
    <Stepper
      initialStep={OutOfNetworkStepsType.BILLING_JOURNEY_INFORMATION}
      progressStepper
    >
      <Step stepId={OutOfNetworkStepsType.BILLING_JOURNEY_INFORMATION}>
        <BillingJourneyInformation />
      </Step>
      <Step stepId={OutOfNetworkStepsType.OON_COST_INFORMATION}>
        <OonCostInformation
          onPaymentSelect={(
            value: PaymentChoice.CASHPAY | PaymentChoice.INSURANCE
          ) => setPaymentSelect(value)}
        />
      </Step>
      {paymentSelect === PaymentChoice.INSURANCE && (
        <Step stepId={OutOfNetworkStepsType.BILLING_END_INFORMATION}>
          <BillingNextStep />
        </Step>
      )}
      {paymentSelect === PaymentChoice.CASHPAY && (
        <Step stepId={OutOfNetworkStepsType.OON_CASH_PAY}>
          <NcsPayment
            onPaymentResult={(status: "success" | "failure") =>
              setPaymentStatus(status)
            }
          />
        </Step>
      )}
      {paymentSelect === PaymentChoice.CASHPAY && paymentStatus === "success" && (
        <Step stepId={NonCoveredServiceStepsType.NCS_SUCCESS}>
          <NcsPaymentConclusion />
        </Step>
      )}
      {paymentSelect === PaymentChoice.CASHPAY && paymentStatus === "failure" && (
        <Step stepId={NonCoveredServiceStepsType.NCS_FAILURE}>
          <NcsPaymentFailure />
        </Step>
      )}
    </Stepper>
  );
};

export default OutOfNetworkStepper;
