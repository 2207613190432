import React, { FC, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import PayorService from "@app/service/payor";
import R from "ramda";

export interface Payor {
  displayName: string;
  groupId?: string;
}

export interface PayorController {
  isLoading: boolean;
  getPayors: (searchValue: string, maxLength?: number) => Promise<Payor[]>;
}

export const PayorContext = React.createContext<PayorController>({
  isLoading: false,
  getPayors: () => Promise.reject(),
});

const PayorProvider: FC = ({ children }) => {
  const [getPayorsQuery, { loading: getPayorsLoading }] = useLazyQuery(
    PayorService.getPayors(),
    {
      fetchPolicy: "no-cache",
    }
  );

  const handleGetPayors = React.useCallback(
    async (searchValue: string, maxLength: number) => {
      const { data } = await getPayorsQuery({
        variables: { searchValue, maxLength },
      });

      const payorList: Payor[] = data.payors.map((payor: Payor) =>
        R.omit(["__typename"], payor)
      );

      return addSearchValueAsOptionIfNeed(payorList, searchValue);
    },
    [getPayorsQuery]
  );

  const addSearchValueAsOptionIfNeed = (
    payorList: Payor[],
    searchValue: string
  ) => {
    if (!searchValue) {
      return payorList;
    }

    const searchValueExistsInList = payorList.some(
      (payor) =>
        payor.displayName.toLocaleLowerCase() ===
        searchValue.toLocaleLowerCase()
    );

    if (!searchValueExistsInList) {
      return [{ displayName: searchValue }, ...payorList];
    }

    return payorList;
  };

  const isLoading = useMemo(() => getPayorsLoading, [getPayorsLoading]);

  const payorController: PayorController = useMemo(
    () => ({
      isLoading,
      getPayors: handleGetPayors,
    }),
    [isLoading, handleGetPayors]
  );

  return (
    <PayorContext.Provider value={payorController}>
      {children}
    </PayorContext.Provider>
  );
};

export default PayorProvider;
