import * as React from "react";
import { FC } from "react";
import Email from "@assets/svg/icons/email.svg";
import { HeapPHIDecorator } from "@app/components/analytics";
import { maskEmail } from "@app/utils";
import { Button } from "@natera/material/lib/button";
import { Link } from "react-router-dom";
import { SpinnerView } from "@app/components";
import { defineMessages, useIntl } from "react-intl";
import Svg from "@natera/material/lib/svg";
import { Form, LinkButton } from "@natera/form";

import "./sentEmailAfterUnlockView.scss";

const messages = defineMessages({
  sentEmailAfterUnlockViewCheckMail: {
    id: "sentEmailAfterUnlockViewCheckMail",
    defaultMessage: "Check your mail",
  },
  sentEmailAfterUnlockViewInstructions: {
    id: "sentEmailAfterUnlockViewInstructions",
    defaultMessage:
      "We have sent password recovery instructions to your email.",
  },
  sentEmailAfterUnlockViewValidLink: {
    id: "sentEmailAfterUnlockViewValidLink",
    defaultMessage: "The link is valid only for {time} minutes.",
  },
  sentEmailAfterUnlockViewResendResetLinkButton: {
    id: "sentEmailAfterUnlockViewResendResetLinkButton",
    defaultMessage: "Resend Reset Link",
  },
  sentEmailAfterUnlockViewDone: {
    id: "sentEmailAfterUnlockViewDone",
    defaultMessage: "Done",
  },
  sentEmailAfterUnlockViewCheckSpam: {
    id: "sentEmailAfterUnlockViewCheckSpam",
    defaultMessage:
      "Did not receive the email? Check your spam filter,{br} or {link}",
  },
  sentEmailAfterUnlockViewTryAnotherEmail: {
    id: "sentEmailAfterUnlockViewTryAnotherEmail",
    defaultMessage: "Try another email address",
  },
});

interface SentEmailAfterUnlockViewProps {
  email: string;
  handleResendEmail: () => void;
  resendIsDisabled: boolean;
  isLoading: boolean;
  tryAnotherEmailLink: string;
  handleTryAnotherEmail?: () => void;
}

const SentEmailAfterUnlockView: FC<SentEmailAfterUnlockViewProps> = ({
  email,
  handleResendEmail,
  resendIsDisabled,
  isLoading,
  tryAnotherEmailLink,
  handleTryAnotherEmail,
}) => {
  const intl = useIntl();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleResendEmail();
  };

  return (
    <div className="send-email-after-unlock">
      <div className="image-container">
        <Svg content={Email} />
      </div>
      <div className="description-container">
        <h2>
          {intl.formatMessage(messages.sentEmailAfterUnlockViewCheckMail)}
        </h2>

        <p>
          {intl.formatMessage(messages.sentEmailAfterUnlockViewInstructions)}
        </p>

        <p>
          <HeapPHIDecorator protectText noWrap>
            <span>{maskEmail(email || "")}</span>
          </HeapPHIDecorator>
        </p>

        <p>
          {intl.formatMessage(messages.sentEmailAfterUnlockViewValidLink, {
            time: "60",
          })}
        </p>
      </div>
      <Form
        onSubmit={handleSubmit}
        className="send-email-after-unlock-form"
        buttons={
          <>
            <Button type="submit" outlined disabled={resendIsDisabled}>
              {intl.formatMessage(
                messages.sentEmailAfterUnlockViewResendResetLinkButton
              )}
            </Button>

            <LinkButton
              renderLink={(props) => <Link {...props} />}
              to={"/"}
              raised
            >
              {intl.formatMessage(messages.sentEmailAfterUnlockViewDone)}
            </LinkButton>
          </>
        }
        noValidate
      ></Form>

      <p>
        {intl.formatMessage(messages.sentEmailAfterUnlockViewCheckSpam, {
          br: <br />,
          link: (
            <Link to={tryAnotherEmailLink} onClick={handleTryAnotherEmail}>
              {intl.formatMessage(
                messages.sentEmailAfterUnlockViewTryAnotherEmail
              )}
            </Link>
          ),
        })}
      </p>

      <SpinnerView isLoading={isLoading} />
    </div>
  );
};

export default SentEmailAfterUnlockView;
