import { PaymentType } from "@app/pages/private/simpleOrder/paymentMethod/paymentMethod";
import { Grid, GridCell, GridRow } from "@natera/material/lib/layout";
import { Button, Form } from "@natera/form";
import { StepperContext } from "@natera/stepper";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { OrderContext } from "@app/provider";
import {
  getPriceEstimation,
  getTestNameForHeap,
  isComboOrder,
  isHorizonOrder,
} from "@app/utils";

import "./selfPayStep.scss";
import { HEAP_EVENTS } from "@app/provider/types";

const messages = defineMessages({
  selfPayStepHeaderTitle: {
    id: "selfPayStepHeaderTitle",
    defaultMessage: "Self Pay",
  },
  selfPayStepHeaderSubTitle: {
    id: "selfPayStepHeaderSubTitle",
    defaultMessage:
      "Natera offers an affordable self-pay option for patients without insurance or who don’t want to use their insurance plan.",
  },
  selfPayStepTestContentInsurance: {
    id: "selfPayStepTestContentInsurance",
    defaultMessage: "No health insurance",
  },
  selfPayStepPriceEstimation: {
    id: "selfPayStepPriceEstimation",
    defaultMessage: "*This estimate is an approximation and is not guaranteed.",
  },
  selfPayStepTestTypeTitleHorizon: {
    id: "selfPayStepTestTypeTitleHorizon",
    defaultMessage: "Horizon Test",
  },
  selfPayStepTestTypeTitlePanorama: {
    id: "selfPayStepTestTypeTitlePanorama",
    defaultMessage: "Panorama Test",
  },
  selfPayStepTestTypeTitleCombo: {
    id: "selfPayStepTestTypeTitleCombo",
    defaultMessage: "Combo: Horizon and Panorama",
  },
  selfPayStepButtonNext: {
    id: "selfPayStepButtonNext",
    defaultMessage: "Next",
  },
  selfPayStepButtonBack: {
    id: "selfPayStepButtonBack",
    defaultMessage: "Back",
  },
});

const SelfPayStep: React.FC<{
  paymentType: PaymentType;
  onConfirm: (data: unknown) => void;
}> = ({ paymentType, onConfirm }) => {
  const intl = useIntl();
  const { goBack } = React.useContext(StepperContext);

  const { orderData } = React.useContext(OrderContext);

  const isCombo = orderData && isComboOrder(orderData);
  const isHorizon = orderData && isHorizonOrder(orderData);
  const orderUid = orderData?.uid;
  const limsId = orderData?.clinic?.limsId;

  const getTestTypeTitle = () => {
    if (isCombo) {
      return intl.formatMessage(messages.selfPayStepTestTypeTitleCombo);
    }
    return isHorizon
      ? intl.formatMessage(messages.selfPayStepTestTypeTitleHorizon)
      : intl.formatMessage(messages.selfPayStepTestTypeTitlePanorama);
  };

  const priceEstimation = orderData && getPriceEstimation(orderData);
  const testTypeTitle = getTestTypeTitle();

  const onClick = () => {
    onConfirm({ paymentMethod: paymentType });

    heap.track(HEAP_EVENTS.upp_simpleorder_selfpay, {
      tests_ordered: getTestNameForHeap(orderData),
      lims_clinic_id: limsId,
      order_uid: orderUid,
    });
  };

  return (
    <StepperContent title={intl.formatMessage(messages.selfPayStepHeaderTitle)}>
      <Form
        buttons={
          <>
            <Button raised type="button" onClick={onClick}>
              {intl.formatMessage(messages.selfPayStepButtonNext)}
            </Button>
            <Button onClick={goBack}>
              {intl.formatMessage(messages.selfPayStepButtonBack)}
            </Button>
          </>
        }
      >
        <div className="self-pay-step">
          <Grid>
            <GridRow>
              <GridCell span={12}>
                <p className="self-pay-step__description">
                  {intl.formatMessage(messages.selfPayStepHeaderSubTitle)}
                </p>

                <div className="self-pay-step__test-content">
                  <p className="self-pay-step__test-content__title">
                    {testTypeTitle}
                  </p>
                  <p className="self-pay-step__test-content__insurance">
                    {intl.formatMessage(
                      messages.selfPayStepTestContentInsurance
                    )}
                  </p>
                </div>

                <div className="price-box">
                  <span className="price-box__label">{priceEstimation}</span>
                </div>

                <p className="self-pay-step__price-estimation">
                  {intl.formatMessage(messages.selfPayStepPriceEstimation)}
                </p>
              </GridCell>
            </GridRow>
          </Grid>
        </div>
      </Form>
    </StepperContent>
  );
};

export default SelfPayStep;
