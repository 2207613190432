import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { FACTORS_TO_SHOW } from "@app/service/user";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/form";
import { Svg } from "@natera/material/lib/svg";
import DoneIcon from "@assets/svg/icons/done.svg";
import { routes } from "@app/routing";
import { UserContext } from "@app/provider";

import "./factorList.scss";

const messages = defineMessages({
  factorSuccessMessageTitle: {
    id: "factorSuccessMessageTitle",
    defaultMessage: "verified",
  },
  factorSuccessMessageTitleEmail: {
    id: "factorSuccessMessageTitleEmail",
    defaultMessage: "Email address",
  },
  factorSuccessMessageTitleSms: {
    id: "factorSuccessMessageTitleSms",
    defaultMessage: "Phone number",
  },
  factorSuccessMessageText: {
    id: "factorSuccessMessageText",
    defaultMessage: "Two - factor authentication is now active.",
  },
  factorSuccessMessageSubmit: {
    id: "factorSuccessMessageSubmit",
    defaultMessage: "Go to My Dashboard",
  },
});

interface FactorSuccessModalProps extends React.HTMLAttributes<HTMLDivElement> {
  type: FACTORS_TO_SHOW;
  onClose: () => void;
}

const FactorSuccessModal: FC<FactorSuccessModalProps> = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const { type, onClose } = props;

  const { loadProfile } = useContext(UserContext);

  const getTitle = () => {
    switch (type) {
      case FACTORS_TO_SHOW.email:
        return intl.formatMessage(messages.factorSuccessMessageTitleEmail);
      case FACTORS_TO_SHOW.call:
      case FACTORS_TO_SHOW.sms:
      default:
        return intl.formatMessage(messages.factorSuccessMessageTitleSms);
    }
  };

  const handleClose = async () => {
    await loadProfile();
    onClose();
    history.replace(routes.home + history.location.search);
  };

  return (
    <Dialog
      className="factor-success-modal"
      onClose={handleClose}
      showCrossButton
      title={
        <span>
          {getTitle()} {intl.formatMessage(messages.factorSuccessMessageTitle)}
        </span>
      }
      actions={
        <Button raised onClick={handleClose}>
          {intl.formatMessage(messages.factorSuccessMessageSubmit)}
        </Button>
      }
    >
      <div className="factor-success-modal-container">
        <Svg content={DoneIcon} />

        <span className="factor-success-modal-text">
          {intl.formatMessage(messages.factorSuccessMessageText)}
        </span>
      </div>
    </Dialog>
  );
};

export default FactorSuccessModal;
