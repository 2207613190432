import React from "react";
import { defineMessages, useIntl } from "react-intl";
import Svg from "@natera/material/lib/svg";
import SearchCloudIcon from "@assets/svg/search-cloud.svg";
import { Link } from "react-router-dom";
import { ConfigContext } from "@app/provider";
import "./noLongerAvailableCard.scss";

const messages = defineMessages({
  noLongerAvailableCardTitle: {
    id: "noLongerAvailableCardTitle",
    defaultMessage: "Billing update no longer available",
  },
  noLongerAvailableCardDescription1: {
    id: "noLongerAvailableCardDescription1",
    defaultMessage: "We'll continue processing with your insurance.",
  },
  noLongerAvailableCardDescription2: {
    id: "noLongerAvailableCardDescription2",
    defaultMessage: "While we do that, feel free to explore next steps.",
  },
  noLongerAvailableCardHelp: {
    id: "noLongerAvailableCardHelp",
    defaultMessage: "Need more help?",
  },
  noLongerAvailableCardContactUs: {
    id: "noLongerAvailableCardContactUs",
    defaultMessage: "Contact Us",
  },
});

const NoLongerAvailableCard: React.FC = () => {
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);

  return (
    <div className="no-longer-available-card__box">
      <div className="no-longer-available-card__box-icon">
        <Svg content={SearchCloudIcon} />
      </div>
      <div className="no-longer-available-card__box-content">
        <div className="no-longer-available-card__box-content__title">
          {intl.formatMessage(messages.noLongerAvailableCardTitle)}
        </div>
        <div className="no-longer-available-card__box-content__text">
          <div>
            {intl.formatMessage(messages.noLongerAvailableCardDescription1)}
          </div>
          <div>
            {intl.formatMessage(messages.noLongerAvailableCardDescription2)}
          </div>
        </div>
        <div className="no-longer-available-card__box-content__contact">
          <span>{intl.formatMessage(messages.noLongerAvailableCardHelp)} </span>
          <span className="no-longer-available-card__box-content__contact-us">
            <Link
              target="_blank"
              key="privacyPolicy"
              rel="noopener noreferrer"
              to={{ pathname: config.links.CONTACT_US }}
            >
              {intl.formatMessage(messages.noLongerAvailableCardContactUs)}
            </Link>
          </span>
        </div>
      </div>
      <div className="no-longer-available-card__divider" />
    </div>
  );
};

export default NoLongerAvailableCard;
