import { StepperContent } from "@app/components/ui/layout/stepperContent";
import React, { useEffect, useState, useContext } from "react";
import { StepperContext } from "@natera/stepper";
import { defineMessages, useIntl } from "react-intl";
import { PreBillAPIContext } from "@app/provider";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { Button } from "@natera/form";
import { Language, IntlContext } from "@app/provider";
import "./ncsInformationStep.scss";
import { getTestName } from "@app/preBill/utils/getTestName";

const messages = defineMessages({
  ncsInformationTitle: {
    id: "ncsInformationTitle",
    defaultMessage:
      "Hi {firstName}, please review your billing update carefully.",
  },
  ncsInformationDescription: {
    id: "ncsInformationDescription",
    defaultMessage:
      "Based on our experience with your insurance plan, your {testName} test is likely not covered but we can offer you our prompt-pay cash price.",
  },
  ncsInformationSubTitle: {
    id: "ncsInformationSubTitle",
    defaultMessage: "Prompt-pay cash price",
  },
  ncsInformationSubDescription: {
    id: "ncsInformationSubDescription",
    defaultMessage:
      "We can offer you a prompt-pay cash price that is valid for the next 21 days. If you don't submit payment by {estimateDate}, we will proceed with billing your insurance and your final bill may be much higher.",
  },
  ncsInformationViewPromptCashPay: {
    id: "ncsInformationViewPromptCashPay",
    defaultMessage: "View prompt-pay cash price",
  },
});

const NcsInformation: React.FC = () => {
  const intl = useIntl();
  const { currentLanguage } = useContext(IntlContext);
  const { resolve } = React.useContext(StepperContext);
  const {
    apiProviderState: { orderEstimate },
  } = useContext(PreBillAPIContext);
  const firstName = orderEstimate?.patient?.firstName;
  const [testName, setTestName] = useState<string>("");

  useEffect(() => {
    setTestName(getTestName(orderEstimate?.testType, intl));
  }, [orderEstimate?.testType, intl]);

  const formattedEstimateDate = (): string => {
    if (!orderEstimate?.tokenExpireDate) return "";

    const locale = currentLanguage === Language.EN ? "en-US" : "es-ES";

    return new Date(orderEstimate?.tokenExpireDate).toLocaleDateString(locale, {
      timeZone: "UTC",
      month: "long",
      day: "2-digit",
    });
  };

  const handleSubmit = async () => {
    resolve("Step 1 resolved");
  };

  return (
    <>
      <StepperHeaderProgress />
      <div className="ncs-information">
        <StepperContent
          title={intl.formatMessage(messages.ncsInformationTitle, {
            firstName: firstName,
          })}
          description={intl.formatMessage(messages.ncsInformationDescription, {
            testName: testName,
          })}
        >
          <h3
            className="ncs-information__sub-title"
            data-testid="ncsInformationSubTitle"
          >
            {intl.formatMessage(messages.ncsInformationSubTitle)}
          </h3>
          <div
            className="ncs-information__sub-description"
            data-testid="ncsInformationSubDescription"
          >
            {intl.formatMessage(messages.ncsInformationSubDescription, {
              estimateDate: formattedEstimateDate(),
            })}
          </div>
        </StepperContent>
        <div className="stepper__actions">
          <Button
            className="stepper__actions__button"
            raised
            onClick={handleSubmit}
          >
            {intl.formatMessage(messages.ncsInformationViewPromptCashPay)}
          </Button>
        </div>
      </div>
    </>
  );
};

export default NcsInformation;
