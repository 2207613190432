import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Option, Select } from "@natera/form";
import { getTimeZones } from "@app/utils";
import { ErrorContext } from "@app/provider";
import { FormField } from "@app/components";

import "./timezoneSelector.scss";

const messages = defineMessages({
  timezoneSelectorPlaceholder: {
    id: "timezoneSelectorPlaceholder",
    defaultMessage: "Please select your time zone",
  },
  timezoneSelectorLabel: {
    id: "timezoneSelectorLabel",
    defaultMessage: "Time Zone",
  },
});

type Props = {
  value?: string;
  onSelect: (newValue: string) => void;
  defaultValue?: string;
};

export const timezoneValidationName = "timezone";

const TimezoneSelector: React.FC<Props> = ({
  value,
  onSelect,
  defaultValue,
}) => {
  const intl = useIntl();

  const timeZoneHandler = (value: string) => onSelect(value);
  const { getValidationError } = React.useContext(ErrorContext);

  const timeZones = getTimeZones(intl);

  return (
    <FormField
      label={intl.formatMessage(messages.timezoneSelectorLabel)}
      error={getValidationError(timezoneValidationName)}
      withPadding
      id="timeZone"
    >
      <Select
        placeholder={intl.formatMessage(messages.timezoneSelectorPlaceholder)}
        labelId="timeZone"
        onValueChange={timeZoneHandler}
        outlined
        value={value}
        defaultValue={defaultValue}
        data-testid="timezone_selector"
        role="combobox"
      >
        {timeZones.map((timeZone) => (
          <Option
            key={timeZone.browserValue}
            value={timeZone.browserValue}
            data-testid={timeZone.browserValue}
          >
            {timeZone.userValue}
          </Option>
        ))}
      </Select>
    </FormField>
  );
};

export default TimezoneSelector;
