import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useErrorController } from "@natera/platform/lib/hooks";
import { Grid, GridCell, GridRow } from "@natera/material/lib/layout";
import { Button, Form, LinkButton } from "@natera/form";
import { ResultCodes } from "@app/service/resultCodes";
import { UppAuthContext, UserContext } from "@app/provider";
import {
  BlueHeader,
  DropdownWrapper,
  FormField,
  PasswordField,
  PasswordNotice,
  SpinnerView,
  usePasswordPolicy,
} from "@app/components";
import { routes } from "@app/routing";
import { IntlMessage } from "@app/provider/types";

import { Link } from "@natera/navigation";

import "./changePassword.scss";

const messages: IntlMessage = defineMessages({
  changePasswordBack: {
    id: "changePasswordBack",
    defaultMessage: "Security Settings",
  },
  changePasswordTitle: {
    id: "changePasswordTitle",
    defaultMessage: "Update Account Password",
  },
  changePasswordDescription: {
    id: "changePasswordDescription",
    defaultMessage: "Your new password cannot be the same as an old password.",
  },
  changePasswordOld: {
    id: "changePasswordOld",
    defaultMessage: "Old Password",
  },
  changePasswordOldInputPlaceholder: {
    id: "changePasswordOldInputPlaceholder",
    defaultMessage: "Please enter your old password",
  },
  changePasswordNew: {
    id: "changePasswordNew",
    defaultMessage: "Create New Password",
  },
  changePasswordReEnter: {
    id: "changePasswordReEnter",
    defaultMessage: "Re-enter New Password",
  },
  changePasswordCancelButton: {
    id: "changePasswordCancelButton",
    defaultMessage: "Cancel",
  },
  changePasswordUpdateButton: {
    id: "changePasswordUpdateButton",
    defaultMessage: "Update My Password",
  },
  changePasswordInvalidError: {
    id: "changePasswordInvalidError",
    defaultMessage: "Invalid password.",
  },
  changePasswordMatchError: {
    id: "changePasswordMatchError",
    defaultMessage: "Passwords must match",
  },
  changePasswordIncorrectOldPasswordError: {
    id: "changePasswordIncorrectOldPasswordError",
    defaultMessage: "Old password must match with the current user password",
  },
  changePasswordSamePasswordError: {
    id: "changePasswordSamePasswordError",
    defaultMessage: "Your new password cannot be the same as an old password.",
  },
  changePasswordRecentlyPasswordError: {
    id: "changePasswordRecentlyPasswordError",
    defaultMessage: "Password has been used too recently.",
  },
});

enum ValidationErrors {
  NEW_PASSWORD_ERROR = "NEW_PASSWORD_ERROR",
  OLD_PASSWORD_ERROR = "OLD_PASSWORD_ERROR",
}

const ChangePassword: FC = () => {
  const { profile } = useContext(UppAuthContext);
  const {
    changePassword,
    changePasswordError,
    resetChangePasswordError,
    isLoading,
  } = useContext(UserContext);
  const errorController = useErrorController();
  const intl = useIntl();
  const [passwordNoticeIsVisible, setPasswordNoticeIsVisible] = useState(false);
  const history = useHistory();
  const [password, setPassword] = useState("");

  const [
    newPasswordValidationErrorKey,
    setNewPasswordValidationErrorKey,
  ] = useState<string | null>(null);
  const [
    oldPasswordValidationErrorKey,
    setOldPasswordValidationErrorKey,
  ] = useState<string | null>(null);

  const {
    passwordPolicyComplexity,
    invalidPasswordRequirement,
  } = usePasswordPolicy({
    password,
    email: profile?.email || "",
  });

  React.useEffect(() => {
    if (newPasswordValidationErrorKey) {
      errorController.setValidationError(
        ValidationErrors.NEW_PASSWORD_ERROR,
        intl.formatMessage(messages[newPasswordValidationErrorKey])
      );
    }
    if (oldPasswordValidationErrorKey) {
      errorController.setValidationError(
        ValidationErrors.OLD_PASSWORD_ERROR,
        intl.formatMessage(messages[oldPasswordValidationErrorKey])
      );
    }
  }, [intl.locale]);

  const handlePasswordFieldFocus = () => {
    setPasswordNoticeIsVisible(true);
  };

  const handlePasswordFieldBlur = () => {
    setPasswordNoticeIsVisible(false);
  };

  const clearOldPasswordError = useCallback(() => {
    errorController.clearValidationError(ValidationErrors.OLD_PASSWORD_ERROR);
    setOldPasswordValidationErrorKey(null);
  }, []);

  const clearNewPasswordError = useCallback(() => {
    errorController.clearValidationError(ValidationErrors.NEW_PASSWORD_ERROR);
    setNewPasswordValidationErrorKey(null);
  }, []);

  const hadnlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    clearNewPasswordError();
    setPassword(e.target.value);
  };

  const submit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    clearOldPasswordError();
    clearNewPasswordError();

    const formData = e.currentTarget;
    const oldPassword = formData.oldPassword.value;
    const newPassword = formData.newPassword.value;
    const secondaryPassword = formData.secondaryPassword.value;

    if (newPassword !== secondaryPassword) {
      errorController.setValidationError(
        ValidationErrors.NEW_PASSWORD_ERROR,
        intl.formatMessage(messages.changePasswordMatchError)
      );
      setNewPasswordValidationErrorKey("changePasswordMatchError");
      return;
    }

    if (invalidPasswordRequirement) {
      errorController.setValidationError(
        ValidationErrors.NEW_PASSWORD_ERROR,
        intl.formatMessage(messages.changePasswordInvalidError)
      );
      setNewPasswordValidationErrorKey("changePasswordInvalidError");
      return;
    }

    if (profile) {
      changePassword({ oldPassword, newPassword }).then((data) => {
        if (data?.success) {
          history.replace(routes.securitySettings, {
            passwordUpdated: data.success,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (changePasswordError) {
      switch (changePasswordError) {
        case ResultCodes.OLD_PASSWORD_IS_NOT_CORRECT: {
          errorController.setValidationError(
            ValidationErrors.OLD_PASSWORD_ERROR,
            intl.formatMessage(messages.changePasswordIncorrectOldPasswordError)
          );
          setOldPasswordValidationErrorKey(
            "changePasswordIncorrectOldPasswordError"
          );
          break;
        }

        case ResultCodes.PASSWORD_CANNOT_BE_YOUR_CURRENT_PASSWORD: {
          errorController.setValidationError(
            ValidationErrors.OLD_PASSWORD_ERROR,
            intl.formatMessage(messages.changePasswordSamePasswordError)
          );
          setOldPasswordValidationErrorKey("changePasswordSamePasswordError");
          break;
        }

        case ResultCodes.PASSWORD_HAS_BEEN_USED_TOO_RECENTLY: {
          errorController.setValidationError(
            ValidationErrors.NEW_PASSWORD_ERROR,
            intl.formatMessage(messages.changePasswordRecentlyPasswordError)
          );
          setNewPasswordValidationErrorKey(
            "changePasswordRecentlyPasswordError"
          );
          break;
        }
      }

      return resetChangePasswordError;
    }
  }, [changePasswordError]);

  return (
    <>
      {<SpinnerView isLoading={isLoading} />}
      <div className="change-password">
        <BlueHeader
          arrowRoute={routes.securitySettings}
          title={intl.formatMessage(messages.changePasswordBack)}
        />

        <GridRow className="change-password__form">
          <GridCell span={12}>
            <Form onSubmit={submit}>
              <Grid>
                <GridRow className="change-password__form__wrapper">
                  <GridCell
                    className="change-password__form__wrapper__title"
                    span={12}
                  >
                    {intl.formatMessage(messages.changePasswordTitle)}
                  </GridCell>

                  <GridCell
                    className="change-password__form__wrapper__description"
                    span={12}
                  >
                    {intl.formatMessage(messages.changePasswordDescription)}
                  </GridCell>

                  <GridCell span={12}>
                    <FormField
                      required
                      label={intl.formatMessage(messages.changePasswordOld)}
                      error={errorController.getValidationError(
                        ValidationErrors.OLD_PASSWORD_ERROR
                      )}
                      htmlFor="oldPassword"
                      withPadding
                    >
                      <PasswordField
                        required
                        id="oldPassword"
                        name="oldPassword"
                        placeholder={intl.formatMessage(
                          messages.changePasswordOldInputPlaceholder
                        )}
                        outline
                        onChange={clearOldPasswordError}
                        autoComplete="curent-password"
                      />
                    </FormField>
                  </GridCell>

                  <GridCell span={12} className="new-password-container">
                    <FormField
                      required
                      label={intl.formatMessage(messages.changePasswordNew)}
                      error={errorController.getValidationError(
                        ValidationErrors.NEW_PASSWORD_ERROR
                      )}
                      htmlFor="newPassword"
                      withPadding
                    >
                      <PasswordField
                        required
                        id="newPassword"
                        name="newPassword"
                        placeholder={intl.formatMessage(
                          messages.changePasswordNew
                        )}
                        outline
                        onChange={hadnlePasswordChange}
                        onFocus={handlePasswordFieldFocus}
                        onBlur={handlePasswordFieldBlur}
                        autoComplete="new-password"
                      />
                    </FormField>

                    <DropdownWrapper isOpen={passwordNoticeIsVisible}>
                      <PasswordNotice
                        passwordPolicyComplexity={passwordPolicyComplexity}
                      />
                    </DropdownWrapper>
                  </GridCell>

                  <GridCell
                    span={12}
                    className={
                      !passwordNoticeIsVisible
                        ? "re-enter-password-container"
                        : ""
                    }
                  >
                    <FormField
                      required
                      label={intl.formatMessage(messages.changePasswordReEnter)}
                      error={errorController.getValidationError(
                        ValidationErrors.NEW_PASSWORD_ERROR
                      )}
                      htmlFor="secondaryPassword"
                      withPadding
                    >
                      <PasswordField
                        required
                        id="secondaryPassword"
                        name="secondaryPassword"
                        placeholder={intl.formatMessage(
                          messages.changePasswordReEnter
                        )}
                        outline
                        onChange={clearNewPasswordError}
                        autoComplete="new-password"
                      />
                    </FormField>
                  </GridCell>

                  <GridCell span={12}>
                    <LinkButton
                      to={routes.securitySettings}
                      outlined
                      renderLink={(props) => <Link {...props} />}
                      tabIndex={-1}
                    >
                      {intl.formatMessage(messages.changePasswordCancelButton)}
                    </LinkButton>
                  </GridCell>

                  <GridCell span={12}>
                    <Button type="submit" raised>
                      {intl.formatMessage(messages.changePasswordUpdateButton)}
                    </Button>
                  </GridCell>
                </GridRow>
              </Grid>
            </Form>
          </GridCell>
        </GridRow>
      </div>
    </>
  );
};

export default ChangePassword;
