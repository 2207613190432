import React, { FC, useContext, useMemo } from "react";
import { Dialog } from "@natera/material/lib/dialog";
import { defineMessages, useIntl } from "react-intl";
import { IntlContext } from "@app/provider";
import { Button } from "@natera/form";
import { BusinessUnits } from "@app/provider/drawRequest";
import { contactPhoneMapper } from "@app/utils/contactPhoneMapper";

import "./cancelAppointmentDialogFail.scss";

const messages = defineMessages({
  cancelAppointmentDialogFailTitle: {
    id: "cancelAppointmentDialogFailTitle",
    defaultMessage: "Apologies",
  },
  cancelAppointmentDialogFailText: {
    id: "cancelAppointmentDialogFailText",
    defaultMessage:
      "We are unable to cancel your appointment request at this time.",
  },
  cancelAppointmentDialogFailTextContact: {
    id: "cancelAppointmentDialogFailTextContact",
    defaultMessage: "Please contact us to get help",
  },
  cancelAppointmentDialogFailTextContactWH: {
    id: "cancelAppointmentDialogFailTextContactWH",
    defaultMessage: "Please contact us to get help",
  },
  cancelAppointmentDialogFailBackButton: {
    id: "cancelAppointmentDialogFailBackButton",
    defaultMessage: "Back",
  },
  cancelAppointmentDialogFailCrossButtonLabel: {
    id: "cancelAppointmentDialogFailCrossButtonLabel",
    defaultMessage: "Close",
  },
});

interface CancelAppointmentDialogFailProps {
  onClose: () => void;
  businessUnit?: BusinessUnits;
}

const CancelAppointmentDialogFail: FC<CancelAppointmentDialogFailProps> = ({
  onClose,
  businessUnit = BusinessUnits.ORGAN_HEALTH,
}) => {
  const intl = useIntl();

  const header = intl.formatMessage(messages.cancelAppointmentDialogFailTitle);
  const { currentLanguage } = useContext(IntlContext);

  const appointmentDialogFailTextContact = useMemo(() => {
    switch (businessUnit) {
      case BusinessUnits.WOMENS_HEALTH:
        return intl.formatMessage(
          messages.cancelAppointmentDialogFailTextContactWH
        );
      default:
        return intl.formatMessage(
          messages.cancelAppointmentDialogFailTextContact
        );
    }
  }, [businessUnit, currentLanguage]);

  const content = (
    <>
      <span>
        {intl.formatMessage(messages.cancelAppointmentDialogFailText)}
      </span>
      <br />
      <span>{appointmentDialogFailTextContact}</span>
      <br />
      <span>{contactPhoneMapper(businessUnit)}</span>
    </>
  );

  const actions = (
    <Button onClick={onClose} raised>
      {intl.formatMessage(messages.cancelAppointmentDialogFailBackButton)}
    </Button>
  );

  const altText = intl.formatMessage(
    messages.cancelAppointmentDialogFailCrossButtonLabel
  );

  return (
    <Dialog
      className="cancel-appointment-fail-dialog"
      onClose={onClose}
      title={header}
      showCrossButton
      actions={actions}
      altText={altText}
    >
      <div className="cancel-appointment-fail-dialog__content">{content}</div>
    </Dialog>
  );
};

export default CancelAppointmentDialogFail;
