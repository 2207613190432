import React, { useContext, useEffect, useState, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Svg } from "@natera/material/lib/svg";
import backIcon from "@assets/svg/icons/billing-updates-back.svg";
import BillingUpdatesPageNotificationCard from "@app/components/billingUpdatesPageNotificationCard";
import { PreBillAPIContext } from "@app/preBill/provider";
import { Utils } from "@natera/material/lib/date";
import "./billingUpdates.scss";

const messages = defineMessages({
  billingUpdatesTitle: {
    id: "billingUpdatesTitle",
    defaultMessage: "Billing updates",
  },
});

interface Notification {
  id: string;
  testType: string;
  date: string | null;
  isRead: boolean;
  tokenizedLink: string;
}

const BillingUpdates: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { listActiveOrdersForPatient } = useContext(PreBillAPIContext);
  const isInitialLoadDone = useRef(false);

  useEffect(() => {
    let mounted = true;

    const loadNotifications = async () => {
      if (isInitialLoadDone.current) {
        return;
      }

      try {
        const response = await listActiveOrdersForPatient();

        if (!mounted) return;

        const transformedNotifications = response.activeOrders.flatMap(
          (order, index) =>
            order.testTypeInformation.map((testInfo, testIndex) => ({
              id: `${index + 1}-${testIndex + 1}`,
              testType: testInfo.testType,
              date: Utils.getUSLocaleDate(new Date(order.tokenExpireDate)),
              isRead: order.lastOrderSeen !== null,
              tokenizedLink: order.tokenizedLink,
            }))
        );

        setNotifications(transformedNotifications);
        isInitialLoadDone.current = true;
      } catch (error) {
        console.error("Failed to load notifications:", error);
      }
    };

    loadNotifications();

    return () => {
      mounted = false;
    };
  }, []);

  const handleNotificationClick = async (tokenizedLink: string) => {
    window.location.href = tokenizedLink;
  };

  return (
    <div className="billing-updates">
      <header className="billing-updates__header">
        <Svg
          data-testid="billing-updates-back-icon"
          className="billing-updates__back-icon"
          content={backIcon}
          onClick={() => history.goBack()}
        />
        <h1 className="billing-updates__title">
          {intl.formatMessage(messages.billingUpdatesTitle)}
        </h1>
      </header>
      <div
        className="billing-updates__notifications"
        data-testid="billing-updates-notifications"
      >
        {notifications.map((notification) => (
          <BillingUpdatesPageNotificationCard
            key={notification.id}
            notification={notification}
            onClick={() => handleNotificationClick(notification.tokenizedLink)}
          />
        ))}
      </div>
    </div>
  );
};

export default BillingUpdates;
