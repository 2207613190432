import React from "react";
import CheckIcon from "@app/preBill/assets/images/check-icon.png";
import "./successInfoBox.scss";

interface SuccessInfoBoxProps {
  title: string;
  description: string;
}

const SuccessInfoBox: React.FC<SuccessInfoBoxProps> = ({
  title,
  description,
}) => {
  return (
    <div className="success-info-box">
      <img
        className="success-info-box__icon"
        src={CheckIcon}
        alt="check-icon"
      />
      <div className="success-info-box__container">
        <h3 className="success-info-box__container__title">{title}</h3>
        <p className="success-info-box__container__description">
          {description}
        </p>
      </div>
    </div>
  );
};

export default SuccessInfoBox;
