import { RadioGroup, RadioButton } from "@natera/form";
import React from "react";

import "./multipleInsurancesSelector.scss";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  multipleInsurancesSelectorCommercialText: {
    id: "multipleInsurancesSelectorCommercialText",
    defaultMessage: "Commercial health insurance",
  },
  multipleInsurancesSelectorGovernmentText: {
    id: "multipleInsurancesSelectorGovernmentText",
    defaultMessage: "Government issued insurance (e.g. Medicaid)",
  },
});

export enum MultipleInsurance {
  Commercial = "Commercial",
  Government = "Government",
}

type Props = {
  value?: string;
  onChange: (newData: MultipleInsurance) => void;
};

const MultipleInsurancesSelector: React.FC<Props> = ({ value, onChange }) => {
  const intl = useIntl();

  const insurances = Object.values(MultipleInsurance);

  const getInsuranceLabel = (insurance: string) => {
    switch (insurance) {
      case MultipleInsurance.Commercial:
        return intl.formatMessage(
          messages.multipleInsurancesSelectorCommercialText
        );
      case MultipleInsurance.Government:
        return intl.formatMessage(
          messages.multipleInsurancesSelectorGovernmentText
        );
      default:
        return insurance;
    }
  };

  return (
    <RadioGroup
      name="multiple-insurance-selector"
      value={value}
      onChange={onChange}
      aria-required
    >
      {insurances.map((insurance) => (
        <RadioButton
          id={insurance}
          key={insurance}
          value={insurance}
          label={getInsuranceLabel(insurance)}
          aria-checked={insurance === value}
          name={insurance}
          className="multiple-insurance-selector__item"
        />
      ))}
    </RadioGroup>
  );
};

export default MultipleInsurancesSelector;
