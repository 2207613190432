import React, { FC, useContext, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import { routes } from "@app/routing";
import Main from "./main";
import SetupProfile from "./setupProfile";
import {
  Profile,
  Home,
  ContactDetails,
  UpdateEmail,
  ResendUpdateEmail,
  ChangePassword,
  VideoPage,
  TestResultPage,
  SampleDraw,
  SimpleOrder,
  TestHistory,
  BillingCenter,
  CommunicationPreferences,
  PersonalInformation,
  BillingUpdates,
  GuestLogin,
  BillingEstimate,
  OutOfNetwork,
  NonCoveredService,
  PriorAuthorization,
} from "@app/pages";
import {
  NotificationContext,
  RedirectionContext,
  RoutingType,
} from "@app/provider";
import DateOfBirthVerification from "./dateOfBirthVerification";
import Confirmation from "./confirmation";
import SecuritySettings from "./securitySettings";
import EditMfa from "./editMfa";
import { PUSH_ACTION } from "@app/pages/public/routing";
import UpdateEmailError from "@app/pages/public/updateEmailError";
import ScheduleAGeneticInformationPage from "@app/pages/private/scheduleAGeneticInformationSession/scheduleAGeneticInformationPage";

const REPLACE = "REPLACE";

const PrivateRouting: FC = () => {
  const { notifications, clear } = useContext(NotificationContext);
  const history = useHistory();

  const { setRedirection, redirectionTrigger, handleRedirection } = useContext(
    RedirectionContext
  );

  useEffect(() => {
    setRedirection(RoutingType.PRIVATE);
  }, []);

  useEffect(() => {
    if (redirectionTrigger) {
      handleRedirection();
    }
  }, [redirectionTrigger]);

  useEffect(() => {
    return history.listen((location, action) => {
      // Remove notifications after replace to private routes
      if ([PUSH_ACTION, REPLACE].includes(action)) {
        clear();
      }
    });
  }, [history]);

  return (
    <Main>
      {notifications}
      <Switch>
        <Route path={routes.setupProfile} component={SetupProfile} />
        <Route
          path={routes.dateOfBirthVerification}
          component={DateOfBirthVerification}
        />
        <Route path={routes.confirmation} component={Confirmation} />
        <Route path={routes.profile} component={Profile} />
        <Route path={routes.securitySettings} component={SecuritySettings} />
        <Route path={routes.home} component={Home} />
        <Route path={routes.contactDetails} component={ContactDetails} />
        <Route
          path={routes.personalInformation}
          component={PersonalInformation}
        />
        <Route
          path={`${routes.displayVideo}/:videoType`}
          component={VideoPage}
        />
        <Route path={routes.updateEmail} component={UpdateEmail} />
        <Route path={routes.resendUpdateEmail} component={ResendUpdateEmail} />
        <Route path={routes.editMfa} component={EditMfa} />
        <Route path={routes.changePassword} component={ChangePassword} />
        <Route path={routes.test} component={TestResultPage} />
        <Route path={routes.sampleDraw} component={SampleDraw} />
        <Route path={routes.simpleOrder} component={SimpleOrder} />
        <Route path={routes.testHistory} component={TestHistory} />
        <Route path={routes.billing} component={BillingCenter} />
        <Route
          path={routes.scheduleAGeneticInformationSession}
          component={ScheduleAGeneticInformationPage}
        />
        <Route
          path={routes.communicationPreferences}
          component={CommunicationPreferences}
        />
        <Route path={routes.billingUpdates} component={BillingUpdates} />
        <Route path={routes.billingEstimate} component={BillingEstimate} />
        <Route path={routes.outOfNetwork} component={OutOfNetwork} />
        <Route path={routes.nonCoveredService} component={NonCoveredService} />
        <Route
          path={routes.priorAuthorization}
          component={PriorAuthorization}
        />
        <Route path={routes.guestLogin} component={GuestLogin} />
        <Route component={UpdateEmailError} path={routes.updateEmailError} />
        <Route
          render={() => <Redirect to={routes.home + history.location.search} />}
        />
      </Switch>
    </Main>
  );
};

export default PrivateRouting;
