import React, { FC, useContext, useMemo, useState } from "react";
import R from "ramda";
import {
  ApolloError,
  isApolloError,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import DrawRequestService from "@app/service/drawRequest";
import {
  ParsedTestCard,
  TestCardStatus,
  TestType,
} from "@app/provider/testData/types";
import {
  Clinic,
  DrawRequest,
  HEAP_EVENTS,
  MobilePhlebotomyStatus,
  ShippingAddress,
} from "@app/provider/types";
import {
  HeapAnalyticDataContext,
  IntlContext,
  NotificationContext,
  TestCardContext,
  UppAuthContext,
} from "@app/provider";
import {
  capitalizeFirstLetter,
  getNameTimezones,
  snakeCaseToCapitalizedWords,
} from "@app/utils";
import { useDialog } from "@natera/platform/lib/hooks";
import {
  ApologiesModal,
  SuccessModal,
} from "@app/components/sampleDraw/dialogs";
import { TestDetailsContext } from "./testData";
import {
  CancelAppointmentDialog,
  CancelAppointmentDialogFail,
  CancelAppointmentDialogSuccess,
} from "@app/components";
import { ProfileNonAuth } from "./profile/types";
import { businessUnitMapper } from "@app/utils/businessUnitMapper";
import { getDrawRequestWithBrowserTimezone } from "./testData/utils/getTestDetailsWithBrowserTimezone";

export enum BusinessUnits {
  ORGAN_HEALTH = "ORGAN_HEALTH",
  ONCOLOGY = "ONCOLOGY",
  WOMENS_HEALTH = "WOMENS_HEALTH",
}

export enum DrawRequestFlow {
  GUEST = "GUEST",
  ACCOUNT = "ACCOUNT",
  PUBLIC_MP = "PUBLIC_MP",
}

export const comboOrderTestTypes = [
  TestType.HORIZON,
  TestType.PANORAMA,
  TestType.VISTARA,
  TestType.ALTERA,
  TestType.EMPOWER,
  TestType.SIGNATERA,
];

export const editableMpStatuses = [
  MobilePhlebotomyStatus.DRAFTED,
  MobilePhlebotomyStatus.AVAILABLE,
];

export type DrawRequestTimeslots = {
  start1: string;
  start2?: string;
  start3?: string;
  end1: string;
  end2?: string;
  end3?: string;
};

export interface DrawRequestDetailsData extends DrawRequestTimeslots {
  shippingAddress: ShippingAddress;
  jobTimezone: string;
  jobStatus: MobilePhlebotomyStatus;
}

export interface DrawRequestCreateInput extends DrawRequestTimeslots {
  shippingAddress: ShippingAddress;
  jobTimezone: string;
  notes?: string;
}

export interface DrawRequestUpdateInput extends DrawRequestTimeslots {
  orderUid: string;
  uid: string;
  jobTimezone: string;
}

export interface SampleDrawResourceResponse {
  success: boolean;
  reqId?: string;
}

export interface CheckPhoneIsRequiredResponse {
  isRequired: boolean;
}

export interface CheckSampleDrawResponse {
  isValid: boolean;
  needsKitShippingStep: boolean;
  skipLabStep: boolean;
}

interface SampleNonAuth {
  orderUid: string;
  testUid: string;
  testType: TestType;
  testTypes: TestType[];
  creationDate: string;
  testCardStatus?: TestCardStatus;
  isOncologyOrder?: boolean;
  lims_clinic_id?: number;
  testName?: string;
}

export interface VerifiedSampleDrawResponse {
  profileInfo: {
    isValid: boolean;
    profile?: ProfileNonAuth;
  };
  businessUnit?: BusinessUnits;
  sampleInfo: {
    isDrawRequested: boolean;
    sample?: SampleNonAuth;
  };
}

export interface MatchPatientNonAuthResponse {
  token?: string;
  skipCommunicationPreferencesStep: boolean;
  code?: string;
}

type CreateDrawRequestProps = {
  timeZone: string;
  shippingAddress: ShippingAddress;
  timeSlots: DrawRequestTimeslots;
  notes?: string;
  testType?: TestType;
  orderUid?: string;
  testUid?: string;
  token?: string;
  isPublicRequest?: boolean;
};

type UpdateDrawRequestProps = Omit<
  CreateDrawRequestProps,
  "notes" | "shippingAddress"
>;

type UpdateDrawRequest = (
  props: UpdateDrawRequestProps
) => Promise<SampleDrawResourceResponse | undefined>;

export type MatchPatientDataNonAuthProps = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phone: string;
  email: string;
  alternativeLastName?: string;
};

export type MatchPatientNonAuthProps = {
  patientNonAuthInfoData: MatchPatientDataNonAuthProps;
  recaptchaValue: string;
};

export interface DrawRequestController {
  isLoading: boolean;
  comboOrders: ParsedTestCard[];

  drawRequestId?: string;
  drawRequestData?: DrawRequest;
  selectedTimeZone?: string;

  createDrawRequestError?: ApolloError;
  createDrawRequestData?: SampleDrawResourceResponse;
  createDrawRequest: (
    props: CreateDrawRequestProps
  ) => Promise<SampleDrawResourceResponse | undefined>;
  getDrawRequestDetails: (
    orderUid: string,
    testUid: string,
    token?: string
  ) => Promise<void>;

  updateDrawRequestError?: ApolloError;
  updateDrawRequestData?: SampleDrawResourceResponse;
  checkPhoneIsRequired: (
    token: string
  ) => Promise<CheckPhoneIsRequiredResponse | undefined>;
  checkPhoneIsRequiredLoading: boolean;
  checkSampleDraw: (
    orderUid: string,
    testType: string,
    token?: string
  ) => Promise<CheckSampleDrawResponse | undefined>;
  verifySampleDrawWithoutLogging: (
    token: string,
    dateOfBirth: string,
    phone?: string
  ) => Promise<VerifiedSampleDrawResponse | undefined>;
  verifiedSampleDrawData: VerifiedSampleDrawResponse | undefined;
  updateDrawRequest: UpdateDrawRequest;
  matchPatientNonAuthRequest: (
    props: MatchPatientNonAuthProps
  ) => Promise<MatchPatientNonAuthResponse | undefined>;
  matchPatientNonAuthRequestData: MatchPatientNonAuthResponse | undefined;
  matchPatientNonAuthRequestError?: ApolloError;

  testCardDetailsData?: {
    drawRequest: DrawRequestDetailsData & { uid: string };
    clinic?: Clinic;
  };

  openCancelAppointmentDialog: () => void;
  openCreateDrawErrorDialog: () => void;
  openUpdateDrawErrorDialog: () => void;
}

export const Context = React.createContext<DrawRequestController>({
  isLoading: false,
  comboOrders: [],
  createDrawRequest: async () => Promise.reject(),
  updateDrawRequest: async () => Promise.reject(),
  getDrawRequestDetails: async () => Promise.reject(),
  checkPhoneIsRequired: async () => Promise.reject(),
  checkPhoneIsRequiredLoading: false,
  checkSampleDraw: async () => Promise.reject(),
  verifySampleDrawWithoutLogging: async () => Promise.reject(),
  verifiedSampleDrawData: undefined,
  testCardDetailsData: undefined,
  openCancelAppointmentDialog: () => undefined,
  openCreateDrawErrorDialog: () => undefined,
  openUpdateDrawErrorDialog: () => undefined,
  matchPatientNonAuthRequest: async () => Promise.reject(),
  matchPatientNonAuthRequestData: undefined,
});

Context.displayName = "DrawRequestContext";

const DrawRequestProvider: FC = ({ children }) => {
  const { currentLanguage } = useContext(IntlContext);
  const [drawRequestId, setDrawRequestId] = useState<string>();
  const [drawRequest, setDrawRequest] = React.useState<
    DrawRequestDetailsData | undefined
  >();
  const { profile } = useContext(UppAuthContext);

  const { drawRequestDataForHeapEventData } = React.useContext(
    HeapAnalyticDataContext
  );

  const [selectedTimeZone, setSelectedTimeZone] = useState<string>();

  const [comboOrders, setComboOrders] = useState<ParsedTestCard[]>([]);

  const apologiesModal = useDialog(ApologiesModal);
  const successModal = useDialog(SuccessModal);
  const cancelAppointmentDialog = useDialog(CancelAppointmentDialog);
  const cancelAppointmentDialogFail = useDialog(CancelAppointmentDialogFail);
  const cancelAppointmentDialogSuccess = useDialog(
    CancelAppointmentDialogSuccess
  );

  const { addNotification } = useContext(NotificationContext);
  const { getOrderUid, getTestUid, getTestDetails, refetchTest } = useContext(
    TestDetailsContext
  );
  const {
    getTestsDataForHomePage,
    isLoading: testCardDataIsLoading,
  } = useContext(TestCardContext);

  const testDetails = getTestDetails();
  const orderUid = getOrderUid();
  const testUid = getTestUid();
  const testType = testDetails?.testType;
  const businessUnit = testDetails?.businessUnit;

  const [
    createDraw,
    {
      loading: createDrawIsLoading,
      error: createDrawError,
      data: createDrawData,
    },
  ] = useMutation<{
    createDrawRequest: SampleDrawResourceResponse;
  }>(DrawRequestService.createDrawRequest());

  const [
    updateDraw,
    {
      loading: updateDrawIsLoading,
      error: updateDrawError,
      data: updateDrawData,
    },
  ] = useMutation<{
    updateDrawRequest: SampleDrawResourceResponse;
  }>(DrawRequestService.updateDrawRequest());

  const [
    getDrawRequestDetails,
    { loading: getDrawRequestDetailsIsLoading, data: testCardDetailsData },
  ] = useLazyQuery<{
    getDrawRequestDetails: {
      drawRequest: DrawRequestDetailsData & { uid: string };
      clinic?: Clinic;
    };
  }>(DrawRequestService.getDrawRequestDetails(), { fetchPolicy: "no-cache" });

  const [
    checkSampleDrawRequestPossibility,
    { loading: checkSampleDrawRequestPossibilityIsLoading },
  ] = useLazyQuery<{
    checkSampleDrawRequestPossibility: CheckSampleDrawResponse;
  }>(DrawRequestService.checkSampleDrawRequestPossibility(), {
    fetchPolicy: "no-cache",
  });

  const [
    checkPhoneIsRequired,
    { loading: checkPhoneIsRequiredLoading },
  ] = useLazyQuery<{
    checkPhoneIsRequired: CheckPhoneIsRequiredResponse;
  }>(DrawRequestService.checkPhoneIsRequired(), {
    fetchPolicy: "no-cache",
  });

  const [
    verifySampleDrawRequestPossibilityNonAuth,
    {
      loading: verifySampleDrawRequestPossibilityNonAuthIsLoading,
      data: verifiedSampleDrawData,
    },
  ] = useLazyQuery<{
    verifySampleDrawRequestPossibilityNonAuth: VerifiedSampleDrawResponse;
  }>(DrawRequestService.verifySampleDrawRequestPossibilityNonAuth(), {
    fetchPolicy: "no-cache",
  });

  const [
    matchPatientNonAuth,
    {
      loading: matchPatientNonAuthIsLoading,
      error: matchPatientNonAuthError,
      data: matchPatientNonAuthData,
    },
  ] = useLazyQuery<{
    matchPatientNonAuth: MatchPatientNonAuthResponse;
  }>(DrawRequestService.matchPatientNonAuth(), {
    fetchPolicy: "no-cache",
  });

  const [cancelDraw, { error: cancelDrawRequestError }] = useMutation<{
    cancelDrawRequest: SampleDrawResourceResponse;
  }>(DrawRequestService.cancelDrawRequest());

  const checkComboOrders = async () => {
    if (testDetails?.isComboOrder) {
      const testCards = await getTestsDataForHomePage();
      const comboOrderTestCards = testCards.filter(
        (item: ParsedTestCard) =>
          item.orderUid === orderUid &&
          comboOrderTestTypes.includes(item.testType)
      );

      if (comboOrderTestCards.length > 1) {
        setComboOrders(comboOrderTestCards);
      }
    }
  };

  React.useEffect(() => {
    handleGetDrawRequestDetails(orderUid, testUid);
    checkComboOrders();
  }, []);

  React.useEffect(() => {
    if (cancelDrawRequestError) {
      openCancelAppointmentDialogFail();
    }
  }, [cancelDrawRequestError]);

  const handleCreateDrawRequest = async ({
    timeZone,
    shippingAddress,
    timeSlots,
    notes,
    testType: testTypeProp,
    orderUid: orderUidProp,
    testUid: testUidProp,
    token,
    isPublicRequest = false,
  }: CreateDrawRequestProps): Promise<
    SampleDrawResourceResponse | undefined
  > => {
    const testType$ = testTypeProp ?? testType;
    const orderUid$ = orderUidProp ?? orderUid;
    const testUid$ = testUidProp ?? testUid;

    if (testType$) {
      const requestData: DrawRequestCreateInput = {
        shippingAddress,
        ...timeSlots,
        jobTimezone: getNameTimezones(timeZone),
      };

      if (notes) {
        requestData.notes = notes;
      }

      const response = await createDraw({
        variables: {
          orderUid: orderUid$,
          testUid: testUid$,
          requestData,
          token,
          isPublicRequest,
        },
      });

      if (response.data?.createDrawRequest) {
        setSelectedTimeZone(timeZone);
        await handleGetDrawRequestDetails(orderUid$, testUid$, token);
        !isPublicRequest && (await openSuccessModal(false));
      }

      return response.data?.createDrawRequest;
    }
  };

  const handleUpdateDrawRequest: UpdateDrawRequest = async ({
    timeZone,
    timeSlots,
    testType: testTypeProp,
    orderUid: orderUidProp,
    testUid: testUidProp,
    token,
  }): Promise<SampleDrawResourceResponse | undefined> => {
    const testType$ = testTypeProp ?? testType;
    const orderUid$ = orderUidProp ?? orderUid;
    const testUid$ = testUidProp ?? testUid;

    if (drawRequestId && testType$) {
      const requestData: DrawRequestUpdateInput = {
        orderUid: orderUid$,
        uid: drawRequestId,
        ...timeSlots,
        jobTimezone: getNameTimezones(timeZone),
      };

      const response = await updateDraw({
        variables: {
          testUid: testUid$,
          requestData,
          token,
        },
      });

      if (response.data?.updateDrawRequest.success) {
        setSelectedTimeZone(timeZone);
        await handleGetDrawRequestDetails(orderUid$, testUid$, token);
        await openSuccessModal(true);
      }

      return response.data?.updateDrawRequest;
    }
  };

  const handleCheckSampleDraw = async (
    orderUid: string,
    testUid: string,
    token?: string
  ): Promise<CheckSampleDrawResponse | undefined> => {
    const testData = {
      orderUid,
      testUid,
    };
    try {
      const response = await checkSampleDrawRequestPossibility({
        variables: {
          testData,
          token,
        },
      });
      if (response.error) {
        throw response.error;
      }
      return response.data?.checkSampleDrawRequestPossibility;
    } catch (error) {
      if (isApolloError(error) && error.graphQLErrors?.length > 0) {
        const errors = error.graphQLErrors.map((e) => e.extensions?.exception);
        throw errors[0];
      } else {
        addNotification({
          type: "error",
        });
      }
    }
  };

  const handleCheckPhoneIsRequired = async (
    token: string
  ): Promise<CheckPhoneIsRequiredResponse | undefined> => {
    const response = await checkPhoneIsRequired({
      variables: {
        token,
      },
    });
    if (response.error) {
      throw response.error;
    }
    return response.data?.checkPhoneIsRequired;
  };

  const handleVerifySampleDrawWithoutLogging = async (
    token: string,
    dateOfBirth: string,
    phone?: string
  ): Promise<VerifiedSampleDrawResponse | undefined> => {
    try {
      const response = await verifySampleDrawRequestPossibilityNonAuth({
        variables: {
          token,
          dateOfBirth,
          phone,
        },
      });

      if (response.error) {
        throw response.error;
      }

      return response.data?.verifySampleDrawRequestPossibilityNonAuth;
    } catch (error) {
      if (isApolloError(error) && error.graphQLErrors?.length > 0) {
        const errors = error.graphQLErrors.map((e) => e.extensions?.exception);
        throw errors[0];
      } else {
        addNotification({
          type: "error",
        });
      }
    }
  };

  const handleMatchPatientNonAuth = async (
    props: MatchPatientNonAuthProps
  ): Promise<MatchPatientNonAuthResponse | undefined> => {
    try {
      const { patientNonAuthInfoData, recaptchaValue } = props;

      const response = await matchPatientNonAuth({
        variables: {
          patientNonAuthInfoData,
          recaptchaValue,
        },
      });

      if (response.error) {
        throw response.error;
      }

      return response.data?.matchPatientNonAuth;
    } catch (error) {
      if (isApolloError(error) && error.graphQLErrors?.length > 0) {
        const errors = error.graphQLErrors.map((e) => e.extensions?.exception);
        throw errors[0];
      } else {
        addNotification({
          type: "error",
        });
      }
    }
  };

  const handleGetDrawRequestDetails = async (
    orderUid: string,
    testUid: string,
    token?: string
  ): Promise<void> => {
    const response = await getDrawRequestDetails({
      variables: {
        orderUid,
        testUid,
        token,
      },
    });

    if (response.data?.getDrawRequestDetails.drawRequest) {
      setDrawRequestId(response.data?.getDrawRequestDetails.drawRequest.uid);
      setDrawRequest(response.data?.getDrawRequestDetails.drawRequest);
    }
  };

  const openSuccessModal = async (isAfterModify: boolean): Promise<void> =>
    new Promise<void>((resolve) => {
      successModal.open({
        isAfterModify,
        handleCloseDialog: async () => {
          successModal.close();
          resolve();
        },
      });
    });

  const openCreateDrawErrorDialog = () => {
    apologiesModal.open({
      isModify: false,
      onClose: apologiesModal.close,
      isGuest: !profile,
    });
  };

  const openUpdateDrawErrorDialog = () => {
    apologiesModal.open({
      isModify: true,
      onClose: apologiesModal.close,
      isGuest: !profile,
    });
    heap.track(HEAP_EVENTS.upp_sampledraw_mp_modifyappt_failure, {
      business_unit: drawRequestDataForHeapEventData?.business_unit,
      test_name: drawRequestDataForHeapEventData?.test_name,
      lims_clinic_id: drawRequestDataForHeapEventData?.lims_clinic_id,
      flow: profile ? "account" : "guest",
    });
  };

  const openCancelAppointmentDialog = React.useCallback(() => {
    cancelAppointmentDialog.open({
      onClickCancel: handleCancelSampleDrawRequest,
      onClose: cancelAppointmentDialog.close,
    });
  }, [testDetails, currentLanguage]);

  const openCancelAppointmentDialogFail = () => {
    cancelAppointmentDialogFail.open({
      onClose: cancelAppointmentDialogFail.close,
      businessUnit,
    });
    heap.track(HEAP_EVENTS.upp_sampledraw_mp_cancelappt_failure, {
      business_unit: drawRequestDataForHeapEventData?.business_unit,
      test_name: drawRequestDataForHeapEventData?.test_name,
      lims_clinic_id: drawRequestDataForHeapEventData?.lims_clinic_id,
      flow: profile ? "account" : "guest",
    });
  };

  const openCancelAppointmentDialogSuccess = () => {
    cancelAppointmentDialogSuccess.open({
      onClose: cancelAppointmentDialogSuccess.close,
    });
  };

  const handleCancelSampleDrawRequest = React.useCallback(async (): Promise<
    void
  > => {
    const isCancelableStatus = R.includes(
      testDetails?.drawRequest?.jobStatus,
      editableMpStatuses
    );
    const isAvailableToCancelMP =
      testDetails &&
      testDetails.status === TestCardStatus.APPT_REQUESTED &&
      isCancelableStatus;

    if (isAvailableToCancelMP) {
      const response = await cancelDraw({
        variables: {
          orderUid,
          testUid,
          requestUid: testDetails.drawRequest?.uid,
        },
      });

      await refetchTest();

      const cancelDrawRequestResult = response.data?.cancelDrawRequest;

      if (cancelDrawRequestResult?.success) {
        setDrawRequestId(undefined);
        setDrawRequest(undefined);
        openCancelAppointmentDialogSuccess();
      } else {
        openCancelAppointmentDialogFail();
      }
    }
  }, [testDetails]);

  const isLoading = useMemo(
    () =>
      testCardDataIsLoading ||
      createDrawIsLoading ||
      updateDrawIsLoading ||
      getDrawRequestDetailsIsLoading ||
      checkSampleDrawRequestPossibilityIsLoading ||
      verifySampleDrawRequestPossibilityNonAuthIsLoading ||
      matchPatientNonAuthIsLoading,
    [
      testCardDataIsLoading,
      createDrawIsLoading,
      updateDrawIsLoading,
      getDrawRequestDetailsIsLoading,
      checkSampleDrawRequestPossibilityIsLoading,
      verifySampleDrawRequestPossibilityNonAuthIsLoading,
      matchPatientNonAuthIsLoading,
    ]
  );

  const drawRequestController: DrawRequestController = useMemo(
    () => ({
      isLoading,
      comboOrders,
      drawRequestId,
      drawRequestData: getDrawRequestWithBrowserTimezone(drawRequest),
      selectedTimeZone,
      createDrawRequestError: createDrawError,
      createDrawRequestData: createDrawData?.createDrawRequest,
      createDrawRequest: handleCreateDrawRequest,
      getDrawRequestDetails: handleGetDrawRequestDetails,
      checkPhoneIsRequired: handleCheckPhoneIsRequired,
      checkPhoneIsRequiredLoading,
      checkSampleDraw: handleCheckSampleDraw,
      verifySampleDrawWithoutLogging: handleVerifySampleDrawWithoutLogging,
      verifiedSampleDrawData:
        verifiedSampleDrawData?.verifySampleDrawRequestPossibilityNonAuth,
      updateDrawRequestError: updateDrawError,
      updateDrawRequestData: updateDrawData?.updateDrawRequest,
      updateDrawRequest: handleUpdateDrawRequest,
      testCardDetailsData: testCardDetailsData?.getDrawRequestDetails,
      openCancelAppointmentDialog,
      openCreateDrawErrorDialog,
      openUpdateDrawErrorDialog,
      matchPatientNonAuthRequest: handleMatchPatientNonAuth,
      matchPatientNonAuthRequestError: matchPatientNonAuthError,
      matchPatientNonAuthRequestData:
        matchPatientNonAuthData?.matchPatientNonAuth,
    }),
    [
      isLoading,
      comboOrders,
      drawRequestId,
      drawRequest,
      selectedTimeZone,
      createDrawError,
      createDrawData?.createDrawRequest,
      handleCreateDrawRequest,
      handleCheckPhoneIsRequired,
      checkPhoneIsRequiredLoading,
      handleCheckSampleDraw,
      handleVerifySampleDrawWithoutLogging,
      verifiedSampleDrawData,
      getDrawRequestDetailsIsLoading,
      updateDrawError,
      updateDrawData?.updateDrawRequest,
      handleUpdateDrawRequest,
      testCardDetailsData?.getDrawRequestDetails,
      openCancelAppointmentDialog,
      openCreateDrawErrorDialog,
      openUpdateDrawErrorDialog,
      handleGetDrawRequestDetails,
      handleMatchPatientNonAuth,
      matchPatientNonAuthError,
      matchPatientNonAuthData?.matchPatientNonAuth,
    ]
  );

  return (
    <Context.Provider value={drawRequestController}>
      {apologiesModal.getDialog()}
      {successModal.getDialog()}
      {cancelAppointmentDialog.getDialog()}
      {cancelAppointmentDialogFail.getDialog()}
      {cancelAppointmentDialogSuccess.getDialog()}
      {children}
    </Context.Provider>
  );
};

export default DrawRequestProvider;
