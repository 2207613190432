import { DocumentNode, gql } from "@apollo/client";

class PreBillService {
  public static getPreBillAccessToken = (): DocumentNode => gql`
    query GetPreBillAccessToken {
      getPreBillAccessToken {
        accessToken
      }
    }
  `;
}

export default PreBillService;
