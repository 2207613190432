import React, { FC, useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button, RadioButton, RadioGroup } from "@natera/form";
import { DrawRequestScreens } from "../drawRequest";
import { useDialog } from "@natera/platform/lib/hooks";
import OrganHealthSchedulingDialog from "@app/components/organHealthSchedulingDialog/organHealthSchedulingDialog";
import { ConfigContext } from "@app/provider";

import "./signedOrderFormScreen.scss";

const messages = defineMessages({
  signedOrderFormScreenBUSelectionTitle: {
    id: "signedOrderFormScreenBUSelectionTitle",
    defaultMessage:
      "Great! Please select your test(s). Which keyword best describe your test type?",
  },
  signedOrderFormScreenBUSelectionOptionWHTitle: {
    id: "signedOrderFormScreenBUSelectionOptionWHTitle",
    defaultMessage: "Women's Health",
  },
  signedOrderFormScreenBUSelectionOptionWHDesc: {
    id: "signedOrderFormScreenBUSelectionOptionWHDesc",
    defaultMessage:
      "Panorama (NIPT), Horizon (carrier screening), Empower (hereditary cancer screening ordered through your OBGYN), and other reproductive health tests.",
  },
  signedOrderFormScreenBUSelectionOptionOHTitle: {
    id: "signedOrderFormScreenBUSelectionOptionOHTitle",
    defaultMessage: "Organ Health",
  },
  signedOrderFormScreenBUSelectionOptionOHDesc: {
    id: "signedOrderFormScreenBUSelectionOptionOHDesc",
    defaultMessage:
      "Renasight (kidney gene panel), or Prospera (transplant assessment).",
  },
  signedOrderFormScreenBUSelectionOptionOncologyTitle: {
    id: "signedOrderFormScreenBUSelectionOptionOncologyTitle",
    defaultMessage: "Oncology",
  },
  signedOrderFormScreenBUSelectionOptionOncologyDesc: {
    id: "signedOrderFormScreenBUSelectionOptionOncologyDesc",
    defaultMessage:
      "Signatera (circulating tumor DNA test), Altera (tumor genetic profiling), or Empower (hereditary cancer screening ordered by a medical oncologist).",
  },
  signedOrderFormScreenBUSelectionButtonNext: {
    id: "signedOrderFormScreenBUSelectionButtonNext",
    defaultMessage: "Next",
  },
  signedOrderFormScreenBUSelectionButtonBack: {
    id: "signedOrderFormScreenBUSelectionButtonBack",
    defaultMessage: "Back",
  },
});

type SignedOrderFormProps = {
  setScheduleScreen: React.Dispatch<React.SetStateAction<DrawRequestScreens>>;
};

export enum BusinessUnitSelection {
  WH = "WH",
  OH = "OH",
  ONCOLOGY = "ONCOLOGY",
}

const SignedOrderFormScreen: FC<SignedOrderFormProps> = ({
  setScheduleScreen,
}) => {
  const intl = useIntl();
  const { config } = useContext(ConfigContext);

  const organHealthSchedulingDialog = useDialog(OrganHealthSchedulingDialog);

  const [businessUnitSelected, setBusinessUnitSelected] = React.useState<
    BusinessUnitSelection | undefined
  >(undefined);

  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(true);

  const handleSelectionChange = (includeFetalSex: BusinessUnitSelection) => {
    setIsNextButtonDisabled(false);
    setBusinessUnitSelected(includeFetalSex);
  };

  const handleNextClick = () => {
    let url;

    switch (businessUnitSelected) {
      case BusinessUnitSelection.OH:
        organHealthSchedulingDialog.open({
          onCloseDialog: () => organHealthSchedulingDialog.close(),
        });
        return;
      case BusinessUnitSelection.WH:
        url = config.links.WOMENS_HEALTH_DRAW_REQUEST;
        break;
      case BusinessUnitSelection.ONCOLOGY:
        url = config.links.ONCOLOGY_DRAW_REQUEST;
        break;
      default:
        return;
    }

    window.open(url, "_blank");
  };

  const handleBack = () => {
    setScheduleScreen(DrawRequestScreens.NO_MATCH_ORDER_SCREEN);
  };

  return (
    <>
      {organHealthSchedulingDialog.getDialog()}
      <div className="signed-order-form-screen">
        <div className="signed-order-form-screen__content">
          <div className="signed-order-form-screen-title__container">
            <h2 className="signed-order-form-screen-title">
              {intl.formatMessage(
                messages.signedOrderFormScreenBUSelectionTitle
              )}
            </h2>
          </div>

          <div className="signed-order-form-list">
            <RadioGroup
              name="buSelectionList"
              value={businessUnitSelected}
              onChange={handleSelectionChange}
            >
              <RadioButton
                className="signed-order-form-item"
                label={
                  <>
                    <div className="signed-order-form-item-title">
                      {intl.formatMessage(
                        messages.signedOrderFormScreenBUSelectionOptionWHTitle
                      )}
                    </div>
                    <div className="signed-order-form-item-desc">
                      {intl.formatMessage(
                        messages.signedOrderFormScreenBUSelectionOptionWHDesc
                      )}
                    </div>
                  </>
                }
                value={BusinessUnitSelection.WH}
              />
              <RadioButton
                className="signed-order-form-item"
                label={
                  <>
                    <div className="signed-order-form-item-title">
                      {intl.formatMessage(
                        messages.signedOrderFormScreenBUSelectionOptionOHTitle
                      )}
                    </div>
                    <div className="signed-order-form-item-desc">
                      {intl.formatMessage(
                        messages.signedOrderFormScreenBUSelectionOptionOHDesc
                      )}
                    </div>
                  </>
                }
                value={BusinessUnitSelection.OH}
              />
              <RadioButton
                className="signed-order-form-item"
                label={
                  <>
                    <div className="signed-order-form-item-title">
                      {intl.formatMessage(
                        messages.signedOrderFormScreenBUSelectionOptionOncologyTitle
                      )}
                    </div>
                    <div className="signed-order-form-item-desc">
                      {intl.formatMessage(
                        messages.signedOrderFormScreenBUSelectionOptionOncologyDesc
                      )}
                    </div>
                  </>
                }
                value={BusinessUnitSelection.ONCOLOGY}
              />
            </RadioGroup>
          </div>

          <div className="action-buttons">
            <Button
              raised
              disabled={isNextButtonDisabled}
              onClick={handleNextClick}
            >
              {intl.formatMessage(
                messages.signedOrderFormScreenBUSelectionButtonNext
              )}
            </Button>

            <Button outlined onClick={handleBack}>
              {intl.formatMessage(
                messages.signedOrderFormScreenBUSelectionButtonBack
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignedOrderFormScreen;
