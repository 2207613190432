import React from "react";
import { Textfield } from "@natera/form";
import CalendarIcon from "@assets/svg/icons/calendar.svg";
import classNames from "classnames";
import {
  DateRange as RangeModifier,
  DayRangePickerWrapper,
} from "@natera/material/lib/date";
import { defineMessages } from "react-intl";
import Svg from "@natera/material/lib/svg";
import { handleKeyDownByMenu } from "@app/utils/handleKeyDown";
import { weekDayFormatter } from "@app/utils";

// overrides @natera/material/lib/date Range picker component translations
const messages = defineMessages({
  materialInputMaskPlaceholder: {
    id: "material.inputMask.placeholder",
    defaultMessage: "MM/DD/YYYY",
  },
  materialRangePlaceholder: {
    id: "material.range.placeholder",
    defaultMessage: "MM/DD/YYYY - MM/DD/YYYY",
  },
  materialRangeFrom: {
    id: "material.range.from",
    defaultMessage: "From",
  },
  materialRangeTo: {
    id: "material.range.to",
    defaultMessage: "To",
  },
  materialRangeClear: {
    id: "material.range.clear",
    defaultMessage: "Clear",
  },
  materialRangeCancel: {
    id: "material.range.cancel",
    defaultMessage: "Cancel",
  },
  materialRangeOk: {
    id: "material.range.ok",
    defaultMessage: "Apply",
  },
  materialRangePreviousMonth: {
    id: "material.date.previousMonth",
    defaultMessage: "Previous month",
  },
  materialRangeNextMonth: {
    id: "material.date.nextMonth",
    defaultMessage: "Next month",
  },
});

interface Props {
  name: string;
  value?: string;
  onSelectRange: (date$: RangeModifier) => void;
}

const DayRangePicker: React.FunctionComponent<Props> = ({
  name,
  value,
  onSelectRange,
}) => {
  return (
    <DayRangePickerWrapper
      maxDate={new Date()}
      onSelectRange={onSelectRange}
      formatWeekdayName={weekDayFormatter}
      floating
    >
      {(menu) => (
        <>
          <Textfield
            name={name}
            outline
            trailingIcon={
              <Svg onClick={menu.toggleMenu} content={CalendarIcon} />
            }
            onClick={menu.toggleMenu}
            onKeyDown={handleKeyDownByMenu(menu)}
            value={value}
            readOnly
            fullWidth
          />
          <div
            className={classNames("overlay", {
              "overlay--disabled": !menu.isOpen(),
            })}
          />
        </>
      )}
    </DayRangePickerWrapper>
  );
};

export default DayRangePicker;
