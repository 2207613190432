import { StepperContent } from "@app/components/ui/layout/stepperContent";
import React, { useContext, useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { StepperContext } from "@natera/stepper";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import { ExpansionPanel } from "@natera/platform/lib/components/expansionPanel";
import Svg from "@natera/material/lib/svg";
import { Button } from "@natera/form";
import Notification from "@app/components/notification";
import InsurancaProcessIcon from "@app/preBill/assets/svg/insurance-process.svg";
import CalculationEN from "@app/preBill/assets/images/Calculation-en.png";
import CalculationES from "@app/preBill/assets/images/Calculation-es.png";
import "./billingJourneyInformation.scss";
import { IntlContext, Language, PreBillAPIContext } from "@app/provider";
import { getTestName } from "@app/preBill/utils/getTestName";

const messages = defineMessages({
  billingJourneyInformationTitle: {
    id: "billingJourneyInformationTitle",
    defaultMessage:
      "Hi {firstName}, you’re about to review your {testName} cost estimate.",
  },
  billingJourneyInformationDescription: {
    id: "billingJourneyInformationDescription",
    defaultMessage:
      "Please take a few moments to review the billing process at Natera.",
  },
  billingJourneyInformationCalculateTitle: {
    id: "billingJourneyInformationCalculateTitle",
    defaultMessage: "How we calculate the estimated out-of-pocket cost.",
  },
  billingJourneyInformationCalculateImage: {
    id: "billingJourneyInformationCalculateImage",
    defaultMessage: "How we calculate the estimated out-of-pocket cost.",
  },
  billingJourneyInformation: {
    id: "billingJourneyInformationCalculateImage",
    defaultMessage: "How we calculate the estimated out-of-pocket cost.",
  },
  billingJourneyInformationHealthCareQ1Label: {
    id: "billingJourneyInformationHealthCareQ1Label",
    defaultMessage: "What is an out-of-pocket cost?",
  },
  billingJourneyInformationHealthCareQ1Content: {
    id: "billingJourneyInformationHealthCareQ1Content",
    defaultMessage:
      "Your expenses for medical care that aren't reimbursed by insurance. Patient responsibility includes deductibles, coinsurance, and copayments for covered services plus all costs for services that aren't covered.",
  },
  billingJourneyInformationHealthCareQ2Label: {
    id: "billingJourneyInformationHealthCareQ2Label",
    defaultMessage: "What is co-pay?",
  },
  billingJourneyInformationHealthCareQ2Content: {
    id: "billingJourneyInformationHealthCareQ2Content",
    defaultMessage:
      "A fixed amount ($20, for example) you pay for a covered health care service after you've paid your deductible. Let's say your health insurance plan's allowable cost for a doctor's office visit is $100. Your copayment for a doctor visit is $20.",
  },
  billingJourneyInformationHealthCareQ2ContentListItem1: {
    id: "billingJourneyInformationHealthCareQ2ContentListItem1",
    defaultMessage:
      "If you've paid your deductible: You pay $20, usually at the time of the visit.",
  },
  billingJourneyInformationHealthCareQ2ContentListItem2: {
    id: "billingJourneyInformationHealthCareQ2ContentListItem2",
    defaultMessage:
      "If you haven't met your deductible: You pay $100, the full allowable amount for the visit.",
  },
  billingJourneyInformationHealthCareQ3Label: {
    id: "billingJourneyInformationHealthCareQ3Label",
    defaultMessage: "What is remaining deductible?",
  },
  billingJourneyInformationHealthCareQ3Content: {
    id: "billingJourneyInformationHealthCareQ3Content",
    defaultMessage:
      "The amount you pay for covered health care services before your insurance plan starts to pay. With a $2,000 deductible, for example, you pay the first $2,000 of covered services yourself. After you pay your deductible, you usually pay only a copayment or coinsurance for covered services. Your insurance company pays the rest.",
  },
  billingJourneyInformationHealthCareQ4Label: {
    id: "billingJourneyInformationHealthCareQ4Label",
    defaultMessage: "What is co-insurance?",
  },
  billingJourneyInformationHealthCareQ4Content: {
    id: "billingJourneyInformationHealthCareQ4Content",
    defaultMessage:
      "The percentage of costs of a covered health care service you pay (20%, for example) after you've paid your deductible.",
  },
  billingJourneyInformationTipTitle: {
    id: "billingJourneyInformationTipTitle",
    defaultMessage: "Key tip for deductibles ✨",
  },
  billingJourneyInformationTipContent: {
    id: "billingJourneyInformationTipContent",
    defaultMessage:
      "It’s important to note that most individuals will meet their annual deductible if they’re receiving significant medical care that year (such as giving birth or managing chronic conditions), and the cost of the Natera test may apply towards the deductible. Once your deductible is met, you will only be responsible for co-insurance or co-pays. ",
  },
  billingJourneyInformationInsuranceProcessTitle: {
    id: "billingJourneyInformationInsuranceProcessTitle",
    defaultMessage: "Insurance process",
  },
  billingJourneyInformationInsuranceProcessDescription: {
    id: "billingJourneyInformationInsuranceProcessDescription",
    defaultMessage: "Here's how the insurance billing process works:",
  },
  billingJourneyInformationInsuranceProcessStepTitle: {
    id: "billingJourneyInformationInsuranceProcessStepTitle",
    defaultMessage: "Step {stepNumber}:",
  },
  billingJourneyInformationInsuranceProcessStepDescription1: {
    id: "billingJourneyInformationInsuranceProcessStepDescription1",
    defaultMessage:
      "Natera will submit your claim. If your claim is denied, we may appeal.",
  },
  billingJourneyInformationInsuranceProcessStepDescription2: {
    id: "billingJourneyInformationInsuranceProcessStepDescription2",
    defaultMessage:
      "You will receive an Explanation of Benefits (EOB) from your insurance plan.",
  },
  billingJourneyInformationInsuranceProcessStepDescription3: {
    id: "billingJourneyInformationInsuranceProcessStepDescription3",
    defaultMessage:
      "We will send you the final bill once the claim is finalized, which may be several months after the test is completed. We ask you to pay within 21 days and you'll have financial assistance options available.",
  },
  billingJourneyInformationInsuranceProcessStepNotificationTitle: {
    id: "billingJourneyInformationInsuranceProcessStepNotificationTitle",
    defaultMessage: "An EOB is not a bill",
  },
  billingJourneyInformationInsuranceProcessStepNotificationDescription: {
    id: "billingJourneyInformationInsuranceProcessStepNotificationDescription",
    defaultMessage:
      "It provides a breakdown of covered services you received. This will reflect our list price, which is often different than what you will owe. The EOB will likely not reflect what you'll pay Natera.",
  },
  billingJourneyInformationInsuranceProcessViewEstimate: {
    id: "billingJourneyInformationInsuranceProcessViewEstimate",
    defaultMessage: "View estimate",
  },
});

interface QuestionItem {
  label: string;
  content: string;
  listItems?: string[];
}
interface QuestionSectionProps {
  questionList: QuestionItem[];
}

const QuestionSection: React.FC<QuestionSectionProps> = ({ questionList }) => {
  return (
    <div className="billing-journey-information__questions">
      {questionList.map((question, index) => (
        <ExpansionPanel
          key={index}
          label={
            <div className="billing-journey-information__questions__label">
              {question.label}
            </div>
          }
        >
          <p className="billing-journey-information__questions__content">
            {question.content}
            {question.listItems && (
              <ul>
                {question.listItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
          </p>
        </ExpansionPanel>
      ))}
    </div>
  );
};

const TipSection = () => {
  const intl = useIntl();

  return (
    <div className="billing-journey-information__tip-section">
      <div className="billing-journey-information__tip-section__container">
        <h3 className="billing-journey-information__tip-section__title">
          {intl.formatMessage(messages.billingJourneyInformationTipTitle)}
        </h3>
        <p className="billing-journey-information__tip-section__content">
          {intl.formatMessage(messages.billingJourneyInformationTipContent)}
        </p>
      </div>
    </div>
  );
};

interface InsuranceProcessStepProps {
  stepNumber: number;
  description: string;
  notification?: { title: string; description: string };
}

const InsuranceProcessStep: React.FC<InsuranceProcessStepProps> = ({
  stepNumber,
  description,
  notification,
}) => {
  const intl = useIntl();

  return (
    <div className="insurance-process-section__step">
      <span className="insurance-process-section__step__header">
        {intl.formatMessage(
          messages.billingJourneyInformationInsuranceProcessStepTitle,
          {
            stepNumber,
          }
        )}
      </span>
      <span className="insurance-process-section__step__content">
        {description}
      </span>
      {notification && (
        <Notification type="info" titleElem={notification.title}>
          {notification.description}
        </Notification>
      )}
    </div>
  );
};

const InsuranceProcessSection: React.FC = () => {
  const intl = useIntl();

  const steps = [
    {
      description: intl.formatMessage(
        messages.billingJourneyInformationInsuranceProcessStepDescription1
      ),
    },
    {
      description: intl.formatMessage(
        messages.billingJourneyInformationInsuranceProcessStepDescription2
      ),
      notification: {
        title: intl.formatMessage(
          messages.billingJourneyInformationInsuranceProcessStepNotificationTitle
        ),
        description: intl.formatMessage(
          messages.billingJourneyInformationInsuranceProcessStepNotificationDescription
        ),
      },
    },
    {
      description: intl.formatMessage(
        messages.billingJourneyInformationInsuranceProcessStepDescription3
      ),
    },
  ];

  return (
    <div className="insurance-process-section">
      <h3 className="insurance-process-section__title">
        {intl.formatMessage(
          messages.billingJourneyInformationInsuranceProcessTitle
        )}
      </h3>
      <div className="insurance-process-section__icon-container">
        <Svg content={InsurancaProcessIcon} />
      </div>

      <p className="insurance-process-section__description">
        {intl.formatMessage(
          messages.billingJourneyInformationInsuranceProcessDescription
        )}
      </p>

      {steps.map((step, index) => (
        <InsuranceProcessStep
          key={index}
          stepNumber={index + 1}
          description={step.description}
          notification={step.notification}
        />
      ))}
    </div>
  );
};

const BillingJourneyInformation: React.FC = () => {
  const intl = useIntl();
  const { currentLanguage } = useContext(IntlContext);
  const { resolve } = React.useContext(StepperContext);
  const {
    apiProviderState: { orderEstimate },
  } = useContext(PreBillAPIContext);
  const firstName = orderEstimate?.patient?.firstName;
  const [testName, setTestName] = useState<string>("");

  useEffect(() => {
    setTestName(getTestName(orderEstimate?.testType, intl));
  }, [orderEstimate?.testType, intl]);

  const handleSubmit = async () => {
    resolve({ text: `Step 1 Resolved` });
  };

  const questionList: QuestionItem[] = [
    {
      label: intl.formatMessage(
        messages.billingJourneyInformationHealthCareQ1Label
      ),
      content: intl.formatMessage(
        messages.billingJourneyInformationHealthCareQ1Content
      ),
    },
    {
      label: intl.formatMessage(
        messages.billingJourneyInformationHealthCareQ2Label
      ),
      content: intl.formatMessage(
        messages.billingJourneyInformationHealthCareQ2Content
      ),
      listItems: [
        intl.formatMessage(
          messages.billingJourneyInformationHealthCareQ2ContentListItem1
        ),
        intl.formatMessage(
          messages.billingJourneyInformationHealthCareQ2ContentListItem2
        ),
      ],
    },
    {
      label: intl.formatMessage(
        messages.billingJourneyInformationHealthCareQ3Label
      ),
      content: intl.formatMessage(
        messages.billingJourneyInformationHealthCareQ3Content
      ),
    },
    {
      label: intl.formatMessage(
        messages.billingJourneyInformationHealthCareQ4Label
      ),
      content: intl.formatMessage(
        messages.billingJourneyInformationHealthCareQ4Content
      ),
    },
  ];

  return (
    <>
      <StepperHeaderProgress />
      <div className="billing-journey-information">
        <StepperContent
          title={intl.formatMessage(messages.billingJourneyInformationTitle, {
            firstName: firstName,
            testName: testName,
          })}
          description={intl.formatMessage(
            messages.billingJourneyInformationDescription
          )}
        >
          <h3 className="billing-journey-information__calculate-title">
            {intl.formatMessage(
              messages.billingJourneyInformationCalculateTitle
            )}
          </h3>
          <div className="billing-journey-information__calculate-image-box">
            <img
              src={
                currentLanguage === Language.EN ? CalculationEN : CalculationES
              }
              alt={intl.formatMessage(
                messages.billingJourneyInformationCalculateImage
              )}
              className="billing-journey-information__calculate-image"
            />
          </div>
          <QuestionSection questionList={questionList} />
          <TipSection />
          <InsuranceProcessSection />
        </StepperContent>
        <div className="stepper__actions">
          <Button raised onClick={handleSubmit}>
            {intl.formatMessage(
              messages.billingJourneyInformationInsuranceProcessViewEstimate
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default BillingJourneyInformation;
