import React, { useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import {
  NotificationContext,
  TestDetailsContext,
  TestResultContext,
} from "@app/provider";
import { routes } from "@app/routing";
import PdfView from "@app/components/pdf/pdfView";
import { BlueHeaderContent } from "@app/components/ui/layout";

import "./testResult.scss";
import { NevaWrapper } from "@app/neva/nevaWrapper";

const messages = defineMessages({
  testResultResultsDocument: {
    id: "testResultResultsDocument",
    defaultMessage: "Test Results",
  },
  testResultCloseResults: {
    id: "testResultCloseResults",
    defaultMessage: "Close",
  },
  testResultPdfAriaLabel: {
    id: "testResultPdfAriaLabel",
    defaultMessage: "PDF document with test results",
  },
});

const TestResultPage: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { orderUid, testUid, testDocumentUid } = useParams<{
    orderUid: string;
    testUid: string;
    testDocumentUid: string;
  }>();
  const { addNotification } = useContext(NotificationContext);
  const {
    accessTestResults,
    accessTestResultsData,
    isLoading,
    accessTestResultsError,
  } = useContext(TestResultContext);

  const { getTestDetails } = useContext(TestDetailsContext);
  const testDetails = getTestDetails();

  const isResultPdfPage = useMemo(() => {
    return history.location.pathname.includes("result-pdf");
  }, [history.location.pathname]);

  useEffect(() => {
    if (
      orderUid &&
      testDetails?.testType &&
      !isLoading &&
      (!accessTestResultsData || accessTestResultsData.orderUid !== orderUid) &&
      !accessTestResultsError
    ) {
      accessTestResults(
        orderUid,
        testUid,
        testDetails.testType,
        testDocumentUid
      ).catch((error) => {
        if (!isResultPdfPage) {
          addNotification({
            type: "error",
          });
        }
      });
    }
  }, [
    orderUid,
    testDetails?.testType,
    isLoading,
    accessTestResultsData,
    accessTestResultsError,
  ]);

  return (
    <>
      <div className="test-result" data-testid="test-result-pdf-page">
        <BlueHeaderContent
          blueHeaderTitle={intl.formatMessage(
            messages.testResultResultsDocument
          )}
          crossHeaderRoute={routes.testResultDetailsPage(orderUid, testUid)}
          fullScreen
        >
          <PdfView
            url={`/api/1.0/result/${orderUid}/${testUid}/${testDocumentUid}`}
            fileName={"Result.pdf"}
            downloadUrl={`/result/${orderUid}/${testUid}/${testDocumentUid}`}
            pageAriaLabel={intl.formatMessage(messages.testResultPdfAriaLabel)}
          />
        </BlueHeaderContent>
      </div>
      <NevaWrapper hideIfResultViewed={true} />
    </>
  );
};

export default TestResultPage;
