import * as React from "react";
import { NoContentView } from "@app/pages";
import { defineMessages, useIntl } from "react-intl";
import { LinkButton } from "@natera/form";
import { routes } from "@app/routing";
import { Link } from "@natera/navigation";

const messages = defineMessages({
  completeSimpleOrderServiceDisruptionViewTitle: {
    id: "completeSimpleOrderServiceDisruptionViewTitle",
    defaultMessage:
      "We are currently experiencing a service disruption. We apologize for the inconvenience.",
  },
  completeSimpleOrderServiceDisruptionViewText: {
    id: "completeSimpleOrderServiceDisruptionViewText",
    defaultMessage:
      "Please try completing your order again later via your homepage or the link provided in your email.",
  },
  completeSimpleOrderServiceDisruptionViewButton: {
    id: "completeSimpleOrderServiceDisruptionViewButton",
    defaultMessage: "Home",
  },
});

const ServiceDisruptionView: React.FC = () => {
  const intl = useIntl();

  const action = (
    <LinkButton
      to={routes.home}
      renderLink={(props) => <Link {...props} />}
      raised
    >
      {intl.formatMessage(
        messages.completeSimpleOrderServiceDisruptionViewButton
      )}
    </LinkButton>
  );

  return (
    <NoContentView
      title={intl.formatMessage(
        messages.completeSimpleOrderServiceDisruptionViewTitle
      )}
      message={intl.formatMessage(
        messages.completeSimpleOrderServiceDisruptionViewText
      )}
      action={action}
    />
  );
};

export default ServiceDisruptionView;
