import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/form";
import CommonFormActionsView from "../commonFormActionsView";

export const messages = defineMessages({
  commonAddressFormActionsCancelButtonText: {
    id: "commonFormActionsCancelButtonText",
    defaultMessage: "Cancel",
  },
});

interface Props {
  submitButtonText: string;
  submitButtonDisabled?: boolean;
  handleCancel?: () => void;
}

const CommonFormActions: FC<Props> = ({
  submitButtonText,
  submitButtonDisabled,
  handleCancel,
}) => {
  const intl = useIntl();

  return (
    <CommonFormActionsView>
      {handleCancel && (
        <Button
          onClick={handleCancel}
          data-testid="cancel"
          type="reset"
          outlined
        >
          {intl.formatMessage(
            messages.commonAddressFormActionsCancelButtonText
          )}
        </Button>
      )}
      <Button
        type="submit"
        data-testid="submit"
        raised
        disabled={submitButtonDisabled}
      >
        {submitButtonText}
      </Button>
    </CommonFormActionsView>
  );
};

export default CommonFormActions;
