import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Button } from "@natera/form";
import { ConfigContext, TestResultContext } from "@app/provider";
import { openNewWindow } from "@app/utils";
import { ProsperaOrganType, TestType } from "@app/provider/testData/types";

export const messages = defineMessages({
  understandingYourResultsAdditionalInformation: {
    id: "understandingYourResultsAdditionalInformation",
    defaultMessage: "Click below for more information about your test.",
  },
  understandingYourResultsAdditionalInformationButton: {
    id: "understandingYourResultsAdditionalInformationButton",
    defaultMessage: "Additional Information About Test",
  },
  understandingYourResultsProsperaThirdParagraph: {
    id: "understandingYourResultsProsperaThirdParagraph",
    defaultMessage:
      "Your doctor will consider these results along with your other regular lab tests to monitor the health of your transplanted organ.",
  },
});

const UnderstandingYourResultsAdditionalInfo: FC = () => {
  const intl = useIntl();
  const { config } = React.useContext(ConfigContext);
  const { testResult } = React.useContext(TestResultContext);

  const organType = testResult?.organ;
  const testType = testResult?.testType;

  const testTypeIsProspera = testType === TestType.PROSPERA;
  const testTypeIsRenasight = testType === TestType.RENASIGHT;

  const getOrganHealthExternalLink = () => {
    if (testTypeIsRenasight) return config.links.product.RENASIGHT;

    const extLink = {
      [ProsperaOrganType.HEART]: config.links.product.PROSPERA.HEART,
      [ProsperaOrganType.KIDNEY]: config.links.product.PROSPERA.KIDNEY,
      [ProsperaOrganType.LIVER]: config.links.product.PROSPERA.LIVER,
      [ProsperaOrganType.LUNG]: config.links.product.PROSPERA.LUNG,
      [ProsperaOrganType.SPK]: config.links.product.PROSPERA.SPK,
      [ProsperaOrganType.OTHER]: config.links.product.PROSPERA.OTHER,
    };

    return organType ? extLink[organType] : extLink[ProsperaOrganType.OTHER];
  };

  const openExternalLink = () => openNewWindow(getOrganHealthExternalLink());

  const isInfoAvailable = (testType?: TestType) => {
    const showInfoForTests = [TestType.PROSPERA, TestType.RENASIGHT];
    return testType && showInfoForTests.includes(testType);
  };

  return (
    <>
      {testTypeIsProspera && (
        <p>
          {intl.formatMessage(
            messages.understandingYourResultsProsperaThirdParagraph
          )}
        </p>
      )}
      {isInfoAvailable(testType) ? (
        <>
          <p>
            {intl.formatMessage(
              messages.understandingYourResultsAdditionalInformation
            )}
          </p>
          <Button
            className="understanding-your-results__container__action-button"
            outlined
            onClick={openExternalLink}
          >
            {intl.formatMessage(
              messages.understandingYourResultsAdditionalInformationButton
            )}
          </Button>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UnderstandingYourResultsAdditionalInfo;
