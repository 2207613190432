import { useContext } from "react";
import { IntlContext, PreBillContext } from "@app/provider";
import { useGeneratedTokenizedLink } from "@app/hooks";
import { TokenizedLinkAction } from "@app/hooks/useTokenizedLinks";
import {
  GeneratedTokenizedLink,
  PreBillNotificationTemplate,
} from "@app/hooks/useGeneratedTokenizedLink";

const useTokenizedLinkSignup = (): {
  redirectToTokenizedSignup: () => Promise<void>;
  isLoading: boolean;
} => {
  const { currentLanguage } = useContext(IntlContext);
  const { patientUid } = useContext(PreBillContext);
  const { isLoading, getTokenizedLink } = useGeneratedTokenizedLink();

  const redirectToTokenizedSignup = async (): Promise<void> => {
    if (!patientUid) {
      return;
    }

    const tokenizedLinkData: GeneratedTokenizedLink = {
      action: TokenizedLinkAction.TOKENIZED_LINK_SIGN_UP,
      data: {
        patientUid,
        language: currentLanguage,
        notificationType: PreBillNotificationTemplate.PRE_BILL_COMM,
      },
      shortened: false,
    };

    const payload = await getTokenizedLink(tokenizedLinkData);

    if (payload?.link) {
      window.location.href = payload.link;
    }
  };

  return { redirectToTokenizedSignup, isLoading };
};

export default useTokenizedLinkSignup;
