import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { Dialog } from "@natera/material/lib/dialog";
import { Button } from "@natera/form";
import { routes } from "@app/routing";
import { FetalSexContext } from "@app/provider";
import { LoadingContext } from "@natera/platform/lib/components/context";
import { HEAP_EVENTS } from "@app/provider/types";

import "./fetalSexDialog.scss";

const messages = defineMessages({
  fetalSexDialogTitle: {
    id: "fetalSexDialogTitle",
    defaultMessage: "Would you like to learn the fetal sex?",
  },
  fetalSexDialogDesc: {
    id: "fetalSexDialogDesc",
    defaultMessage:
      "Please select whether you would like to include the fetal sex of your baby in your results.",
  },
  fetalSexDialogAccept: {
    id: "fetalSexDialogAccept",
    defaultMessage: "Yes, include fetal sex",
  },
  fetalSexDialogReject: {
    id: "fetalSexDialogReject",
    defaultMessage: "No, don’t include fetal sex",
  },
});

export interface FetalSexModalProps {
  onCloseDialog: () => void;
  orderUid: string;
  testUid: string;
  testDocumentUid: string;
  limsClinicId?: number;
}
const FetalSexModal: FC<FetalSexModalProps> = ({
  onCloseDialog,
  orderUid,
  testUid,
  testDocumentUid,
  limsClinicId,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const {
    handleUpdateFetalSexIsShown,
    updateFetalSexIsShownIsLoading,
  } = useContext(FetalSexContext);

  const handleFetalSexDialogReject = async () => {
    const res = await handleUpdateFetalSexIsShown(orderUid, testUid, false);

    if (!res?.documentUid) {
      onCloseDialog();
      return;
    }
    onCloseDialog();
    history.push(routes.testResultPdfPage(orderUid, testUid, res.documentUid));
    heap.track(HEAP_EVENTS.upp_fetalsex_hide_on_PDF, {
      lims_clinic_id: limsClinicId,
      order_uid: orderUid,
    });
  };

  const handleFetalSexDialogAccept = async () => {
    await handleUpdateFetalSexIsShown(orderUid, testUid, true);
    onCloseDialog();
    history.push(routes.testResultPdfPage(orderUid, testUid, testDocumentUid));
    heap.track(HEAP_EVENTS.upp_fetalsex_view_on_PDF, {
      lims_clinic_id: limsClinicId,
      order_uid: orderUid,
    });
  };

  return (
    <Dialog
      className="fetal-sex-modal"
      data-testid="fetal-sex-modal"
      title={intl.formatMessage(messages.fetalSexDialogTitle)}
      onClose={onCloseDialog}
      showCrossButton
      actions={
        <>
          <LoadingContext isLoading={updateFetalSexIsShownIsLoading}>
            <Button onClick={handleFetalSexDialogReject} outlined>
              {intl.formatMessage(messages.fetalSexDialogReject)}
            </Button>
            <Button onClick={handleFetalSexDialogAccept} raised>
              {intl.formatMessage(messages.fetalSexDialogAccept)}
            </Button>
          </LoadingContext>
        </>
      }
    >
      <div className="fetal-sex-modal__container">
        <p>{intl.formatMessage(messages.fetalSexDialogDesc)}</p>
      </div>
    </Dialog>
  );
};

export default FetalSexModal;
