import { HeapPHIDecorator } from "@app/components/analytics";
import React, { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button, DateInput, Form } from "@natera/form";
import {
  ClearErrors,
  GetValidationError,
  SetValidationError,
} from "@natera/platform/lib/hooks/useError";
import { FormField } from "@app/components";
import { getFormattedDOB, isPatientMinor, weekDayFormatter } from "@app/utils";
import { DobData } from "@app/pages/public/drawRequest/personalInformation/stepScreens/dobScreen";
import { StepperContext } from "@natera/stepper";
import { messages } from "@app/components/forms/publicMpForms";

interface PublicMpDobFormProps {
  dobData?: DobData;
  isLoading?: boolean;
  onSubmit: (dobData: DobData) => void;
  setValidationError: SetValidationError;
  getValidationError: GetValidationError;
  clearErrors: ClearErrors;
}

const PublicMpDobForm: FC<PublicMpDobFormProps> = ({
  dobData,
  isLoading,
  onSubmit,
  setValidationError,
  getValidationError,
  clearErrors,
}) => {
  const intl = useIntl();

  const { goBack } = React.useContext(StepperContext);

  const [profileFormData, setProfileFormData] = useState<FormData>();

  const getDobFormData = (formData: FormData) => {
    const dateOfBirth = formData.get("dateOfBirth")?.toString();

    return {
      dateOfBirth,
    };
  };

  const validateProfileFormData = async (formData: FormData) => {
    const profileFormData = getDobFormData(formData);

    const userForm = [
      { title: "dateOfBirth", value: profileFormData.dateOfBirth },
    ];

    for (const field of userForm) {
      if (!field.value) {
        return setValidationError(
          field.title,
          intl.formatMessage(messages.setupProfileFormRequired)
        );
      }
    }
    if (!profileFormData.dateOfBirth) {
      setValidationError(
        "dateOfBirth",
        intl.formatMessage(messages.setupProfileFormInvalidDateOfBirth)
      );

      return;
    }

    const dOB = new Date(getFormattedDOB(profileFormData.dateOfBirth));

    if (isPatientMinor(dOB)) {
      setValidationError(
        "dateOfBirth",
        intl.formatMessage(messages.setupProfileFormUnder18DateOfBirth)
      );

      return;
    }

    return profileFormData;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    clearErrors();

    const formData = new FormData(e.currentTarget);

    setProfileFormData(formData);

    const validatedProfileFormData = await validateProfileFormData(formData);

    if (validatedProfileFormData) {
      onSubmit({
        dateOfBirth: validatedProfileFormData.dateOfBirth,
      });
    }
  };

  useEffect(() => {
    if (profileFormData) {
      validateProfileFormData(profileFormData);
    }
  }, [intl.locale]);

  const handleBack = () => {
    goBack();
  };

  return (
    <Form
      className="public-mp-personal-information-form__container"
      noValidate
      onSubmit={handleSubmit}
    >
      <FormField
        label={intl.formatMessage(messages.setupProfileFormDateOfBirth)}
        required
        error={getValidationError("dateOfBirth")}
        withPadding
      >
        <HeapPHIDecorator protectAttr={["value"]}>
          <DateInput
            id="date"
            name="dateOfBirth"
            maxDate={new Date()}
            placeholder={intl.formatMessage(messages.dateOfBirthPlaceholder)}
            required
            outline
            floating
            formatWeekdayName={weekDayFormatter}
            defaultDate={
              dobData?.dateOfBirth
                ? new Date(getFormattedDOB(dobData.dateOfBirth))
                : undefined
            }
          />
        </HeapPHIDecorator>
      </FormField>

      <div className="stepper-form-buttons">
        <Button type="submit" raised loading={isLoading}>
          {intl.formatMessage(messages.setupProfileFormNext)}
        </Button>

        <Button onClick={handleBack} outlined loading={isLoading}>
          {intl.formatMessage(messages.publicMpPersonalInfoPageBackButton)}
        </Button>
      </div>
    </Form>
  );
};

export default PublicMpDobForm;
