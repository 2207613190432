import React, { FC, createContext, useState } from "react";
import {
  GuestValidationInput,
  SendPaymentChoiceInput,
  GetOrderEstimateResponse,
  GetSetupIntentResponse,
  ListActiveOrdersForPatient as ListActiveOrdersForPatientResponse,
  SurveyAnswerResponse,
  SendSurveyAnswerInput,
} from "@app/preBill/models";
import { usePreBillApiController } from "@app/preBill/hooks";

type PreBillAPIProviderState = {
  orderEstimate?: GetOrderEstimateResponse;
  customerSetupIntent?: GetSetupIntentResponse;
  activeOrders?: ListActiveOrdersForPatientResponse;
};

export interface PreBillAPIController {
  apiProviderState: PreBillAPIProviderState;
  getSetupIntent: () => Promise<GetSetupIntentResponse>;
  getOrderEstimate: () => Promise<GetOrderEstimateResponse>;
  listActiveOrdersForPatient: () => Promise<ListActiveOrdersForPatientResponse>;
  guestValidation: (input: GuestValidationInput) => Promise<void>;
  sendPaymentChoice: (input: SendPaymentChoiceInput) => Promise<void>;
  sendSurveyAnswer: (
    input: SendSurveyAnswerInput
  ) => Promise<SurveyAnswerResponse>;
}

export const PreBillAPIContext = createContext<PreBillAPIController>({
  apiProviderState: {},
  getSetupIntent: async () => Promise.reject(),
  getOrderEstimate: async () => Promise.reject(),
  listActiveOrdersForPatient: async () => Promise.reject(),
  guestValidation: async () => Promise.reject(),
  sendPaymentChoice: async () => Promise.reject(),
  sendSurveyAnswer: async () => Promise.reject(),
});

PreBillAPIContext.displayName = "PreBillAPIContext";

const PreBillAPIProvider: FC = ({ children }) => {
  const [apiProviderState, setApiProviderState] = useState<
    PreBillAPIProviderState
  >({});

  const {
    getSetupIntent,
    getOrderEstimate,
    listActiveOrdersForPatient,
    guestValidation,
    sendPaymentChoice,
    sendSurveyAnswer,
  } = usePreBillApiController();

  const handleGetSetupIntent = async (): Promise<GetSetupIntentResponse> => {
    try {
      const response = await getSetupIntent();
      setApiProviderState((prevState) => ({
        ...prevState,
        customerSetupIntent: response,
      }));
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleGetOrderEstimate = async (): Promise<
    GetOrderEstimateResponse
  > => {
    try {
      const response = await getOrderEstimate();
      setApiProviderState((prevState) => ({
        ...prevState,
        orderEstimate: response,
      }));
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleListActiveOrdersForPatient = async (): Promise<
    ListActiveOrdersForPatientResponse
  > => {
    try {
      const response = await listActiveOrdersForPatient();
      setApiProviderState((prevState) => ({
        ...prevState,
        activeOrders: response,
      }));
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleGuestValidation = async (
    data: GuestValidationInput
  ): Promise<void> => {
    try {
      await guestValidation(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSendPaymentChoice = async (
    data: SendPaymentChoiceInput
  ): Promise<void> => {
    try {
      await sendPaymentChoice(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSendSurveyAnswer = async (
    data: SendSurveyAnswerInput
  ): Promise<SurveyAnswerResponse> => {
    try {
      return await sendSurveyAnswer(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const value = {
    apiProviderState,
    getSetupIntent: handleGetSetupIntent,
    getOrderEstimate: handleGetOrderEstimate,
    listActiveOrdersForPatient: handleListActiveOrdersForPatient,
    guestValidation: handleGuestValidation,
    sendPaymentChoice: handleSendPaymentChoice,
    sendSurveyAnswer: handleSendSurveyAnswer,
  };

  return (
    <PreBillAPIContext.Provider value={value}>
      {children}
    </PreBillAPIContext.Provider>
  );
};

export default PreBillAPIProvider;
