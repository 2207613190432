import React, { useContext, useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { StepperContent } from "@app/components/ui/layout/stepperContent";
import { PreBillAPIContext } from "@app/provider";
import { getTestName } from "@app/preBill/utils/getTestName";
import StepperHeaderProgress from "@app/components/stepperHeaderProgress";
import "./priorAuthorizationNextSteps.scss";

const messages = defineMessages({
  ptpaEstimateTitle: {
    id: "ptpaEstimateTitle",
    defaultMessage: "Action required for your {testName} test",
  },
  insuranceStatusLabel: {
    id: "insuranceStatusLabel",
    defaultMessage: "Insurance status",
  },
  insuranceStatusValue: {
    id: "insuranceStatusValue",
    defaultMessage: "Additional Action Required",
  },
  insurancePlanLabel: {
    id: "insurancePlanLabel",
    defaultMessage: "Insurance plan",
  },
  whatsNextTitle: {
    id: "whatsNextTitle",
    defaultMessage: "What's next?",
  },
  whatsNextContent: {
    id: "whatsNextContent",
    defaultMessage:
      "Your insurance policy requires that you first speak with an independent genetic counselor to be considered for coverage for the test. Please expect a call from a genetic counselor.",
  },
});

const PriorAuthorizationNextSteps: React.FC = () => {
  const intl = useIntl();
  const {
    apiProviderState: { orderEstimate },
  } = useContext(PreBillAPIContext);
  const [testName, setTestName] = useState<string>("");

  useEffect(() => {
    setTestName(getTestName(orderEstimate?.testType, intl));
  }, [orderEstimate?.testType, intl]);

  return (
    <>
      <StepperHeaderProgress />
      <div className="ptpa-page">
        <div className="ptpa-estimate">
          <StepperContent
            title={intl.formatMessage(messages.ptpaEstimateTitle, {
              testName: testName,
            })}
          >
            <div className="ptpa-estimate__info-section">
              <div className="ptpa-estimate__info-item">
                <div className="ptpa-estimate__info-label">
                  {intl.formatMessage(messages.insuranceStatusLabel)}
                </div>
                <div className="ptpa-estimate__info-value">
                  {intl.formatMessage(messages.insuranceStatusValue)}
                </div>
              </div>
              <div className="ptpa-estimate__info-item">
                <div className="ptpa-estimate__info-label">
                  {intl.formatMessage(messages.insurancePlanLabel)}
                </div>
                <div className="ptpa-estimate__info-value">
                  {orderEstimate?.insuranceCompanyName}
                </div>
              </div>
            </div>
            <div className="ptpa-estimate__whats-next">
              <h3 className="ptpa-estimate__whats-next-title">
                {intl.formatMessage(messages.whatsNextTitle)}
              </h3>
              <p className="ptpa-estimate__whats-next-content">
                {intl.formatMessage(messages.whatsNextContent)}
              </p>
            </div>
          </StepperContent>
        </div>
      </div>
    </>
  );
};

export default PriorAuthorizationNextSteps;
